/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Navigate } from "react-router-dom";

/**
 * protects each url navigation that needs authentication
 * it checks if the user is authenticated and redirects
 * to login if user is not authentication
 * its a route guard for each routes
 */
export default function ProtectedRoute({children, guardCondition, ...rest }) {
  
  return (guardCondition? (children): <Navigate to={ '/login'} state={{ from: rest.location }} replace />);
}
