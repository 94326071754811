import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem, Select, Snackbar, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import * as yup from 'yup';
import { useFormik } from "formik";
import { createPages, sendSOHEmailByActivity, sendSOHToEmail, updatePages } from "../../Services/ApiManager";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";
import { getFiscalYearList } from "../../Services/locationRequest";
import moment from "moment";
import { CONSTANTS } from "../../Utils/Constants";
import { validateEmail } from "../../Utils/StringUtils";
import MuiAlert from '@mui/material/Alert';

const getValidationSchema = (values) => {
  return yup.object().shape({
    Report: yup.string().required('Report is required'),
    FiscalYear: values.Report === CONSTANTS.ReportTypes.YearlyDistribution
      ? yup.string().required('Fiscal Year is required')
      : yup.string(),
    EmailAddress: (values.Report === CONSTANTS.ReportTypes.NationalSOH || values.Report === CONSTANTS.ReportTypes.NationalSOHByActivity)
      ? yup.string().required('Email Address is required')
      : yup.string(),
    TransactionDate: (values.Report === CONSTANTS.ReportTypes.NationalSOH || values.Report === CONSTANTS.ReportTypes.NationalSOHByActivity)
      ? yup.date().required('Date is required')
      : yup.date(),
    Mode: yup.boolean(),
  });
};
const validationSchema = yup.object({
  Report: yup.string().required('Report is required'),
  FiscalYear: yup.string().required('Fiscal Year is required'),
  Mode: yup.boolean(),
});

const distributionData = {
    fy2017_RDF: '1VxC2NBy42enEWndGt2TZAQFxJdvWRKLu',
    fy2017_HP: '17Sg5gqPyoIXydxPURT-ja0I1LA7Zlwxn',
    fy2016_RDF: '1U7NWKFShIO9d7ifCcUfL8WF97UXvY6TS',
    fy2016_HP: '1hewc_g5zTnW2I5FvPPNTPcFhzhkEcNsW',
    fy2015_RDF: '1TMQw_gXvHeLCsdBXjZ0gZVPAO5vHo0qI',
    fy2015_HP: '12wLRapmWwZO3j596UVRZVDuRfRVw1WAz',
    fy2014_RDF: '1NtWnpd1xoM2x232-HsNAuPWJ0uNnajDF',
    fy2014_HP: '1cqNDttoP1xmXcdZYAWnPpgU4jnoCrCRZ',
    fy2013_RDF: '1YOEmNUkUTNoxvoxSDZzOmgF4BS37n32E',
    fy2013_HP: '1320aK1dxrngfwskTHzFuI5zgMXYRX9W7',
    fy2012_RDF: '1Y6q_fHPqmZ3jbJljr_AIpWaeyw4ITYhT',
    fy2012_HP: '1qqG2oA1_YygRg_5keS9SwnNtkfyZ509F',
    fy2011_RDF: '1PjU_wkXpj4j231E16rHp3xtmMG9d1rv8',
    fy2011_HP: '16u5vzxIRRyhQJfMCtyJDUn6FjUdyIn9n',
    fy2010_RDF: '1vdLRr__zl7EqybLuedhVIBEjGxKUaMwC',
    fy2010_HP: '1Hxt843DjU-9u6zRew2hFRL0MQ8SK-zb8',
    fy2009_RDF: '1i6EWRG3DOdwsNW5V8zdQ9eDfWaejsPER',
    fy2009_HP: '10BHn6Ql3lipYhFFUprrrKj_HWlXlKa7x',
    fy2008_RDF: '1tp6J35Kq82GQLJvlWueDzKYcmNXCGEcV',
    fy2008_HP: '1OCYGGxJnuW9t3MFNwiyHJ56ls_Zp7ezm'
};

const reportList = [{key: "Yearly Distribution Data", value: CONSTANTS.ReportTypes.YearlyDistribution}, {key: "National SOH Data", value: CONSTANTS.ReportTypes.NationalSOH}, {key: "National SOH Data by Activity", value: CONSTANTS.ReportTypes.NationalSOHByActivity}]

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const DownloadData = ({dialogOpen, setDialogOpen}) => {
  const [loading, setLoading] = useState(false);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openWait, setOpenWait] = useState(false);
  const waitTime = 7000 * 60;
  
  const formik = useFormik({
    initialValues: {
      Report: CONSTANTS.ReportTypes.YearlyDistribution,
      FiscalYear: '',
      EmailAddress: '',
      TransactionDate: moment().format('MM/DD/YYYY'),
      ShowAdditionalOptions: false,
      Mode: true,
    },
    validationSchema: () => getValidationSchema(formik.values),
    onSubmit: (values) =>  {
      if(values.Report === CONSTANTS.ReportTypes.YearlyDistribution) {
        const currentMode = values.Mode?'HP': 'RDF';
        window.open(`https://drive.google.com/uc?id=${distributionData['fy' + values.FiscalYear + '_' + currentMode]}&export=download`, '_blank');
      } else if(values.Report === CONSTANTS.ReportTypes.NationalSOH) {
        const currentMode = values.Mode?'HPR': 'RDF';
        if(validateSOHReportOptions()) {
          const filter = `emailAddress=${values.EmailAddress.replace(",", ";")}&modeCode=${currentMode}&transactionDate=${moment(values.TransactionDate).format("YYYY-MM-DD")}`
          if(allowSendReport(CONSTANTS.ReportTypes.NationalSOH)) {
            sendSOHToEmail(filter).then(() => {
              setOpenSuccess(true);
            });
          }
        } else {
          setOpenError(true);
        }
      } else if(values.Report === CONSTANTS.ReportTypes.NationalSOHByActivity) {
        const currentMode = values.Mode?'HPR': 'RDF';
        if(validateSOHReportOptions()) {
          const filter = `emailAddress=${values.EmailAddress.replace(",", ";")}&modeCode=${currentMode}&transactionDate=${moment(values.TransactionDate).format("YYYY-MM-DD")}`
          if(allowSendReport(CONSTANTS.ReportTypes.NationalSOHByActivity)) {
            sendSOHEmailByActivity(filter).then(() => {
              setOpenSuccess(true);
            });
          }
        } else {
          setOpenError(true);
        }
      }
    },
  });

  const allowSendReport = (reportType) => {
      let allow = false;
      const lastRequestTime = localStorage.getItem(reportType);
      if(lastRequestTime) {
          var currentDate = new Date();
          if(currentDate - (new Date(lastRequestTime)) > waitTime) {
              allow = true;
              localStorage.setItem(reportType, new Date());
          }
          else {
              setOpenWait(true);
          }
      }
      else {
          localStorage.setItem(reportType, new Date());
          allow = true;
      }
      return allow;
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  }

  const handleCloseError = () => {
    setOpenError(false);
  }

  const handleCloseWait = () => {
    setOpenWait(false);
  }

  const validateSOHReportOptions = () => {
    let isValidEmail = true;
    const addresses = formik.values.EmailAddress.split(",");
    addresses.forEach((address) => {
        if(!validateEmail(address)) isValidEmail = false;
    });
    return isValidEmail;
  }

  useEffect(() => {
    getFiscalYearList().then((res) => {
      res.data.Data.push({ 'FiscalYear': "2017", "IsCurrent": false, 'StartDate': "2024-07-07T00:00:00", 'EndDate': "2025-07-07T00:00:00" })
      setFiscalYearList(res.data.Data)
    })
  }, []);


  return (
    
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {'Download Data'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
            <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Report
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth required size="small">
                <InputLabel id="environment-select-label">Report Type</InputLabel>
                <Select
                  id="Report"
                  name="Report"
                  label="Report"
                  value={formik.values.Report}
                  onChange={formik.handleChange}
                  error={formik.touched.Report && Boolean(formik.errors.Report)}
                  helperText={formik.touched.Report && formik.errors.Report}
                >
                  {reportList.map((item) => (
                    <MenuItem value={item.value}>{item.key}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {formik.values.Report === CONSTANTS.ReportTypes.YearlyDistribution && (<>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700
                  }}
                >
                  Fiscal Year
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth required size="small">
                  <InputLabel id="fiscal-year-select-label">Fiscal Year</InputLabel>
                  <Select
                    id="FiscalYear"
                    name="FiscalYear"
                    label="Fiscal Year"
                    value={formik.values.FiscalYear}
                    onChange={formik.handleChange}
                    error={formik.touched.FiscalYear && Boolean(formik.errors.FiscalYear)}
                    helperText={formik.touched.FiscalYear && formik.errors.FiscalYear}
                  >
                    {fiscalYearList.map((item) => (
                      <MenuItem value={item.FiscalYear}>{item.FiscalYear}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>)}
            {(formik.values.Report === CONSTANTS.ReportTypes.NationalSOH || formik.values.Report === CONSTANTS.ReportTypes.NationalSOHByActivity) && (<>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700
                  }}
                >
                  Receipents
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id="EmailAddress"
                  name="EmailAddress"
                  label="enter receipents email separated by comma"
                  required
                  fullWidth
                  value={formik.values.EmailAddress}
                  onChange={formik.handleChange}
                  error={formik.touched.EmailAddress && Boolean(formik.errors.EmailAddress)}
                  helperText={formik.touched.EmailAddress && formik.errors.EmailAddress}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: 700
                  }}
                >
                  Report Date
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id="TransactionDate"
                  name="TransactionDate"
                  label="Transaction Date"
                  type="TransactionDate"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.TransactionDate}
                  onChange={formik.handleChange}
                  error={formik.touched.TransactionDate && Boolean(formik.errors.TransactionDate)}
                  helperText={formik.touched.TransactionDate && formik.errors.TransactionDate}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
            </>)}
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Mode
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControlLabel 
                sx={{
                    display: "flex",
                }}
                control={
                  <Switch
                    name="mode"
                    id="mode"
                    checked={formik.values.Mode}
                    onChange={(event) => {
                      formik.setFieldValue("Mode", event.target.checked);  // Update the formik value manually
                    }}
                  />
                } 
                label={formik.values.Mode ? 'Health Program' : 'RDF' } 
              />
            </Grid>
            <Grid item xs={12} sm={12}/>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => {
                    setDialogOpen(false);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button type="submit" 
                fullWidth 
                style={{ 
                  backgroundColor: '',
                }}
                disabled={loading}
                variant="contained" 
                color="primary">
                {
                  formik.values.Report === CONSTANTS.ReportTypes.YearlyDistribution? 'Download': 
                  (formik.values.Report === CONSTANTS.ReportTypes.NationalSOH || formik.values.Report === CONSTANTS.ReportTypes.NationalSOHByActivity)? 'Send Email': 'Download'
                }
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
          </Grid>
        </form>
        </DialogContent>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Your request is being processed. You will receive the result on the email address/es you provided (average wait time is 5 minutes).
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Enter valid email addresses separated by comma!
        </Alert>
      </Snackbar>
      <Snackbar open={openWait} autoHideDuration={6000} onClose={handleCloseWait}>
        <Alert onClose={handleCloseWait} severity="error" sx={{ width: '100%' }}>
          Please wait while your request is being processed.!
        </Alert>
      </Snackbar>
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;