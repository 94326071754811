import { Box, Modal } from "@mui/material";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";

export const CustomDetailsModal = ({title, open, handleClose, params, api, columns}) => {

  return (
    
    <Modal
            open={open}
            onClose={() => handleClose(false)}
        >
        <Box 
          className="modal-class"  
          style={{
            padding: "1rem",
            paddingTop: "3rem",
            position: "relative",
            width: "80%",
            height: "38rem",
            background: "white",
            }}
          >
            <CustomDataGrid
              component_config={{
                title: title,
                data_source_api: api,
                data_property: "Data",
                columns: columns,
                wgInfoId: 'wgManufacturerGrid',
                isSOH: false,
                width: "72vw",
                pageSize: 5,
                use_default_filter_params: false,
                defaultFilterParams: params,
              }}
            />
          </Box>
        </Modal>
  )
}