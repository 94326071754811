import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import * as yup from 'yup';
import { useFormik } from "formik";
import { createPages, updatePages } from "../../Services/ApiManager";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";

const validationSchema = yup.object({
  PageName: yup.string().required('Page Name is required'),
  PageDescription: yup.string().required('Page Description is required'),
  PageUrl: yup.string(),
  IsActive: yup.boolean(),
});

export const PageForm = ({dialogOpen, setDialogOpen, updating, setUpdating}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: updating?
    {
      PageName: updating.PageName,
      PageDescription: updating.PageDescription,
      PageUrl: updating.PageUrl,
      IsActive: updating.IsActive,
      PageID: updating.PageID,
    }:
    {
      PageName: '',
      PageDescription: '',
      PageUrl: '',
      IsActive: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      setLoading(true);
      updating?
      updatePages({...values})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)}):
      createPages({...values})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)});
    },
  });


  return (
    
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {updating?'Update Page': 'Add Page'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
            <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Page Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="PageName"
                name="PageName"
                label="PageName"
                required
                fullWidth
                value={formik.values.PageName}
                onChange={formik.handleChange}
                error={formik.touched.PageName && Boolean(formik.errors.PageName)}
                helperText={formik.touched.PageName && formik.errors.PageName}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Page Description
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="PageDescription"
                name="PageDescription"
                label="PageDescription"
                required
                fullWidth
                value={formik.values.PageDescription}
                onChange={formik.handleChange}
                error={formik.touched.PageDescription && Boolean(formik.errors.PageDescription)}
                helperText={formik.touched.PageDescription && formik.errors.PageDescription}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Page Url
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="PageUrl"
                name="PageUrl"
                label="PageUrl"
                required
                fullWidth
                value={formik.values.PageUrl}
                onChange={formik.handleChange}
                error={formik.touched.PageUrl && Boolean(formik.errors.PageUrl)}
                helperText={formik.touched.PageUrl && formik.errors.PageUrl}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel 
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
                control={
                <Switch
                  name="IsActive"
                  id="IsActive"
                  checked={formik.values.IsActive}
                  onChange={formik.handleChange} />} 
                  label="Is Active" />
            </Grid>
            <Grid item xs={12} sm={12}/>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => {
                    setDialogOpen(false);
                    setUpdating(null);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button type="submit" 
                fullWidth 
                style={{ 
                  backgroundColor: '',
                }}
                disabled={loading}
                variant="contained" 
                color="primary">
                {updating? 'Update': 'Create'}
                {loading &&
                (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                  <CustomLoadingIndicator />
                </span>)}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
          </Grid>
        </form>
        </DialogContent>
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;