import { create } from 'zustand'

const hiddenPrograms = ['BBS', 'COVD', 'TBReg', 'TBLab', 'VP', 'KHT', 'KRT', 'Es', 'Hema', 'Labs', 'CD', 'RD', 'SHI', 'BBE', 'Viral'];

export const useFanosStore = create((set) => ({
  options: [
    {
      label: "Program",
      image: "../../assets/vector/programs.svg",
      submenus: [],
    },
    {
      label: "Location",
      image: "../../assets/vector/locations.svg",
      submenus: [],
    },
    {
      label: "Finance",
      image: "../../assets/vector/covid-reports.png",
      submenus: [  
        {
          label: "Item Summary",
          image: "../../assets/vector/covid-reports.png",
        },
        { 
          label: "Item Price",
          image: "../../assets/vector/recovery.png" 
        },
      ],
    },
    {
      label: "Regulatory",
      image: "../../assets/vector/shipment.png",
      submenus: [
        {
          label: "eRIS",
          image: "../../assets/vector/shipment.png",
        },
        {
          label: "AEFI",
          image: "../../assets/vector/framework.png"
        },
      ]
    },
    {
      label: "Inbound Reports",
      image: "../../assets/vector/vital-report.png",
      submenus: [
        {
          label: "Miscellaneous Stock Report",
          image: "../../assets/vector/vital-report.png",
        },
        {
          label: "Inbound Report",
          image: "../../assets/vector/covid-reports.png",
        },
        {
          label: "LC CAD Expiry",
          image: "../../assets/vector/covid-reports.png",
        },
        {
          label: "Shipment Status",
          image: "../../assets/vector/purchase-order.png",
        },
        {
          label: "Procurement"
        }
      ]
    },  
    // {
    //   label: "ERP",
    //   submenus: [
    //     {
    //       label: "Transaction Report"
    //     }
    //   ]
    // },
    {
      label: "RDF",
      image: "../../assets/vector/price.png",
      submenus: [
        {
          label: "RDF",
          image: "../../assets/vector/price.png",
        },
      ]
    },
    
    {
      label: "Facility",
      image: "../../assets/vector/price.png",
      submenus: [
        {
          label: "Facility Dashboard",
          image: "../../assets/vector/price.png",
        },
        {
          label: "RRF",
          image: "../../assets/vector/price.png",
          link: "https://rrf.fanosonline.org",
        },
      ]
    },
    // {
    //   label: "COVID",
    //   image: "../../assets/vector/price.png",
    //   submenus: [
    //     {
    //       label: "Covid 19",
    //       image: "../../assets/vector/covid-reports.png",
    //     },
    //   ]
    // },
    // {
    //   label: "Public Reports",
    //   image: "../../assets/vector/price.png",
    //   submenus: [
    //     {
    //       label: "KPI",
    //       image: "../../assets/vector/kpi.png",
    //       link: "http://kpi.fanosonline.org/",
    //     },
    //     {
    //       label: "Product Availability",
    //       image: "../../assets/vector/covid-reports.png",
    //       link: "https://csm.fanosonline.org/product-availability",
    //     },
    //     {
    //       label: "New Arrival",
    //       image: "../../assets/vector/covid-reports.png",
    //       link: "http://arrivals.fanosonline.org/new-arrivals",
    //     },
    //     {
    //       label: "Local PO",
    //       image: "../../assets/vector/covid-reports.png",
    //     }
    //   ]
    // },
    {
      label: "Routine Report",
      image: "../../assets/vector/price.png",
      submenus: [
        {
          label: "Quarter Report",
          image: "../../assets/vector/kpi.png"
        },
        {
          label: "Hub Vital Report",
          image: "../../assets/vector/covid-reports.png",
        },
        {
          label: "Item Distribution",
          image: "../../assets/vector/covid-reports.png",
        },
        {
          label: "Medical Equipment Distribution",
          image: "../../assets/vector/covid-reports.png",
        },
      ]
    },
  ],
  manufacturerItemsModalOpen: false,
  modalManufacturer: null,
  countryItemsModalOpen: false,
  modalCountry: null,
  locationPendingModalOpen: false,
  modalLocationPending: null,
  locationGitModalOpen: false,
  modalLocationGit: null,
  locationInvoiceModalOpen: false,
  modalLocationInvoice: null,
  shiProductModalOpen: false,
  modalProduct: null,
  stockExpiryModalOpen: false,
  modalExpiry: null,
  stockQuantityModalOpen: false,
  modalQuantity: null,
  isPlacement: false,
  isLabSupply: false,
  showDownloadModal: false,
  stockItemModalOpen: false,
  issueTrendModalOpen: false,
  issueTrendModalData: {},
  modalItem: null,
  setAllPrograms: (programs) => set((state) => ({...state, options: 
      state.options.map((opt) => opt.label === "Program"? {...opt, submenus: programs.filter((prg) => !hiddenPrograms.includes(prg.ProgramCode)).sort(function(a, b){
          let x = a.ProgramName.toLowerCase();
          let y = b.ProgramName.toLowerCase();
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;}).map((d) => d = {...d, label: d.ProgramName})
       }: opt)})),
  setAllLocations: (locations) => set((state) => ({...state, options: state.options.map((opt) => opt.label === "Location"? {...opt, submenus: locations.map((d) => d = {...d, label: d.Environment})}: opt)})),
  setManufacturerItemsModalOpen: (manufacturerItemsModalOpen) => set((state) => ({...state, manufacturerItemsModalOpen})),
  setModalManufacturer: (modalManufacturer) => set((state) => ({...state, modalManufacturer})),
  setCountryItemsModalOpen: (countryItemsModalOpen) => set((state) => ({...state, countryItemsModalOpen})),
  setModalCountry: (modalCountry) => set((state) => ({...state, modalCountry})),
  setLocationPendingModalOpen: (locationPendingModalOpen) => set((state) => ({...state, locationPendingModalOpen})),
  setModalLocationPending: (modalLocationPending) => set((state) => ({...state, modalLocationPending})),
  setLocationGitModalOpen: (locationGitModalOpen) => set((state) => ({...state, locationGitModalOpen})),
  setModalLocationGit: (modalLocationGit) => set((state) => ({...state, modalLocationGit})),
  setLocationInvoiceModalOpen: (locationInvoiceModalOpen) => set((state) => ({...state, locationInvoiceModalOpen})),
  setModalLocationInvoice: (modalLocationInvoice) => set((state) => ({...state, modalLocationInvoice})),
  setShiProductModalOpen: (shiProductModalOpen) => set((state) => ({...state, shiProductModalOpen})),
  setModalProduct: (modalProduct) => set((state) => ({...state, modalProduct})),
  setStockExpiryModalOpen: (stockExpiryModalOpen) => set((state) => ({...state, stockExpiryModalOpen})),
  setModalExpiry: (modalExpiry) => set((state) => ({...state, modalExpiry})),
  setStockQuantityModalOpen: (stockQuantityModalOpen) => set((state) => ({...state, stockQuantityModalOpen})),
  setModalQuantity: (modalQuantity) => set((state) => ({...state, modalQuantity})),
  setIsPlacement: (isPlacement) => set((state) => ({...state, isPlacement})),
  setIsLabSupply: (isLabSupply) => set((state) => ({...state, isLabSupply})),
  setShowDownloadModal: (showDownloadModal) => set((state) => ({...state, showDownloadModal})),
  setStockItemModalOpen: (stockItemModalOpen) => set((state) => ({...state, stockItemModalOpen})),
  setIssueTrendModalOpen: (issueTrendModalOpen) => set((state) => ({...state, issueTrendModalOpen})),
  setModalItem: (modalItem) => set((state) => ({...state, modalItem})),
  setIssueTrendModalData: (issueTrendModalData) => set((state) => ({...state, issueTrendModalData}))
}))