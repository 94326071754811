import Axios from "axios";

const key = JSON.parse(localStorage.getItem("userInfo"));
let authkey;
if (key) {
  authkey = key.Key;
}
Axios.defaults.headers.common = { Authorization: `Basic ${authkey}` };

const { REACT_APP_API_ENDPOINT } = process.env;

export const getDistributionByCommodityType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/DistributionByCommodityType",
    params,
  });
  return response;
};
export const getDistributionByRegion = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/DistributionByRegion",
    params: {
      ModeCode: "HPR",
    },
  });
  return response;
};

export const getStockUtilization = async (modeCode, programCode, order) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/Nationalstockutilization",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: order,
    },
  });
  return response;
};

export const getSOHMOSAMCByHubByProgram = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/StockStatusStandardOnly",
    params: {
      ModeCode: "HPR",
      ProgramCode: "FP",
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getNearExpiryBreakdown = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/NearExpiryBreakDown",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getFillRateGauge = async (modeCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RQD_WebApi/FillRatesOrder",
    params: {
      ModeCode: modeCode,
    },
  });
  return response;
};
export const getDistributionByFacilityType = async (
  modeCode,
  programCode,
  params
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByFacilityType",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      ...params,
    },
  });
  return response;
};
export const getDistributionByOwnershipType = async (
  modeCode,
  programCode,
  params,
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByOwnershipType",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      ...params
    },
  });
  return response;
};
export const ItemProcurer = async (modeCode, programCode, params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/ItemProcurer",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      ...params
    },
  });
  return response;
};
export const CashVsCredit = async (programCode, year) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OID_WebApi/CreditAndCashIssue",
    params: {
      ModeCode: programCode,
      FiscalYear: year,
    },
  });
  return response;
};
export const getOrderFillRate = async (ModeCode, ProgramCode, params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/FR_WebApi/OrderFillRate",
    params: {
      ModeCode: ModeCode,
      ProgramCode: ProgramCode,
      ...params,
    },
  });
  return response;
};
export const getSupplierFillRate = async (
  ModeCode,
  ProgramCode,
  params,
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/FR_WebApi/SupplierFillRate",
    params: {
      ModeCode: ModeCode,
      ProgramCode: ProgramCode,
      ...params,
    },
  });
  return response;
};
export const ItemFunding = async (modeCode, programCode, params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/ItemFundingSourceAndProcurer",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      ...params
    },
  });
  return response;
};
export const getMOSShareBySite = async (modeCode, programCode, environment) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/MOSShareBySite",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
      EnvironmentGroupCode: "HUB",
    },
  });
  return response;
};
export const getHypotheticalMOSShareBySite = async (modeCode, programCode, environment) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/hypotheticalMOSShareBySite",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
      EnvironmentGroupCode: "HUB",
    },
  });
  return response;
};
export const getCenterPipeline = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POHRIHRCH_WebApi/CenterPipeline3",
    params: {
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getDistributionByFacilityTypePerItem = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByFacilityTypePerItem",
    params: {
      FiscalYear: 2016,
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getItemProcurerPerItem = async (modeCode, programCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/ItemProcurerPerItem",
    params: {
      FiscalYear: 2016,
      ModeCode: modeCode,
      ProgramCode: programCode,
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getQRDistributionByCommodityType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSADistributionByCommodityTypeOnlyStaging?EnvironmentCode",
    params: params,
  });
  return response;
};
export const getQRDistributionByRegionType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSADistributionByRegionStaging",
    params: params,
  });
  return response;
};
export const getQRDistributionByHealthFacility = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSADistributionByHealthFacilitiesStaging",
    params: params,
  });
  return response;
};
export const getQRDistributionByStockCommodityType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSASOHAmountByCommodityTypeStaging",
    params: params,
  });
  return response;
};
export const getQRDistributionByProgramType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSADistributionByProgramStaging?EnvironmentCode",
    params: params,
  });
  return response;
};
export const getQRNearExpiry = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSANearExpiryByCommodityType?EnvironmentCode",
    params: params,
  });
  return response;
};
export const getQRNearExpiryAmount = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSANearExpiryAmount",
    params: params,
  });
  return response;
};
export const getQRExpiredAmount = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PFSAQuarterReport/PFSAExpiredAmount",
    params: params,
  });
  return response;
};
export const getDistributionByEquipment = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/StagingReports/GF_CovidEquipmentDistrbutionSummary",
  });
  return response;
};
export const getDistributionByLocation = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/StagingReports/GF_CovidEquipmentDistrbutionSummary",
  });
  return response;
};
export const getTenderProcess = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Kaizen/TenderProcess",
  });
  return response;
};
export const getContractProcess = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Kaizen/ContractProcess",
  });
  return response;
};

export const getProcessTrends = async (type) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Kaizen/ProcessTrends?Type=" + type,
  });
  return response;
};

export const getProductManufacturers = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/BinCard_WebApi/ManufacturerSOH",
    params: {...params},
  });
  return response;
};

export const getProductUnits = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SS_UnitSOH",
    params: {...params},
  });
  return response;
};

export const getStockLocationByItem = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHbyIW",
    params: {...params},
  });
  return response;
};

export const getStockByAccount = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHbyIWACC",
    params: {...params},
  });
  return response;
};

export const getStockByActivity = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHbyIWAct",
    params: {...params},
  });
  return response;
};

const requests = [
  getDistributionByCommodityType,
  getDistributionByRegion,
  getStockUtilization,
  getNearExpiryBreakdown,
  getDistributionByFacilityType,
  getDistributionByOwnershipType,
  ItemProcurer,
  getOrderFillRate,
  getSupplierFillRate,
  ItemFunding,
  getMOSShareBySite,
  getHypotheticalMOSShareBySite,
  getCenterPipeline,
  getTenderProcess,
  getContractProcess,
  getProcessTrends
];

export default requests;
