import { Button, Dialog, DialogContent, DialogTitle, Grid, InputLabel, TextField } from "@mui/material";
import styled from "styled-components";
import CustomLoadingIndicator from "../../shared/Shared Utils/CustomLoadingIndicator";
import { saveNationalAMC } from "../../../Services/ApiManager";
import { useState } from "react";

export const EditAMC = ({dialogOpen, setDialogOpen, item, setItem }) => {
const [loading, setLoading] = useState(false);

const saveChange = () => {
    setLoading(true)
    saveNationalAMC([item]).then((res) => {
        setLoading(false)
        setDialogOpen(false)
        setItem(null)
    }).catch(() => {
        setLoading(false)
    })
}

return (
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {'Update AMC'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                <InputLabel
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 700
                    }}
                >
                    National AMC
                </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7}>
                <TextField
                    id="amc"
                    name="amc"
                    label="AMC"
                    fullWidth
                    value={item.NationalAMC}
                    onChange={(e) => setItem({...item, NationalAMC: e.target.value})}
                    size="small"
                    autoComplete="off"
                    variant="outlined"
                />
                </Grid>
                <Grid item xs={12} sm={6}/>
                <Grid item xs={12} sm={3}>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            setItem(null);
                        }}
                        style={{ 
                            color: '#12b3b7',
                            backgroundColor: '',
                        }}
                    >
                        Close
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="submit" 
                        fullWidth 
                        style={{ 
                            backgroundColor: '',
                        }}
                        disabled={loading}
                        onClick={saveChange}
                        variant="contained" 
                        color="primary">
                        Update
                        {loading &&
                        (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                        <CustomLoadingIndicator />
                        </span>)}
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
    </DialogWrapper>
  )
}

const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;