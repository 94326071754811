import React, { } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { Divider } from "@material-ui/core";
import DoughnutChart from "../../shared/Charts/Doughnut";

export default function RDFSideNav({ data }) {
  return (
    <div style={{ padding: "0 0.5rem 0 0.5rem" }}>
      <Divider />
      <SidebarMenu>
        <SidebarMenu.Header>
          {/* <SidebarMenu.Nav.Link>MOS</SidebarMenu.Nav.Link> */}
          <br />{" "}
          <SidebarMenu.Brand>
            <DoughnutChart
              data={data}
              label="RDF"
              fields="RDF"
              style={{
                padding: "0",
                position: "relative",
                maxWidth: "7vw",
                height: "10rem",
                margin: "auto",
              }}
            />
          </SidebarMenu.Brand>
          <Divider />
          <br />
        </SidebarMenu.Header>
      </SidebarMenu>
    </div>
  );
}
