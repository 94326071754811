import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { ERP_UsableStock_API, ERP_UsableStock_DETAIL_API } from "../../shared/Shared Utils/AppData";
import styled from "styled-components";
import { FormControlLabel, Checkbox } from "@mui/material";

const UsableStockReport = () => {
  const [showDetail, setShowDetail] = useState(false);
  
  let DISTRIBUTION_REPORT_COLUMN = [
    {
      field: "MaterialNumber",
      renderHeader: (params) => <strong>{"Material No"}</strong>,
      width: 150,
    },
    {
      field: "ItemSN",
      renderHeader: (params) => <strong>{"Item SN"}</strong>,
      width: 100,
    },
    {
      field: "MaterialDescription",
      renderHeader: (params) => <strong>{"Item"}</strong>,
      width: 300
    },
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      width: 100
    },
    {
      field: "CommodityType",
      renderHeader: (params) => <strong>{"Type"}</strong>,
      width: 150
    },
    {
      field: "BatchNumber",
      renderHeader: (params) => <strong>{"Batch No"}</strong>,
      width: 100
    },
    {
      field: "StorageLocation",
      renderHeader: (params) => <strong>{"Location code"}</strong>,
      width: 100
    },
    {
      field: "StorageLocationDescription",
      renderHeader: (params) => <strong>{"Location"}</strong>,
      width: 200
    },
    {
      field: "Plant",
      renderHeader: (params) => <strong>{"Plant code"}</strong>,
      width: 100
    },
    {
      field: "PlantName",
      renderHeader: (params) => <strong>{"Plant"}</strong>,
      width: 200
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 100,
      type: 'number'
    },
    {
      field: "SpecialStockIndicator",
      renderHeader: (params) => <strong>{"Indicator"}</strong>,
      width: 100
    }
  ];

  return (
    <Wrapper>
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
        <h2 className="grid-title">Usable Stock</h2>
          <FormControlLabel
            control={
              <Checkbox
                name={"show detail"}
                value={showDetail}
                onClick={ function(){ 
                  setShowDetail(!showDetail);
                } }
              />
            }
            label={"Show stock by batch number"}
          />
          { !showDetail && <CustomDataGrid
            component_config={{
              width: "94vw",
              data_source_api: ERP_UsableStock_API,
              data_property: "Data",
              columns: DISTRIBUTION_REPORT_COLUMN,
              wgInfoId: 'wgUsableStockReport',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          /> 
          }
           { showDetail && <CustomDataGrid
            component_config={{
              width: "94vw",
              data_source_api: ERP_UsableStock_DETAIL_API,
              data_property: "Data",
              columns: DISTRIBUTION_REPORT_COLUMN,
              wgInfoId: 'wgUsableStockReport',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          /> }
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default UsableStockReport;
