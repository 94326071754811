import { Box, Dialog, Modal } from "@mui/material";
import styled from "styled-components";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { MANUFACTURER_API } from "../../shared/Shared Utils/AppData";
import { MANUFACTURER_PARAM } from "../../shared/Shared Utils/DataTableConstants";
import { getShare } from "../../../Utils/NumberUtils";

const MANUFACTURER_COLUMN = [
    { field: "RowNumber", headerName: " ", width: 50 },
    {
      field: "Manufacturer",
      renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
      flex: 1.5,
    },
    {
      field: "Country",
      renderHeader: (params) => <strong>{"Country"}</strong>,
      flex: 1,
    },
    {
      field: "ProductCN",
      renderHeader: (params) => <strong>{"Item"}</strong>,
      flex: 1,
    },
    {
      field: "AmountBirr",
      renderHeader: (params) => <strong>{"Value (ETB)"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
    {
      field: "ReceivedbyAllRecipants",
      renderHeader: (params) => <strong>{"Share"}</strong>,
      flex: 1,
      valueGetter: getShare,
    },
  ];

export const ManufacturerItemsModal = ({open, handleClose, manufacturer, programCode}) => {

  return (
    
    <Modal
            open={open}
            onClose={() => handleClose(false)}
        >
        <Box 
          className="modal-class"  
          style={{
            padding: "1rem",
            paddingTop: "3rem",
            position: "relative",
            width: "80%",
            height: "38rem",
            background: "white",
            }}
          >
            <CustomDataGrid
              component_config={{
                title: "Manufacturer Items",
                data_source_api: MANUFACTURER_API,
                data_property: "Data",
                columns: MANUFACTURER_COLUMN,
                wgInfoId: 'wgManufacturerGrid',
                isSOH: false,
                width: "72vw",
                pageSize: 5,
                use_default_filter_params: false,
                defaultFilterParams: {...MANUFACTURER_PARAM, ManufacturerID: manufacturer, ProgramCode: programCode },
              }}
            />
          </Box>
        </Modal>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;