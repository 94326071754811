import React, { } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { Divider } from "@material-ui/core";
import DoughnutChart from "../shared/Charts/Doughnut";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "@mui/material";
import { FormatDecimal } from "../../Utils/NumberUtils";

const manufacturerColumns = [
    {
      field: "Manufacturer",
      renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const unitColumns = [
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const accountColumns = [
    {
      field: "Account",
      renderHeader: (params) => <strong>{"Account"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const activityColumns = [
    {
      field: "Activity",
      renderHeader: (params) => <strong>{"Activity"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

export default function ItemSideNav({ mosData, manufacturerData, unitData, accountData, activityData, modeCode, productSN }) {
  const history = useNavigate();

  return (
    <div style={{ padding: "0 0.5rem 0 0.5rem" }}>
      <Divider />
      <SidebarMenu>
        <SidebarMenu.Header>
          {/* <SidebarMenu.Nav.Link>MOS</SidebarMenu.Nav.Link> */}
          <br />{" "}
          <SidebarMenu.Brand>
            <DoughnutChart
              data={mosData}
              label="MOS"
              fields="MOS"
              style={{
                padding: "0",
                position: "relative",
                maxWidth: "10vw",
                margin: "auto"
              }}
              target={13}
            />
            {/* {manufacturerData.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {manufacturerColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {manufacturerData.map((row) => (
                  <tr>
                    {manufacturerColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>} */}
            {unitData.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {unitColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {unitData.map((row) => (
                  <tr>
                    {unitColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>}
            {/* {accountData.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {accountColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {accountData.map((row) => (
                  <tr>
                    {accountColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>} */}
            {/* {activityData.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {activityColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {activityData.map((row) => (
                  <tr>
                    {activityColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>} */}
            <Box display="flex" justifyContent="center">
              <BinButton
                onClick={() => {
                  history("/BinCard", {
                    state: {
                      ModeCode: modeCode,
                      ProductSN: productSN
                    },
                  });
                }}
              >
                Bin Card
              </BinButton>
            </Box>
          </SidebarMenu.Brand>
          <Divider />
          <br />
        </SidebarMenu.Header>
      </SidebarMenu>
    </div>
  );
}

const BinButton = styled.button`
  border: 1px solid transparent;
  height: 2rem;
  width: 6rem;
  font-size: 14px;
  background-color: cadetBlue;
  color: white;
  margin-right: 1rem;
  border-radius: 4px;
`

const TableHeader = styled.th`
  font-size: 13px;
`

const TableCell = styled.td`
  font-size: 13px;
  width: 55%;
`