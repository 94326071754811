
import React, { 
  useEffect, 
  useState,
  useRef
} from "react";

import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';

import { 
  Checkbox,
  FormControlLabel 
} from "@mui/material";

import { 
  DataGrid, 
  GridToolbar 
} from "@mui/x-data-grid";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// custom components
import DynamicSelect 
    from "../Inputs/DynamicSelect";
import CustomDatePicker 
    from "../Inputs/CustomDatePicker";
import GridLegend 
    from "../Plugins/GridLegend";
import CustomLoadingIndicator 
    from "../Plugins/CustomLoadingIndicator";
import CustomPieChart from "./CustomPieChart";
import WidgetInfo from "../Plugins/WidgetInfo";

import { IsEmpty } from "../Helpers/FormatHelper";
import { StringMatch, StringMatchStartsWith } from "../Helpers/StringHelper";
import { GetChartDataDefault } from "../Helpers/DataManipulationHelper";

import { getFanosData } 
    from "../Services/dashboardService";

// Icons
import { 
  FaExpandAlt,
  FaEdit
} from "react-icons/fa";

import "../Styles/Widget.css"

function DynamicDataGrid({ component_config, component_config_detail, chart_config }) {
  if(chart_config === undefined) chart_config = {};

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const defaultDensity = windowSize.current[0] > 500 ? "standard" : "comfortable";

  const [gridDataLocal, setGridDataLocal] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [chartData, setChartData] = useState([]);
  let dataApi = null;

  const [isLoading, setLoading] = useState(true);
  const dataGridFilterParams = component_config.defaultFilterParams;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ })
  
  const [componentConfigDetail, setComponentConfigDetail] = useState(
    component_config_detail
  );

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = (params) => {
    var filterParamsLocal = {
      ItemSN: params.row.SN,
      PurchaseOrderGuid: params.row.PurchaseOrderGuid,
    };
    setComponentConfigDetail({
      ...componentConfigDetail,
      defaultFilterParams: filterParamsLocal,
    });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [currentTab, setCurrentTab] = useState(null);
  function changeTab(tab, tab_data) {
    if(tab != currentTab) {
      setCurrentTab(tab);
      dataApi = component_config.tab_apis[tab];
      //component_config.setMemisStatusColumns(component_config.getColumnsFunction(tab));
      reload();
    }
  }

  if (component_config_detail) {
    component_config.columns[0].renderCell = (params) => {
      // show expand row icon by default
      // or use a fuction specified to determine visibility 
      let showExpandIcon = component_config_detail.show_detail !== undefined ?
          component_config_detail.show_detail(params.row)
          : true;
      if(showExpandIcon){
        return (
          <div>
            <FaExpandAlt
              className="expand-icon-2"
              title="show detail"
              onClick={() => handleOpen(params)}
            />
          </div>
        );
      }
    };
  }

  if (component_config.columns[0].type === 'link') {
    component_config.columns[0].renderCell = (params) => {
      if(true){
        return (
          <div>
            <FaEdit
              className="expand-icon-2"
              title="Edit User"
              onClick={() => component_config.callback_action( "Update Account",  params.row ) }
            />
          </div>
        );
      }
    };
  }

  component_config.columns.forEach(column => {
    if(column.headerName == "Item" && windowSize.current[0] < 500) {
      column.minWidth = 200;
      column.renderCell = (params) => {
        if(params.value.length > 25){
          return (
          <div className="flex-column" >
              <div title={params.value}> { params.value.substring(0, 25) }</div>
              <div title={params.value}> 
                { params.value.substring(25, params.value.length <= 50 ? params.value.length : 50) }
                { params.value.length > 50 ? "..." : "" }
              </div>
          </div>
          );
        }
        else{
            return (<div title={params.value}>{params.value}</div>);
        }
      };
    }
  });

  let [columns, setColumns] = useState(component_config.columns);
  let [editMode, setEditMode] = useState(false);;

  const handleChange = (param) => {
    dataGridFilterParams[param.paramName] = param.paramValue;
    reload();
  };

  const onChangeCheckbox = (event, value, config) => {
    const paramName = config.param_name;
    const valueFunction = config.value_function;
    const invert = config.invert;
    if(!valueFunction)
      if(invert)
        dataGridFilterParams[paramName] = !value;
      else
        dataGridFilterParams[paramName] = value;
    else if (valueFunction)
      dataGridFilterParams[paramName] = valueFunction(value);
    reload();
  };

  const onChangeDatePicker = (dates) => {
    Object.keys(dates).forEach(key => {
      dataGridFilterParams[key] = dates[key];
    });
    reload();
  };

  const filterGridData = (legend, active) => {
    if (active) {
      const filtered = gridDataLocal.filter((row) => {
        let statusCol = component_config.legend[0].statusCol
        return (
          legend.value 
              ? StringMatchStartsWith(row[statusCol], legend.value) 
              : StringMatch(row[statusCol], legend.label)
        );
      });
      setGridData(filtered);
    } else {
      setGridData(gridDataLocal);
    }
  };

  useEffect(() => {
    async function fetchPageData() {
      reload();
    }
    fetchPageData();
  }, []);

  function reload() {
    setColumnVisibility();
    updateParentFilters();
    setLoading(true);
    getFanosData(
      dataApi ? dataApi : component_config.data_source_api,
      component_config.use_default_filter_params
        ? component_config.defaultFilterParams
        : dataGridFilterParams,
      component_config.data_source,
      component_config.method,
      component_config.convert_params_to_formData,
      component_config.api_data
    ).then((data) => {
      var result = component_config.data_property
        ? data.data[component_config.data_property]
        : data.data;
      if (component_config.data_manipulator) {
        component_config.data_manipulator(result);
      }
      if (component_config.data_manipulator_2) {
        result = component_config.data_manipulator_2(result);
      }
      if(component_config.data_source == "MEMIS") {
        result = data.data.data[component_config.data_property]
      }
      generateRowIds(result);
      setGridData(result);
      setGridDataLocal(result.slice());
      setLoading(false);
      
      if(chart_config.chart_data_source_api) {
        getFanosData(
            chart_config.chart_data_source_api,
            component_config.use_default_filter_params
              ? component_config.defaultFilterParams
              : dataGridFilterParams
          ).then((data) => {
            var result2 = component_config.data_property
              ? data.data[component_config.data_property]
              : data.data;
            setChartData(result2);
          }
        )
      }
      else {
        setChartData(GetChartDataDefault(result, chart_config.data_aggregation_property));
      }
    });
  }

  function generateRowIds(data) {
    for (let index = 0; index < data.length; index++) {
      data[index].autoGeneratedId = index;
    }
  }

  function updateParentFilters() {
    component_config.select_filter_config.forEach((filter) => {
      var parentFilter = filter.parent_filter;
      if (parentFilter) {
        parentFilter.value = dataGridFilterParams[parentFilter.param_name];
      }
    });
  }

  // hide or show columns based on selected filters
  function setColumnVisibility() {
    component_config.columns.forEach(column => {
      if (column.filterParam) {
        let isVisible = column.visibleValues 
            ? column.visibleValues.includes(dataGridFilterParams[column.filterParam])
            : IsEmpty(dataGridFilterParams[column.filterParam]);
        setColumnVisibilityModel({ ...columnVisibilityModel, [column.field]: isVisible });
      }
    });
  }

  return (
    <div className="widget">
      <div className="flex-column widget-container widget-container-data-grid"
          style={ component_config.modal_view ? 
              { margin: 0, border: 0, padding: 0 } 
              : {} }>
        
        <div>
          <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
            <Box className="rs-d-container">
              <DynamicDataGrid component_config={componentConfigDetail} />
            </Box>
          </Modal>
        </div>

        <div className="rs-w-header">
          { component_config.title !== undefined && 
              <div className="flex-row">
                <div className="rs-w-title">
                  {component_config.title + " "} 
                  <WidgetInfo widget_config={{
                      title: component_config.title,
                      page: component_config.page
                    }}/>
                </div>
                { (userInfo?.UserTypes?.filter(userType => { 
                            return userType.UserType === "Super Admin"
                          })?.length > 0)
                            &&
                  <div>
                    <FaEdit 
                        className="expand-icon-2" 
                        onClick={() => setEditMode(!editMode) }/>
                  </div>
                }
                <div>{ isLoading && 
                <span style={{ position: "relative", bottom: "-1.5rem", marginLeft: "5px"}}>
                    <CustomLoadingIndicator component_config={{}} /> 
                  </span>}</div>
              </div> }
          { component_config.title !== undefined && 
            <div className="bottom-line">
              <div className="decorative-bar medium bg-blue-p"></div>
            </div>
          }
        </div>

        <div className="widget-toolbar">
          <div className="flex-row flex-wrap widget-toolbar-container-">          
            { component_config.date_picker && 
              <div className="tool-bar-item-container flex-container" 
                  style={{ marginTop: "0.5rem" }}>
                <div className="tool-bar-item tool-bar-item-1">
                  <CustomDatePicker
                    handle_change={ onChangeDatePicker }
                    component_config={ component_config.date_picker }
                  />
                </div>
              </div>  
            }
            {component_config.select_filter_config?.map(function (config) {
              return (
                <div className="toolbar-item">
                  <DynamicSelect
                    handle_change={ handleChange}
                    component_config={config}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-row flex-wrap widget-toolbar-container-">
            {component_config.checkbox_filter_config?.map(function (config) {
              return (
                <div className="tool-bar-item tool-bar-item-1  tool-bar-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={config.param_name}
                        onChange={ (e, value) => {
                          onChangeCheckbox(e, value, config) 
                        }}
                        defaultChecked={ config.default_checked }
                      />
                    }
                    label={ config.label }
                    title={ config.title }
                  />
                </div>
              );
            })}
          </div>
          { component_config.tabs && 
            <div className="tabs">
              <ul className="flex-row tab-ul-admin">
                { component_config.tabs.map(function (tab) {
                    return (<li className={`tab-li 
                        ${ tab == (currentTab == null ? component_config.active_tab : currentTab) 
                        ? 'tab-li-active' : null }`}>
                      <a onClick={() => changeTab(tab)}>{tab}</a>
                    </li>
                    );
                })}
              </ul>
            </div>
          }
          { !chart_config.show_chart && 
          <div className="flex-row flex-wrap widget-toolbar-container-" 
              style={{ marginTop: "0.5rem" }}>
            <GridLegend
              component_config={component_config}
              on_click_handler={filterGridData}
              class_name={"flex-row flex-wrap"}
            />
          </div>  
          }
        </div>

        <div className="grid-chart" style={{ opacity: isLoading ? "0.5" : "1" }}>
          { chart_config.show_chart && !IsEmpty(gridData) &&
          <div className="flex-row flex-center flex-item-center grid-chart-container">
            <div className="grid-chart-legend">
              <GridLegend
                component_config={component_config}
                on_click_handler={filterGridData}
                class_name={"flex-column flex-baseline"}
              />
            </div>
            <div className="grid-chart-body">
              <div>
                <CustomPieChart data={chartData} 
                    label={chart_config.data_label_property}
                    fields={[ chart_config.data_value_property, chart_config.data_label_property ]}
                    style={chart_config.style}
                    component_config={{ data_label_info : chart_config.data_label_info }}>
                </CustomPieChart>
              </div>
            </div>
          </div>
          }
        </div>

        { editMode && <div>
          <JSONInput
              id          = {'json_input_' + component_config.title.replace(' ', '')}
              placeholder = { columns }
              locale      = { locale }
              height      = '20rem'
              width      = '99%'
              onChange = { (data) => {
                console.log(data.jsObject);
                if(!data.error)
                  setColumns(data.jsObject);
              }}
          />
        </div>
        }

        <div 
            style={ component_config.modal_view ? 
                { margin: 0 } : 
                {} }>
          {/* <div className="widget-body-container flex-container"> */}
          <div className="widget-body">
            <DataGrid
              style={{
                background: "white",
                fontFamily: '"Open Sans" , "apple-system", "blinkmacsystemfont", "Segoe UI", "Helvetica Neue", "arial", "sans-serif"',
                opacity: isLoading ? "0.5" : "1",
                fontWeight: 400,
                color: "#212529",
                fontSize: "0.8rem",
                border: 'none'
              }}
              autoHeight
              rows={gridData}
              columns={columns}
              density={
                component_config.density ? component_config.density : defaultDensity
              }
              getCellClassName={(params) => {
                if (params.colDef.colorCodeStatusCol)
                  return params.row[params.colDef.colorCodeStatusCol]
                    ? params.row[params.colDef.colorCodeStatusCol]
                        .replace(" ", "")
                        .toLowerCase()
                    : "";
                return "";
              }}
              getRowId={(row) => row.autoGeneratedId}
              rowHeight={40}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[10, 20, 50]}
              pagination
              animateRows={true}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 600 },
                },
              }}
              disableDensitySelector
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              sx={{
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: '#f2f2f2',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  borderRight: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  borderRight: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#f9f9f9',
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicDataGrid;
