import React, { Component, useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import { MISCELLANEOUS_STOCK_API, PURCHASE_ORDER_API, SHIPMENT_DWELLING_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { getCommodityList, getProgramList, getShipmentList } from "../../../Services/ApiManager";
import { INVOICE_TYPE_SELECT_CONFIG, MODE_SELECT_CONFIG, PO_COMMODITY_TYPE_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { getGShortDate } from "../../../Utils/NumberUtils";
import { Box } from "@mui/material";

function getInvoiceType(params) {
  return `${params.row.InvoiceType.replace('Invoice Transportation ', '')}`;
}

function getTenderNumber(params) {
  return `${params.row.TenderNumber == 'bbbbbbbbbbb/40 request' ? '' : params.row.TenderNumber}`;
}

const PURCHASE_ORDER_COLUMN = [
  {
    field: "SN",
    renderHeader: (params) => <strong>{"SN"}</strong>,
    width: 100,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 300,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Activity",
    renderHeader: (params) => <strong>{"Activity"}</strong>,
    width: 100,
  },
  {
    field: "ProcurementRequestNo",
    renderHeader: (params) => <strong>{"Request No"}</strong>,
    width: 130,
  },
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO No"}</strong>,
    width: 100,
  },
  {
    field: "TenderNumber",
    renderHeader: (params) => <strong>{"Tender Number"}</strong>,
    width: 120,
    valueGetter: getTenderNumber,
  },
  {
    field: "RequestedQuantity",
    renderHeader: (params) => <strong>{"Requested Quantity"}</strong>,
    width: 130,
    type: 'decimalZero',
  },
  {
    field: "POQuantity",
    renderHeader: (params) => <strong>{"PO Quantity"}</strong>,
    width: 100,
  },
  {
    field: "InvoicedQuantity",
    renderHeader: (params) => <strong>{"Invoiced Quantity"}</strong>,
    width: 130,
  },
  {
    field: "Status",
    renderHeader: (params) => <strong>{"Status"}</strong>,
    width: 100,
  },  
];


const PURCHASE_ORDER_PARAM = {
  CommodityTypeCode: "PHAR",
  ModeCode: "HPR",
};

const SHIPMENT_DWELLING_COLUMN = [
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO No"}</strong>,
    flex: 0.8,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 2.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 0.7,
  },
  {
    field: "InvoiceNumber",
    renderHeader: (params) => <strong>{"Invoice No"}</strong>,
    flex: 1,
  },
  {
    field: "InvoiceOrder",
    renderHeader: (params) => <strong>{"Invoice Order"}</strong>,
    flex: 0.8,
  },
  {
    field: "InvoicedQuantity",
    renderHeader: (params) => <strong>{"Invoiced Quantity"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "WayBillNumber",
    renderHeader: (params) => <strong>{"Way Bill No"}</strong>,
    flex: 1,
  },
  {
    field: "ShipmentOfficer",
    renderHeader: (params) => <strong>{"Shipment Officer"}</strong>,
    flex: 1,
  },
  {
    field: "PortArrivalDate",
    renderHeader: (params) => <strong>{"Port Arrival Date"}</strong>,
    flex: 1,
    valueFormatter: getGShortDate,
  },
  {
    field: "DwellingTime",
    renderHeader: (params) => <strong>{"Dwelling Time (days)"}</strong>,
    flex: 1,
    renderCell: (params) => (<Box style={{width: "100%", height: "100%", color: "white", display: "flex", alignItems: "center", paddingLeft: "5px", backgroundColor: (params.row.InvoiceType === "Invoice Transportation by Air" && params.row.DwellingTime <= 5) || (params.row.InvoiceType === "Invoice Transportation by Sea" && params.row.DwellingTime <= 10) ? "#99d594": (params.row.InvoiceType === "Invoice Transportation by Air" && params.row.DwellingTime <= 10) || (params.row.InvoiceType === "Invoice Transportation by Sea" && params.row.DwellingTime <= 15) ? "#fc8d59": (params.row.InvoiceType === "Invoice Transportation by Air") || (params.row.InvoiceType === "Invoice Transportation by Sea") ? "#d53e4f": "white" }}>{params.row.DwellingTime}</Box>)
  },  
];


const SHIPMENT_DWELLING_PARAM = {
};


const InboundReport = () => {
  const [shipmentFilterConfigs, setShipmentFilterConfigs] = useState([INVOICE_TYPE_SELECT_CONFIG]);
  const [procurerList, setProcurerList] = useState([{ label:'-- Procurer --', value:'' }]);
  const [donorList, setDonorList] = useState([{ label:'-- Donor --', value:'' }]);

  useEffect(() => {
    getShipmentList().then((data) => {
      const donorOptions = [];
      const uniqueDonors = data.data.Data.reduce(function (donors, row) {
        if(!donorOptions.includes(row.Donor)){
          donors.push({ value: row.Donor, label: row.Donor });
          donorOptions.push(row.Donor);
        }
        return donors;
      }, []);
      const procurerOptions = [];
      const uniqueProcurers = data.data.Data.reduce(function (procurers, row) {
        if(!procurerOptions.includes(row.Procurer)){
          procurers.push({ value: row.Procurer, label: row.Procurer });
          procurerOptions.push(row.Procurer);
        }
        return procurers;
      }, []);
      setShipmentFilterConfigs(
        [...shipmentFilterConfigs, 
          {
            hard_code_options: [...donorList, ...uniqueDonors],
            param_name: "Donor",
          },
          {
            hard_code_options: [...procurerList, ...uniqueProcurers],
            param_name: "Procurer",
          }
        ])
    });
  }, []);
  
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Inbound Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "94vw",
              title: "Shipment Dwelling Time",
              data_source_api: SHIPMENT_DWELLING_API,
              data_property: "Data",
              columns: SHIPMENT_DWELLING_COLUMN,
              isSOH: false,
              isShipmentDwelling: true,
              wgInfoId: 'wgShipmentDwellTimeReport',
              use_default_filter_params: false,
              local_filter_config: shipmentFilterConfigs,
              defaultFilterParams: {},
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "94vw",
              title: "Purchase Order Follow-Up",
              data_source_api: PURCHASE_ORDER_API,
              data_property: "Data",
              columns: PURCHASE_ORDER_COLUMN,
              isSOH: false,
              wgInfoId: 'wgProcurementLifeCycleTrace',
              use_default_filter_params: false,
              select_filter_config: [
                MODE_SELECT_CONFIG,
                PO_COMMODITY_TYPE_SELECT_CONFIG,
              ],
              defaultFilterParams: PURCHASE_ORDER_PARAM,
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default InboundReport;
