// import {
//   DefaultAmountFormatter,
//   DefaultDateFormatter,

import { Box, Button, Tooltip, styled, tooltipClasses } from "@mui/material";
import moment from "moment";
import { FaExpandAlt } from "react-icons/fa";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import { FormatDecimal, getShare } from "../../../Utils/NumberUtils";
import { ProgressBar } from "react-bootstrap";
import { FiExternalLink } from "react-icons/fi";
import {toEthiopian} from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";

import { useFanosStore } from "../../shared/Shared Utils/store";

// } from "../../Helpers/FormatHelper.js";'
const fanosEnvironmentList = "/EN_WebApi/ByEnvironmentCode?EnvironmentGroupCode=hub";
export const fanosProgramList = "/SS_WebApi/ProgramList";
const fanosProcurerList = "/SS_WebApi/ProcurerList";
const fanosYearList = "/LookUp/FiscalYearList";
const daguRegionList = "/Lookup/GetAdministrativeUnits/4089";
const daguInstitutionList = "/Lookup/GetInstitutions/0/0";
const fanosVaccineList = "/AEFI_WebApi/VaccinesList";
const fanosFacilityList = "/eRIS_WebApi/FacilityTypes";
const fanosApplicationList = "/eRIS_WebApi/ApplicationTypes";
const fanosProductList = "/eRIS_WebApi/ProductTypes";
const fanosCommodityList = "/CT_WebApi/CommodityList";
const fanosNationalProgramList = "/RRFSSNDPPLN_WebApi/MOHRRFProgramsList";
const fanosPlacementCategoryList = "/RRFSSNDPPLN_WebApi/PlacementCategory";

function getReceivedDate(params) {
  return `${moment(params.row.FullDate).format("MMMM Do, YYYY")}`;
}
function getDate(rawDate) {
  return `${moment(rawDate).format("MMMM Do, YYYY")}`;
}

function getShortDate(params) {
  return `${moment(params.row.IssueDate).format("MMM Do, YYYY")}`;
}

function getPOShortDate(params) {
  return `${moment(params.row.purchaseOrderDate).format("MMM Do, YYYY")}`;
}

function getShipped(params) {
  return `${FormatDecimal(params.row.QuantityInvoiced)} (${FormatDecimal((100 * params.row.QuantityInvoiced)/params.row.QuantityPurchaseOrder)}%)`;
}

function getItemSOH(params) {
  return (params.row.Environment === 'Home Office'?'Center': params.row.Environment.replace('Branch', '').replace('PFSA', '').replace('Hub', 'H'));
}

function getRegionZoneWoreda(params) {
  return `${params.row.Region} - ${params.row.Zone} - ${params.row.Woreda}`;
}

export const STOCK_ON_HAND_DEFAULT_PARAM = {
  ModeCode: "HPR",
  EnvironmentGroupCode: "HUB",
  OrderBy: "Environment ASC",
};
export const STOCK_ON_HAND_ITEM__PARAM = {
  ModeCode: "HPR",
  EnvironmentGroupCode: "HUB",
  OrderBy: "Environment",
};
export const STOCK_STATUS_DEFAULT_PARAM = {
  ModeCode: "HPR",
  EnvironmentTypeCode: "PFSAH",
  OrderBy: "ProductCN",
};
export const RDF_STOCK_STATUS_DEFAULT_PARAM = {
  ModeCode: "RDF",
  TransactionDate: moment().format("MM/DD/YYYY"),
  OrderBy: "ProductCN",
};
export const SHI_ITEM_DEFAULT_PARAM = {
  ModeCode: "HPR",
  OrderBy: "Item,Unit",
  State: "home",
};
export const SHI_MODAL_DEFAULT_PARAM = {
  OrderBy: "Item,Unit",
  State: "home",
};
export const MANUFACTURER_PARAM = {
  ModeCode: "HPR",
  FiscalYear: 2016,
  OrderBy: "Manufacturer",
};
export const MANUFACTURER_LLIN_PARAM = {
  ModeCode: "HPR",
};
export const PROGRAM_PARAM = {
  ModeCode: "HPR",
  FiscalYear: 2016,
};
export const RECEIVED_PARAM = {
  ModeCode: "HPR",
  EnvironmentCode: "CNPH",
};
export const CENTER_TO_HUB_DEFAULT_PARAM = {
  ModeCode: "HPR",
};
export const MODE_CODE_DEFAULT_PARAM = {
  ModeCode: "HPR",
};
export const MODE_CODE_DEFAULT_PARAM_PAGE_SIZE = {
  ModeCode: "HPR", PageSize: 100, Page: 1
};
export const MODE_ENVIRONMENT_DEFAULT_PARAM = {
  ModeCode: "HPR",
  EnvironmentCode: "CNPH",
};
export const DEFAULT_ARGS = {
  isValid: true,
};
export const COMMODITY_DISTRIBUTION_DEFAULT_PARAM = {
  ModeCode: "rdf",
};
export const QR_REPORT_DEFAULT_PARAM = {
  ModeCode: 'HPR',
};
export const QR_COMMODITY_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  EnvironmentCode: null,
  CostType: 'UnitPrice'
};
export const QR_REGION_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  EnvironmentCode: null,
  CostType: 'UnitPrice',
};
export const QR_HEALTH_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  EnvironmentCode: null,
  chartType: 'byAmount',
  CostType: 'UnitPrice'
};
export const QR_STOCK_COMMODITY_DEFAULT_PARAM = {
  ModeCode: 'HPR',
};
export const QR_PROGRAM_TYPE_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  EnvironmentCode: null,
  CostType: 'UnitPrice'
};
export const QR_NEAR_EXPIRY_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  EnvironmentCode: null,
  Type: '12',
};
export const QR_NEAR_EXPIRY_AMOUNT_DEFAULT_PARAM = {
  ModeCode: 'HPR',
  Type: '6',
};
export const QR_EXPIRED_AMOUNT_DEFAULT_PARAM = {
  ModeCode: 'HPR',
};
export const PAGINATION_DEFAULT_PARAM = {
  Page: '1',
  PageSize: '10',
};
export const DAYS_OF_STOCK_PARAMS = {
  ModeCode: 'HPR',
  EnvironmentGroupCode: "HUB",
};

export const ENVIRONMENT_SELECT_CONFIG = {
  id: fanosEnvironmentList,
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentCode",
  place_holder: "All",
  param_name: "EnvironmentCode",
};

export const HUB_ENVIRONMENT_SELECT_CONFIG = {
  id: fanosEnvironmentList,
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentCode",
  place_holder: "CNPH",
  param_name: "EnvironmentCode",
};

export const HUB_VITAL_ENVIRONMENT_SELECT_CONFIG = {
  id: fanosEnvironmentList,
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentCode",
  place_holder: "CNPH",
  show_all: true,
  param_name: "EnvironmentCode",
};

export const TYPE_SELECT_CONFIG = {
  id: "TYPE_SELECT_CONFIG",
  hard_code_options: [{label:'Less Than 6',value:'6' },{label:'Less Than 12',value:'12' }],
  place_holder: "6",
  param_name: "Type",
};

export const COST_SELECT_CONFIG = {
  id: "COST_SELECT_CONFIG",
  hard_code_options: [{label:'',value:'' }, {label:'Cost',value:'UnitCost' },{label:'Price',value:'UnitPrice' }],
  param_name: "CostType",
};

export const PROGRAM_SELECT_CONFIG = {
  id: fanosProgramList,
  api_url: fanosProgramList,
  options_data_property: "Data",
  label_property: "ProgramName",
  value_property: "ProgramCode",
  place_holder: "FP",
  param_name: "ProgramCode",
};

export const REGION_SELECT_CONFIG = {
  id: daguRegionList,
  api_url: daguRegionList,
  options_data_property: "model",
  label_property: "name",
  value_property: "id",
  data_source: "dagu",
  place_holder: "--Region--",
  param_name: "regionId",
};

export const INSTITUTION_SELECT_CONFIG = {
  id: daguInstitutionList,
  api_url: daguInstitutionList,
  options_data_property: "model",
  label_property: "name",
  value_property: "id",
  data_source: "dagu",
  place_holder: "--Facility--",
  param_name: "institutionId",
};

export const YEAR_SELECT_CONFIG = {
  id: fanosYearList,
  api_url: fanosYearList,
  options_data_property: "Data",
  label_property: "FiscalYear",
  value_property: "FiscalYear",
  place_holder: 2016,
  param_name: "FiscalYear",
  reverse: true,
  width: "4rem",
};

export const LOCAL_PO_YEAR_SELECT_CONFIG = {
  id: fanosYearList,
  api_url: fanosYearList,
  options_data_property: "Data",
  label_property: "FiscalYear",
  value_property: "FiscalYear",
  place_holder: "2012",
  param_name: "FiscalYear",
  reverse: true,
  width: "4rem",
};

export const ITEM_ACCOUNT_SELECT_CONFIG = {
  id: "ITEM_ACCOUNT_SELECT_CONFIG",
  hard_code_options: [{ value: "", label: 'HUB' }, { value: 'cnph', label: 'CENTER' }],
  place_holder: "HUB",
  param_name: "EnvironmentCode",
};

export const ACCOUNT_SELECT_CONFIG = {
  id: "ACCOUNT_SELECT_CONFIG",
  hard_code_options: [{label:'--Account--',value:'' }, { value: 'HPR', label: 'Health Program' }, { value: 'RDF', label: 'RDF' }],
  param_name: "accountCode",
};

export const ENVIRONMENT_GROUP_CODE = {
  id: "ENVIRONMENT_GROUP_CODE",
  hard_code_options: [{ value: 'HUB', label: 'HUB' }, { value: 'CENTER', label: 'CENTER' }],
  place_holder: "HUB",
  param_name: "EnvironmentGroupCode",
};

export const COMMODITY_TYPE_SELECT_CONFIG = {
  id: "COMMODITY_TYPE_SELECT_CONFIG",
  hard_code_options: [{label:'--Commodity Type--',value:'' }, { label: 'Chemicals and Reagents', value: 'Chemicals and Reagents' }, { label: 'Pharmaceuticals', value: 'Pharmaceuticals' } , { label: 'Medical Equipments', value: 'Medical Equipments' }, { label: 'Medical Supplies', value: 'Medical Supplies' }],
  param_name: "commodityType",
};

export const INVOICE_TO_GNRF_SELECT_CONFIG = {
  id: "INVOICE_TO_GNRF_SELECT_CONFIG",
  hard_code_options: [{label: 'All', value: ""}, {label: '< 10 Days', value: "10"}, {label: '< 20 Days', value: '20'}, {label: '< 30 Days', value: '30'}, {label: '< 60 Days', value: '60'}, {label: '> 60 Days', value: '70'}],
  param_name: "Gap",
};

export const STATE_SELECT_CONFIG = {
  id: "STATE_SELECT_CONFIG",
  hard_code_options: [{label: 'All', value: ""}, {label: 'In Progress', value: 'InProgress'}],
  place_holder: "InProgress",
  param_name: "State",
};

export const QUARTER_SELECT_CONFIG = {
  id: "QUARTER_SELECT_CONFIG",
  hard_code_options: [{ label:'All', value: null }, { label:'Q1', value: 'Q1' },{ label:'Q2', value: 'Q2' }, { label:'Q3', value: 'Q3' }, { label:'Q4', value: 'Q4' }],
  place_holder: "Q1",
  param_name: "Quarter",
};

export const PROCURER_SELECT_CONFIG = {
  id: fanosProcurerList,
  api_url: fanosProcurerList,
  options_data_property: "Data",
  label_property: "Name",
  value_property: "Code",
  place_holder: "PFSA",
  param_name: "ProcurerCode",
};

export const PO_PROCURER_SELECT_CONFIG = {
  id: fanosProcurerList,
  api_url: fanosProcurerList,
  options_data_property: "Data",
  label_property: "Name",
  value_property: "Code",
  place_holder: "UNF",
  param_name: "ProcurerCode",
};

export const QR_COST_SELECT_CONFIG = {
  id: "QR_COST_SELECT_CONFIG",
  hard_code_options: [{label: '', value: ""}, {label: 'Cost', value: "UnitCost"}, {label: 'Price', value: 'UnitPrice'},],
  param_name: "CostType",
};

export const QR_CHART_TYPE_SELECT_CONFIG = {
  id: "QR_CHART_TYPE_SELECT_CONFIG",
  hard_code_options: [{label:'',value:'' }, {label:'Count',value:'byCount' },{label:'Amount',value:'byAmount' }],
  param_name: "chartType",
};

export const QR_TYPE_SELECT_CONFIG = {
  id: "QR_TYPE_SELECT_CONFIG",
  hard_code_options: [{label:'',value:'' }, {label:'< 3 Month',value:3 }, {label:'< 6 Month',value:6 }, {label:'< 12 Month',value:12 }],
  param_name: "Type",
};

export const QR_TYPE_AMOUNT_SELECT_CONFIG = {
  id: "QR_TYPE_AMOUNT_SELECT_CONFIG",
  hard_code_options: [{label:'',value:'' }, {label:'Less Than 6',value:6 }, {label:'Less Than 12',value:12 }],
  param_name: "Type",
};

export const INVOICE_TYPE_SELECT_CONFIG = {
  id: "INVOICE_TYPE_SELECT_CONFIG",
  hard_code_options: [{label: '-- Invoice Type --', value: null }, { label: 'By Air', value: 'Invoice Transportation by Air' }, { label: 'By Sea', value: 'Invoice Transportation by Sea' }],
  param_name: "InvoiceType",
};

export const PO_COMMODITY_TYPE_SELECT_CONFIG = {
  id: fanosCommodityList,
  api_url: fanosCommodityList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "CommodityTypeCode",
  place_holder: "PHAR",
  show_all: true,
  param_name: "CommodityTypeCode",
};

export const NATIONAL_COMMODITY_TYPE_SELECT_CONFIG = {
  id: fanosCommodityList,
  api_url: fanosCommodityList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "CommodityTypeCode",
  place_holder: "-- Commodity Type --",
  param_name: "CommodityTypeCode",
};

export const RDF_COMMODITY_TYPE_SELECT_CONFIG = {
  id: fanosCommodityList,
  api_url: fanosCommodityList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "CommodityTypeCode",
  place_holder: "All",
  param_name: "CommodityTypeCode",
};

export const NATIONAL_PROGRAM_SELECT_CONFIG = {
  id: fanosNationalProgramList,
  api_url: fanosNationalProgramList,
  options_data_property: "Data",
  label_property: "Program",
  value_property: "ProgramCode",
  place_holder: "-- Program --",
  param_name: "ProgramCode",
};

export const PLACEMENT_CATEGORY_SELECT_CONFIG = {
  id: fanosPlacementCategoryList,
  api_url: fanosPlacementCategoryList,
  options_data_property: "Data",
  label_property: "PlacementCategory",
  value_property: "PlacementCategory",
  place_holder: "-- Placement Category --",
  param_name: "PlacementCategory",
};

export const MODE_SELECT_CONFIG = {
  id: "MODE_SELECT_CONFIG",
  hard_code_options: [{label:'All',value:null }, { value: 'HPR', label: 'Health Program' }, { value: 'RDF', label: 'RDF' }],
  place_holder: "HPR",
  param_name: "ModeCode",
};

export const NATIONAL_MODE_SELECT_CONFIG = {
  id: "NATIONAL_MODE_SELECT_CONFIG",
  hard_code_options: [{ value: 'RDF', label: 'RDF' }, { value: 'HPR', label: 'Health Program' }],
  place_holder: "RDF",
  param_name: "ModeCode",
};

export const COVID_MODE_SELECT_CONFIG = {
  id: "COVID_MODE_SELECT_CONFIG",
  hard_code_options: [{ value: 'HPR', label: 'Health Program' }, { value: 'RDF', label: 'RDF' }],
  param_name: "ModeCode",
};

export const COVID_PROGRAM_SELECT_CONFIG = {
  id: "COVID_PROGRAM_SELECT_CONFIG",
  hard_code_options: [{ value: null, label: 'All' }, { value: 'COVID', label: 'COVID' }],
  place_holder: "HPR",
  param_name: "ActivityCode",
};

export const VACCINE_TYPE_SELECT_CONFIG = {
  id: fanosVaccineList,
  api_url: fanosVaccineList,
  options_data_property: "Data",
  label_property: "Name",
  value_property: "Id",
  place_holder: "All",
  param_name: "VaccineID",
};

export const FACILITY_TYPE_SELECT_CONFIG = {
  id: fanosFacilityList,
  api_url: fanosFacilityList,
  options_data_property: "Data",
  label_property: "FacilityType",
  value_property: "FacilityType",
  place_holder: "All",
  param_name: "FacilityType",
};

export const APPLICATION_TYPE_SELECT_CONFIG = {
  id: fanosApplicationList,
  api_url: fanosApplicationList,
  options_data_property: "Data",
  label_property: "ApplicationType",
  value_property: "ApplicationType",
  place_holder: "All",
  param_name: "ApplicationType",
};

export const PRODUCT_TYPE_SELECT_CONFIG = {
  id: fanosProductList,
  api_url: fanosProductList,
  options_data_property: "Data",
  label_property: "ProductType",
  value_property: "ProductType",
  place_holder: "All",
  param_name: "ProductType",
};


export const manufacturerColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "Manufacturer", headerName: "Manufacturer", width: 250 },
  { field: "Country", headerName: "Country", width: 250 },
  { field: "ProductCN", headerName: "Item", width: 250 },
  { field: "AmountBirr", headerName: "Value (ETB)	", width: 250 },
  { field: "QuantityReceived", headerName: "Quantity", width: 250 },
  { field: "ReceivedbyAllRecipants", headerName: "Share", width: 250 },
];

export const stockOnHandColumn = [
  { field: "RowNumber", headerName: " ", width: 15 },
  { field: "Environment", headerName: "Site", width: 150 },
  { field: "ProductSN", headerName: "EC.", width: 150 },
  { field: "Imp-Nxt", headerName: "Imp-Nxt", width: 150 },
  { field: "MCn", headerName: "MCn", width: 150 },
  { field: "COC.", headerName: "COC.", width: 150 },
  { field: "IUD.", headerName: "IUD.", width: 150 },
  { field: "POP.", headerName: "POP.", width: 150 },
  { field: "Medroxy", headerName: "Medroxy", width: 150 },
  { field: "Jadelle1", headerName: "Jadelle1", width: 150 },
  { field: "PP-IUCD 1", headerName: "PP-IUCD 1", width: 150 },
];

export const stockStatusColumn = [
  { field: "ProductCN", headerName: "Item", width: 150 },
  { field: "SS", headerName: "Status", width: 150 },
  { field: "SOH", headerName: "SOH", width: 150 },
  { field: "MOS", headerName: "MOS", width: 150 },
  { field: "QuantityPurchaseOrder", headerName: "Ordered", width: 150 },
  { field: "GIT", headerName: "GIT", width: 150 },
  { field: "AMC", headerName: "AMC", width: 150 },
  { field: "Min", headerName: "Min", width: 150 },
  { field: "Max", headerName: "Max", width: 150 },
];

export const supplierColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "supplierName", headerName: "supplier", width: 670 },
  { field: "AmountBirr", headerName: "Value (ETB)	", width: 250 },
  { field: "ReceivedbyAllRecipants", headerName: "Share", width: 250 },
];

export const STOCK_STATUS_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "Environment", renderHeader: (params) => <strong>{"Site"}</strong>, flex: 1.7,
    renderCell: (params) => (<LightTooltip title={params.row.SS}>
        <div style={{width: '100%'}}>
          {params.row.Environment === 'Home Office'?'Center': params.row.Environment.replace('Branch', '').replace('PFSA', '').replace('Hub', 'H')}
        </div>
      </LightTooltip>) },
  { field: "SOH", renderHeader: (params) => <strong>{"SOH"}</strong>, flex: 1, type: 'decimalZero', },
  { field: "MOS", renderHeader: (params) => <strong>{"MOS"}</strong>, flex: 1, type: 'decimal', },
  { field: "QuantityPurchaseOrder", renderHeader: (params) => <div><strong>{"Ordered"}</strong><br/><span>{"(In MOS)"}</span></div>, flex: 1, renderCell: (params) => <div><div>{FormatDecimal(params.row.QuantityPurchaseOrder + params.row.GIT, 0)}</div> <div>({FormatDecimal((params.row.QuantityPurchaseOrder + params.row.GIT)/params.row.AMC)})</div></div>,},
  { field: "GIT", renderHeader: (params) => <div><strong>{"GIT"}</strong><br/><span>{"(In MOS)"}</span></div>, flex: 1, renderCell: (params) => <div><div>{FormatDecimal(params.row.GIT, 0)}</div> <div>({FormatDecimal((params.row.GIT)/params.row.AMC)})</div></div>},
  { field: "AMC", renderHeader: (params) => <strong>{"AMC"}</strong>, flex: 1, type: 'decimalZero', },
  { field: "Min", renderHeader: (params) => <strong>{"MIN"}</strong>, flex: 1, type: 'decimalZero', },
  { field: "Max", renderHeader: (params) => <strong>{"MAX"}</strong>, flex: 1, type: 'decimalZero', },
  { field: "QuantityToReachMax", renderHeader: (params) => <strong>{"Need"}</strong>, flex: 1.2, renderCell: (params) => <>{params.row.QuantityToReachMax > 0? <BsArrowUpCircle color="green" />: <BsArrowDownCircle color="red" />}&nbsp; {FormatDecimal(params.row.QuantityToReachMax, 0)}</>, },
];

export const PO_SHIMPENT_COLUMN = [
  { field: "PurchaseOrderNumber", renderHeader: (params) => <strong>{"PO. Number"}</strong>, flex: 1},
  { field: "purchaseOrderDate", renderHeader: (params) => <strong>{"Date"}</strong>,  flex: 1, valueGetter: getPOShortDate },
  { field: "Donor", renderHeader: (params) => <strong>{"Donor"}</strong>,  flex: 1 },
  { field: "Procurer", renderHeader: (params) => <strong>{"Procurer"}</strong>,  flex: 1 },
  { 
    field: "supplier", 
    renderHeader: (params) => <strong>{"Supplier"}</strong>, 
    flex: 1,
    renderCell: (params) => (<LightTooltip title={`Manufacturer: ${params.row.Manufacturer}`}>
        <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
          {params.row.supplier}
        </div>
      </LightTooltip>), },
  { field: "QuantityPurchaseOrder", renderHeader: (params) => <strong>{"Ordered"}</strong>,  flex: 1, type: "decimalZero" },
  { 
    field: "QuantityInvoiced", 
    renderHeader: (params) => <strong>{"Shipped"}</strong>,  
    flex: 1, 
    renderCell: (params) => <div className="shipment-progress"><div>{FormatDecimal(params.row.QuantityInvoiced, 0)}</div> <Box sx={{ width: '5rem', marginTop: "0.5rem" }}>
    <ProgressBar now={FormatDecimal((100*params.row.QuantityInvoiced)/params.row.QuantityPurchaseOrder)} 
        label={`${FormatDecimal((100*params.row.QuantityInvoiced)/params.row.QuantityPurchaseOrder)}%`} />
  </Box> </div>, },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Received"}</strong>,  flex: 1, type: "decimalZero" },
  { field: "PendingQuantity", renderHeader: (params) => <strong>{"Pending"}</strong>,  flex: 1, type: "decimalZero" },
  { 
    field: "Pending", 
    renderHeader: (params) => <strong>{"Receive Completed"}</strong>,  flex: 1, 
    renderCell: (params) => <Box sx={{ width: '100%', marginTop: 2 }}>
      <ProgressBar now={FormatDecimal(100- params.row.Pending)} label={`${FormatDecimal(100 - params.row.Pending)}%`} />
    </Box> 
  }
];

export const MANUFACTURER_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50},
  { field: "Manufacturer", renderHeader: (params) => <strong>{"Manufacturer"}</strong>, renderCell: (params) => <span>{params.row.Manufacturer} &nbsp;&nbsp;&nbsp;<a href={params.row.Website} target="_blank"><FiExternalLink size={17} /></a></span>, flex: 1.5 },
  { field: "Country", renderHeader: (params) => <strong>{"Country"}</strong>,  flex: 1 },
  { field: "AmountBirr", renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1, type: "decimalZero" },
  { field: "ReceivedbyAllRecipants", renderHeader: (params) => <strong>{"Share"}</strong>,  flex: 1, valueGetter: getShare },
];

export const SUPPLIER_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "supplierName", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "AmountBirr", renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "ReceivedbyAllRecipants", renderHeader: (params) => <strong>{"Share"}</strong>, flex: 1, valueGetter: getShare},
];

export const COUNTRY_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "Country", renderHeader: (params) => <strong>{"Country"}</strong>, flex: 1 },
  { field: "AmountBirr", renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "ReceivedbyAllRecipants", renderHeader: (params) => <strong>{"Share"}</strong>, flex: 1, valueGetter: getShare},
];

export const RECEIVED_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "FullDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: getReceivedDate },
  { field: "Supplier", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "Manufacturer", renderHeader: (params) => <strong>{"Manufacturer"}</strong>, flex: 1 },
  { field: "Country", renderHeader: (params) => <strong>{"Country"}</strong>, flex: 1 },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1, type: 'decimalZero' },
  { 
    field: "AmountReceivedBirr", 
    renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, 
    flex: 1, 
    renderCell: (params) => (<LightTooltip title={`Funding Source: ${params.row.FundingSource}`}>
        <div>
          {FormatDecimal(params.row.AmountReceivedBirr, 0)}
        </div>
      </LightTooltip>),
  },
];

const ExpandIssueTrend = (params) => {
  const setIssueTrendModalOpen = useFanosStore((state) => state.setIssueTrendModalOpen);
  const setIssueTrendModalData = useFanosStore((state) => state.setIssueTrendModalData);

  const handleItemDetailsClicked = () => {
    setIssueTrendModalOpen(true);
    setIssueTrendModalData( { row: params.params.row } );
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleItemDetailsClicked()}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

export const ITEM_DISTRIBUTION_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "IssueDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: getShortDate, },
  { field: "Region", renderHeader: (params) => <strong>{"Region-Zone-Woreda"}</strong>, flex: 1.4, valueGetter: getRegionZoneWoreda },
  // {
  //   field: "InstitutionSN",
  //   headerName: " ",
  //   renderCell: (params) => <ExpandIssueTrend params={params} />,
  //   width: 75,
  // },
  { 
    field: "Institution", 
    renderHeader: (params) => <strong>{"Facility"}</strong>, 
    flex: 1, 
    renderCell: (params) => (<LightTooltip title={params.row.Manufacturer}>
        <div>
          {params.row.Institution}
        </div>
      </LightTooltip>) 
  },
  { field: "QuantitySupplied", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 0.7, type: 'decimalZero' },
  { field: "InvoiceNumber", renderHeader: (params) => <strong>{"Invoice"}</strong>, flex: 0.7 },
  { field: "Supplier", renderHeader: (params) => <strong>{"Distributor"}</strong>, flex: 1 },
];

export const ITME_RECEIVED_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "Country", headerName: "Hub", width: 670 },
  { field: "AmountBirr", headerName: "Supplier", width: 250 },
  { field: "ReceivedbyAllRecipants", headerName: "Quantity", width: 250 },
  { field: "AmountBirr", headerName: "Value (ETB)	", width: 250 },
  { field: "ReceivedbyAllRecipants", headerName: "Date", width: 250 },
];
export const ITEM_PRICE_ANALYSIS_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, headerName: " ", width: 50 },
  { field: "Manufacturer", renderHeader: (params) => <strong>{"Manufacturer"}</strong>, flex: 1 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "ReceiptDate", renderHeader: (params) => <strong>{"Received Date"}</strong>, flex: 1, valueGetter: (params) => getDate(params.row.ReceiptDate), },
  { field: "UnitCost", renderHeader: (params) => <strong>{"Unit Cost"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "PreviousUnitCost", renderHeader: (params) => <strong>{"Previous Cost"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "PercentageIncreaseDecrease", renderHeader: (params) => <strong>{"% Change"}</strong>, flex: 1, type: 'decimalZero' },
];

export const ITEM_PRICE_HISTORY_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "Supplier", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "Manufacturer", renderHeader: (params) => <strong>{"Manufacturer"}</strong>, flex: 1 },
  { field: "PurchaseOrderDate", renderHeader: (params) => <strong>{"PurchaseOrderDate"}</strong>, flex: 1, valueGetter: (params) => getDate("PurchaseOrderDate") },
  { field: "ReceiveUnitCost", renderHeader: (params) => <strong>{"ReceiveUnitCost"}</strong>, flex: 1, type: "decimalZero" },
  { field: "POPrice", renderHeader: (params) => <strong>{"POPrice"}</strong>, flex: 1, type: "decimalZero" },
];

const DEFAULT_PARAMS = [DEFAULT_ARGS];

export default DEFAULT_PARAMS;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid black',
      boxShadow: theme.shadows[1],
      fontSize: 13,
  },
}));
