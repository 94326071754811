import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const MapContext = createContext();

export function MapProvider({ children }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDCblEu_RLHAy8-gzy702cm9B24sZivGkg',
    libraries: ['visualization'],
  });

  return <MapContext.Provider value={{ isLoaded }}>{children}</MapContext.Provider>;
}

export function useMap() {
  return useContext(MapContext);
}
