

export function barLineComboChartDataFormatter(data) {
    let chartData = {
        label: [],
        bar: [],
        line: [],
        lineRef: []
    }

    data.forEach(row => {
        chartData.label.push(row.IssuePeriod);
        chartData.bar.push(row.IssuedQuantity);
        chartData.line.push(row.MovingAverage);
        chartData.lineRef.push(row.AverageIssuedQuantity);
    });

    return chartData;
}

const DataServiceHelper = [
  
]

export default DataServiceHelper;