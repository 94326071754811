import React, { useState } from "react";
import NavBar from "../../shared/NavBar";
import { NATIONAL_AMC_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { EditAMC } from "./EditAMC";
import { COVID_MODE_SELECT_CONFIG, MODE_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";


const NationalAMC = () => {
  const [nationalAMC, setNationalAMC] = useState(null);
  const [item, setItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const NATIONAL_AMC_COLUMN = [
    {
      field: "RowNumber",
      renderHeader: (params) => <strong>{"#"}</strong>,
      width: 70,
      valueGetter: (params) => params.id + 1,
    },
    {
      field: "ItemSN",
      renderHeader: (params) => <strong>{"Item SN"}</strong>,
      flex: 0.7,
    },
    {
      field: "ItemName",
      renderHeader: (params) => <strong>{"Item"}</strong>,
      flex: 3,
    },
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      flex: 1,
    },
    {
      field: "NationalAMC",
      renderHeader: (params) => <strong>{"Current National AMC"}</strong>,
      flex: 1.2,
      renderCell: (params) => (<Box>
        <Button type="submit" 
          style={{ 
            backgroundColor: 'orange',
            marginRight: 5,
          }}
          variant="contained" 
          size="small"
          onClick={() => {
            setItem({...params.row, CurrentNationalAMC: params.row.NationalAMC, NationalAMC: params.row.NationalAMC});
            setDialogOpen(true);
          }}>
          <FaEdit />
        </Button>
        {params.row.NationalAMC}
      </Box>),
    },
  ];
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos National Stock Status and Pipeline Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "National AMC Update",
              data_source_api: NATIONAL_AMC_API,
              data_property: "Data",
              select_filter_config: [COVID_MODE_SELECT_CONFIG],
              columns: NATIONAL_AMC_COLUMN,
              isSOH: false,
              wgInfoId: 'wgAdjustedAmcUpdate',
              use_default_filter_params: false,
              defaultFilterParams: {},
            }}
          />
        </div>
      </div>
      {dialogOpen && <EditAMC dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} item={item} setItem={setItem} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default NationalAMC;