import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select, Snackbar, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import * as yup from 'yup';
import { useFormik } from "formik";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";
import { adminAddProgramGroup, getAdminProgramList, getProgramListByGroup } from "../../Services/ApiManager";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const GroupProgram = ({dialogOpen, setDialogOpen, selectedGroup}) => {
  const [loading, setLoading] = useState(false);
  const [groupProgramList, setGroupProgramList] = useState([]);
  const [selectedProgramsList, setSelectedProgramsList] = useState([]);
  const [allProgramsAdded, setAllProgramsAdded] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false); 
  const [errorAlertOpen, setErrorAlertOpen] = useState(false); 

  useEffect (() => {
    getAdminProgramList().then((result) => {
      if(result.status === 200) {
        getProgramListByGroup(selectedGroup.GroupID).then((res) => {
          if(res.status === 200) {
            setGroupProgramList(result.data.Data.filter((gp) => res.data.Data.filter((ngp) => gp.ProgramID === ngp.ProgramID).length === 0))
            if(result.data.Data.filter((gp) => res.data.Data.filter((ngp) => gp.ProgramID === ngp.ProgramID).length === 0).length === 0){
                setAllProgramsAdded(true);
            }
          }
        })
      }
    })
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProgramsList(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onSave = () => {
    setLoading(true);
    adminAddProgramGroup(groupProgramList.filter((gpf) => selectedProgramsList.filter((spl) => gpf.ProgramName === spl).length > 0).map((gp) => ({GroupID: selectedGroup.GroupID, ProgramID: gp.ProgramID}))).then(() => { 
      setSuccessAlertOpen(true); 
      setLoading(false);
      setDialogOpen(false);
    }).catch(() => setErrorAlertOpen(true))
  }

  const handleCloseSuccess = () => {
    setSuccessAlertOpen(false);
  }

  const handleCloseError = () => {
    setErrorAlertOpen(false);
  }

  return (
    
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {'Additional Page Access to ' + selectedGroup.GroupName + ' Group'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
          <Grid container spacing={3}>
            {allProgramsAdded? 
            (<Grid item xs={12} sm={12} display="flex" justifyContent="center">
                <Box style={{fontWeight: 700}}>User has all Program Permissions</Box>
            </Grid>):
            groupProgramList.length > 0?
            (<>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Select Additional Programs
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">Programs</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedProgramsList}
                        onChange={handleChange}
                        input={<OutlinedInput label="Program" />}
                        MenuProps={MenuProps}
                        >
                        {groupProgramList.map((program) => (
                        <MenuItem
                            key={program.ProgramName}
                            value={program.ProgramName}
                        >
                            {program.ProgramName}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Grid>
            </>):
            <></>}
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => {
                    setDialogOpen(false);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button type="submit" 
                fullWidth 
                style={{ 
                  backgroundColor: '',
                }}
                onClick={() => onSave()}
                disabled={loading}
                variant="contained" 
                color="primary">
                {'Save'}
                {loading &&
                (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                  <CustomLoadingIndicator />
                </span>)}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
          </Grid>
        </DialogContent>
        <Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            Successful!
          </Alert>
        </Snackbar>
        <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            Error!
          </Alert>
        </Snackbar>
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;