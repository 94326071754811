import { Box, Dialog, Modal, } from "@mui/material";
import styled from "styled-components";
import BarLineComboChart from "../shared/Charts/BarLineComboChart";

export const IssueTrendModal = ({ open, handleClose, data }) => {
  const apiUrl = `/OIH_WebApi/FacilityDistributionMovingAverage?ItemSN=${ data?.productSN }&InstitutionSN=${ data?.row?.InstitutionSN }` ;
  
  console.log(apiUrl);
  return (
    
    <Modal className="rs-dialog"
            open={open}
            onClose={() => handleClose(false)}
        >
        <Box 
          className="flex-column rs-d-container widget-modal-body">
            <BarLineComboChart component_config={{
              title: "Issue Trend | " + data?.row?.Item + " - " + data?.row?.Institution,
              api_url: apiUrl,
              data_source: 'fanos'
            }}/>
          </Box>
        </Modal>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;