import React, { useEffect, useState } from "react";

import { Button, FormControlLabel, Switch, TextField, } from "@mui/material";

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-quartz.css';

import "../../Home/MiscellaneousStockReport/style.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// custom components
// import CustomSelect from "../Inputs/CustomSelect";
// import GridLegend from "./Plugins/GridLegend";
// import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
// import { IsEmpty } from "../Helpers/FormatHelper";
import CustomSelect from "./CustomSelect";
import { getFanosData } from "../../../Services/ApiManager";
import CustomDatePicker from "./CustomDatePicker";
// Icons
import { MdOutlineExpandMore } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

import styled from "styled-components";
import clsx from "clsx";
import Dots from "../../../assets/GIFs/dotss.gif";
import InfoIcon from '@mui/icons-material/Info';
import { GridInfo } from "./InfoGrid";
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useRef } from "react";

import ExcelJS from 'exceljs';
import moment from "moment";
import { FormatDecimal, getValueByProperty } from "../../../Utils/NumberUtils";
import { useQuery } from '@tanstack/react-query';
import { NATIONAL_MODE_SELECT_CONFIG, PLACEMENT_CATEGORY_SELECT_CONFIG } from "./DataTableConstants";
import { CONSTANTS } from "../../../Utils/Constants";

function CustomDataGrid({ component_config, component_config_detail }) {
  const tableRef = useRef(null);
  const [gridDataLocal, setGridDataLocal] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [dataGridFilterParams, setDataGridFilterParams] = useState(component_config.isNational? {...component_config.defaultFilterParams, isPlacement: false, isLabSupply: false}: {...component_config.defaultFilterParams});
  const [localFilterParams, setLocalFilterParams] = useState({});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [expand, setExpand] = useState(component_config.expand !== undefined ? component_config.expand : true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [componentConfigDetail, setComponentConfigDetail] = useState(
    component_config_detail
  );
  const [searchValue, setSearchValue] = useState("");

  const [open, setOpen] = React.useState(false);
  const [stockOnHandColumnKey, setStockonHandColumn] = useState([]);
  const [columnKey, setColumnKey] = useState([]);
  const [nationalSelectFilterConfig, setNationalSelectFilterConfig] = useState(component_config.isNational? [...component_config.select_filter_config]: []);
  const [localDates, setLocalDates] = useState(component_config.local_date_picker && component_config.local_date_picker.localDateDefaultParams? [new Date(component_config.local_date_picker.localDateDefaultParams[0]), new Date(component_config.local_date_picker.localDateDefaultParams[1])]: [new Date(moment().subtract(1, 'months').format("MM/DD/YYYY")), new Date()]);
  const [stockOnHandRow, setStockOnHandRows] = useState([]);
  const [isPlacement, setIsPlacement] = useState(false);
  const [isLabSupply, setIsLabSupply] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const hubRoutes = ["Center to Hub", "Hub to Facility"];
  let gridClassName = component_config.striped_col ? "widget-body striped-col " : "widget-body ";
  gridClassName = component_config.vertical_border ?
      gridClassName + "vertical-border" :
      gridClassName;
  const {data: response, isFetching: isLoading, isStale} = useQuery({
    queryKey: ['tableQuery', component_config.data_source_api, dataGridFilterParams, component_config.data_source, component_config.method],
    queryFn: () => getFanosData(
      component_config.data_source_api,
      component_config.use_default_filter_params
        ? component_config.defaultFilterParams
        : dataGridFilterParams,
      component_config.data_source,
      component_config.method
    ),
    staleTime: 10800000,
  });

  useEffect(() => {
    if(component_config.defaultFilterParams) {
      setDataGridFilterParams(component_config.isNational? {...component_config.defaultFilterParams, isPlacement: false, isLabSupply: false}: {...component_config.defaultFilterParams});
    }
  }, [component_config.defaultFilterParams])
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: "cadetblue" }} />
        <GridToolbarFilterButton style={{ color: "cadetblue" }} />
        <GridToolbarExport csvOptions={{fileName: component_config.title}}  style={{ color: "cadetblue" }} />
        {component_config.wgInfoId && <Button onClick={() => setDialogOpen(true)}><InfoIcon style={{ color: "cadetblue" }} /></Button>}
        {component_config.show_search && <GridToolbarQuickFilter style={{ color: "cadetblue" }} />}
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if(response){
      var result = component_config.data_property
          ? response.data[component_config.data_property]
          : response.data;

        if(component_config.dateColumn){
          result = result.map((res) => {
            
            const d = res[component_config.dateColumn].split("T")[0];
            res[component_config.dateColumn] = d;
            return res;
          })
        }

        if (component_config.isSOH) {
          let columns = {
            rowNumber: {
              field: "rowNumber",
              headerName: " ",
              flex: 1,
              minWidth: 20,
            },
            site: { field: "site", headerName: "Site", flex: 1, minWidth: 150 },
          };
          let rows = {};
          let tempColumn = [];
          let tempRow = [];
          let count = 1;
          result &&
            result.map((d) => {
              if (!rows[d.Environment]) {
                rows[d.Environment] = { site: d.Environment, rowNumber: count };
                count++;
              }
              if (!columns[d.ProductCN]) {
                columns[d.ProductCN] = {
                  field: d.ProductCN,
                  headerName: d.ProductCN,
                  flex: 1,
                  minWidth: 80,
                  type: "decimalZero",
                };
              }
              rows[d.Environment][d.ProductCN] = d.SOH;
            });
          Object.keys(rows).forEach((key) => {
            tempRow.push(rows[key]);
          });
          generateRowIds(tempRow);
          setGridData(tempRow);
          Object.keys(columns).forEach((key) => {
            tempColumn.push(columns[key]);
          });
          const getCell = (params, col) => {
            return result.filter((res) => res.ProductCN == col.field && res.Environment == params.row.site )[0]?.SS
          }
          const tempColumnCoded = tempColumn.map((col) => (col.type === "decimal"? 
            {
              ...col,
              cellClassName: (params) =>
                clsx({
                  normal: getCell(params, col) === 'Normal',
                  excess: getCell(params, col) === 'Excess',
                  belowMin: getCell(params, col) === 'Below Min',
                  stockedOut: getCell(params, col) === 'Stocked Out',
                  belowEOP: getCell(params, col) === 'Below EOP',
                }),
              headerClassName: 'header-background',
              valueFormatter: (param) => (FormatDecimal(param.value))
            }:
            col.type === "decimalZero"? 
            {
              ...col,
              cellClassName: (params) =>
                clsx({
                  normal: getCell(params, col) === 'Normal',
                  excess: getCell(params, col) === 'Excess',
                  belowMin: getCell(params, col) === 'Below Min',
                  stockedOut: getCell(params, col) === 'Stocked Out',
                  belowEOP: getCell(params, col) === 'Below EOP',
                }),
              headerClassName: 'header-background',
              valueFormatter: (param) => (FormatDecimal(param.value, 0))
            }:
            {
              ...col,
              cellClassName: (params) =>
                clsx({
                  normal: getCell(params, col) === 'Normal',
                  excess: getCell(params, col) === 'Excess',
                  belowMin: getCell(params, col) === 'Below Min',
                  stockedOut: getCell(params, col) === 'Stocked Out',
                  belowEOP: getCell(params, col) === 'Below EOP',
                }),
              headerClassName: 'header-background',
            }));
          setStockonHandColumn(tempColumnCoded);
        } else if (component_config.isShipment) {
          generateRowIds(result);
          setGridData(result);
          const tempColumnCoded = component_config.columns.map((col) => {
            if(col.field === 'PAmountGRNFPrint') {
              return {
                ...col,
                cellClassName: (params) =>
                  clsx({
                    lowPercentage: params.row.PAmountGRNFPrint <= 40,
                    mediumPercentage: params.row.PAmountGRNFPrint > 40 && params.row.PAmountGRNFPrint < 85,
                    highPercentage: params.row.PAmountGRNFPrint >= 85,
                  }),
              }
            } else if(col.field === 'PAmountGRVPrint') {
              return {
                ...col,
                cellClassName: (params) =>
                  clsx({
                    lowPercentage: params.row.PAmountGRVPrint <= 40,
                    mediumPercentage: params.row.PAmountGRVPrint > 40 && params.row.PAmountGRVPrint < 85,
                    highPercentage: params.row.PAmountGRVPrint >= 85,
                  }),
              }
            } else {
              return col;
            }
          });
          setColumnKey(tempColumnCoded.map((col) => (col.type === "decimal"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value))}: col.type === "decimalZero"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value, 0))}: {...col, headerClassName: 'header-background'})))
        } else if (component_config.isItemSOH) {
          generateRowIds(result);
          setGridData(result);
          const tempColumnCoded = component_config.columns.map((col) => {
            if(col.field === 'Environment') {
              return {
                ...col,
                cellClassName: (params) =>
                  clsx({
                    normalItem: params.row.SS === 'Normal',
                    excessItem: params.row.SS === 'Excess',
                    belowMinItem: params.row.SS === 'Below Min',
                    stockedOutItem: params.row.SS === 'Stocked Out',
                    belowEOPItem: params.row.SS === 'Below EOP',
                  }),
              }
            } else if(col.field === 'Environment') {
              return {
                ...col,
                cellClassName: (params) =>
                  clsx({
                    normal: params.row.SS === 'Normal',
                    excess: params.row.SS === 'Excess',
                    belowMin: params.row.SS === 'Below Min',
                    stockedOut: params.row.SS === 'Stocked Out',
                    belowEOP: params.row.SS === 'Below EOP',
                  }),
              }
            } else {
              return col;
            }
          });
          setColumnKey(tempColumnCoded.map((col) => (col.type === "decimal"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value))}: col.type === "decimalZero"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value, 0))}: {...col, headerClassName: 'header-background'})))
        } else {
          const filteredResult = component_config.sorted? 
           result.filter((res) => {
            let localDatePickerFilter = true
            if(component_config.local_date_picker){
              localDatePickerFilter = new Date(res[component_config.local_date_picker.date_field]) >= localDates[0] && new Date(res[component_config.local_date_picker.date_field]) <= localDates[1];
            }
            return Object.keys(localFilterParams).map((k) => res[k] === localFilterParams[k]).reduce((accumulator, currentValue) => accumulator && currentValue, true) && localDatePickerFilter}).sort((a, b) => a[component_config.sorted].localeCompare(b[component_config.sorted])):
          result.filter((res) => {
            let localDatePickerFilter = true
            if(component_config.local_date_picker){
              localDatePickerFilter = new Date(res[component_config.local_date_picker.date_field]) >= localDates[0] && new Date(res[component_config.local_date_picker.date_field]) <= localDates[1];
            }
            return Object.keys(localFilterParams).map((k) => res[k] === localFilterParams[k]).reduce((accumulator, currentValue) => accumulator && currentValue, true) && localDatePickerFilter})
          generateRowIds(filteredResult);
          setGridData(filteredResult);
          setColumnKey(component_config.columns.map((col) => (col.type === "decimal"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value))}: col.type === "decimalZero"? {...col, headerClassName: 'header-background', valueFormatter: (param) => (FormatDecimal(param.value, 0))}: {...col, headerClassName: 'header-background'})));
        }
    }
  }, [response, localFilterParams])

  useEffect(() => {
    if(component_config.isNational && isPlacement){
      setIsLabSupply(false);
      setNationalSelectFilterConfig([PLACEMENT_CATEGORY_SELECT_CONFIG]);
      setDataGridFilterParams({ModeCode: "RDF", IsPlacement: true, IsLabSupply: false});
    }

  }, [isPlacement])

  useEffect(() => {
    if(component_config.isNational && isLabSupply){
      setIsPlacement(false);
      setNationalSelectFilterConfig([NATIONAL_MODE_SELECT_CONFIG]);
      setDataGridFilterParams({ModeCode: dataGridFilterParams.ModeCode || "RDF", IsPlacement: false, IsLabSupply: true});
    }
  }, [isLabSupply])

  useEffect(() => {

    if(component_config.isNational && !isPlacement && !isLabSupply){
      setNationalSelectFilterConfig(component_config.select_filter_config);
      setDataGridFilterParams({ModeCode: dataGridFilterParams.ModeCode || "RDF", isPlacement: false, isLabSupply: false})
    }

  }, [isPlacement, isLabSupply])

  const onChangeDatePicker = (dates) => {
    const dataGridFilterParamsTemp = dataGridFilterParams;
    Object.keys(dates).forEach((key) => {
      dataGridFilterParamsTemp[key] = dates[key];
    });
    setDataGridFilterParams({...dataGridFilterParamsTemp});
  };

  const onChangeLocalDatePicker = (dates) => {
    Object.keys(dates).map((key, i) => {
      const temp = localDates;
      temp[i] = new Date(dates[key]);
      setLocalDates([...temp]);
    });
  };
  const handleClose = () => setOpen(false);

  const handleChange = (param) => {
    const dataGridFilterParamsTemp = dataGridFilterParams;
    if(component_config.method === "POST"){
      if (param.paramValue) {
        dataGridFilterParamsTemp.append(param.paramName, param.paramValue)
      } else {
        delete dataGridFilterParamsTemp.delete(param.paramName);
      }
    } else {
      if (param.paramValue) {
        dataGridFilterParamsTemp[param.paramName] = param.paramValue;
      } else {
        delete dataGridFilterParamsTemp[param.paramName];
      }
    }
    setDataGridFilterParams({...dataGridFilterParamsTemp});
  };

  const handleLocalChange = (param) => {
    if (param.paramValue !== null) {
      setLocalFilterParams({...localFilterParams, [param.paramName]: param.paramValue});
    } else {
      const tempLocalFilterParams = localFilterParams;
      delete tempLocalFilterParams[param.paramName];
      setLocalFilterParams({...tempLocalFilterParams});
    }
  };

  const onChangeCheckbox = (event, value) => {
    dataGridFilterParams[event.target.name] = !value;
  };

  function generateRowIds(data) {
    for (let index = 0; index < data.length; index++) {
      data[index].autoGeneratedId = index;
    }
  }

  const exportToExcel = async() => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    let columnIndex = 1;
    component_config.columnGroupingModel.forEach((group) => {
      if (group.children) {
        group.children.forEach((column) => {
          const excelColumn = worksheet.getColumn(columnIndex);
          excelColumn.header = group.headerName;
          excelColumn.key = column.field;
          excelColumn.width = 15;
          columnIndex++;
        });
      }
    });

    component_config.columnGroupingModel.forEach((group) => {
      if (group.children) {
        const startCol = worksheet.getColumn(group.children[0].field).number;
        const endCol = worksheet.getColumn(group.children[group.children.length - 1].field).number;
        worksheet.mergeCells(1, startCol, 1, endCol);
      }
    });

    const newHeader = {};
    component_config.columns.forEach((column) => {
      newHeader[column.field] = column.field.toString();
    });
    worksheet.addRow(newHeader);

    gridData.forEach((row) => {
      const newRow = {};
      columnIndex = 1;
      component_config.columns.forEach((column) => {
        newRow[column.field] = column.valueGetter? column.valueGetter({row}): row[column.field];
        columnIndex++;
      });
      worksheet.addRow(newRow);
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = component_config.title + '.xlsx';
    link.click();
    URL.revokeObjectURL(url);
  };




  return (
    <Wrapper style={{flex: 1}}>
      <div>
        {/* <Modal open={open} onClose={handleClose}>
          <Box style={{ margin: "auto", width: "90%" }}>
            <CustomDataGrid component_config={componentConfigDetail} />
          </Box>
        </Modal> */}
      </div>
      <div className="widget-container widget-container-data-grid">
        <div className="widget-toolbar widget-toolbar-data-grid">
          {component_config.isNational && 
            (<div className="tool-bar-item-container" style={{justifyContent: "flex-end"}}>
              <FormControlLabel 
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
                control={
                <Switch
                  name="IsPlacement"
                  id="IsPlacement"
                  checked={isPlacement}
                  onChange={(e) => setIsPlacement(e.target.checked)} />} 
                  label="Placement" />
              <FormControlLabel
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
                control={
                <Switch
                  name="IsLabSupply"
                  id="IsLabSupply"
                  checked={isLabSupply}
                  onChange={(e) => setIsLabSupply(e.target.checked)} />} 
                  label="Lab Supply" />
            </div>)
          }
          {component_config.isShipmentDwelling && 
            (<div className="tool-bar-item-container" style={{justifyContent: "flex-end"}}>
              <FormControlLabel 
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
                control={
                <Switch
                  name="isCleared"
                  id="isCleared"
                  checked={isCleared}
                  onChange={(e) => {
                    setDataGridFilterParams({...dataGridFilterParams, IsCleared: !isCleared })
                    setIsCleared(!isCleared)
                  }} />} 
                  label="Cleared" />
            </div>)
          }
          <div className="tool-bar-item-container" style={{justifyContent: "flex-end"}}>
            {component_config.distribution_filter?.showDistributionFilter && 
            (<div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
              <select
                  className="select"
                  style={{marginLeft: 0, marginBottom: 0, width: "10rem"}}
                  onChange={(e) => component_config.distribution_filter?.setDistribution(e.target.value)}
                >
                  {hubRoutes.map((el) => (
                  <option key={el} value={el}>
                      {el}
                  </option>
                  ))}
              </select>
            </div>)}
            {component_config.isNational? nationalSelectFilterConfig?.map((config) => 
            (<div key={config.id} className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
              <CustomSelect
                handle_change={ handleChange}
                component_config={config}
              />
            </div>)
            ): component_config.select_filter_config?.map((config) => 
            (<div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
              <CustomSelect
                handle_change={ handleChange}
                component_config={config}
              />
            </div>)
            )}
             {component_config.local_filter_config?.map((config) => 
            (<div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
              <CustomSelect
                handle_change={ handleLocalChange}
                component_config={config}
              />
            </div>)
            )}
            {/* {component_config.checkbox_filter_config?.map(function (config) {
              return (
                <div className="tool-bar-item tool-bar-item-1">
                  <FormControlLabel
                        control={
                          <Checkbox
                            name={config.param_name}
                            onChange={onChangeCheckbox}
                          />
                        }
                        label={config.label}
                      />
                </div>
              );
            })} */}
            {component_config.date_picker && (
              <div className="tool-bar-item-container" style={{width: component_config.date_picker.date_range? "13.5rem": "auto"}}>
                <div className="tool-bar-item" style={{width: component_config.date_picker.date_range? "13.5rem": "7rem", maxWidth: component_config.date_picker.date_range? "13.5rem": "11rem"}}>
                  <CustomDatePicker
                    className="date-picker-input"
                    handle_change={onChangeDatePicker}
                    component_config={component_config.date_picker}
                  />
                </div>
              </div>
            )}

            {component_config.local_date_picker && (
              <div className="tool-bar-item-container" style={{width: component_config.local_date_picker.date_range? "13.5rem": "auto"}}>
                <div className="tool-bar-item" style={{width: component_config.date_picker.date_range? "13.5rem": "7rem", maxWidth: component_config.date_picker.date_range? "13.5rem": "11rem"}}>
                  <CustomDatePicker
                    className="date-picker-input"
                    handle_change={onChangeLocalDatePicker}
                    component_config={component_config.local_date_picker}
                  />
                </div>
              </div>
            )}
            {(component_config.customExport && !isLoading) && (<Button onClick={exportToExcel}>Export excel</Button>)}
          </div>
        </div>
        {component_config.title && (<div>
          <div className="widget-title">
            {component_config.title !== undefined && (
              <h2 className="grid-title">
                {component_config.isShipment? `${component_config.title} ${getValueByProperty(CONSTANTS.ProcurerList, "Code", dataGridFilterParams.ProcurerCode, "Name")}`: component_config.title}
                {isLoading && (
                  <span>
                    <img src={Dots} alt="" />
                  </span>
                )}
               {component_config.isNational &&  <TextField id="standard-basic" style={{marginLeft: 15,}} onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder="Search" variant="standard" />}
                {component_config.notes && <span style={{fontSize: 17, marginLeft: 15, fontStyle: "italic", color: "#c4c8c6"}}>
                  {component_config.notes}
                </span>}
              </h2>
            )}
            
            {expand && (
              <MdOutlineExpandMore
                className="expand-icon-1"
                onClick={() => setExpand(!expand)}
              />
            )}
            {!expand && (
              <IoIosArrowForward
                className="collapse-icon"
                onClick={() => setExpand(!expand)}
              />
            )}
          </div>
        </div>)}
        {(component_config.isNational || component_config.isNationalDetail) && (
        <div className={gridClassName}>
          <div
            className={
              "ag-theme-quartz"
            }
            style={{
              height: '700px',
              width: '100%'
            }}
          >
            <AgGridReact
              rowStyle={{whiteSpace: "pre-wrap"}}
              quickFilterText={searchValue}
              rowData={gridData}
              columnDefs={columnKey.map((colDef) => ({
                ...colDef,
                children: colDef.children.map((ch) => ({...ch, 
                  wrapText: true,
                  autoHeight: true,}))
              }))}
            />
          </div>
        </div>)}
        {expand && !(component_config.isNational || component_config.isNationalDetail) && (
          <div className={gridClassName} style={component_config.isNational?{height: 700}: {}}>
            <DataGrid
              style={{
                background: "white",
                fontFamily:
                  '"open sans","Helvetica Neue",Helvetica,Arial,sans-serif',
                opacity: isLoading ? "0.5" : "1",
              }}
              ref={tableRef}
              initialState={{pagination:{paginationModel:{pageSize:component_config.pageSize || 10, page:0}}}}
              autoHeight={!component_config.isNational}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              rows={gridData}
              columns={
                component_config.isSOH
                  ? stockOnHandColumnKey
                  : columnKey
              }
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={component_config.columnGroupingModel || null}
              pinnedColumns={component_config.pinnedColumns || null}
              density="standard"
              getRowId={(row) => row.autoGeneratedId}
              rowHeight={40}
              pagination
              sx={{
                '& .MuiDataGrid-row': {
                  minHeight: '45px !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'unset',
                  overflow: 'unset',
                  maxHeight: 'fit-content !important',
                },
                '& .MuiDataGrid-columnHeader': {
                  maxHeight: 'fit-content !important',
                  lineHeight: '26px !important',
                  position: "sticky"
                },
                '.css-ql19fo-MuiDataGrid-overlayWrapper':{
                  position: 'unset',
                },
                ".highlight": {
                  bgcolor: "#f0eded"
                },
                }}
              pageSizeOptions={[5, 10, 25]}
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 600,  },
                },
              }}
              disableDensitySelector
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 && component_config.striped
                    ? 'highlight' : ''
              }
              // columnVisibilityModel={columnVisibilityModel}
            />
            {component_config.isSOH && 
              <div align="center">
                <span className="excess"> &nbsp; &nbsp; </span> &nbsp; Excess &nbsp;&nbsp;&nbsp;
                <span className="normal">&nbsp;  &nbsp;</span> Normal &nbsp;&nbsp;&nbsp;
                <span className="belowMin"> &nbsp;  &nbsp;</span> Below Min &nbsp;&nbsp;&nbsp;
                <span className="belowEOP"> &nbsp;  &nbsp;</span> Below EOP &nbsp;&nbsp;&nbsp;
                <span className="stockedOut"> &nbsp;  &nbsp;</span> Stocked Out &nbsp;&nbsp;&nbsp;
              </div>
            }
          </div>
        )}
      </div>
      {component_config.wgInfoId && <GridInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} widgetId={component_config.wgInfoId} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .expand-icon-1 {
    color: grey;
    font-size: 1.4rem;
    margin-top: 0.4rem;
  }
  .collapse-icon {
    color: grey;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  .widget-title {
    display: flex;
    justify-content: space-between;
  }
  .striped-col .MuiDataGrid-row .MuiDataGrid-cell:nth-child(odd){ 
		background: white;
	}
  .striped-col .MuiDataGrid-row .MuiDataGrid-cell:nth-child(even){
		background: #f0eded;
	}
  .vertical-border .MuiDataGrid-cell {
    border-right: 1px solid;
    border-right-color: rgb(224, 224, 224);
  }
  .shipment-progress .progress-bar {
    background-color: #6495ED;
  }
`;

export default CustomDataGrid;
