import { isEmpty } from "lodash";
import { IsEmpty } from "./NumberUtils";

export function DateDiffCeil(endDate, startDate) {
  if(IsEmpty(startDate)) return null;
  else startDate =  new Date(startDate);
  if(isEmpty(endDate)) endDate = new Date();
  else endDate =  new Date(endDate);

  return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
}

const ArrayUtils = [
  
]

export default ArrayUtils;
