export const CONSTANTS = {
    adminUser: "HCMIS User",
    InitialSupplyChainData: {
        POQuantity: 0,
        InvoicedQuantity: 0,
        ReceivedQuantity: 0,
        IssuedQuantity: 0,
        SOH: 0,
        WastageQuantity: 0,
        FiscalYear: 0
    },
    ReportTypes: { 
        YearlyDistribution: "YearlyDistributionData",
        NationalSOH: "NationalSOHData",
        NationalSOHByActivity: "NationalSOHDataByActivity",
    },
    ProcurerList: [
  {
    ProcurerID: 1,
    Name: "UNFPA",
    Code: "UNF"
  },
  {
    ProcurerID: 2,
    Name: "UNICEF",
    Code: "UNICF"
  },
  {
    ProcurerID: 3,
    Name: "EPSS",
    Code: "PFSA"
  },
  {
    ProcurerID: 4,
    Name: "SCMS",
    Code: "SCMS"
  },
  {
    ProcurerID: 5,
    Name: "WHO",
    Code: "WHO"
  },
  {
    ProcurerID: 6,
    Name: "MSH",
    Code: "MSH"
  },
  {
    ProcurerID: 7,
    Name: "PPM ",
    Code: "PPM"
  },
  {
    ProcurerID: 8,
    Name: "PSI / Ethiopia ",
    Code: "PSI"
  },
  {
    ProcurerID: 9,
    Name: "Unknown",
    Code: "UNKN"
  },
  {
    ProcurerID: 10,
    Name: "The Task Force for Global Health",
    Code: "TFGH"
  },
  {
    ProcurerID: 11,
    Name: "PSM",
    Code: "PSM"
  },
  {
    ProcurerID: 12,
    Name: "Pfizer",
    Code: "PFZ"
  },
  {
    ProcurerID: 13,
    Name: "Challenge TB\r\n",
    Code: "CTB"
  },
  {
    ProcurerID: 14,
    Name: "DKT",
    Code: "DKT"
  },
  {
    ProcurerID: 15,
    Name: "Pathfinder",
    Code: "PFN"
  },
  {
    ProcurerID: 16,
    Name: "GDF ",
    Code: "GDF"
  },
  {
    ProcurerID: 17,
    Name: "PFSCM",
    Code: "PFSCM"
  },
  {
    ProcurerID: 18,
    Name: "i+solutions",
    Code: "i+sol"
  },
  {
    ProcurerID: 19,
    Name: "IDA Foundation",
    Code: "IDA"
  },
  {
    ProcurerID: 20,
    Name: "MOH",
    Code: "MOH"
  }
]
}