import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { ENVIRONMENT_SELECT_CONFIG } from "../shared/Shared Utils/DataTableConstants";
import { BIN_CARD_API } from "../shared/Shared Utils/AppData";
import NavBar from "../shared/NavBar";
import CustomDataGrid from "../shared/Shared Utils/CustomDataTable";
import { useLocation } from "react-router-dom";

function getDate(params) {
  return `${moment(params.row.IssueDate).format("MMMM Do, YYYY")}`;
}

const BIN_CARD_COLUMN = [
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Date"}</strong>,
    width: 150,
    valueGetter: getDate,
  },
  {
    field: "InvoiceNumber",
    renderHeader: (params) => <strong>{"Invoice"}</strong>,
    width: 100,
  },
  {
    field: "TransactionType",
    renderHeader: (params) => <strong>{"Transaction"}</strong>,
    width: 100,
  },
  {
    field: "DocumentType",
    renderHeader: (params) => <strong>{"Document"}</strong>,
    width: 100,
  },
  {
    field: "Type",
    renderHeader: (params) => <strong>{"Type"}</strong>,
    width: 180,
  },
  {
    field: "From",
    renderHeader: (params) => <strong>{"From"}</strong>,
    width: 130,
  },
  {
    field: "To",
    renderHeader: (params) => <strong>{"To"}</strong>,
    width: 130,
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 130,
  },
  {
    field: "Balance",
    renderHeader: (params) => <strong>{"Balance"}</strong>,
    width: 130,
  },
];


const ItemBinCard = () => {
  const location = useLocation();
  const ModeCode = location.state.ModeCode;
  const ProductSN = location.state.ProductSN;

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(1, 'week').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Distribution Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Bin Card",
              data_source_api: BIN_CARD_API,
              data_property: "Data",
              columns: BIN_CARD_COLUMN,
              isSOH: false,
              wgInfoId: 'wgDistributionReport',
              use_default_filter_params: false,
              select_filter_config: [
                ENVIRONMENT_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {
                  ModeCode,
                  ProductSN,
                },
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                weekly_report: true,
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default ItemBinCard;
