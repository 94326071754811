import moment from "moment";

function getTotal(params) {
  return `${params.row.Pharmaceuticals + params.row.MedicalEquipments + params.row.MedicalSupplies + params.row.ChemicalsAndReagents}`;
}

function getID(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

function getDate(params) {
  return `${moment(params.row.IssueDate).format("MMMM Do, YYYY")}`;
}

export const QR_DISTRIBUTION_BY_COMMODITY_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    flex: 1,
  },
  {
    field: "Pharmaceuticals",
    renderHeader: (params) => <strong>{"Pharmaceuticals"}</strong>,
    flex: 1,
  },
  {
    field: "MedicalEquipments",
    renderHeader: (params) => <strong>{"Medical Equipments"}</strong>,
    flex: 1,
  },
  {
    field: "MedicalSupplies",
    renderHeader: (params) => <strong>{"Medical Supplies"}</strong>,
    flex: 1,
  },
  {
    field: "ChemicalsAndReagents",
    renderHeader: (params) => <strong>{"Chemicals And Reagents"}</strong>,
    flex: 1,
  },
  {
    field: "Total",
    renderHeader: (params) => <strong>{"Total"}</strong>,
    flex: 1,
    valueGetter: getTotal,
  },
];

export const QR_DISTRIBUTION_BY_OWNERSHIP_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    flex: 1,
  },
  {
    field: "Public",
    renderHeader: (params) => <strong>{"Public"}</strong>,
    flex: 1,
  },
  {
    field: "Private",
    renderHeader: (params) => <strong>{"Private"}</strong>,
    flex: 1,
  },
  {
    field: "NGO",
    renderHeader: (params) => <strong>{"NGO"}</strong>,
    flex: 1,
  },
];

export const QR_PROCURED_LOCAL_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    flex: 1,
  },
  {
    field: "AmountInBirr",
    renderHeader: (params) => <strong>{"Amount In Birr"}</strong>,
    flex: 1,
  },
];

export const QR_RAW_DISTRIBUTION_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "QuantityIssued",
    renderHeader: (params) => <strong>{"Quantity Issued"}</strong>,
    width: 150,
  },
  {
    field: "Cost",
    renderHeader: (params) => <strong>{"Unit Price (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "UnitCost",
    renderHeader: (params) => <strong>{"Unit Cost (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    width: 200,
    valueGetter: getDate,
  },
  {
    field: "STVNumber",
    renderHeader: (params) => <strong>{"STV Number"}</strong>,
    width: 150,
  },
  {
    field: "Region",
    renderHeader: (params) => <strong>{"Region"}</strong>,
    width: 150,
  },
  {
    field: "Zone",
    renderHeader: (params) => <strong>{"Zone"}</strong>,
    width: 150,
  },
  {
    field: "Woreda",
    renderHeader: (params) => <strong>{"Woreda"}</strong>,
    width: 150,
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Institution"}</strong>,
    width: 150,
  },
  {
    field: "InstitutionType",
    renderHeader: (params) => <strong>{"Institution Type"}</strong>,
    width: 150,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity"}</strong>,
    width: 150,
  },
  {
    field: "OwnershipType",
    renderHeader: (params) => <strong>{"Ownership"}</strong>,
    width: 150,
  },
  {
    field: "DocumentType",
    renderHeader: (params) => <strong>{"Document"}</strong>,
    width: 150,
  },
];

export const QR_DISTRIBUTION_BY_PROGRAM_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "QuantityIssued",
    renderHeader: (params) => <strong>{"Quantity Issued"}</strong>,
    width: 150,
  },
  {
    field: "Cost",
    renderHeader: (params) => <strong>{"Unit Price (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "UnitCost",
    renderHeader: (params) => <strong>{"Unit Cost (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    width: 200,
    valueGetter: getDate,
  },
  {
    field: "STVNumber",
    renderHeader: (params) => <strong>{"STV Number"}</strong>,
    width: 150,
  },
  {
    field: "Region",
    renderHeader: (params) => <strong>{"Region"}</strong>,
    width: 150,
  },
  {
    field: "Zone",
    renderHeader: (params) => <strong>{"Zone"}</strong>,
    width: 150,
  },
  {
    field: "Woreda",
    renderHeader: (params) => <strong>{"Woreda"}</strong>,
    width: 150,
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Institution"}</strong>,
    width: 150,
  },
  {
    field: "InstitutionType",
    renderHeader: (params) => <strong>{"Institution Type"}</strong>,
    width: 150,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity"}</strong>,
    width: 150,
  },
  {
    field: "OwnershipType",
    renderHeader: (params) => <strong>{"Ownership"}</strong>,
    width: 150,
  },
  {
    field: "DocumentType",
    renderHeader: (params) => <strong>{"Document"}</strong>,
    width: 150,
  },
];

export const QR_SOH_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "AmountBalanceProgress",
    renderHeader: (params) => <strong>{"SOH(ETB)"}</strong>,
    width: 150,
  },
  {
    field: "BalanceProgress",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    width: 150,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    width: 150,
  },
  {
    field: "Issued",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    width: 150,
  },
  {
    field: "Received",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    width: 150,
  },
  {
    field: "Expired",
    renderHeader: (params) => <strong>{"Expired"}</strong>,
    width: 150,
  },
  {
    field: "Reserved",
    renderHeader: (params) => <strong>{"Reserved"}</strong>,
    width: 150,
  },
  {
    field: "Suspended",
    renderHeader: (params) => <strong>{"Suspended"}</strong>,
    width: 150,
  },
  {
    field: "Damaged",
    renderHeader: (params) => <strong>{"Damaged"}</strong>,
    width: 150,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity"}</strong>,
    width: 150,
  },
];

export const QR_LOCAL_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "QuantityReceived",
    renderHeader: (params) => <strong>{"Quantity Received"}</strong>,
    width: 150,
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    width: 200,
    valueGetter: getDate,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    width: 150,
  },
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    width: 150,
  },
];

export const QR_NEAR_EXPIRY_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 150,
  },
  {
    field: "Amount",
    renderHeader: (params) => <strong>{"Amount (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    width: 200,
    valueGetter: getDate,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    width: 150,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity"}</strong>,
    width: 150,
  },
];

export const QR_EXPIRED_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"No."}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 150,
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 150,
  },
  {
    field: "Amount",
    renderHeader: (params) => <strong>{"Amount (Birr)"}</strong>,
    width: 150,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    width: 200,
    valueGetter: getDate,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    width: 150,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity"}</strong>,
    width: 150,
  },
];