
import {
  CONTRACT_PROCESS_SEA,
  CONTRACT_PROCESS_AIR,
  TENDER_PROCESS
} from "./ProcurementConstants";

import { Formatter } from "../../../Utils/NumberUtils";
import { SumByProperty, SumByPropertyNegative, AverageByProperty } from "../../../Utils/ArrayUtils";
import { DateDiffCeil } from "../../../Utils/DateUtils";

export function PyramidDataProcessor (widgetData, config) {
  let pyramidData = config.type === "Air" ? CONTRACT_PROCESS_AIR : config.data_template;
  let aggregationProperty = config.aggregation_property;

  pyramidData.forEach(element => {
    element.value = widgetData.filter(row => row[aggregationProperty] === element.label).length;
  });
  return pyramidData;
}

export function PieChartDataProcessor (widgetData, config) {
  let pieChartData = config.type === "Air" ? CONTRACT_PROCESS_AIR : config.data_template;
  pieChartData = pieChartData.filter(row => row.label !== "Completed");
  let labels = [];
  let dataValues = [];
  pieChartData.forEach((element, index) => {
    labels.push(element.label);
    dataValues.push(AverageByProperty(widgetData, element.value_property + "AgainstTarget", true, true));
  });
  return labels.map((l, i) => ({name: l, size: dataValues[i]}));
}

export function CardsDataProcessor (widgetData, config) {
  let cards = config.data_template;
  let aggregationProperty = config.aggregation_property;

  const values = { 
    activeTenders: widgetData.filter(row => row[aggregationProperty]).length,
    totalTenders: widgetData.filter(row => row.label !== "Total").length,
    totalValueOfTenders: Formatter(SumByProperty(widgetData, "TotalValue"), 1)
  };

  cards.forEach(card => {
    card.value = values[card.value_property];
  });

  return cards;
}

export function GridDataProcessor (widgetData) {
  let gridData = widgetData;
  widgetData.forEach((element, index) => {
    element.id = index + 1;
  });
  if(gridData.filter(row => row.label === "Total").length === 0)
    gridData.push(
        { 
          id: 1000, 
          label: "Total", 
          TotalValue: 0,
          TotalDaysConsumed: SumByProperty(widgetData, "TotalDaysConsumed"),
          TotalDaysRemaining: SumByProperty(widgetData, "TotalDaysRemaining")
        });
  return gridData;
}

export function SelectedRowDataProcessor (widgetData, selectRowIndex, config) {
  let rowData = config.type === "Air" ? CONTRACT_PROCESS_AIR : config.data_template;
  rowData = rowData.filter(row => row.label !== "Completed");

  rowData.forEach(element => {
    element.value2 = widgetData[selectRowIndex][element.value_property + "StartDate"];
  });
  return rowData;
}

export function ContractProcessDataTransformer (fetched_data) {
  fetched_data.forEach(row => {
    let totalDaysConsumed = 0;
    let totalDaysSaved = 0;
    let totalTarget = 0;

    row.Type = row.InvoiceType === 
        "Invoice Transportation by Air" ? "Air" : "Sea";

    let processTemplate = row.Type === "Sea" 
        ? CONTRACT_PROCESS_SEA
        : CONTRACT_PROCESS_AIR
    processTemplate.forEach(process => {
      const valueProperty = process.value_property;
      const valuePropertyNext = process.value_property_next;
      row[valueProperty + "DaysConsumed"] = 
          DateDiffCeil(row[valuePropertyNext + "StartDate"], row[valueProperty + "StartDate"]);
      row[valueProperty + "AgainstTarget"] = row[valueProperty + "DaysConsumed"] !== null
          ? process.target - row[valueProperty + "DaysConsumed"]
          : null;
      totalDaysConsumed += row[valueProperty + "DaysConsumed"];
      totalDaysSaved += row[valueProperty + "AgainstTarget"];
      totalTarget += process.target;
    });
    
    row.TotalDaysConsumed = totalDaysConsumed;
    row.TotalDaysRemaining = totalTarget - totalDaysConsumed;
    row.TotalDaysSaved = totalDaysSaved;
  });
  return fetched_data;
}

export function TenderProcessDataTransformer (fetched_data) {
  fetched_data.forEach(row => {
    let totalDaysConsumed = 0;
    let totalDaysSaved = 0;
    let totalTarget = 0;

    TENDER_PROCESS.forEach(process => {
      const valueProperty = process.value_property;
      const valuePropertyNext = process.value_property_next;
      row[valueProperty + "DaysConsumed"] = 
          DateDiffCeil(row[valuePropertyNext + "StartDate"], row[valueProperty + "StartDate"]);
      row[valueProperty + "AgainstTarget"] = row[valueProperty + "DaysConsumed"] !== null
          ? process.target - row[valueProperty + "DaysConsumed"]
          : null;
      totalDaysConsumed += row[valueProperty + "DaysConsumed"];
      totalDaysSaved += row[valueProperty + "AgainstTarget"];
      totalTarget += process.target;
    });
    
    row.TotalDaysConsumed = totalDaysConsumed;
    row.TotalDaysRemaining = totalTarget - totalDaysConsumed;
    row.TotalDaysSaved = totalDaysSaved;
  });
  return fetched_data;
}

export function LineChartDataTransformer (fetched_data) {
  const labels = ['June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May' ];
  const backgroundColor = [
    "#15616d",
    "#e06c00",
    "#77af9c",
    "#c94057",
    "#acd2ed",
    "#78290f",
    "#73605b",
    "#4d648d",
    "#e4ea8c",
    "#ffddad",
    "#001524",
    "#4d648d",
    "#265c00",
  ];

  let lineChartDataset = [];
  fetched_data.forEach(row => {
    let existingItem = lineChartDataset.find(item => item.label === row.Status);
    
    if (!existingItem) {
      let dataset = { };
      dataset.label = row.Status;
      dataset.data = [ row.Performance ];
      lineChartDataset.push(dataset);
      dataset.startMonth = row.MonthName.replaceAll(/\s/g,'');
    }
    else {
      existingItem.data.push(row.Performance);
    }
  });

  lineChartDataset.forEach((dataset, i) => {
    let emptyData = [];
    let index = 0;
    while(dataset.startMonth !== labels[index] && index < labels.length) {
      emptyData.push(null);
      index++;
    }
    dataset.data = emptyData.concat(dataset.data);
    dataset.borderColor = backgroundColor[i];
    dataset.backgroundColor = backgroundColor[i];
  });

  return lineChartDataset;
}

const ProcurementHelper = []
export default ProcurementHelper;