import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import { FACILITY_STOCK_STATUS_API, FACILITY_SYNC_API,} from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import { ACCOUNT_SELECT_CONFIG, COMMODITY_TYPE_SELECT_CONFIG, INSTITUTION_SELECT_CONFIG, REGION_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import { getDaguFacilitySyncStatus, getDaguFacilityTotalCount } from "../../../Services/ApiManager";
import { getShortDate } from "../../../Utils/NumberUtils";

function getIndex(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

function getNonNegativeAmc(params) {
  return Math.max(0, params.row.amc);
}

function getNonNegativeMos(params) {
  return Math.max(0, params.row.mos);
}

function getNonNegativeSoh(params) {
  return Math.max(0, params.row.storeSoh);
}

const FACILITY_SYNC_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getIndex,
  },
  {
    field: "institution",
    renderHeader: (params) => <strong>{"Institution"}</strong>,
    flex: 1.3,
  },
  {
    field: "lastSyncDate",
    renderHeader: (params) => <strong>{"Last Sync Date"}</strong>,
    flex: 1,
    valueFormatter: getShortDate,
  },
  {
    field: "recentSyncCount",
    renderHeader: (params) => <strong>{"Synced in 30 Days"}</strong>,
    flex: 1,
  },
];


const STOCK_STATUS_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 50,
    valueGetter: getIndex,
  },
  {
    field: "item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 2,
  },
  {
    field: "unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 0.8,
  },
  {
    field: "commodityType",
    renderHeader: (params) => <strong>{"Commodity Type"}</strong>,
    flex: 1.5,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    flex: 1,
    type: 'decimalZero',
    valueGetter: getNonNegativeAmc,
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    flex: 1,
    type: 'decimal',
    valueGetter: getNonNegativeMos,
  },
  {
    field: "receivedQuantity",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "issuedQuantity",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "damagedQuantity",
    renderHeader: (params) => <strong>{"Damaged"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "expiredQuantity",
    renderHeader: (params) => <strong>{"Expired"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "storeSoh",
    renderHeader: (params) => <strong>{"Store SOH"}</strong>,
    flex: 1,
    type: 'decimalZero',
    valueGetter: getNonNegativeSoh,
  },
];


const STOCK_STATUS_PARAM = new FormData();


const FaclityDashboard = () => {
  const [headerInfo, setHeaderInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(7, 'day').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }

  useEffect(() => {
    async function fetchData() {
      getDaguFacilitySyncStatus().then((data) => {
        setHeaderInfo(data.data.model);
      });
      getDaguFacilityTotalCount().then((data) => {
        setTotalCount(data.data.totalCount)
      })
    }
    fetchData();
  }, []);

  
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Facility Dashboard`} />
      <div className="card-container mt-3" style={{display: "flex", border: "1px solid lightgrey", padding: "10px 10px", alignItems: "center"}}>
          <CustomCard
            widget_config={{
              title: "Total Facilities",
              value: totalCount,
              logo_url: sohPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Total Synced Facilities",
              value: headerInfo.length,
              logo_url: stackPic,
            }}
          />
      </div>
      <div className="grid-section">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Facility Sync Status",
              data_source_api: FACILITY_SYNC_API,
              data_source: "dagu",
              data_property: "model",
              columns: FACILITY_SYNC_COLUMN,
              isSOH: false,
              wgInfoId: 'wgDaguFacilitySyncStatus',
              use_default_filter_params: false,
              defaultFilterParams: {},
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Stock Status",
              data_source_api: FACILITY_STOCK_STATUS_API,
              data_source: "dagu",
              method: "POST",
              data_property: "model",
              columns: STOCK_STATUS_COLUMN,
              isSOH: false,
              wgInfoId: 'wgDaguStockStatus',
              use_default_filter_params: false,
              select_filter_config: [
                REGION_SELECT_CONFIG,
                INSTITUTION_SELECT_CONFIG,
                ACCOUNT_SELECT_CONFIG,
                COMMODITY_TYPE_SELECT_CONFIG,
              ],
              defaultFilterParams: STOCK_STATUS_PARAM,
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .card-container {
    margin: 3vw 5vw;;
  }
`;

export default FaclityDashboard;
