import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../shared/NavBar";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import {
  STAGING_ISSUE_API,
  STAGING_SOH_API,
} from "../../shared/Shared Utils/AppData";
import { HUB } from "../../shared/Shared Utils/InputConstants";

import { STOCK_STATUS_DEFAULT_PARAM } from "../../shared/Shared Utils/DataTableConstants";
import {
  ALTERNATE_ITEM_COLUMN,
  STAGING_ISSUE_COLUMN,
  STAGING_SOH_COLUMN,
} from "./ProgramsTableColumns";
export default function StagingData() {
  const history = useNavigate();
  const location = useLocation();
  const programCode = location.state.ProgramCode;

  const headerName = location.state.ProgramName;
  function loadFilterToDefaultParams(dateOption) {
    const filterObj = {};
    let newDate = new Date();
    let day = newDate.getDate();
    let month = newDate.getMonth() - 1;
    let year = newDate.getFullYear();
    let previousMonth = `${month}/${day}/${year}`;
    let day1 = newDate.getDate();
    let month1 = newDate.getMonth() + 1;
    let year1 = newDate.getFullYear();
    let currentDate = `${month1}/${day1}/${year1}`;
    if (dateOption === "dateRange") {
      filterObj["From"] = previousMonth;
      filterObj["To"] = currentDate;
      filterObj["IssueType"] = "CTH";
    } else {
      filterObj["TransactionDate"] = currentDate;
    }
    filterObj["ProgramCode"] = programCode;
    filterObj["ModeCode"] = "hpr";

    return filterObj;
  }

  return (
    <Wrapper>
      <div>
        {" "}
        <NavBar title={`Fanos ${headerName} Staging Dashboard`} />
      </div>
      <div className="container-box">
        <div className="grid">
          <CustomDataGrid
            component_config={{
              title: "Issued - Center to Hub",
              data_source_api: STAGING_ISSUE_API,
              data_property: "Data",
              columns: STAGING_ISSUE_COLUMN,
              isSOH: false,
              use_default_filter_params: false,
              defaultFilterParams: loadFilterToDefaultParams("dateRange"),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                start_date_param: "From",
                end_date_param_name: "To",
                end_data_param: "To",
              },
            }}
          />
        </div>
        <div className="grid">
          <CustomDataGrid
            component_config={{
              title: "Stock Status",
              data_source_api: STAGING_SOH_API,
              data_property: "Data",
              columns: STAGING_SOH_COLUMN,
              isSOH: false,
              use_default_filter_params: false,
              defaultFilterParams: loadFilterToDefaultParams("dateFilter"),
              select_filter_config: [HUB],
              date_picker: {
                date_range: false,
                start_date_param_name: "TransactionDate",
                start_date_param: "TransactionDate",
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  .container-box {
    background: #fff;
    padding: 35px 5vw;
    margin: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
`;
