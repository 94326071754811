import React, { Component, useEffect, useRef } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

// css
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// custom
import { IsEmpty } from "./FormatHelpers";

function CustomDatePicker({ component_config, handle_change }) {
  const myRef = useRef();
  component_config = component_config == null ? {} : component_config;

  const startDateParamName = IsEmpty(component_config.start_date_param_name)
    ? "from"
    : component_config.start_date_param_name;
  const endDateParamName = IsEmpty(component_config.end_date_param_name)
    ? "to"
    : component_config.end_date_param_name;

  const handleDateChange = (event, picker) => {
    const start = picker.startDate
      ? moment(picker.startDate._d).format("MM/DD/YYYY")
      : null;
    handle_change({
      [startDateParamName]: start,
    });
  };

  const handleDateRangeChange = (event, picker) => {
    const start = picker.startDate
      ? moment(picker.startDate._d).format("YYYY-MM-DD")
      : null;
      
    const end = picker ? moment(picker.endDate._d).format("YYYY-MM-DD") : null;
    handle_change({
      [startDateParamName]: start,
      [endDateParamName]: end,
    });
  };

  useEffect(() => {
    if(component_config.start_date){
      myRef.current.setStartDate(moment(new Date(component_config.start_date)).format("MM/DD/YYYY"));
    }
    if(component_config.end_date){
      myRef.current.setEndDate(moment(new Date(component_config.end_date)).format("MM/DD/YYYY"));
    }
  }, [component_config])

  return (
    <>
      {!component_config.date_range && (
        <DateRangePicker
          ref={myRef}
          initialSettings={{
            singleDatePicker: true,
          }}
          onApply={handleDateChange}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      )}
      {component_config.date_range && (
        <DateRangePicker 
          ref={myRef}
          onApply={handleDateRangeChange}
          initialSettings={component_config.no_date? {}
          :{startDate: component_config.quarter_report?
              `${moment().subtract(3, 'months').format("MM/DD/YYYY")}`:
              component_config.yearly_report?
              `${moment().subtract(1, 'years').format("MM/DD/YYYY")}`:
              component_config.weekly_report?
              `${moment().subtract(1, 'week').format("MM/DD/YYYY")}`:
              `${moment().subtract(1, 'months').format("MM/DD/YYYY")}`, 
            endDate: `${(new Date()).getMonth()+1}/${(new Date()).getDate()}/${(new Date()).getFullYear()}` }}>
          <input type="text" className="form-control" />
        </DateRangePicker>
      )}
    </>
  );
}

export default CustomDatePicker;
