import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";

export default function CustomPieChart({
  data,
  label,
  fields,
  style,
  component_config
}) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dataValue = [];
    const dataLabel = [];

    data &&
      data.map((element) => {
        element && dataValue.push(parseInt(element[fields[0]]));
        element && dataLabel.push(element[fields[1]]);
        setLoading(false);
      });
    setReceivedData(dataValue);
    setReceivedLabel(dataLabel);
  }, [data]);

  const options = {
    layout: {
      padding: 10,
    },
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + "%";
          return percentage;
        },
        display: "auto",
        color: "white",
      },
      legend: {
        position: "bottom",
        // align: "start",
        labels: {
          boxWidth: 15,
          borderColor: "white",
        },
      },
      labels: {
        render: "percentage",
      },
    },
  };

  return (
    <Wrapper>
      <div style={{margin: "0 auto", ...style}}>
        <Pie
          data={{
            yValueFormatString: "$####.00",
            labels: [...recievedLabel],
            datasets: [
              {
                label: { label },
                data: [...recievedData],
                backgroundColor: component_config?.background_color ? component_config?.background_color : [
                  "#8ed0d1",
                  "#0b7075",
                  "#c4b8a6",
                  "#098d98",
                  "#0a4f5a",
                  "#8ed0d1",
                  "#ddc908",
                  "#67a789",
                ],

                borderWidth: 1,
                borderColor: "white",
                hoverOffset: 10,
              },
            ],
            datalabels: {
              align: "end",
              anchor: "start",
            },
          }}
          plugins={[ChartDataLabels]}
          options={options}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .select {
    display: block;
    width: 5.8rem;
    height: 36px;
    background-color: white;
    border-radius: 4px;
    transition: 0.4s;
    margin-bottom: -15px;
  }
  .header-space {
    left: -20px;
    position: relative;
  }
`;
