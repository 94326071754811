import React, { Component } from 'react';

export default class TreeMapItem extends Component {
  static displayName = 'TreemapItem';

  static defaultProps = {};

  render() {
    const { depth, x, y, width, height, index, name, bgColors } = this.props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={bgColors[index]}
          stroke="#fff"
          strokeWidth={2 / (depth + 1e-10)}
          strokeOpacity={1 / (depth + 1e-10)}
        />
        {depth === 1 ? (
          <text x={x + width / 2} y={y + height / 2 + 9} textAnchor="middle" fill="#fff" stroke="none" fontSize={12}>
            {name}
          </text>
        ) : null}
      </g>
    );
  }
}