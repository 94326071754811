import React, { useEffect, useState } from "react";

import { Chart as ChartJS, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { getFanosData } from "../../../Services/ApiManager";
import { barLineComboChartDataFormatter } from "../Shared Utils/DataServiceHelper";
import styled from "styled-components";

import CustomLoadingIndicator from "../Shared Utils/CustomLoadingIndicator";

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const BarLineComboChart = ({ component_config }) => {
  
  const [chartData, setChartData] = useState({ label: [], line: [], bar: [], lineRef: [] });
  const [isLoading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchOptions() {
      await getFanosData( 
        component_config.api_url,
        {},
        component_config.data_source
      ).then((data) => {
        setLoading(false);
        setChartData(barLineComboChartDataFormatter(data.data.Data));
      })
    }

    fetchOptions();
  }, []);

  const data = {
    labels: chartData.label,
    datasets: [
      {
        type: 'bar',
        label: 'Issued Quantity',
        data: chartData.bar,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        type: 'line',
        label: 'Moving Average',
        data: chartData.line,
        backgroundColor: 'rgba(255, 99, 132, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        fill: false,
      },
      {
        type: 'line',
        label: 'Average Issued Quantity',
        data: chartData.lineRef,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
        fill: false,
      }
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    elements: {
      line: {
        tension : 0.4  // smooth lines
      },
      point:{
          radius: 1.5
      }
    }
  };

  return (
  <Wrapper className="rs-widget">
    <div className="flex-column rs-widget-container">

      <div className="rs-w-header flex-stretch">
        <div className="flex-row flex-space-between">
          <div className="rs-w-title">
            <div className="flex-row">
              <h6>{ component_config.title }</h6>
              { isLoading && ( <span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                  <CustomLoadingIndicator />
                </span>)}
            </div>
          </div>
        </div>
      </div>

      <div className="rs-w-body">
        <div className="rs-w-b-container">
          { !isLoading && <Bar data={data} options={options} /> }
        </div>
      </div>

    </div>
  </Wrapper>);
};

const Wrapper = styled.div`
`;

export default BarLineComboChart;