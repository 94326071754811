import React, { useEffect, useRef, useState } from "react";
import "./Charts.css";
import "chart.js/auto";
import { Chart } from 'chart.js';
import { Bar } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaExpandAlt, FaPrint, FaSave } from "react-icons/fa";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GroupedStackedBarChart from "./GroupedStackedBarChart";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useReactToPrint } from "react-to-print";
import Dots from "../../../assets/GIFs/dotss.gif";
import InfoIcon from '@mui/icons-material/Info';
import { Button } from "@mui/material";
import { GridInfo } from "../Shared Utils/InfoGrid";
import CustomChartSelect from "../Shared Utils/CustomChartSelect";
import CustomDatePicker from "../Shared Utils/CustomDatePicker";
import { CheckFloat, FormatDecimal } from "../../../Utils/NumberUtils";
import { IsEmpty } from "../Shared Utils/FormatHelpers";

Chart.register(annotationPlugin);

export default function CustomBarChart({
  datas,
  labels,
  fields,
  Lengths,
  styles,
  chart_options,
  defaultFilterParams,
  selectFilterConfig,
  parameter,
  setParameter,
  datePicker,
  tabTitles,
  chartTypes,
  changeLoading,
  background
}) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [recievedAmount, setReceivedAmount] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [expand, setExpand] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const chartFilterParams = defaultFilterParams;
  const defaultStyle = {
    padding: "1rem",
    position: "relative",
    width: "33vw",
    height: "18rem",
  };

  styles = styles
    ? styles.map((style) => (style ? style : defaultStyle))
    : null;

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton style={{ color: "cadetblue" }} />
        <GridToolbarDensitySelector style={{ color: "cadetblue" }} />
        <GridToolbarExport csvOptions={{fileName: chart_options.title}}  style={{ color: "cadetblue" }} />
      </GridToolbarContainer>
    );
  }

  const backgroundColor = chart_options.background ? chart_options.background : [
    "#12b3b7",
    "#8ed0d1",
    "#67a789",
    "#15616d",
    "#ffddad",
    "#e06c00",
    "#098d98",
    "#ddc908",
    "#77af9c",
    "#78290f",
    "#0b7075",
    "#acd2ed",
    "#0a4f5a",
    "#c4b8a6",
    "#001524",
    "#265c00",
    "#73605b",
    "#e4ea8c",
    "#4d648d",
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const hubs = ["HUB", "CENTER"];

  let count = 0;
  useEffect(() => {
    const dataValue = [];
    const dataLabel = [];

    datas &&
      datas.map((data, i) => {
        dataLabel.push([]);
        dataValue.push([]);
        data &&
          data.length > 0 &&
          labels[i]?.map((l, index) => {
            let temp = {
              label: l,
              data: [],
              backgroundColor: backgroundColor[index],
            };
            data.map((el, index1) => {
              temp.data.push(el[fields[i][index]]?.toString());

              if (dataLabel[i].indexOf(el[chart_options.option]) < 0) {
                dataLabel[i].push(el[chart_options.option]);
              }
            });
            dataValue[i].push(temp);
            setLoading(false);
          });
      });

    setReceivedLabel(dataLabel);
    setReceivedData(dataValue);

    let tempDatasets = dataLabel.map((e, i) => {
      return {
        labels: [...dataLabel[i]],
        datasets: [...dataValue[i]],
      };
    });
    setDatasets(tempDatasets);
  }, [datas]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: chart_options.indexAxis && chart_options.indexAxis == 'y'? 'y': 'x',
    plugins: {
      legend: {
        labels: {
          boxWidth: 15,

          font: {
            usePointStyle: true,
            boxWidth: 6,
            size: 11,
          },
        },
        display: chart_options.title !== "National MOS" && !chart_options.hideLegend,
        position: "bottom",
        maxWidth: 9,
      },
      
    },
    tooltips: chart_options.max && chart_options.indexAxis != 'y'?
      chart_options.tooltips.map((t, i) => {
        return {
          callbacks: {
            title: (item) => chart_options.customTooltipsTitle? datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][chart_options.customTooltipsTitle]: datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][t],
            label: (context) => {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.y !== null) {
                  if(chart_options.customTooltips && chart_options.showAmount){
                    label += `${FormatDecimal(datas[i][context.dataIndex][chart_options.customTooltips[context.datasetIndex]], 0)} (${FormatDecimal(context.parsed.y, 1)}%)`;
                  } else {
                    label += `${context.parsed.y}%`;
                  }
              }
              return label;
            }
          },
          mode: "index",
        };
      }):
      chart_options.max && chart_options.indexAxis == 'y'?
      chart_options.tooltips.map((t, i) => {
        return {
          callbacks: {
            title: (item) => chart_options.customTooltipsTitle? datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][chart_options.customTooltipsTitle]: datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][t],
            label: (context) => {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              if (context.parsed.x !== null) {
                  if(chart_options.customTooltips && chart_options.showAmount){
                    label += `${FormatDecimal(datas[i][context.dataIndex][chart_options.customTooltips[context.datasetIndex]], 0)} (${FormatDecimal(context.parsed.x, 1)}%)`;
                  } else {
                    label += `${context.parsed.x}%`;
                  }
              }
              return label;
            }
          },
          mode: "index",
        };
      }):
      chart_options.customTooltipsTitle?
      chart_options.tooltips.map((t, i) => {
        return {
          callbacks: {
            title: (item) => datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][chart_options.customTooltipsTitle],
          },
          mode: "index",
        };
      }):
      chart_options.tooltips.map((t, i) => {
        return {
          callbacks: {
            title: (item) => datas[i].filter((d) => d[chart_options.option] === item[0].label)[0][t],
          },
          mode: "index",
        };
      }),

    //  indexAxis: labels[0][0] === ">3M" ? "y" : "x",
    animations: {
      tension: {
        duration: 1000,
        easing: "easeOutExpo",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      x:  chart_options.max && chart_options.indexAxis == 'y'?
      {
        stacked: true,
        min:  0,
        max: chart_options.max,
        ticks: {
          stepSize: 20,
          callback: function (value, index, values) {
            return value + " %";
          }
        },
      }:
      {
        // grid: {
        //   display: labels[0][0] === ">3M" ? true : false,
        // },
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
          font: {
            size: 10,
          },
        },
      } ,
      y: chart_options.max && chart_options.indexAxis != 'y'? {
        stacked: true,
        min:  0,
        max: chart_options.max,
        ticks: {
          stepSize: 20,
          callback: function (value, index, values) {
            return value + " %";
          }
        },
        title: chart_options.yTitle? {
          display: true,
          text: chart_options.yTitle,
        }: 
        { display: false},
      }:
      !chart_options.max && chart_options.indexAxis != 'y'?
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            if (value < 1e3) return value;
            if (value > 1e3 && value < 1e6)
              return (value / 1e3).toFixed(2) + "K";
            if (value >= 1e6 && value < 1e9)
              return +(value / 1e6).toFixed(2) + "M";
            if (value >= 1e9) return +(value / 1e9).toFixed(2) + "B";
          },
          autoSkip: false,
        },
        title: chart_options.yTitle? {
          display: true,
          text: chart_options.yTitle,
        }: 
        { display: false},
      }:
      chart_options.indexAxis == 'y'? {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
          font: {
            size: 10,
          },
          callback: function (value, index, values) {
            return this.getLabelForValue(value)? this.getLabelForValue(value).slice(0, Math.min(25, this.getLabelForValue(value).length)): "";
          },
        },
      }:
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            if (value < 1e3) return value;
            if (value > 1e3 && value < 1e6)
              return (value / 1e3).toFixed(2) + "K";
            if (value >= 1e6 && value < 1e9)
              return +(value / 1e6).toFixed(2) + "M";
            if (value >= 1e9) return +(value / 1e9).toFixed(2) + "B";
          },
          autoSkip: false,
        },
        title: chart_options.yTitle? {
          display: true,
          text: chart_options.yTitle,
        }: 
        { display: false},
      },
    },
  };

  const annotationsPlugin = {
    annotation: {
      annotations: [
        {
          id: "maxLine",
          type: 'line',
          mode: 'horizontal',
          value: chart_options.annotationLines? chart_options.annotationLines[0]: 0,
          scaleID: "y",
          borderWidth: 1,
          label: {
            font: {
              size: 10
            },
            display: true,
            content: `Max`,
            position: 'start',
          }
        },
        {
          id: "minLine",
          type: 'line',
          mode: 'horizontal',
          value: chart_options.annotationLines? chart_options.annotationLines[1]: 0,
          scaleID: "y",
          borderWidth: 1,
          label: {
            font: {
              size: 10
            },
            display: true,
            content: `Min`,
            position: 'middle',
          }
        },
        {
          id: "eop",
          type: 'line',
          mode: 'horizontal',
          value: chart_options.annotationLines? chart_options.annotationLines[1]/2: 0,
          scaleID: "y",
          borderWidth: 1,
          label: {
            font: {
              size: 10
            },
            display: true,
            content: `EOP`,
            position: 'end',
          }
        }
      ]
    }
  }


  const parseGridRows = (data, mainKey) => {
    return data.datasets.map((d, j) => {
      let hub = { [mainKey]: d.label };
      data.labels.map((label, i) => {
        hub[label] = d.data[i];
      });
      return hub;
    });
  };

  const parseGridColumns = (data, mainKey) => {
    const columns = data.labels.map((property) => {
      return {
        field: property,
        headerName: property,
        flex: 1,
        sortable: false,
        valueFormatter: (params) => property.toLowerCase() === "percentage"? `${FormatDecimal(parseFloat(params.value), 1)}%`: CheckFloat(params.value)? FormatDecimal(parseFloat(params.value), 0): params.value,
      };
    });
    const columnsWithKey = [
      {
        field: mainKey,
        headerName: mainKey,
        flex:1.5,
      },
      ...columns,
    ];
    return columnsWithKey;
  };

  const defaultColumn = (data) => {
    const columns = Object.keys(data).map((property) => {
      return {
        field: property,
        headerName: property,
        flex: 1,
        sortable: false,
        valueFormatter: (params) => property.toLowerCase() === "percentage"? `${FormatDecimal(parseFloat(params.value), 1)}%`: CheckFloat(params.value)? FormatDecimal(parseFloat(params.value), 0): params.value,
      };
    });
    return columns;
  };

  const withMainColumn = (data, field, mainKey) => {
    const columns = Object.keys(data).filter((d) => field.includes(d)).map((property) => {
      return {
        field: property,
        headerName: property,
        flex: 1,
        sortable: false,
        valueFormatter: (params) => property.toLowerCase() === "percentage"? `${FormatDecimal(parseFloat(params.value), 1)}%`: CheckFloat(params.value)? FormatDecimal(parseFloat(params.value), 0): params.value,
      };
    });

    const columnsWithKey = [
      {
        field: mainKey,
        headerName: mainKey,
        flex: 1,
      },
      ...columns,
    ];

    return columnsWithKey;
  };

  const handle_change = (param) => {
    setParameter({...parameter, [param.paramName]: param.paramValue})
  };

  const onChangeDatePicker = (dates) => {
    setParameter({...parameter, ...dates})
  };

  const getStartDateParamName = (component_config) => {
    if(IsEmpty(component_config.start_date_param_name)){
      return "from"
    }
    return component_config.start_date_param_name;
  }

  const getEndDateParamName = (component_config) => {
    if(IsEmpty(component_config.end_date_param_name)){
      return "from"
    }
    return component_config.end_date_param_name;
  }

  const getDatePickerConfig = () => {
    if(Object.keys(parameter).includes(getStartDateParamName(datePicker))){
      datePicker.start_date = parameter[getStartDateParamName(datePicker)]
    } 
    if(Object.keys(parameter).includes(getEndDateParamName(datePicker))){
      datePicker.end_date = parameter[getEndDateParamName(datePicker)]
    } 
    return datePicker
  }

  return (
    <div className="container" style={{flex: 1, maxWidth: "unset"}}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-class">
            <Typography id="modal-modal-description">
              <Box display={"flex"}>
              <h6 style={{ margin: "20px" }}>
                {chart_options.title} -{" "}
                <FaPrint onClick={handlePrint} size={"1.2em"} />
              </h6>
              <div className="tool-bar-item-container" style={{ margin: "10px" }}>
                {selectFilterConfig?.map((config) => 
                (<div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config}
                  />
                </div>)
                )}
                {datePicker && (
                <div className="tool-bar-item" style={{width: datePicker.date_range? "13.5rem": "7rem", maxWidth: datePicker.date_range? "13.5rem": "7rem"}}>
                  <CustomDatePicker
                    className="date-picker-input"
                    handle_change={onChangeDatePicker}
                    component_config={getDatePickerConfig()}
                  />
                </div>)}
              </div>
              </Box>
              <div></div>

              {datasets.length > 1 ? (
                <Tabs style={{ paddingTop: 10 }}>
                  <TabList>
                    {datasets.map((l, i) => (
                      <Tab>{tabTitles[i]}</Tab>
                    ))}
                  </TabList>

                  {datasets.map((l, i) => (
                    <TabPanel>
                      <Tabs style={{ paddingTop: 10 }}>
                        <TabList>
                          <Tab>Charts</Tab>
                          <Tab>Tabular</Tab>
                        </TabList>
                        <TabPanel>
                          {chartTypes[i] === "bar-chart" ? (
                            <>
                              <div ref={componentRef}>
                                <Bar
                                  options={{
                                    ...options,
                                    plugins: chart_options.annotationLines && (chart_options.annotationTabs? chart_options.annotationTabs[i] : true)?
                                      {
                                        ...options.plugins,
                                        ...annotationsPlugin,
                                        tooltip: options.tooltips[i],
                                      }:
                                      {
                                        ...options.plugins,
                                        tooltip: options.tooltips[i],
                                      },
                                  }}
                                  data={datasets[i]}
                                  plugins={ChartDataLabels}
                                  style={{
                                    padding: "1rem",
                                    position: "relative",
                                    width: "69vw",
                                    height: "28rem",
                                    background: "white",
                                  }}
                                />
                              </div>
                            </>
                          ) : chartTypes[i] === "stacked-chart" ? (
                            <div ref={componentRef}>
                              <GroupedStackedBarChart
                                data={datas[i - 1]}
                                data1={datas[i]}
                                label={labels[i-1]}
                                fields={fields[i-1]}
                                label1={labels[i]}
                                fields1={fields[i]}
                                style={{
                                  padding: "1rem",
                                  position: "relative",
                                  width: "69vw",
                                  height: "28rem",
                                  background: "white",
                                }}
                                chart_options={{
                                  tooltip: {
                                    mode: "index",
                                  },
                                  option: "ProductCN",
                                  hideLegend: chart_options.title === "National MOS",
                                }}
                              />
                            </div>
                          ) : null}
                        </TabPanel>
                        <TabPanel>
                          <div ref={componentRef}>
                            <DataGrid
                              style={{
                                padding: "1rem",
                                position: "relative",
                                width: "69vw",
                                height: "28rem",
                                background: "white",
                                fontSize: "14px",
                              }}
                              autoHeight
                              initialState={{pagination:{paginationModel:{pageSize: 10, page:0}}}}
                              rows={chart_options.parseForGrid? parseGridRows(datasets[i], chart_options.option): datas[i]}
                              columns={chart_options.parseForGrid? parseGridColumns(datasets[i], chart_options.option): chart_options.parseForGridWithMain? withMainColumn(datas[i][0] || {}, fields[i], chart_options.option): defaultColumn(datas[i][0] || {},)}
                              getRowId={(row) => row[chart_options.option]}
                              rowHeight={30}
                              pageSize={10}
                              pageSizeOptions={[5, 10, 25]}
                              pagination
                              animateRows={true}
                              disableColumnSelector
                              components={{ Toolbar: CustomToolbar }}
                            />
                          </div>
                        </TabPanel>
                      </Tabs>
                    </TabPanel>
                  ))}
                </Tabs>
              ) : datasets.length === 1 ? (
                <Tabs style={{ paddingTop: 10 }}>
                  <TabList>
                    <Tab>Charts</Tab>
                    <Tab>Tabular</Tab>
                  </TabList>
                  <TabPanel>
                    <div ref={componentRef}>
                      <Bar
                        options={{
                          ...options,
                          plugins: chart_options.annotationLines? {
                            ...options.plugins,
                            ...annotationsPlugin,
                            tooltip: options.tooltips[0],
                          }: {
                            ...options.plugins,
                            tooltip: options.tooltips[0],
                          },
                        }}
                        data={datasets[0]}
                        plugins={ChartDataLabels}
                        style={{
                          padding: "1rem",
                          position: "relative",
                          width: "59vw",
                          height: "28rem",
                          background: "white",
                        }}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div ref={componentRef}>
                      <DataGrid
                        style={{
                          padding: "1rem",
                          position: "relative",
                          width: "69vw",
                          height: "32rem",
                          background: "white",
                          fontSize: "14px",
                        }}
                        autoHeight
                        initialState={{pagination:{paginationModel:{pageSize: 10, page:0}}}}
                        rows={chart_options.parseForGrid? parseGridRows(datasets[0], chart_options.option): datas[0]}
                        columns={chart_options.parseForGrid? parseGridColumns(datasets[0], chart_options.option): chart_options.parseForGridWithMain? withMainColumn(datas[0][0] || {}, fields[0], chart_options.option): defaultColumn(datas[0][0] || {})}
                        getRowId={(row) => row[chart_options.option]}
                        rowHeight={30}
                        pageSize={10}
                        pageSizeOptions={[5, 10, 25]}
                        pagination
                        animateRows={true}
                        disableColumnSelector
                        components={{ Toolbar: CustomToolbar }}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              ) : null}
            </Typography>
          </Box>
        </Modal>
      </div>
      {datasets.length > 1 ? (
        <>
          <div className="header-container">
            <div style={{display: 'flex'}} >
              <h6 style={{fontSize:"1.1rem"}}>
                {chart_options.title}
                {(!loading && !changeLoading) && (
                  <span style={{ marginLeft: 30 }}>
                    {chart_options.showExpandAlt && (
                      <FaExpandAlt className="expand-icon" onClick={handleOpen} />
                    )}
                    <Button onClick={() => setDialogOpen(true)}><InfoIcon style={{ color: "cadetblue" }} /></Button>
                  </span>
                )}
                {(loading || changeLoading) && (
                  <span>
                    <img src={Dots} alt="" />
                  </span>
                )}
              </h6>
              {chart_options.showHubFilter && 
              (<select
                  className="select"
                  style={{marginLeft: 0}}
                  onChange={(e) => chart_options.setEnvironment(e.target.value)}
                >
                  {hubs.map((el) => (
                  <option key={el} value={el}>
                      {el}
                  </option>
                  ))}
              </select>)}
            </div>
            <Tabs className="tabs">
              <TabList style={{ justifyContent: "end" }}>
                {datasets.map((l, i) => (
                  <Tab className="tab-list">{tabTitles[i]}</Tab>
                ))}
              </TabList>

              {datasets.map((l, i) => (
                <TabPanel>
                  {chartTypes[i] === "bar-chart" ? (
                    <div style={styles[i]}>
                      <Bar
                        options={{
                          ...options,
                          plugins: chart_options.annotationLines && (chart_options.annotationTabs? chart_options.annotationTabs[i] : true)?
                          {
                            ...options.plugins,
                            ...annotationsPlugin,
                            tooltip: options.tooltips[i],
                          }:
                          {
                            ...options.plugins,
                            tooltip: options.tooltips[i],
                          },
                        }}
                        data={datasets[i]}
                        plugins={ChartDataLabels}
                      />
                    </div>
                  ) : chartTypes[i] === "stacked-chart" ? (
                    <GroupedStackedBarChart
                      data={datas[i - 1]}
                      data1={datas[i]}
                      label={labels[i-1]}
                      fields={fields[i-1]}
                      label1={labels[i]}
                      fields1={fields[i]}
                      style={styles[i]}
                      chart_options={{
                        tooltip: {
                          mode: "index",
                        },
                        option: "ProductCN",
                        hideLegend: chart_options.title === "National MOS",
                      }}
                    />
                  ) : null}
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </>
      ) : datasets.length === 1 ? (
        <>
          <div className="flex-row" style={{ marginBottom: "6px" }}>
            <div
              style={{
                position: "relative",
                marginBottom: "0.1rem",
                color: "black",
              }}
            >
              <h6 style={{ fontSize: "1.1rem"}}>
                {chart_options.title}{" "}
                {(!loading && !changeLoading) && (
                  <span style={{ marginLeft: 30 }}>
                    {chart_options.showExpandAlt && (
                      <FaExpandAlt
                        className="expand-icon"
                        onClick={handleOpen}
                      />
                    )}
                    <Button onClick={() => setDialogOpen(true)}><InfoIcon style={{ color: "cadetblue" }} /></Button>
                  </span>
                )}
                {(loading || changeLoading) && (
                  <span>
                    <img src={Dots} alt="" />
                  </span>
                )}
              </h6>
              <div className="tool-bar-item-container">
                {selectFilterConfig?.map((config) => 
                (<div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
                  <CustomChartSelect
                    handle_change={ handle_change}
                    component_config={Object.keys(parameter).includes(config.param_name)? {...config, selected_value: parameter[config.param_name]}: config}
                  />
                </div>)
                )}
                {datePicker && (
                <div className="tool-bar-item" style={{width: datePicker.date_range? "13.5rem": "7rem", maxWidth: datePicker.date_range? "13.5rem": "7rem"}}>
                  <CustomDatePicker
                    className="date-picker-input"
                    handle_change={onChangeDatePicker}
                    component_config={getDatePickerConfig()}
                  />
                </div>)}
              </div>
              
            </div>
          </div>
          <div style={styles[0]}>
            <Bar
              options={{
                ...options,
                plugins: chart_options.annotationLines? {
                  ...options.plugins,
                  ...annotationsPlugin,
                  tooltip: options.tooltips[0],
                }: {
                  ...options.plugins,
                  tooltip: options.tooltips[0],
                },
              }}
              data={datasets[0]}
              plugins={ChartDataLabels}
            />
          </div>
        </>
      ) : null}
      <GridInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} widgetId={chart_options.wgInfoId} />
    </div>
  );
}
