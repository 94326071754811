
import { IsEmpty } from "../components/shared/Shared Utils/FormatHelpers";

export function SumByProperty(arr, propery) {
  const sum = arr.reduce((accumulator, object) => {
    if(!IsEmpty(object[propery]))
      return accumulator + object[propery];
    else
      return accumulator;
  }, 0);
  return !IsEmpty(sum) ? sum : 0;
}

export function SumByPropertyNegative(arr, propery) {
  const sum = arr.reduce((accumulator, object) => {
    if(!IsEmpty(object[propery]) & object[propery] < 0)
      return accumulator + object[propery];
    else
      return accumulator;
  }, 0);
  return !IsEmpty(sum) ? sum * -1 : 0;
}

export function AverageByProperty(arr, propery, invert_sign, negative_to_zero) {
  let sum = arr.reduce((accumulator, object) => {
    if(!IsEmpty(object[propery]) & object[propery] < 0)
      return accumulator + object[propery];
    else
      return accumulator;
  }, 0);

  let count = arr.reduce((accumulator, object) => {
    if(!IsEmpty(object[propery]) & object[propery] < 0)
      return accumulator + 1;
    else
      return accumulator;
  }, 0);

  if(IsEmpty(sum)) {
    sum = 0;
  }

  if(invert_sign) {
    sum = sum * -1;
  }

  if(negative_to_zero && sum < 0) {
    sum = 0;
  }
  return count !== 0 ? Math.round(sum / count) : 0;
}

export function GetNormalizeLabels(data, fields) {
  let emptyLabels = [];
  if(data.length > 0) {
    fields.forEach(field => {
      let fieldHasNoData = true;
      data.forEach(row => {
        if(row[field]) {
          fieldHasNoData = false;
          return;
        }
      });
      if(fieldHasNoData) emptyLabels.push(field);
    })
  };

  return fields.filter(str => !emptyLabels.includes(str.toString().replace(/\s+/g, '')));
}


const ArrayUtils = [
  
]

export default ArrayUtils;