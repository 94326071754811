import Axios from "axios";

const key = JSON.parse(localStorage.getItem("userInfo"));
let authkey;
if (key) {
  authkey = key.Key;
}
Axios.defaults.headers.common = { Authorization: `Basic ${authkey}` };

const { REACT_APP_API_ENDPOINT } = process.env;

//Header Request
export const getHeaderInformation = async (locationCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_webApi/LocationHeader",
    params: {
      EnvironmentCode: locationCode,
    },
  });
  return response;
};
//Chart Requests
export const getEnvTrend = async (EnvironmentCode, params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/EnvironmentTrend",
    params: {
      EnvironmentCode: EnvironmentCode,
      ...params
    },
  });
  return response;
};
export const getFiscalYearList = async () => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/LookUp/FiscalYearList",
  });
  return response;
};
export const getDistByProg = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/EnvironmentPivotedByProgram",
    params: params,
  });
  return response;
};
export const getDistByOwnership = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/EnvtPivotedByOwnershipType",
    params: params,
  });
  return response;
};
export const getDistByCommodityType = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/EnvironmentPivotedByCommodityType",
    params: params,
  });
  return response;
};
export const getDistByMode = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCH_WebApi/LocationReceiptByMode",
    params: params,
  });
  return response;
};
export const getDisposalAmount = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/EnvironmentDisposedAmount",
    params: params,
  });
  return response;
};
export const getFacilityCount = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByFacilityType",
    params: params,
  });
  return response;
};
export const getRecentReceives = async (EnvironmentCode, To, From) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/EnvironmentRecentReceipt",
    params: {
      EnvironmentCode: EnvironmentCode,
      From: "2022-10-12",
      To: "2022-10-17",
    },
  });
  return response;
};
export const getRecentIssued = async (EnvironmentCode, To, From) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OID_WebApi/EnvironmentRecentIssued",
    params: {
      EnvironmentCode: EnvironmentCode,
      To: "10/06/2022",
      From: "07/17/2022",
    },
  });
  return response;
};
export const getPendingOrders = async (EnvironmentCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RQD_WebApi/OpenOrders",
    params: {
      EnvironmentCode: EnvironmentCode,
      Month: "1",
    },
  });
  return response;
};
export const getStockStatus = async (EnvironmentCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/StockStatusByEnvironment",
    params: {
      EnvironmentCode: EnvironmentCode,
      OrderBy: "FullItemName",
    },
  });
  return response;
};
export const getGITByInvoice = async (EnvironmentCode, To, From) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RIH_WebApi/EnvironmentGITHeader",
    params: {
      EnvironmentCode: EnvironmentCode,
      To: "10/06/2022",
      From: "07/17/2022",
    },
  });
  return response;
};
export const getInvoice = async (EnvironmentCode, To, From) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/EnvironmentIssueByDocumentType",
    params: {
      EnvironmentCode: EnvironmentCode,
      To: "10/06/2022",
      From: "07/17/2022",
    },
  });
  return response;
};
const requests = [
  getHeaderInformation,

  //chart requests
  getEnvTrend,
  getDistByProg,
  getDistByOwnership,
  getDistByCommodityType,
  getDistByMode,
  getDisposalAmount,

  //table requests
  getRecentReceives,
  getRecentIssued,
  getPendingOrders,
  getStockStatus,
  getGITByInvoice,
  getInvoice,
];

export default requests;
