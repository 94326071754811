
// Icons
import { BsCheck, BsExclamation } from "react-icons/bs";

const sm = 50;
const md = 120;

export const TENDER_PROCESS = [
  { 
    id: 1, 
    label: "Preparation & Budget Analysis", 
    value: 1, 
    value_property: "ProcurementPlanAndBudgetAnalysis",
    value_property_next: "BiddingDocumentPreparation", 
    color: "rgb(18,35,158)",
    target: 8
  },
  { 
    id: 2, 
    label: "Bidding Document Preparation", 
    value: 1, 
    value_property: "BiddingDocumentPreparation",
    value_property_next: "AnnouncementofBidFloatation", 
    color: "rgb(17,141,255)",
    target:13
  },
  { 
    id: 3, 
    label: "Announcement of Bid Floatation", 
    value: 3, 
    value_property: "AnnouncementofBidFloatation",
    value_property_next: "TenderFloatation", 
    color: "rgb(107,0,123)",
    target: 2
  },
  { 
    id: 4, 
    label: "Tender Floatation", 
    value: 1, 
    value_property: "TenderFloatation",
    value_property_next: "TenderEvaluation", 
    color: "rgb(230,108,55)",
    target:38
  },
  { 
    id: 5, 
    label: "Tender Evaluation", 
    value: 3, 
    value_property: "TenderEvaluation",
    value_property_next: "AnnouncementOfWinner", 
    color: "rgb(116,78,194)",
    target: 35
  },
  { 
    id: 6, 
    label: "Announcement of Winner", 
    value: 1, 
    value_property: "AnnouncementOfWinner",
    value_property_next: "POPreparation", 
    color: "rgb(224,68,167)",
    target: 7
  },
  { 
    id: 7, 
    label: "PO Preparation", 
    value: 1, 
    value_property: "POPreparation",
    value_property_next: "ContractSignedByDG", 
    color: "rgb(217,179,0)",
    target: 10
  },
  { 
    id: 8, 
    label: "Contract Signed By DG", 
    value: 4, 
    value_property: "ContractSignedByDG",
    value_property_next: "Completed", 
    color: "rgb(17,141,255)",
    target:20
  },
  { 
    id: 9, 
    label: "Completed", 
    value: 4, 
    value_property: "", 
    color: "rgb(17,141,255)",
    target: 0
  }
];

export const CONTRACT_PROCESS_AIR = [
  { 
    id: 1, 
    label: "Receiving And Updation On CMS",
    value_property: "ReceivingAndUpdationOnCMS",
    value_property_next: "PerformanceBondReceivingAndVerifying",
    target: 1
  },
  { 
    id: 2, 
    label: "Performance Bond Receiving And Verifying",
    value_property: "PerformanceBondReceivingAndVerifying",
    value_property_next: "LCOpeningProcess",
    target: 5
  },
  { 
    id: 3, 
    label: "LC Opening Process",
    value_property: "LCOpeningProcess",
    value_property_next: "ShipmentFollowUpAir",
    target: 15
  },
  { 
    id: 5, 
    label: "Shipment Follow Up",
    value_property: "ShipmentFollowUpAir",
    value_property_next: "EFDAInspectionRequestAndFollowUpAir",
    target: 55
  },
  { 
    id: 8, 
    label: "EFDA Inspection Request AND Follow Up",
    value_property: "EFDAInspectionRequestAndFollowUpAir",
    value_property_next: "CustomsAndStoragePayment",
    target: 5
  },
  { 
    id: 9, 
    label: "Customs and Storage Payment",
    value_property: "CustomsAndStoragePayment", 
    value_property_next: "FollowUpofCustomReleaseAndWarehouseDeliveryAir",
    target: 0
  },
  { 
    id: 10, 
    label: "Follow Up of Custom Release", 
    value_property: "FollowUpofCustomReleaseAndWarehouseDeliveryAir", 
    value_property_next: "Completion",
    target: 1
  }
];

export const CONTRACT_PROCESS_SEA = [
  { 
    id: 1, 
    label: "Receiving And Updation On CMS",
    value_property: "ReceivingAndUpdationOnCMS",
    value_property_next: "PerformanceBondReceivingAndVerifying",
    target: 1
  },
  { 
    id: 2, 
    label: "Performance Bond Receiving And Verifying",
    value_property: "PerformanceBondReceivingAndVerifying",
    value_property_next: "LCOpeningProcess",
    target: 15
  },
  { 
    id: 3, 
    label: "LC Opening Process",
    value_property: "LCOpeningProcess",
    value_property_next: "OriginalDocumentReleaseFollowUp",
    target: 15
  },
  { 
    id: 5, 
    label: "Shipment Follow Up",
    value_property: "ShipmentFollowUp",
    value_property_next: "OriginalDocumentReleaseFollowUp",
    target: 55
  },
  { 
    id: 6, 
    label: "Original Document Release Follow Up",
    value_property: "OriginalDocumentReleaseFollowUp",
    value_property_next: "ESLEAndCustomsPayment",
    target: 25
  },
  { 
    id: 7, 
    label: "ESLE And Customs Payment",
    value_property: "ESLEAndCustomsPayment",
    value_property_next: "EFDAInspectionRequestAndFollowUpSea",
    target: 11
  },
  { 
    id: 8, 
    label: "EFDA Inspection Request AND Follow Up",
    value_property: "EFDAInspectionRequestAndFollowUpSea",
    value_property_next: "FollowUpofCustomReleaseAndWarehouseDeliverySea",
    target: 2
  },
  { 
    id: 10, 
    label: "Follow Up of Custom Release", 
    value_property: "FollowUpofCustomReleaseAndWarehouseDeliverySea", 
    value_property_next: "Completion",
    target: 2
  }
];

export const TENDER_PROCESS_CARDS = [
    {
      title: "Active Tenders",
      value_property: "activeTenders",
      accent_color: "#D4AF37",
      position: "left"
    },
    {
      title: "Total Tenders",
      value_property: "totalTenders",
      accent_color: "#D4AF37",
      position: "right"
    },
    {
      title: "Total Value of Tender ($)",
      value_property: "totalValueOfTenders",
      accent_color: "#D4AF37",
      position: "right"
    }
]

export const CONTRACT_PROCESS_CARDS = [
  {
    title: "Active Contracts",
    value_property: "activeTenders",
    accent_color: "#D4AF37",
    position: "left"
  },
  {
    title: "Total Contracts",
    value_property: "totalTenders",
    accent_color: "#D4AF37",
    position: "right"
  },
  {
    title: "Total Value of Contracts ($)",
    value_property: "totalValueOfTenders",
    accent_color: "#D4AF37",
    position: "right"
  }
]

export const TENDER_PROCESS_COLUMNS = [
  { 
    field: "label",
    renderCell: (params) => (
      <>
        { params.row.TotalDaysSaved > 0 && <span>
          <BsCheck 
              className="grid-status-icon status-success" 
              size={20} />
        </span>}
        { params.row.TotalDaysSaved < 0 && <span>
          <BsExclamation 
              className="grid-status-icon status-warning" 
              size={20} />
        </span>}
        {params.value}
      </>),
    headerName: "",
    minWidth: sm,
    maxWidth: sm,
    flex: 1
  },
  {
    field: "TenderNumber", 
    headerName: "Tender No",
    flex: 2,
    maxWidth: 200,
    minWidth: 200,
    filterParam: ""
  },
  { 
    field: "TenderResponsiblePerson", 
    headerName: "Responsible", 
    flex: 2, 
    minWidth: 150,
    maxWidth: 150,
    filterParam: ""
  },
  { 
    field: "ProcurementPlanAndBudgetAnalysisAgainstTarget", 
    headerName: "1",
    description: "1. Preparation & Budget Analysis", 
    flex: 1, 
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  { 
    field: "BiddingDocumentPreparationAgainstTarget", 
    headerName: "2",
    description: "2. Bidding Document Preparation", 
    flex: 1, 
    maxWidth: sm,
    minWidth: sm,
    sortable: false,
    colorCoded: true
  },
  { 
    field: "AnnouncementofBidFloatationAgainstTarget", 
    headerName: "3",
    description: "3. Announcement of Bid Floatation", 
    flex: 1, 
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "TenderFloatationAgainstTarget",
    headerName: "4",
    description: "4. Tender Floatation",
    flex: 1,
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "TenderEvaluationAgainstTarget",
    headerName: "5",
    description: "5. Tender Evaluation",
    flex: 1,
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "AnnouncementOfWinnerAgainstTarget",
    headerName: "6",
    description: "6. Announcement of Winner",
    flex: 1,
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "POPreparationAgainstTarget",
    headerName: "7",
    description: "7. PO Preparation",
    flex: 1,
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "ContractSignedByDGAgainstTarget",
    headerName: "8",
    description: "8. Contract Signed By DG",
    flex: 1,
    maxWidth: sm,
    minWidth: sm, 
    sortable: false,
    colorCoded: true
  },
  {
    field: "TotalDaysConsumed",
    headerName: "Days Consumed",
    description: "The total number of days elapsed since the start of the tender",
    flex: 3,
    colorCoded: true,
    maxWidth: md,
    minWidth: md
  },
  {
    field: "TotalDaysRemaining",
    headerName: "Days Remaining",
    description: "Remaining days until the planned tender end date",
    flex: 3,
    maxWidth: md,
    minWidth: md
  },
  {
    field: "TotalDaysSaved",
    headerName: "Days Saved",
    description: "Days saved or wasted compared to the tender schedule",
    flex: 3,
    colorCoded: true,
    maxWidth: md,
    minWidth: md
  }
];

export const CONTRACT_PROCESS_COLUMNS = getTenderProccessColumns("SEA");
export const CONTRACT_PROCESS_COLUMNS_AIR = getTenderProccessColumns("AIR");

function getTenderProccessColumns(type) {
  let columns = 
  [
    { 
      field: "label",
      renderCell: (params) => (
        <>
          { params.row.TotalDaysSaved > 0 && <span>
            <BsCheck 
                className="grid-status-icon status-success" 
                size={20} />
          </span>}
          { params.row.TotalDaysSaved < 0 && <span>
            <BsExclamation 
                className="grid-status-icon status-warning" 
                size={20} />
          </span>}
          {params.value}
        </>),
      headerName: "",
      minWidth: sm,
      maxWidth: sm,
      flex: 1
    },
    {
      field: "PurchaseOrderNumber",
      headerName: "PO No",
      description: "PO No",
      maxWidth: md,
      minWidth: md
    },
    {
      field: "PurchaseOrderType",
      headerName: "PO Type",
      description: "PO Type",
      maxWidth: md,
      minWidth: md
    },
    {
      field: "InvoiceNumber",
      headerName: "Invoice No",
      description: "Invoice Number",
      maxWidth: md,
      minWidth: md
    },
    {
      field: "TenderNumber", 
      headerName: "Tender No",
      flex: 2,
      maxWidth: 200,
      minWidth: 200,
      filterParam: ""
    }
  ];

  let contractProcess = type == "Air" ? CONTRACT_PROCESS_AIR : CONTRACT_PROCESS_SEA;
  contractProcess.forEach((column, index) => {
    columns.push(
      {
        field: column.value_property + "AgainstTarget",
        headerName: index + 1,
        description: index + 1 + "." + column.label,
        flex: 1,
        maxWidth: sm,
        minWidth: sm, 
        sortable: false,
        colorCoded: true
      }
    );
  });

  columns.push(
    {
      field: "TotalDaysConsumed",
      headerName: "Days Consumed",
      description: "The total number of days elapsed since the start of the contract",
      flex: 3,
      colorCoded: true,
      maxWidth: md,
      minWidth: md
    },
    {
      field: "TotalDaysRemaining",
      headerName: "Days Remaining",
      description: "Remaining days until the planned contract end date",
      flex: 3,
      maxWidth: md,
      minWidth: md
    },
    {
      field: "TotalDaysSaved",
      headerName: "Days Saved",
      description: "Days saved or wasted compared to the contract schedule",
      flex: 3,
      colorCoded: true,
      maxWidth: md,
      minWidth: md
    }
  );

  return columns;
}

const ProcurementConstants = []

export default ProcurementConstants