import "./App.css";
import React, { Component } from "react";
import { useEffect } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./components/Home/home";
import Login from "./components/Login/login";
import Covid from "./components/Home/covid19/covid19";
import CovidFacility from "./components/Home/covid19/covid19Facility";
import CovidEPHI from "./components/Home/covid19/covid19EPHI";

import QuarterReport from "./components/Home/QuarterReports/QuarterReports";
import ShipmentStatus from "./components/Home/ShipmentStatus/ShipmentStatus";
import Covid19Vaccination from "./components/Home/Covid19Vaccination/Covid19Vaccination";
import HubVitalReport from "./components/Home/HubVitalReport/HubVitalReport";
import MiscellaneousStockReport from "./components/Home/MiscellaneousStockReport/MiscellaneousStockReport";
import ItemPipeline from "./components/Home/itemPipeline/itemPipeline";
import ItemPrice from "./components/Home/itemPrice/itemPrice";
import AEFI from "./components/Home/AEFI/AEFI";
import ERIS from "./components/Home/ERIS/ERIS";
import ItemSummary from "./components/Home/itemSummary/itemSummary";
import Programs from "./components/Home/Program/Programs";
import Locations from "./components/Home/location/Location";
import Items from "./components/Items/Items";
import ProductAvailablity from "./components/Home/ProductAvailability/productAvailablity";

import Procurement from "./components/Home/Procurement/Procurement";

import { http } from "./Utils/http";
import { Routes, Route, useNavigate } from "react-router-dom";
import AlternateItemList from "./components/Home/Program/AlternateItemList";
import StagingData from "./components/Home/Program/StagingData";
import RDF from "./components/Home/RDF/RDF";
import InboundReport from "./components/Home/InboundReport/InboundReport";
import CADExpiry from "./components/Home/CAD Expiry/CADExpiry";
import ItemDistributions from "./components/Home/ItemDistributions/ItemDistributions";
import FaclityDashboard from "./components/Home/FacilityDashboard/FacilityDashboard";
import LocalPO from "./components/Home/LocalPO/LocalPO";
import MedicalEquipmentDistribution from "./components/Home/MedicalEquipmentDistribution/MedicalEquipmentDistribution";
import Admin from "./components/EntityManagement/Admin";
import RawData from "./components/Home/QuarterReports/RawData";
import ItemBinCard from "./components/Items/ItemBinCard";
import { MapProvider } from "./Utils/MapContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Formulas from "./components/Formulas/Formulas";
import LocalPOReceive from "./components/Home/LocalPO/LocalPOReceive";
import NationalAMC from "./components/Home/National AMC/NationalAMCUpdate";
import PotentialExpiries from "./components/Home/PotentialExpiries/PotentialExpiries";
import OverageReport from "./components/Home/OverageReport/OverageReport";

import TransactionReport from "./components/Home/ERP/TransactionReport";
import ReceiveReport from "./components/Home/ERP/ReceiveReport";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const history = useNavigate();
  const authData = localStorage.getItem("userInfo");
  useEffect(() => {
    http.setBaseUrl();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    if (!authData) history("/login");
    const userInfo = JSON.parse(authData);
    const tokenExpiryTime = userInfo?.ExpiryTime;
    if (new Date(dateTime).getTime() >= new Date(tokenExpiryTime).getTime())
      history("/login");

    // if (parseInt(dateTime) > parseInt(tokenExpiryTime))
  }, []);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   axios.interceptors.response.use(
  //     (response) => {
  //       return response;
  //     },
  //     (error) => {
  //       if (error.response.status === 401) {
  //         navigate("/login");
  //       }
  //       return error;
  //     }
  //   );
  // });

  return (
    <MapProvider>            
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/covid19" element={<Covid />}></Route>
          <Route path="/covid19Facility" element={<CovidFacility />}></Route>
          <Route path="/covid19EPHI" element={<CovidEPHI />}></Route>
          <Route path="/QuarterReport" element={<QuarterReport />}></Route>
          <Route path="/pfsaquarterreportraw" element={<RawData />}></Route>
          <Route path="/ShipmentStatus" element={<ShipmentStatus />}></Route>
          <Route
            path="/Covid19Vaccination"
            element={<Covid19Vaccination />}
          ></Route>
          <Route path="/LCCADExpiry" element={<CADExpiry />}></Route>
          <Route path="/LocalPO" element={<LocalPO />}></Route>
          <Route path="/PotentialExpiries" element={<PotentialExpiries />}></Route>
          <Route path="/OverageReport" element={<OverageReport />}></Route>
          <Route path="/LocalPOReceive" element={<LocalPOReceive />}></Route>
          <Route path="/HubVitalReport" element={<HubVitalReport />}></Route>
          <Route path="/InboundReport" element={<InboundReport />}></Route>
          <Route
            path="/MiscellaneousStockReport"
            element={<MiscellaneousStockReport />}
          ></Route>
          <Route
            path="/NationalAMC"
            element={<NationalAMC />}
          ></Route>
          <Route path="/ItemPipeline" element={<ItemPipeline />}></Route>
          <Route path="/ItemPrice" element={<ItemPrice />}></Route>
          <Route path="/AEFI" element={<AEFI />}></Route>
          <Route path="/ERIS" element={<ERIS />}></Route>
          <Route path="/ItemSummary" element={<ItemSummary />}></Route>
          <Route path="/RDF" element={<RDF />}></Route>
          <Route path="/Programs/:id" element={<Programs />}></Route>
          <Route path="/A_Programs/:id" element={<Programs />}></Route>
          <Route path="/Locations/:id" element={<Locations />}></Route>
          <Route path="/Items" element={<Items />}></Route>
          <Route path="/BinCard" element={<ItemBinCard />}></Route>
          <Route path="/ItemDistribution" element={<ItemDistributions />}></Route>
          <Route path="/FacilityDashboard" element={<FaclityDashboard />}></Route>
          <Route path="/MedicalEquipmentDistribution" element={<MedicalEquipmentDistribution />}></Route>
          <Route
            path="/AlternateItemList"
            element={<AlternateItemList />}
          ></Route>
          <Route path="/StagingData" element={<StagingData />}></Route>
          <Route
            path="/ProductAvailability"
            element={<ProductAvailablity />}
          ></Route>
          <Route 
            path="/"
            element={ <ProtectedRoute path="/" guardCondition={authData} ><Home /></ProtectedRoute>}
          />
          
          <Route path="/Procurement" element={<Procurement />}></Route>
          <Route path="/Formulas" element={<Formulas />}></Route>

          <Route path="/TransactionReport" element={<TransactionReport />}></Route>
          <Route path="/ReceiveReport" element={<ReceiveReport />}></Route>
        </Routes>
      </QueryClientProvider>
    </MapProvider>
  );
}
