import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import styled from "styled-components";

import PyramidGrid from "../../shared/Charts/PyramidGrid";
import {
  TENDER_PROCESS,
  TENDER_PROCESS_CARDS,
  TENDER_PROCESS_COLUMNS,
  CONTRACT_PROCESS_SEA,
  CONTRACT_PROCESS_CARDS,
  CONTRACT_PROCESS_COLUMNS
} from "./ProcurementConstants";

import { 
  PyramidDataProcessor,
  PieChartDataProcessor,
  CardsDataProcessor,
  GridDataProcessor,
  SelectedRowDataProcessor,
  ContractProcessDataTransformer,
  TenderProcessDataTransformer,
  LineChartDataTransformer
} from "../../Home/Procurement/ProcurementHelper";

import {
  getTenderProcess,
  getContractProcess,
  getProcessTrends
} from "../../../Services/chartRequests";

const Procurement = () => {
  const [currentTab, setCurrentTab] = useState("Tender Process");
  const [tabs, setTabs] = useState(["Tender Process", "Contract Process"]);

  const [tenderProcess, setTenderProcess] = useState([]);
  const [contractProcess, setContractProcess] = useState([]);

  const [tenderProcessTrend, setTenderProcessTrend] = useState([]);
  const [contractProcessTrend, setContractProcessTrend] = useState([]);

  useEffect(() => {
    getTenderProcess().then((data) => {
      setTenderProcess(data.data.Data);
    });

    getContractProcess().then((data) => {
      setContractProcess(data.data.Data);
    });

    getProcessTrends("Tender").then((data) => {
      setTenderProcessTrend(data.data.Data);
    });

    getProcessTrends("Contract").then((data) => {
      setContractProcessTrend(data.data.Data);
    });
  }, []);

  function changeTab(tab) {
    setCurrentTab(tab);
  }

  return (
    <div>
      <NavBar title={`Lead Time Analysis of Tender and Contract Process`} />
      <Wrapper style={{ padding: "0.5rem" }}>
        {tenderProcess.length > 0 && <div>
          <ul className="flex-row tab-ul-admin">
            { tabs.map(function (tab) {
                return (<li className={`tab-li ${ currentTab === tab 
                    ? 'tab-li-active' : null }`}>
                  <a onClick={() => changeTab(tab)}>{tab}</a>
                </li>
                );
            })}
          </ul>
          { currentTab == "Tender Process" &&
            <PyramidGrid 
                component_config={{
                    title: "Tender Management Process",
                    data: TenderProcessDataTransformer(tenderProcess)
                }}
                pyramid_config={{
                  data_template: TENDER_PROCESS,
                  data_source_function: PyramidDataProcessor,
                  aggregation_property: "TenderStatus"
                }}
                pie_chart_config={{
                  data_template: TENDER_PROCESS,
                  data_source_function: PieChartDataProcessor,
                  aggregation_property: "TenderStatus"
                }}
                cards_config={{
                  data_template: TENDER_PROCESS_CARDS,
                  data_source_function: CardsDataProcessor,
                  aggregation_property: "IsTenderActive"
                }} 
                grid_config={{
                  columns: TENDER_PROCESS_COLUMNS,
                  id_property: "TenderNumber",
                  data_source_function: GridDataProcessor
                }} 
                selected_row_config={{
                  data_template: TENDER_PROCESS,
                  data_source_function: SelectedRowDataProcessor
                }} 
                line_chart_config={{
                  raw_data: tenderProcessTrend,
                  data_source_function: LineChartDataTransformer
                }} 
            />
          }
        </div>}

        {contractProcess.length > 0 && <div>
          { currentTab == "Contract Process" &&
            <PyramidGrid 
                component_config={{
                    title: "Contract Management Process",
                    data: ContractProcessDataTransformer(contractProcess)
                }}
                pyramid_config={{
                  data_template: CONTRACT_PROCESS_SEA,
                  data_source_function: PyramidDataProcessor,
                  aggregation_property: "CurrentStatus"
                }}
                pie_chart_config={{
                  data_template: CONTRACT_PROCESS_SEA,
                  data_source_function: PieChartDataProcessor,
                  aggregation_property: "TenderStatus"
                }}
                cards_config={{
                  data_template: CONTRACT_PROCESS_CARDS,
                  data_source_function: CardsDataProcessor,
                  aggregation_property: "IsContractActive"
                }} 
                grid_config={{
                  columns: CONTRACT_PROCESS_COLUMNS,
                  id_property: "PurchaseOrderNumber",
                  data_source_function: GridDataProcessor
                }} 
                selected_row_config={{
                  data_template: CONTRACT_PROCESS_SEA,
                  data_source_function: SelectedRowDataProcessor
                }} 
                line_chart_config={{
                  raw_data: contractProcessTrend,
                  data_source_function: LineChartDataTransformer
                }} 
            />
          }
        </div>}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
.tab-ul {
  list-style-type: none;
  align-items: center;
  border-radius: 0.3rem;
  background-color: rgb(237, 241, 249);
}

.tab-ul-admin {
  list-style-type: none;
  align-items: center;
  border-radius: 0.3rem;
  background-color: rgb(237, 241, 249);
  padding: 0;
  max-width: 30rem;
}

.tab-li {
  flex-grow: 1;
}

.tab-li a {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0.15rem;
  padding: 0.35rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.15rem;
}

.tab-li-active a {
  background: white;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px 0px;
}

`;

export default Procurement;
