import React from "react";
import {
  FaCaretDown,
} from "react-icons/fa";
import { MAP_CONSTANTS } from "../components/shared/Shared Utils/InputConstants";

const CustomDropdownToggle = React.forwardRef(({ children, onClick, setType, mapType }, ref) => {

return (
  <span
    ref={ref}
    onClick={(e) => {
      onClick(e);
      setType();
    }}
    className="custom-dropdown-toggle"
  >
    {children}
    {mapType === MAP_CONSTANTS.SupplyRoutes && <FaCaretDown />}
  </span>
)});

export default CustomDropdownToggle;