import moment from "moment";

// returns true for undefined, null, [], ""
export function IsEmpty(value) {
    return (value == null || value.length === 0);
}

export function FormatDecimal(value, digits) {
    return value? Number(value.toFixed(digits === undefined ? 1 : digits)).toLocaleString(): value;
}

export function CheckFloat(value) {
  if(value !== null && value !== undefined && parseFloat(value) !== NaN) {   
    return value.toString().length === parseFloat(value).toString().length;
  }
  return false;
}

export function CalculateMOS(value, AMC) {
    if(IsEmpty(value) || IsEmpty(AMC)) {
        return 0;
    }
    else {
        return (value / AMC).toFixed(1)
    }
}

export function getShare(params) {
  return `${FormatDecimal(params.row.ReceivedbyAllRecipants, 0)}%`;
}

export function getAllShare(params) {
  return `${FormatDecimal(params.value, 0)}%`;
}

export function getGIT(params) {
  return `${FormatDecimal(params.row.GIT, 0)} (${FormatDecimal((params.row.GIT)/params.row.AMC)})`;
}

export function getOrdered(params) {
  return <div><div>${FormatDecimal(params.row.QuantityPurchaseOrder, 0)}</div> <div>(${FormatDecimal((params.row.QuantityPurchaseOrder)/params.row.AMC)})</div></div>;
}

export function Formatter (value, digit) {
  if(digit === undefined) digit = 1;
  if (value < 1e3) return FormatDecimal(value, digit);
  if (value > 1e3 && value < 1e6) return (value / 1e3).toFixed(digit) + "K";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(digit) + "M";
  if (value >= 1e9) return +(value / 1e9).toFixed(digit) + " B";
}

export function getPercentageValues(fields, data) {
  return data.map((d) => ({...d, ...Object.assign({}, ...fields.map((f) => ({[f+"Percentage"]: FormatDecimal((d[f]* 100)/fields.reduce((accumulator, field) => {
    return accumulator + (d[field] || 0);
  }, 0), 1)})))}))
}

const NumberUtil = [
  
]

export const getValueByProperty= (array, searchProperty, searchValue, targetProperty) => {
  const foundObject = array.find((obj) => obj[searchProperty] === searchValue);

  if (foundObject) {
    return foundObject[targetProperty];
  }

  return undefined;
}

export const getShortDate = (params) => {
  return `${moment(params.value).format("MMM Do, YYYY")}`;
}

export const getGShortDate = (params) => {
  return `${moment(params.value).format("MMM Do, YYYY")}`;
}

export default NumberUtil;
