import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import styled from "styled-components";

import {
  getDistributionByFacilityType,
  ItemProcurer,
  ItemFunding,
  getMOSShareBySite,
  CashVsCredit,
  getFillRateGauge,
  getFacilityCount,
} from "../../../Services/chartRequests";
import {
  getHeaderInformation,
} from "../../../Services/ApiManager";
import {
  STOCK_STATUS_API,
  CENTER_TO_HUB_API,
  FACILITY_COUNT_API,
  HUB_TO_CENTER_API,
  RDF_STOCK_STATUS_API,
} from "../../shared/Shared Utils/AppData.js";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  STOCK_STATUS_COLUMN,
  ISSUED_COLUMN,
} from "../Program/ProgramsTableColumns";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import {
  STOCK_STATUS_DEFAULT_PARAM,
  CENTER_TO_HUB_DEFAULT_PARAM,
  YEAR_SELECT_CONFIG,
  ENVIRONMENT_SELECT_CONFIG,
  RDF_COMMODITY_TYPE_SELECT_CONFIG,
  RDF_STOCK_STATUS_DEFAULT_PARAM,
} from "../../shared/Shared Utils/DataTableConstants";
import {
  MdMedicalServices,
} from "react-icons/md";
import { HUB } from "../../shared/Shared Utils/InputConstants";
import { parseGridColumns, parseGridRows, } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomBarChartV2 from "../../shared/Charts/CustomBarChartV2";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import gitPic from "../../../assets/cardIcons/git.svg";
import expPic from "../../../assets/cardIcons/expired.svg";
import qexpPic from "../../../assets/cardIcons/qExpired.svg";
import dmgPic from "../../../assets/cardIcons/damaged.svg";
import gapPic from "../../../assets/cardIcons/gap.svg";
import ovrgPic from "../../../assets/cardIcons/overage.svg";
import { facillityCountColumn } from "../location/LocationsTableColumns";
import RDFSideNav from "../Program/RDFSideNav";
import { useQuery } from "@tanstack/react-query";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import moment from "moment";

export default function RDF() {
  const history = useNavigate();
  const location = useLocation();
  let { ProgramName } = useParams();
  const [environment, setEnvironment] = useState("HUB");
  const [modeCode, setModeCode] = useState("RDF");

  const {data: { data: { Data: distByType }} = { data: { Data: [] }}, isFetching: distByTypeLoading} = useQuery({
    queryKey: ['distByType', modeCode],
    queryFn: () => getDistributionByFacilityType(modeCode, null,  {FiscalYear: 2016}),
    staleTime: 10800000,
  });
  const {data: { data: { Data: [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoaading} = useQuery({
    queryKey: ['headerInfo', modeCode],
    queryFn: () => getHeaderInformation(modeCode, null),
    staleTime: 10800000,
  });
  const {data: { data: { Data: itemProcurement }} = { data: { Data: [] }}, isFetching: itemProcurementLoading} = useQuery({
    queryKey: ['itemProcurement', modeCode, null],
    queryFn: () => ItemProcurer(modeCode, null,  {FiscalYear: 2016}),
    staleTime: 10800000,
  });
  const {data: { data: { Data: cashVsCredit }} = { data: { Data: [] }}, isFetching: cashVsCreditLoading} = useQuery({
    queryKey: ['cashVsCredit', null],
    queryFn: () => CashVsCredit(modeCode, 2016),
    staleTime: 10800000,
  });
  const {data: { data: { Data: itemFunding }} = { data: { Data: [] }}, isFetching: itemFundingLoading} = useQuery({
    queryKey: ['itemFunding', modeCode],
    queryFn: () => ItemFunding(modeCode, null, {FiscalYear: 2016}),
    staleTime: 10800000,
  });
  const {data: { data: { Data: shareBySite }} = { data: { Data: [] }}, isFetching: shareBySiteLoading} = useQuery({
    queryKey: ['shareBySite', modeCode, environment],
    queryFn: () => getMOSShareBySite(modeCode, null, environment),
    staleTime: 10800000,
  });
  const [facilityCountParams, setFacilityCountParams] = useState({});
  const {data: { data: { Data:  facilityCount }} = { data: { Data: [] }}, isFetching: facilityCountLoading} = useQuery({
    queryKey: ['facilityCount', facilityCountParams],
    queryFn: () => getDistributionByFacilityType(modeCode, null, null),
    staleTime: 10800000,
  });
  const {data: { data: { Data: fillRate }} = { data: { Data: [] }}, isFetching: fillRateLoading} = useQuery({
    queryKey: ['fillRate', modeCode],
    queryFn: () => getFillRateGauge(modeCode),
    staleTime: 10800000,
  });

  const [distribution, setDistribution] = useState("Center to Hub");  

  useState(shareBySite);
  const pageFilterParams = {};
  const filterParams = {};
  const handle_change = (param) => {
    pageFilterParams[param.paramName] = param.paramValue;
  };
  const select = [HUB];

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDataFilter) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["TransactionDate"] = currentDate;
    }
    return filterParams;
  }

  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos RDF Dashboard`} />
    </div>
    <div className="container-context mt-3">
      {" "}
      <RDFSideNav data={fillRate} />
      <div>
        <div className="card-container">
          <CustomCard
            widget_config={{
              title: "SOH",
              value: headerInfo ? headerInfo.SOH.toLocaleString() : 0,
              logo_url: sohPic,
            }}
            Icon={MdMedicalServices}
          />
          <CustomCard
            widget_config={{
              title: "Ordered",
              value: headerInfo
                ? headerInfo.UnderOrderQuantity.toLocaleString()
                : 0,
              logo_url: stackPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "GIT",
              value: headerInfo
                ? headerInfo.GITQuantity.toLocaleString()
                : 0,
              logo_url: gitPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Expired",
              value: headerInfo
                ? headerInfo.QuantityExpired.toLocaleString()
                : 0,
              logo_url: expPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "nExpiry",
              value: headerInfo
                ? headerInfo.L3month.toLocaleString()
                : 0,
              logo_url: qexpPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Damaged",
              value: headerInfo
                ? headerInfo.QuantityDamaged.toLocaleString()
                : 0,
              logo_url: dmgPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Gap",
              value: headerInfo ? headerInfo.Gap.toLocaleString() : 0,
              logo_url: gapPic,
            }}
          />
          <CustomCard
            widget_config={{
              title: "Overage",
              value: headerInfo
                ? headerInfo.Overage.toLocaleString()
                : 0,
              logo_url: ovrgPic,
            }}
          />
        </div>
        <GridContainer>
          <StandardChartWrapper>
            <ChartWrapper title="Procurement Agents" wgInfoId="wgProcurerAgents" isLoading={itemProcurementLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
              <CustomPieChart
                data={itemProcurement}
                label={"Procurement Agent"}
                fields={["AmountInBirr", "ProcurmentAgent"]}
                title="Procurement Agents"
                style={{
                  position: "relative",
                  height: "19.3rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(itemProcurement, [ "ProcurmentAgent", "AmountInBirr"])}
                columns={parseGridColumns(["ProcurmentAgent", "AmountInBirr"], ["Procurment Agent", "Amount (ETB)"])}
                getRowId={(row) => row.ProcurmentAgent}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={itemProcurement}
                label={"Procurement Agent"}
                fields={["AmountInBirr", "ProcurmentAgent"]}
                title="Procurement Agents"
                style={{
                  height:'15rem',
                  width: '32rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapper>
          <StandardChartWrapper>
            <ChartWrapper title="Cash Vs Credit" wgInfoId="wg-rdf-issue-cash-vs-credit" isLoading={cashVsCreditLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
              <CustomPieChart
                data={cashVsCredit}
                label={"Cash Vs Credit"}
                fields={["AmountIssuedInBirr", "PaymentTypeCode"]}
                title="Cash Vs Credit"
                style={{
                  position: "relative",
                  height: "19.3rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(cashVsCredit, ["PaymentTypeCode", "AmountIssuedInBirr", "QuantityIssued"])}
                columns={parseGridColumns(["PaymentTypeCode", "AmountIssuedInBirr", "QuantityIssued"], ["Payment Type Code", "Issued Amount (ETB)", "Issued Quantity"] )}
                getRowId={(row) => row.PaymentTypeCode}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={cashVsCredit}
                label={"Cash Vs Credit"}
                fields={["AmountIssuedInBirr", "PaymentTypeCode"]}
                title="Cash Vs Credit"
                style={{
                  height:'15rem',
                  width: '32rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapper>
          <StandardChartWrapper>
            <ChartWrapper title="Distribution by Facility Type" wgInfoId="wgDistrByFacilitytypeMt" isLoading={distByTypeLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
              <CustomPieChart
                data={distByType}
                label={"Distribution by facility type"}
                fields={["AmountIssuedInBirr", "InstitutionType"]}
                title="Distribution by Facility Type"
                style={{
                  position: "relative",
                  height: "19.3rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(distByType, ["InstitutionType", "AmountIssuedInBirr"])}
                columns={parseGridColumns(["InstitutionType", "AmountIssuedInBirr"], ["InstitutionType", "Amount (ETB)"])}
                getRowId={(row) => row.InstitutionType}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={distByType}
                label={"Distribution by facility type"}
                fields={["AmountIssuedInBirr", "InstitutionType"]}
                title="Distribution by Facility Type"
                style={{
                  height:'15rem',
                  width: '32rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapper>

          <LongChartWrapper>
            <CustomBarChartV2
              datas={[facilityCount]}
              labels={[
                [
                  "Count",
                ],
              ]}
              fields={[
                [
                  "NoOfInstitution",
                ],
              ]}
              styles={[
                {
                  padding: "1rem",
                  position: "relative",
                  height: "19rem",
                  background: "white",
                },
              ]}
              chart_options={{
                title: "Facility Count",
                showExpandAlt: true,
                option: "InstitutionType",
                tooltips: ["InstitutionType"],
                indexAxis: 'y',
              }}
              changeLoading={facilityCountLoading}
            />
          </LongChartWrapper>
          <StandardChartWrapper>
            <ChartWrapper title="Funding Source" wgInfoId="wgDonorAgents" isLoading={itemFundingLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
              <CustomPieChart
                data={itemFunding}
                label={"Funding source"}
                fields={["AmountInBirr", "Donor"]}
                title="Funding Source"
                style={{
                  position: "relative",
                  height: "19.3rem",
                  background: "White",
                  padding: "1rem",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(itemFunding, ["Donor", "AmountInBirr"])}
                columns={parseGridColumns(["Donor", "AmountInBirr"], ["Donor", "Amount (ETB)"])}
                getRowId={(row) => row.Donor}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={itemFunding}
                label={"Funding source"}
                fields={["AmountInBirr", "Donor"]}
                title="Funding Source"
                style={{
                  height:'15rem',
                  width: '32rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapper>

          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Stock Status",
                data_source_api: RDF_STOCK_STATUS_API,
                data_property: "Data",
                columns: STOCK_STATUS_COLUMN,
                isSOH: false,
                local_filter_config: [RDF_COMMODITY_TYPE_SELECT_CONFIG,],
                select_filter_config: [ENVIRONMENT_SELECT_CONFIG,],
                wgInfoId: 'wgSohAmcMosByItemLlin',
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {...RDF_STOCK_STATUS_DEFAULT_PARAM, ModeCode: modeCode},
                  { hasDataFilter: false }
                ),
              }}
            />
          </TableWrapper>
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: `Issued - ${distribution}`,
                data_source_api: distribution == 'Center to Hub'? CENTER_TO_HUB_API: HUB_TO_CENTER_API,
                data_property: "Data",
                columns: ISSUED_COLUMN, 
                isSOH: false,
                wgInfoId: 'wgItemDistributionByProgram',
                use_default_filter_params: false,
                select_filter_config: [HUB],
                distribution_filter: {showDistributionFilter: true, setDistribution: setDistribution, distribution: distribution},
                defaultFilterParams: loadFilterToDefaultParams(
                  {...CENTER_TO_HUB_DEFAULT_PARAM, ModeCode: modeCode},
                  { hasDataFilter: true }
                ),
                date_picker: {
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                },
              }}
            />
          </TableWrapper>
        </GridContainer>
      </div>
    </div>
  </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;