import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { getFanosData } from "../../../Services/ApiManager";

function CustomSelect({ handle_change, component_config }) {
  let [options, setOptions] = useState([]);
  const [optionsFiltered, setOptionsFiltered] = useState([]);
  const [parentFilter, setParentFilter] = useState({});

  useEffect(() => {
    async function fetchOptions() {
      await getFanosData(
        component_config.api_url,
        {},
        component_config.data_source
      ).then((data) => {
        let optionData = data.data[component_config.options_data_property];
        setOptionsFiltered(optionData.filter(e => e[component_config.value_property] === component_config.place_holder).length > 0 && component_config.show_all ?
          [ {label: 'All', value: ''}, ...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})]:
          optionData.filter(e => e[component_config.value_property] === component_config.place_holder).length > 0 ?
          [ ...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})]:
          [{label: component_config.place_holder, value: ''}, ...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})]);
      });
    }
    component_config.api_url? fetchOptions(): setOptionsFiltered(component_config.hard_code_options);
  }, []);

  const handleChange = (selectedOption) => {
    if (selectedOption.value != '')
      handle_change({
        paramName: component_config.param_name,
        paramValue: selectedOption.value,
      });
    else
      handle_change({
        paramName: component_config.param_name,
        paramValue: null,
      });
  };

  const onFocus = () => {
    if (parentFilter) {
      if (parentFilter.value) {
        const filtered = options.filter((option) => {
          return (
            option[parentFilter.value_property] === parentFilter.value ||
            option[parentFilter.value_property] === null ||
            option[parentFilter.value_property] === ""
          );
        });
        setOptionsFiltered(filtered);
      } else {
        setOptionsFiltered(options);
      }
    }
  };

  return (
    <>
      {optionsFiltered.length > 0 && <Select
        id="select-filter"
        defaultValue={optionsFiltered.filter(e => component_config.value_property? (e.value === component_config.place_holder || e.label === component_config.place_holder): component_config.place_holder? e.value === component_config.place_holder: (e.value === "" || e.value === null))[0]}
        label="Select"
        options={optionsFiltered}
        style={{color: "#545E66", width: "8rem", marginRight: "1rem"}}
        onChange={(e) => handleChange(e)}
      />}
    </>
  );
}

export default CustomSelect;
