import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import * as yup from 'yup';
import { useFormik } from "formik";
import { createGroup, updateGroup } from "../../Services/ApiManager";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";

const validationSchema = yup.object({
  GroupName: yup.string().required('Group Name is required'),
  GroupCode: yup.string().required('Group Code is required'),
  GroupDescription: yup.string(),
  IsActive: yup.boolean(),
});

export const GroupForm = ({dialogOpen, setDialogOpen, updating, setUpdating}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: updating?
    {
      GroupName: updating.GroupName,
      GroupCode: updating.GroupCode,
      GroupDescription: updating.GroupDescription,
      IsActive: updating.IsActive,
      GroupID: updating.GroupID,
    }:
    {
      GroupName: '',
      GroupCode: '',
      GroupDescription: '',
      IsActive: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      setLoading(true);
      updating?
      updateGroup({...values})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)}):
      createGroup({...values})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)});
    },
  });


  return (
    
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {updating?'Update Group': 'Add Group'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
            <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Group Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="GroupName"
                name="GroupName"
                label="GroupName"
                required
                fullWidth
                value={formik.values.GroupName}
                onChange={formik.handleChange}
                error={formik.touched.GroupName && Boolean(formik.errors.GroupName)}
                helperText={formik.touched.GroupName && formik.errors.GroupName}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Group Code
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="GroupCode"
                name="GroupCode"
                label="GroupCode"
                required
                fullWidth
                value={formik.values.GroupCode}
                onChange={formik.handleChange}
                error={formik.touched.GroupCode && Boolean(formik.errors.GroupCode)}
                helperText={formik.touched.GroupCode && formik.errors.GroupCode}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: 700
                }}
              >
                Group Description
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="GroupDescription"
                name="GroupDescription"
                label="GroupDescription"
                required
                fullWidth
                value={formik.values.GroupDescription}
                onChange={formik.handleChange}
                error={formik.touched.GroupDescription && Boolean(formik.errors.GroupDescription)}
                helperText={formik.touched.GroupDescription && formik.errors.GroupDescription}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel 
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
                control={
                <Switch
                  name="IsActive"
                  id="IsActive"
                  checked={formik.values.IsActive}
                  onChange={formik.handleChange} />} 
                  label="Is Active" />
            </Grid>
            <Grid item xs={12} sm={6}/>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                    setDialogOpen(false);
                    setUpdating(null);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button type="submit" 
                fullWidth 
                style={{ 
                  backgroundColor: '',
                }}
                disabled={loading}
                variant="contained" 
                color="primary">
                {updating? 'Update': 'Create'}
                {loading &&
                (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                  <CustomLoadingIndicator />
                </span>)}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
          </Grid>
        </form>
        </DialogContent>
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;