
import React, {  } from "react";
import "../Css/CustomLoader.css";

export function CustomLoadingIndicator() {
  return (  
    <span>
      <span style={{ color: "#3288bd" }} className="css-at12u2-loadingIndicator" aria-hidden="true">
        <span className="css-3-Loading-dot"></span>
        <span className="css-2-Loading-dot"></span>
        <span className="css-1-Loading-dot"></span>
      </span>
    </span>
  );
}

export default CustomLoadingIndicator;
