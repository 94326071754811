import { FormatDecimal, getShortDate } from "../../../Utils/NumberUtils";

export const stockStatusColumn = [
  { field: "Item", headerName: "Item", width: 180 },
  { field: "CommodityType", headerName: "Commodity Type", width: 150 },
  { field: "Unit", headerName: "Unit", width: 150 },
  { field: "Issued", headerName: "Issued", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Received", headerName: "Received", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Expired", headerName: "Expired", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Reserved", headerName: "Reserved", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Damaged", headerName: "Damaged", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "SOH", headerName: "SOH", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
];

export const issueHistoryColumn = [
  { field: "ReferenceNumber", headerName: "Invoice No.", width: 120 },
  { field: "Institution", headerName: "Institution", width: 150 },
  { field: "InstitutionType", headerName: "Institution Type", width: 150 },
  { field: "TransactionDate", headerName: "Date", width: 150, valueFormatter: (params) => getShortDate(params) },
  { field: "Region", headerName: "Region", width: 150 },
  { field: "Zone", headerName: "Zone", width: 150 },
  { field: "District", headerName: "District", width: 150 },
  { field: "QuantityIssued", headerName: "Quantity", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "IssuedAmountInETB", headerName: "Issued(ETB)", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
];

export const recentReceivesColumn = [
  { field: "Item", headerName: "Item", width: 150 },
  { field: "Unit", headerName: "Unit", width: 150 },
  { field: "Mode", headerName: "Mode", width: 150 },
  { field: "QuantityReceived", headerName: "Quantity", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "AmountInETB", headerName: "Received(ETB)", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Environment", headerName: "Environment", width: 150 },
  { field: "Supplier", headerName: "Supplier", width: 150 },
  { field: "TransactionDate", headerName: "Date", width: 150, valueFormatter: (params) => getShortDate(params) },
];

export const pipelineReportColumn = [
  { field: "Item", headerName: "Item", width: 150 },
  { field: "Unit", headerName: "Unit", width: 150 },
  { field: "Mode", headerName: "Mode", width: 150 },
  { field: "PurchaseOrderNumber", headerName: "PO NO", width: 150 },
  { field: "PODate", headerName: "PO Date", width: 150, valueFormatter: (params) => getShortDate(params) },
  { field: "Donor", headerName: "Donor", width: 150 },
  { field: "Supplier", headerName: "Supplier", width: 150 },
  { field: "Procurer", headerName: "Procurer", width: 150 },
  { field: "Manufacturer", headerName: "Manufacturer", width: 150 },
  { field: "TotalOrder", headerName: "Total Order", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Ordered", headerName: "Ordered", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "TotalInvoice", headerName: "TotalInvoice", width: 150 },
  { field: "Invoiced", headerName: "Invoiced", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "Received", headerName: "Received", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "PendingReceive", headerName: "Pending", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
  { field: "ReceivedPercentage", headerName: "Received - %", width: 150, valueFormatter: (params) => FormatDecimal(params.value, 0) },
];

const columns = [
  stockStatusColumn,
  issueHistoryColumn,
  recentReceivesColumn,
  pipelineReportColumn,
];

export default columns;
