import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as yup from 'yup';
import { useFormik } from "formik";
import { createNewUser, getAdminAllGroups, getEnvironmentListWithOutGroup, getJobTitles, updateUsers } from "../../Services/ApiManager";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";

const validationSchema = yup.object({
  FullName: yup.string().required('Name is required'),
  Username: yup.string().required('Username is required'),
  Email: yup.string().email('Invalid email'),
  EnvironmentID: yup.string().required('Environment is required'),
  GroupID: yup.string().required('Group is required'),
  JobTitleID: yup.string().required('Job Title is required'),
  Password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  Confirm: yup.string().oneOf([yup.ref('Password'), null], 'Passwords must match').required('Confirm Password is required'),
});

export const UserForm = ({dialogOpen, setDialogOpen, updating, setUpdating}) => {
  const [jobTitleList, setJobTitleList] = useState([]);
  const [environmentList, setEnvironmentList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: updating?
    {
      FullName: updating.FullName,
      Username: updating.Username,
      Email: updating.Email,
      Mobile: updating.Mobile,
      Address: updating.Address,
      EnvironmentID: updating.EnvironmentID,
      GroupID: updating.GroupID,
      JobTitleID: updating.JobTitleID,
      Password: updating.Password,
      Confirm: updating.Password,
      IsActive: updating.IsActive,
      RowNumber: updating.RowNumber,
      UserAccountID: updating.UserAccountID,
      Program: "",
      Roles: 'manager'
    }:
    {
      FullName: '',
      Username: '',
      Email: '',
      Mobile: '',
      Address: '',
      EnvironmentID: '',
      GroupID: '',
      JobTitleID: '',
      Password: '',
      Confirm: '',
      Program: '',
      IsActive: true,
      Roles: 'manager'
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      setLoading(true);
      updating?
      updateUsers({...values, Environment: environmentList.filter((env) => env.EnvironmentID === values.EnvironmentID)[0], Group: groupList.filter((grp) => grp.GroupID === values.GroupID)[0], JobTitle: jobTitleList.filter((jt) => jt.JobTitleID === values.JobTitleID)[0]})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)}):
      createNewUser({...values, Environment: environmentList.filter((env) => env.EnvironmentID === values.EnvironmentID)[0], Group: groupList.filter((grp) => grp.GroupID === values.GroupID)[0], JobTitle: jobTitleList.filter((jt) => jt.JobTitleID === values.JobTitleID)[0]})
      .then((res)=> {
        setLoading(false)
        if(res.status === 200){
          setDialogOpen(false);
          setUpdating(null);
        }
      }).catch(() => {setLoading(false)});
    },
  });

  useEffect(() => {
    getJobTitles().then((res) => {
      setJobTitleList(res.data.Data)
    })
    getEnvironmentListWithOutGroup().then((res) => {
      setEnvironmentList(res.data.Data)
    })
    getAdminAllGroups().then((res) => {
      setGroupList(res.data.Data)
    })
  }, []);


  return (
    
    <DialogWrapper
      open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {updating?'Update User': 'Add User'}
        </DialogTitle>
        <DialogContent style={{
            padding: '20px 10px',
        }}>
            <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Full Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="FullName"
                name="FullName"
                label="Fullname"
                required
                fullWidth
                value={formik.values.FullName}
                onChange={formik.handleChange}
                error={formik.touched.FullName && Boolean(formik.errors.FullName)}
                helperText={formik.touched.FullName && formik.errors.FullName}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                User Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Username"
                name="Username"
                label="Username"
                required
                fullWidth
                value={formik.values.Username}
                onChange={formik.handleChange}
                error={formik.touched.Username && Boolean(formik.errors.Username)}
                helperText={formik.touched.Username && formik.errors.Username}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Email
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Email"
                name="Email"
                label="Email"
                fullWidth
                size="small"
                value={formik.values.Email}
                onChange={formik.handleChange}
                error={formik.touched.Email && Boolean(formik.errors.Email)}
                helperText={formik.touched.Email && formik.errors.Email}
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Phone
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="Mobile"
                name="Mobile"
                label="Phone Number"
                value={formik.values.Mobile}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Address
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Address"
                name="Address"
                label="Address"
                fullWidth
                value={formik.values.Address}
                onChange={formik.handleChange}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Environment
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required size="small">
                <InputLabel id="demo-simple-select-label">Environment</InputLabel>
                <Select
                  id="EnvironmentID"
                  name="EnvironmentID"
                  label="Environment"
                  value={formik.values.EnvironmentID}
                  onChange={formik.handleChange}
                  error={formik.touched.EnvironmentID && Boolean(formik.errors.EnvironmentID)}
                  helperText={formik.touched.EnvironmentID && formik.errors.EnvironmentID}
                >
                  {environmentList.map((item) => (
                    <MenuItem value={item.EnvironmentID}>{item.Environment}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Group
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required size="small">
                <InputLabel id="demo-simple-select-label">Group</InputLabel>
                <Select
                  label="Group"
                  id="GroupID"
                  name="GroupID"
                  value={formik.values.GroupID}
                  onChange={formik.handleChange}
                  error={formik.touched.GroupID && Boolean(formik.errors.GroupID)}
                  helperText={formik.touched.GroupID && formik.errors.GroupID}
                >
                  {groupList.map((item) => (
                    <MenuItem value={item.GroupID}>{item.GroupName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Job Title
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required size="small">
                <InputLabel id="demo-simple-select-label">Job Title</InputLabel>
                <Select
                  id="JobTitleID"
                  name="JobTitleID"
                  value={formik.values.JobTitleID}
                  onChange={formik.handleChange}
                  error={formik.touched.JobTitleID && Boolean(formik.errors.JobTitleID)}
                  helperText={formik.touched.JobTitleID && formik.errors.JobTitleID}
                  label="Job Title"
                >
                  {jobTitleList.map((item) => (
                    <MenuItem value={item.JobTitleID}>{item.JobTitle}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Password
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Password"
                name="Password"
                label="Password"
                required
                fullWidth
                value={formik.values.Password}
                onChange={formik.handleChange}
                error={formik.touched.Password && Boolean(formik.errors.Password)}
                helperText={formik.touched.Password && formik.errors.Password}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700
                }}
              >
                Confirm
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Confirm"
                name="Confirm"
                label="Confirm"
                required
                fullWidth
                value={formik.values.Confirm}
                onChange={formik.handleChange}
                error={formik.touched.Confirm && Boolean(formik.errors.Confirm)}
                helperText={formik.touched.Confirm && formik.errors.Confirm}
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            {updating?
            (<Grid item xs={12} sm={6}>
              <FormControlLabel control={
                <Switch
                  name="IsActive"
                  id="IsActive"
                  checked={formik.values.IsActive}
                  onChange={formik.handleChange} />} 
                  label="Is Active" />
            </Grid>):
            (<Grid item xs={12} sm={6}/>)}
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                    setDialogOpen(false);
                    setUpdating(null);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button type="submit" 
                fullWidth 
                style={{ 
                  backgroundColor: '',
                }}
                disabled={loading}
                variant="contained" 
                color="primary">
                {updating? 'Update': 'Create'}
                {loading &&
                (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                  <CustomLoadingIndicator />
                </span>)}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
          </Grid>
        </form>
        </DialogContent>
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
    max-width: 800px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;