import React, {} from "react";
import NavBar from "../../shared/NavBar";
import { POTENTIAL_EXPIRIES } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { FormatDecimal, getShortDate } from "../../../Utils/NumberUtils";
import DynamicDataGrid from "../../../Packages/DynamicWidget/Widgets/DynamicDataGrid";

const COLUMNS = [
  {
    field: "Hub",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    valueFormatter: (params) => params.value.replace(" Hub", ""),
    width: 100
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 300,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1.5,
  },
  {
    field: "CommodityTypeCode",
    renderHeader: (params) => <strong>{"Type"}</strong>,
    flex: 1,
  },
  {
    field: "Mode",
    renderHeader: (params) => <strong>{"Mode"}</strong>,
    flex: 1,
  },
  {
    field: "PhysicalStore",
    renderHeader: (params) => <strong>{"Physical Store"}</strong>,
    flex: 1,
  },
  {
    field: "PalletLocation",
    renderHeader: (params) => <strong>{"Pallet Location"}</strong>,
    flex: 2,
  },
  {
    field: "PotentialExpiry",
    renderHeader: (params) => <strong>{"Potential Expiry"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 0),
    flex: 1,
  },
  {
    field: "PotentialExpiryAmount",
    renderHeader: (params) => <strong>{"Expiry Amount"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    valueFormatter: (params) => getShortDate(params),
    flex: 2,
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 0),
    flex: 1,
  },
  {
    field: "SOHAmount",
    renderHeader: (params) => <strong>{"SOH Amount"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  }
];

const PotentialExpiries = () => {
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Potential Expiries`} />
      <div className="section-main-container">
        <div className="section-main">
          <DynamicDataGrid
            component_config={{
              title: "Potential Expiries",
              data_source_api: POTENTIAL_EXPIRIES,
              data_property: "Data",
              columns: COLUMNS,
              wgInfoId: 'wgPotentialExpiries',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin: 2rem 1rem 2rem 3rem;
    border-radius: 4px;
  }
`;

export default PotentialExpiries;
