import React, {} from "react";
import NavBar from "../../shared/NavBar";
import { HUB_SOH_API, ITEM_EXPIRY_API, LOCAL_PO_API, PSM_CENTER_TO_HUB_API, PSM_HUB_TO_FACILITY_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import { HUB_VITAL_ENVIRONMENT_SELECT_CONFIG, LOCAL_PO_YEAR_SELECT_CONFIG, PROGRAM_SELECT_CONFIG, QUARTER_SELECT_CONFIG, YEAR_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { ENVIRONMENT_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function getIndex(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

const LOCAL_PO_COLUMN = [
  {
    field: "SN",
    renderHeader: (params) => <strong>{"SN"}</strong>,
    width: 100,
    valueGetter: getIndex,
  },
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO Number"}</strong>,
    flex: 1,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1,
  },
  {
    field: "unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "PurchaseOrderDate",
    renderHeader: (params) => <strong>{"PO Date"}</strong>,
    flex: 1,
  },
  {
    field: "Supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    flex: 1,
  },
  {
    field: "QuantityPurchaseOrder",
    renderHeader: (params) => <strong>{"Breakdown Quantity"}</strong>,
    flex: 1,
  },
  {
    field: "ReceivedQuantity",
    renderHeader: (params) => <strong>{"Received Quantity"}</strong>,
    flex: 1,
  },
  {
    field: "RemainingQuantity",
    renderHeader: (params) => <strong>{"Remaining Quantity"}</strong>,
    flex: 1,
  },
];

const LOCAL_PO_DEFAULT_PARAM = {
  EnvironmentCode: 'CNPH',
  ModeCode: 'HPR',
  FiscalYear: 2012,
  Quarter: 'Q1',
}

const LocalPO = () => {
  const history = useNavigate();
  const [modeCode, setModeCode] = useState("HPR");
  const [checked, setChecked] = useState(true);

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    return filterParams;
  }
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos LOCAL PURCHASE ORDER Dashboard`} />
      <div className="card-container" style={{display: "flex", justifyContent: "end", margin: "0 3.3rem"}}>
        <FormControlLabel control={
          <Switch
            defaultChecked={true} 
            onChange={(e)=> {
              setChecked(e.target.checked);
              e.target.checked? setModeCode('HPR'): setModeCode('RDF');
            }} />} 
          label={checked?"HPR": "RDF"} />
      <button
        className="button"
        onClick={() => {
          history('/LocalPOReceive')
        }}
      >
        Receives
      </button>
      </div>
      <div className="grid-section">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "LOCAL PURCHASE ORDER",
              data_source_api: LOCAL_PO_API,
              data_property: "Data",
              columns: LOCAL_PO_COLUMN,
              isSOH: false,
              use_default_filter_params: false,
              select_filter_config: [
                HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                LOCAL_PO_YEAR_SELECT_CONFIG,
                QUARTER_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {...LOCAL_PO_DEFAULT_PARAM, ModeCode: modeCode},
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                no_date: true,
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin: 2rem 1rem 2rem 3rem;
    border-radius: 4px;
  }
`;

export default LocalPO;
