import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function GroupedLineChart2({ data, style }) {

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    radius: 5,
    hitRadius: 30,
    hoverRadius: 12,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 11,
          },
        },
        display: true,
        position: "top",
      }
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  return (
    <div style={style}>
      <Line options={options} data={data} />
    </div>
  );
}
