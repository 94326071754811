import { useNavigate } from "react-router-dom";

// custom components
//import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
import "./style.css";
import styled from "styled-components";

function CustomCardV2({ widget_config, Icon }) {
  const history = useNavigate();

  const styleIcon = {
    color: "white",
    padding: "0.5rem",
    backgroundColor: widget_config.accent_color,
    borderRadius: "1.5rem",
    margin: "0.2rem 0.2rem 0.2rem 0",
  };

  return (
    <Wrapper className="grow-1">
      <div 
        style={widget_config.value == null ? { opacity: "0.8" } : {}}
      >
        <div
          className="main-container custom-card-container flex-column"
          style={{ justifyContent: "space-between" }}
        >
          <div className="flex-row flex-center flex-item-center">
            <div>
              { widget_config.logo_url && <img alt="loading.." 
                  className="card-img" 
                  style={{color: widget_config.accent_color,}} 
                  src={widget_config.logo_url}></img> }
              { !widget_config.logo_url && <Icon 
                size={42} 
                style={{color: "#15616d"}}
            /> }
            </div>
            <div className="widget-header-container">
              <div className="flex-column flex-item-center">
                <h5 class="widget-header grow-1">{widget_config.title}</h5>
                <span>
                  {/* <Icon size={40} style={styleIcon} />{" "} */}
                  <span style={{ fontSize: "1.4rem", verticalAlign: "top", textWrap: "nowrap"}}>
                    {widget_config.dateFormat? 
                    widget_config.value:
                    widget_config.value?
                    (widget_config.currency ? widget_config.currency : "").concat(Intl.NumberFormat('en-US', {
                      notation: "compact",
                      maximumFractionDigits: 1
                    }).format(parseFloat((widget_config.value).toString().replace(/,/g, '')))):
                    '-'}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex-column"></div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CustomCardV2;

const Wrapper = styled.div`
  min-width: 10rem;
  .card-img {
    max-width: 3rem;
    margin: 0 5px 0 0;
  }
  .widget-header-container {
    padding: 0 0.5rem;
  }
`;
