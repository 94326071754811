import { FormatDecimal } from "./NumberUtils";

export const parseGridRowsInverted = (data) => {
return data.datasets.map((d, j) => {
    let hub = { ProductCN: d.label };
    data.labels.map((label, i) => {
    hub[label] = d.data[i];
    });
    return hub;
});
};

export const parseGridRows = (data, fields) => {
  return data.map((d, i) => {
    const row = Object.keys(d).filter((rf) => fields.includes(rf)).reduce((obj, key) => {
        obj[key] =  typeof d[key] === "number"? FormatDecimal(d[key], 0): d[key];
        return obj;
    }, {});
    return row;
  });
};

export const parseGridColumns = (fields, labels) => {
    const columns = fields.map((property, i) => {
      return {
        field: property,
        renderHeader: (params) => <strong>{labels? labels[i]: property}</strong>,
        width: 1000/fields.length,
        sortable: false,
      };
    });
    const columnsWithKey = [
      ...columns,
    ];
    return columnsWithKey;
  };

export const getChartDataInverted = (data, label, fields) => {
    const backgroundColor = [
        "#12b3b7",
        "#0b7075",
        "#c4b8a6",
        "#098d98",
        "#0a4f5a",
        "#8ed0d1",
        "#ddc908",
        "#67a789",
    ];
    const dataValue = [];
    const dataLabel = [];

    data?.length > 0 &&
      label?.map((l, index) => {
        let temp = {
          label: l,
          data: [],
          backgroundColor: [backgroundColor[index]],
          borderColor: [backgroundColor[index]],
          yAxisID: "y",
        };
        data.map((el, index1) => {
          temp.data.push(el[fields[index]]?.toString());
        });
        dataValue.push(temp);
      });

    const dataset = {
      labels: [...dataLabel],
      datasets: [...dataValue],
    };
    return dataset;
  };

  export const getChartData = (data, label, fields) => {
    const backgroundColor = [
        "#12b3b7",
        "#0b7075",
        "#c4b8a6",
        "#098d98",
        "#0a4f5a",
        "#8ed0d1",
        "#ddc908",
        "#67a789",
    ];
    const dataValue = [];
    const dataLabel = [];

    data?.length > 0 &&
      label?.map((l, index) => {
        let temp = {
          label: l,
          data: [],
          backgroundColor: [backgroundColor[index]],
          borderColor: [backgroundColor[index]],
          yAxisID: "y",
        };
        data.map((el, index1) => {
          temp.data.push(el[fields[index]]?.toString());
        });
        dataValue.push(temp);
      });


    const dataset = {
      labels: [...dataLabel],
      datasets: [...dataValue],
    };
    return dataset;
  };