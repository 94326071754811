import Axios from "axios";

const key = JSON.parse(localStorage.getItem("userInfo"));
let authkey;
if (key) {
  authkey = key.Key;
}
Axios.defaults.headers.common = { Authorization: `Basic ${authkey}` };

const { REACT_APP_API_ENDPOINT } = process.env;

export const getProcuredAmountByProcurer = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PriceAnalysis/ProcuredAmountByProcurer",
    params: params,
  });
  return response;
};

export const getProcuredAmountByFundingSource = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PriceAnalysis/ProcuredAmountByFundingSource",
    params: params,
  });
  return response;
};

export const getOrderFrequencyAnalysisChart = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/PriceAnalysis/OrderFrequencyAnalysis",
    params: params,
  });
  return response;
};