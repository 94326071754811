import React, { Component } from "react";
import NavBar from "../../shared/NavBar";

export class CovidFacility extends Component {
  render() {
    return (
      <div>
        <NavBar />
      </div>
    );
  }
}

export default CovidFacility;
