import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../shared/NavBar";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import {
  ALTERNATE_ITEM_API,
} from "../../shared/Shared Utils/AppData";
import { fanosProgramList } from "../../shared/Shared Utils/DataTableConstants";
import { ALTERNATE_ITEM_COLUMN } from "./ProgramsTableColumns";
export default function AlternateItemList() {
  const history = useNavigate();
  const location = useLocation();
  const programCode = location.state.programCode;
  const filterObj = {};
  //   const headerName = location.state.ProgramName;
  function loadFilterToDefaultParams(filterParams) {
    filterParams["ProgramCode"] = programCode;
    return filterParams;
  }
  return (
    <Wrapper>
      <div>
        {" "}
        <NavBar title={`Fanos Alternate Item List Dashboard`} />
      </div>
      <div className="container-box">
        <div className="grid">
          <CustomDataGrid
            component_config={{
              title: "Alternate Item List",
              data_source_api: ALTERNATE_ITEM_API,
              data_property: "Data",
              columns: ALTERNATE_ITEM_COLUMN,
              isSOH: false,
              select_filter_config: [
                {
                  api_url: fanosProgramList,
                  options_data_property: "Data",
                  label_property: "ProgramName",
                  value_property: "ProgramCode",
                  place_holder: programCode,
                  param_name: "ProgramCode",
                },
              ],
              use_default_filter_params: false,
              defaultFilterParams: loadFilterToDefaultParams(filterObj),
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 35px 5vw;
  }
`;
