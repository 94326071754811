function getID(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

export const BENEFICIARY_BY_AGE_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "Age",
    renderHeader: (params) => <strong>{"Age"}</strong>,
    flex: 1,
  },
  {
    field: "Count",
    renderHeader: (params) => <strong>{"Count"}</strong>,
    flex: 1,
  },
  {
    field: "Percentage",
    renderHeader: (params) => <strong>{"Percentage"}</strong>,
    flex: 1,
  },
];

export const BENEFICIARY_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "BeneficiaryInitials",
    renderHeader: (params) => <strong>{"Initials"}</strong>,
    width: 300,
  },
  {
    field: "Gender",
    renderHeader: (params) => <strong>{"Gender"}</strong>,
    width: 200,
  },
  {
    field: "Age",
    renderHeader: (params) => <strong>{"Age"}</strong>,
    width: 300,
  },
  {
    field: "VaccineName",
    renderHeader: (params) => <strong>{"Vaccine"}</strong>,
    width: 200,
  },
];