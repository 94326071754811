import { BsFillCloudsFill, BsGearFill } from "react-icons/bs";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { TbStar, TbTriangleInverted, TbTriangle } from "react-icons/tb";
import "./style.css";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";
import { getCurrentFiscalYear, getInventoryTurnOverRate, getOrderFillRate, getPMDMode19Collection, getPMDProductAvailablity, getPMDWastageRate } from "../../../Services/ApiManager";
import { useState } from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@mui/material";

const GaugeCard = () => {
    const [availability, setAvailability] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [turnOverRate, setTurnOverRate] = useState(null);
    const [orderFillRate, setOrderFillRate] = useState(null);
    const [PMDWastageRate, setPMDWastageRate] = useState(null);
    const [PMDModel19Collection, setPMDModel19Collection] = useState(null);
    const [gaugeData, setGaugeData] = useState({
        labels: ["low", "tolerable", "acceptable"],
        datasets: [
        {
            data: [75, 20, 5],
            needleValue: 76.5,
            backgroundColor: ["#D76C05", "#DDC908", "#098D98"],
            hoverBackgroundColor: ["#D76C05", "#DDC908", "#098D98"],
            borderWidth: 2,
            circumference: 180,
            rotation: 270,
            cutout: "60%",
        },
        ],
    });

    const [gaugeNeedle, setGaugeNeedle] = useState({
        id: "gaugeNeedle",
        afterDatasetDraw(chart, args, options) {
        const {
            ctx,
            config,
            chartArea: { top, bottom, left, right, width, height },
        } = chart;
        ctx.save();

        const needleValue = gaugeData.datasets[0].needleValue;
        const dataTotal = gaugeData.datasets[0].data.reduce((a, b) => a + b, 0);
        const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

        const cx = width / 2;
        const cy = chart._metasets[0].data[0].y;

        //needle
        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, -2);
        ctx.lineTo(chart._metasets[0].data[0].outerRadius, 0);
        ctx.lineTo(0, 2);
        ctx.fillStyle = "#444";
        ctx.fill();
        //needle dot
        ctx.translate(-cx, -cy);
        ctx.beginPath();
        ctx.arc(cx, cy, 5, 0, 10);
        ctx.fill();
        ctx.restore();
        // text
        ctx.font = "18px Helvetica";
        ctx.fillStyle = "#444";
        ctx.fillText(needleValue + "% available", cx, cy + 20);
        ctx.textAlign = "center";
        ctx.restore();
        },
    });

    useEffect(() => {
        getCurrentFiscalYear().then((res) => {
        })
        getPMDProductAvailablity().then((res) => {
        setAvailability(res.data);
        const newData = {
            labels: ["low", "tolerable", "acceptable"],
            datasets: [
                {
                    data: [75, 20, 5],
                    needleValue: parseFloat(res.data.current),
                    backgroundColor: ["#D76C05", "#DDC908", "#098D98"],
                    hoverBackgroundColor: ["#D76C05", "#DDC908", "#098D98"],
                    borderWidth: 2,
                    circumference: 180,
                    rotation: 270,
                    cutout: "60%",
                },
            ],
        };

        setGaugeData(newData);
        });
        getInventoryTurnOverRate().then((res) => {
           setTurnOverRate(res.data.current)
        })
        getOrderFillRate().then((res) => {
           setOrderFillRate(res.data.current)
        })
        getPMDWastageRate().then((res) => {
           setPMDWastageRate(res.data.current)
        })
        getPMDMode19Collection().then((res) => {
           setPMDModel19Collection(res.data.current)
        })
    }, []);

    useEffect(() => {
        const needleValue = gaugeData.datasets[0].needleValue;
        const dataTotal = gaugeData.datasets[0].data.reduce((a, b) => a + b, 0);
        const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

        const gaugeNeedle = {
        id: "gaugeNeedle",
        afterDatasetDraw(chart, args, options) {
            const {
            ctx,
            config,
            chartArea: { top, bottom, left, right, width, height },
            } = chart;
            ctx.save();

            const cx = width / 2;
            const cy = chart._metasets[0].data[0].y;

            //needle
            ctx.translate(cx, cy);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, -2);
            ctx.lineTo(chart._metasets[0].data[0].outerRadius, 0);
            ctx.lineTo(0, 2);
            ctx.fillStyle = "#444";
            ctx.fill();
            //needle dot
            ctx.translate(-cx, -cy);
            ctx.beginPath();
            ctx.arc(cx, cy, 5, 0, 10);
            ctx.fill();
            ctx.restore();
            // text
            ctx.font = "18px Helvetica";
            ctx.fillStyle = "#444";
            ctx.fillText(needleValue + "% available", cx, cy + 20);
            ctx.textAlign = "center";
            ctx.restore();
        },
        };

        setGaugeNeedle(gaugeNeedle);
    }, [gaugeData]);

    const config = {
        responsive: true,
        plugins: {
        legend: {
            display: false,
        },
        },
    };

  return (
    <Wrapper>
        <Header>
            <Description>
                Product Availability for the Given Range
            </Description>
        </Header>
        <Body>
            {isLoading? 
            <DoughnutWrapper>
                <Skeleton variant="rectangular" width="20rem" height="10rem" />
            </DoughnutWrapper>:
            <DoughnutWrapper>
                <Doughnut
                    options={config}
                    data={gaugeData}
                    plugins={[gaugeNeedle]}
                    style={{ maxHeight: '10rem', maxWidth: '12rem', }}
                />
                {availability && parseFloat(availability.current) >= 95? (<DoughnutMetric>
                    <div>
                        <span>
                            <TbTriangle
                            size={20}
                            style={{
                                fill: "Green",
                                color: "Green",
                                verticalAlign: "baseline",
                            }}
                            />{" "}
                        </span>
                    </div>
                    <div style={{
                                color: "Green",
                            }}>Target Aquired</div>
                </DoughnutMetric>):
                availability?
                (<DoughnutMetric>
                    <div>
                        <span>
                            <TbTriangleInverted
                            size={20}
                            style={{
                                fill: "#EB1D36",
                                color: "#EB1D36",
                                verticalAlign: "baseline",
                            }}
                            />{" "}
                        </span>
                        <span>
                            {Math.abs(parseFloat(availability.percentOffTarget))}%
                        </span>
                    </div>
                    <div>vs. Target</div>
                </DoughnutMetric>):
                <></>
                }
            </DoughnutWrapper>}
            <GridContainer>
                <UpdatesCard style={{backgroundColor: "#6EB0B1"}}>
                    <CardTitle>
                       Turn Over Rate
                    </CardTitle>
                    <CardContent>
                        {turnOverRate}
                    </CardContent>
                </UpdatesCard>
                <UpdatesCard style={{backgroundColor: "#DDC908"}}>
                    <CardTitle>
                       Order Fill Rate
                    </CardTitle>
                    <CardContent>
                        {orderFillRate}
                    </CardContent>
                </UpdatesCard>
                <UpdatesCard style={{backgroundColor: "#098D98"}}>
                    <CardTitle>
                       Wastage Rate
                    </CardTitle>
                    <CardContent>
                        {PMDWastageRate}
                    </CardContent>
                </UpdatesCard>
                <UpdatesCard style={{backgroundColor: "#C4B8A6"}}>
                    <CardTitle>
                       Model 19 Collection
                    </CardTitle>
                    <CardContent>
                        {PMDModel19Collection}
                    </CardContent>
                </UpdatesCard>
            </GridContainer>
        </Body>
        
    </Wrapper>
  );
}

export default GaugeCard;

const Wrapper = styled.div`
    display: flex;
    grid-row: span 2;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 18px;
    gap: 10px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem 1rem 1.5rem 1rem;
`;

const DoughnutWrapper = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
`;

const DoughnutMetric = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const UpdatesCard = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 0;
    color: white;
    border-radius: 8px;
`;

const UpdatesText = styled.div`
    font-size: 1.1rem;
    margin-top: 5px;
`;

const CardTitle = styled(Box)`
    font-size: 17px;
    font-weight: 600;
`;

const CardContent = styled.div`
    font-size: 18px;
    margin-top: 5px;
`;

const IconName = styled.div`
    color: #15616D;
    font-size: 1.1rem;
    padding-left: 0.6rem;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NationalContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; 
    gap: 0.5rem;
`;

const ContentIcon = styled.div`
    color: white;
    padding: 0.5rem;
    background-color: #CEB081;
    border-radius: 50%;
    margin: 0.2rem 1.7rem 0.2rem 0;
    transition: all 0.3s ease-in-out;
`;

const LeadContentIcon = styled.div`
    color: white;
    padding: 0.5rem;
    background-color: #15616D;
    border-radius: 50%;
    margin: 0.2rem 2rem 0.2rem 0;
    transition: all 0.3s ease-in-out;
`;

const CardValue = styled.div`
    color: #545E66;
    font-size: 2rem;
`;

const CardName = styled.div`
    color: #545E66;
    font-size: 1.2rem;
`;

const Description = styled.div`
    color: #545E66;
    font-size: 1.1rem;
    display: flex;
    gap: 5px;
    font-weight: 600;
    justify-content: center;
`;

const PercentageWrapper = styled.div`
    height: 5rem;
    width: 5rem;
`;