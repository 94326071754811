import React, {
  useState, useEffect
} from "react";

import styled from "styled-components";

export default function PyramidChart({component_config}) {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  const maxValue = Math.max(...component_config.data.map(row => row.value));
  const widthFactor = maxValue == 0 ? 1 : (size.x / 30) / maxValue;
  function onClickHandler (row) {
    component_config.on_click(component_config.aggregation_property, row.label);
  }

  return (
    <Wrapper>
      {component_config.data?.map(function (row, index) {
          return (
            <div className="flex-container pyramid-body">
              <div className="item-1">
                <span>{index + 1}. </span>
                <span title={row.label}>{ row.label }</span>
              </div>
              <div className="item-2">
                  <span className="band-of-color" 
                      // style={{ background:row.color }}
                      onClick={ () => onClickHandler(row) }> 
                    { Array(Math.ceil(row.value * widthFactor)).join("_") } 
                    <span className="value">{ row.value } </span>
                    { Array(Math.ceil(row.value * widthFactor)).join("_") } 
                  </span>
              </div>
            </div>
          );
      })}
    </Wrapper>
  );
}
const Wrapper = styled.div`
.flex-container {
  display: flex;
  flex-direction: row;
}

.item-1 {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-2 {
  flex: 2;
  text-align: center;
  cursor: pointer;
}

.pyramid-body {
  font-size: 1rem;
}

.band-of-color {
  background: rgb(17,179,183);
  color: rgb(17,179,183);
}

.band-of-color::selection {
  background: rgb(17,179,183);
  color: rgb(17,179,183);
}

.band-of-color .value {
  color: white;
}
`