import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as FirstImage } from "../../assets/landingPage/01.svg";
import { ReactComponent as SecondImage } from "../../assets/landingPage/02.svg";
import { ReactComponent as ThirdImage } from "../../assets/landingPage/03.svg";

const variants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const LevelOne = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7, type: "spring", stiffness: 100 }}
  >
    <FirstImage />
    <p>{message}</p>
  </motion.div>
);

const LevelTwo = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <SecondImage />
    <p>{message}</p>
  </motion.div>
);

const LevelThree = ({ message }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    transition={{ duration: 0.7 }}
  >
    <ThirdImage />
    <p>{message}</p>
  </motion.div>
);

export default function LandingCarousel() {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  useEffect(() => {
    const carouselTimeOut = setTimeout(() => {
      if (currentCarouselIndex === 2) {
        setCurrentCarouselIndex(0);
      } else {
        setCurrentCarouselIndex(currentCarouselIndex + 1);
      }
    }, 5000);

    return () => {
      clearTimeout(carouselTimeOut);
    };
  }, [currentCarouselIndex]);

  return (
    <Wrapper>
      {currentCarouselIndex === 0 && (
        <LevelOne message={"Visibility of Ethiopia’s pharmaceutical supply chain."} />
      )}
      {currentCarouselIndex === 1 && (
        <LevelTwo message={"Accurate, timely, and complete events, transactions, and associated data across locations and organizational units."} />
      )}
      {currentCarouselIndex === 2 && (
        <LevelThree
          message={
            "For data driven decision making and better supply planning"
          }
        />
      )}
      <section className="dotes-wrapper">
        <CircleIndicator isActive={currentCarouselIndex === 0} />
        <CircleIndicator isActive={currentCarouselIndex === 1} />
        <CircleIndicator isActive={currentCarouselIndex === 2} />
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 10px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;
    margin-top: 100px;
  }
  svg {
    height: 200px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    text-align: center;
    height: 50px;
    font-family: inter;
  }
  .dotes-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 14px;
    margin-top: 0px;
  }
`;

const CircleIndicator = styled.div`
  width: 14px !important;
  height: 14px;
  border-radius: 9999%;
  background-color: ${({ isActive }) =>
    isActive ? "#15616D" : "#EDE3EA"};
  margin: 0px 10px;

  @media screen and (max-width: 625px) {
    background-color: ${({ isActive }) =>
      isActive
        ? "#702459" : "#EDE3EA"};
  }
`;
