import { TbBuildingWarehouse } from "react-icons/tb";
import { GiMedicinePills } from "react-icons/gi";
import { FaHospital, FaLongArrowAltRight } from "react-icons/fa";
import { MdAccessTimeFilled, MdOutlineFactCheck, MdLocalHospital, MdListAlt } from "react-icons/md";
import { HiHashtag } from "react-icons/hi";
import { AiOutlinePlusCircle, AiOutlineDollar } from "react-icons/ai";
import { IoFlag } from "react-icons/io5";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./style.css";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getFacilitiesServed, getHubLeadTime, getPMDProductAvailablity } from "../../../Services/ApiManager";
import { Skeleton } from "@mui/material";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import { Formatter } from "../../../Packages/DynamicWidget/Helpers/FormatHelper";

const CustomLandingCard = ({ title, iconName, value, description, cardType }) => {
  const renderIcon = () => (cardType === "Facility"? <FaHospital size={35} /> :
    cardType === "LeadTime"? <MdAccessTimeFilled size={35} /> :
    cardType === "National"? <IoFlag size={35} /> :
    <></>)

  return (
    <Wrapper>
        <Header>
            <Title>
                {title}
            </Title>
            {/* {renderIcon()} */}
        </Header>
        {cardType === "Hub"? 
        <HubBody iconName={iconName} value={value} description={description} />:
        cardType === "SOHAmount"? 
        <SOHAmountBody iconName={iconName} value={value} description={description} />:
        cardType === "Facility"? 
        <FacilityBody iconName={iconName} value={value} description={description} />:
        cardType === "LeadTime"?
        <LeadTimeBody iconName={iconName} value={value} description={description} />:
        cardType === "National"?
        <NationalBody description={description} />:
        <></>}
    </Wrapper>
  );
}

const HubBody = ({ iconName, description }) => {
    const hubs = 19;
      return (
      <Body style={{margin: "auto"}}>
          <IconName>
              {iconName}
          </IconName>
          <ContentWrapper>
              <ContentIcon>
                  <TbBuildingWarehouse size={32} />
              </ContentIcon>
              <CardValue>
                  {hubs}
              </CardValue>
          </ContentWrapper>
      </Body>
      )
  }

  const SOHAmountBody = ({ iconName, description }) => {
    const sohAmount = "~1.1 B";
      return (
      <Body style={{margin: "auto"}}>
          <IconName>
              {iconName}
          </IconName>
          <ContentWrapper>
              <ContentIcon>
                  <AiOutlineDollar size={32} />
              </ContentIcon>
              <CardValue>
                  {sohAmount}
              </CardValue>
          </ContentWrapper>
      </Body>
      )
  }

const FacilityBody = ({ iconName, description }) => {
    const styleIcon = {
        color: "white",
        padding: "0.5rem",
        backgroundColor: "rgb(21, 97, 109)",
        borderRadius: "1.5rem",
        margin: "0.2rem 0.2rem 0.2rem 0",
        transition: "all 0.3s ease-in-out",
    };
  const [facilitiesServed, setFacilitiesServed] = useState(0);
  const [facilitiesServedPublic, setFacilitiesServedPublic] = useState(0);
  const [facilitiesServedOthers, setFacilitiesServedOthers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getFacilitiesServed(2016).then((res) => {
            setIsLoading(false)
            setFacilitiesServed(res.data.Data[0].FacilitiesServed);
            setFacilitiesServedPublic(res.data.Data[0].Public);
            setFacilitiesServedOthers(res.data.Data[0].Others);
        }).catch(() => setIsLoading(false)); 
    }, [])

    if(isLoading){
        return (<Body >
            <div style={{minHeight:"10rem"}}>
                <Skeleton height="2rem" />
                <Skeleton width="60%" height="2rem" />
                <Skeleton height="2rem" />
                <Skeleton width="60%" height="2rem" />
                <Skeleton height="2rem" />
            </div>
        </Body>)
    }

    return (
    <Body>
        <div className="flex-row">
            <div className="grow-1" style={{margin: "auto"}}>
                <div className="flex-row flex-center flex-item-center">
                    <div>
                        <HiHashtag size={50} color="rgb(21, 97, 109)"/>{" "}
                    </div>
                    <div className="flex-column" style={{padding: "0 0.5rem"}}>
                        <div >Total count</div>
                        <div style={{fontSize: "1.8rem"}} title={facilitiesServedPublic}>{Formatter(facilitiesServed)}</div>
                    </div>
                </div>
            </div>
            <div className="grow-1" style={{margin: "auto", borderLeft: "0.05rem solid rgb(21, 97, 109)"}}>
                <div className="flex-row flex-center flex-item-center"
                    style={{minHeight:"10rem" }}>
                    <div className="grow-1">
                        <div className="flex-row flex-center flex-item-center">
                            <div style={{marginTop: "0.6rem"}}>
                                <MdLocalHospital size={40} color="rgb(21, 97, 109)"/> 
                            </div>
                            <div className="flex-column" style={{margin: "0 0.5rem"}}>
                                <div>Public</div>
                                <div style={{fontSize: "1.5rem"}} title={facilitiesServedPublic}>{Formatter(facilitiesServedPublic)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="grow-1">
                        <div className="flex-row flex-center flex-item-center">
                            <div style={{marginTop: "0.6rem"}}>
                                <MdListAlt size={40} color="rgb(21, 97, 109)"/> 
                            </div>
                            <div className="flex-column" style={{margin: "0 0.5rem"}}>
                                <div>Others</div>
                                <div style={{fontSize: "1.5rem"}} title={facilitiesServedOthers}>{Formatter(facilitiesServedOthers)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Body>
    )
}

const LeadTimeBody = ({ iconName, description }) => {
    const [leadTime, setLeadTime] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getHubLeadTime().then((res) => {
            setIsLoading(false)
            setLeadTime(res.data.current)
        }).catch(() => setIsLoading(false)); 
    }, [])

    if(isLoading){
        return (<Body>
            <Skeleton height="2rem" />
            <Skeleton width="60%" height="2rem" />
            <Skeleton height="2rem" />
            <Skeleton width="60%" height="2rem" />
        </Body>)
    }

    return (
    <LeadBody>
        <ContentWrapper>
            <LeadContentIcon>
                <GiMedicinePills size={38} />
            </LeadContentIcon>
            <CardValue>
                {leadTime}
            </CardValue>
            <CardName>
                ({iconName})
            </CardName>
        </ContentWrapper>
        <Description>
            <span>
                <AiOutlinePlusCircle />
            </span>
            {description}
        </Description>
    </LeadBody>
    )
}

const NationalBody = ({ description }) => {
    const [availability, setAvailability] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
        setIsLoading(true)
        getPMDProductAvailablity().then((res) => {
            setIsLoading(false)
            setAvailability(res.data)
        }).catch(() => {
            setIsLoading(false)
        })
    }, [])

    if(isLoading){
        return (<Body>
            <Skeleton height="2rem" />
            <Skeleton width="60%" height="2rem" />
            <Skeleton height="2rem" />
            <Skeleton width="60%" height="2rem" />
        </Body>)
    }
    return (<Body>
    
        <Description>
            {description}
        </Description>
        <NationalContentWrapper>
            <PercentageWrapper>
                <CircularProgressbar 
                    value={parseFloat(availability?.previousMonth) || 0} 
                    text={`${availability?.previousMonth || 0}%`} 
                    strokeWidth={15} 
                    styles={buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'butt',
                    textSize: '1.5rem',
                    pathTransitionDuration: 0.5,
                    pathColor: `#12B3B7`,
                    textColor: '#545E66',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#D3D3D3',
                    })} 
                />
            </PercentageWrapper>
            <FaLongArrowAltRight size={30} color={"#545E66"} />
            <PercentageWrapper>
                <CircularProgressbar 
                    value={availability? parseFloat(availability.current): 0} 
                    text={`${availability? availability.current: 0}%`} 
                    strokeWidth={15} 
                    styles={buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'butt',
                    textSize: '1.5rem',
                    pathTransitionDuration: 0.5,
                    pathColor: `#12B3B7`,
                    textColor: '#545E66',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#D3D3D3',
                    })} 
                />
            </PercentageWrapper>
        </NationalContentWrapper>
        
    </Body>)
}

export default CustomLandingCard;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
`;

const Header = styled.div`
    display: flex;
    background-color: #098D98;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    color: white;
    border-radius: 8px 8px 0 0;
    gap: 40px;
`;

const Title = styled.div`
    font-size: 1.1rem
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0rem;
`;

const LeadBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem 1.5rem 1rem;
`;

const IconName = styled.div`
    color: #15616D;
    font-size: 1.1rem;
    padding-left: 0.6rem;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NationalContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; 
    gap: 0.5rem;
`;

const ContentIcon = styled.div`
    color: white;
    padding: 0.7rem;
    background-color: #CEB081;
    border-radius: 50%;
    margin: 0.2rem 1.7rem 0.2rem 0;
    transition: all 0.3s ease-in-out;
`;

const LeadContentIcon = styled.div`
    color: white;
    padding: 0.7rem;
    background-color: #15616D;
    border-radius: 50%;
    margin: 0.2rem 2rem 0.2rem 0;
    transition: all 0.3s ease-in-out;
`;

const CardValue = styled.div`
    color: #545E66;
    font-size: 2rem;
`;

const CardName = styled.div`
    color: #545E66;
    font-size: 1.2rem;
`;

const Description = styled.div`
    color: #545E66;
    font-size: 0.9rem;
    display: flex;
    gap: 5px;
    margin-top: 0.5rem;
    justify-content: center;
`;

const PercentageWrapper = styled.div`
    height: 5rem;
    width: 5rem;
`;