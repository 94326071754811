import React, {} from "react";
import NavBar from "../../shared/NavBar";
import { OVERAGE_REPORT } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { FormatDecimal, getShortDate } from "../../../Utils/NumberUtils";
import DynamicDataGrid from "../../../Packages/DynamicWidget/Widgets/DynamicDataGrid";

const COLUMNS = [
  {
    field: "Hub",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    valueFormatter: (params) => params.value.replace(" Hub", ""),
    width: 75
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 3,
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Commodity Type"}</strong>,
    flex: 1,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "Mode",
    renderHeader: (params) => <strong>{"Mode"}</strong>,
    flex: 1,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    valueFormatter: (params) => getShortDate(params),
    flex: 1,
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 0),
    flex: 1,
  },
  {
    field: "SOHAmount",
    renderHeader: (params) => <strong>{"SOH Amount"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "QuantityToReachMax",
    renderHeader: (params) => <strong>{"Qty to Reach Max"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "Overage",
    renderHeader: (params) => <strong>{"Overage"}</strong>,
    valueFormatter: (params) => FormatDecimal(params.value, 1),
    width: 100,
  },
  {
    field: "HubsInNeed",
    renderHeader: (params) => <strong>{"Hubs In Need"}</strong>,
    renderCell: (params) => {
      let value = params.value == null ? "" : params.value?.replaceAll(" Hub", "");
      if(value.length > 25){
        return (
        <div className="flex-column" >
            <div title={value}> 
              { value.substring(0, 25) + "..."}
            </div>
        </div>
        );
      }
      else{
        return (<div title={value}>{value}</div>);
      }
    },
    width: 100
  }
];

const OverageReport = () => {
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Overage Report`} />

      <div className="section-main-container">
        <div className="section-main">
          <DynamicDataGrid
            component_config={{
              title: "Overage Report",
              data_source_api: OVERAGE_REPORT,
              data_property: "Data",
              columns: COLUMNS,
              wgInfoId: 'wgOverageReport',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin: 2rem 1rem 2rem 3rem;
    border-radius: 4px;
  }
`;

export default OverageReport;
