import React, { Component } from "react";
import NavBar from "../../shared/NavBar";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import CustomPieChart from "../../shared/Charts/CustomPieChart";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomBarChartV2 from "../../shared/Charts/CustomBarChartV2";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { getOrderFrequencyAnalysisChart, getProcuredAmountByFundingSource, getProcuredAmountByProcurer } from "./ItemPriceServices";
import { HUB_ENVIRONMENT_SELECT_CONFIG, PROGRAM_SELECT_CONFIG, YEAR_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import moment from "moment";
import { Box } from "@mui/material";
import { parseGridColumns, parseGridRows } from "../../../Utils/ChartConverter";
import styled from "styled-components";
import { ITEM_UNIT_COST_API, ORDER_FREQUENCY_API } from "../../shared/Shared Utils/AppData";

const ITEM_PRICE_COLUMN = [
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 2, valueGetter: (params) => params.row.Item || params.row.item },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "CommodityType", renderHeader: (params) => <strong>{"Commodity Type"}</strong>, flex: 1.4 },
  { field: "Environment", renderHeader: (params) => <strong>{"Environment"}</strong>, flex: 1.4 },
  { field: "Mean", renderHeader: (params) => <strong>{"Mean"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "Median", renderHeader: (params) => <strong>{"Median"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "Min", renderHeader: (params) => <strong>{"Min"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "Max", renderHeader: (params) => <strong>{"Max"}</strong>, flex: 1, type: 'decimalZero' },
];

const ORDER_FREQUENCY_COLUMN = [
  { field: "SN", renderHeader: (params) => <strong>{"SN"}</strong>, flex: 0.4 },
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 2, valueGetter: (params) => params.row.Item || params.row.item },
  { field: "OrderCount", renderHeader: (params) => <strong>{"Orders"}</strong>, flex: 1 },
  { field: "AverageNumberOfDaysBnOrders", renderHeader: (params) => <strong>{"Days between Orders"}</strong>, flex: 1 },
];

export default function ItemPrice() {
  const [procuredAmountParams, setProcuredAmountParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  procuredAmount }} = { data: { Data: [] }}, isFetching: procuredAmountLoading} = useQuery({
    queryKey: ['procuredAmount', procuredAmountParams],
    queryFn: () => getProcuredAmountByProcurer(procuredAmountParams),
    staleTime: 10800000,
  });
  const [procurementFundingParams, setProcurementFundingParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  procurementFunding }} = { data: { Data: [] }}, isFetching: procurementFundingLoading} = useQuery({
    queryKey: ['procurementFunding', procurementFundingParams],
    queryFn: () => getProcuredAmountByFundingSource(procurementFundingParams),
    staleTime: 10800000,
  });
  const [orderFrequencyParams, setOrderFrequencyParams] = useState({Page: "1", PageSize: "20"});
  const {data: { data: { Data:  orderFrequency }} = { data: { Data: [] }}, isFetching: orderFrequencyLoading} = useQuery({
    queryKey: ['orderFrequency', orderFrequencyParams],
    queryFn: () => getOrderFrequencyAnalysisChart(orderFrequencyParams),
    staleTime: 10800000,
  });
    
  return (
    <Box>
      <div>
        <NavBar title={`Fanos Item Price Dashboard`} />
      </div>
      <GridContainerAlternate>
        <StandardChartWrapperAlternate>
          <ChartWrapper title="Procurement Agents" wgInfoId="wgDonorAgents" parameter={procuredAmountParams} setParameter={setProcuredAmountParams} isLoading={procuredAmountLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} style={{padding: "0 90px"}}>
            <CustomPieChart
              data={procuredAmount}
              label={"Procurement Agents"}
              fields={["Amount", "Procurer"]}
              title="Procurement Agents"
              style={{
                position: "relative",
                width: "26rem",
                height: "26rem",
                background: "White",
                padding: "1rem",
                padding: "0rem 1rem 1rem 1rem",
              }}
            />
            <DataGrid
              style={{
                padding: "1rem",
              }}
              initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
              autoHeight
              rows={parseGridRows(procuredAmount, ["Procurer", "Amount"])}
              columns={parseGridColumns(["Procurer", "Amount"])}
              getRowId={(row) => row.Procurer}
              rowHeight={30}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              animateRows={true}
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
            />
            <CustomPieChart
              data={procuredAmount}
              label={"Procurement Agents"}
              fields={["Amount", "Procurer"]}
              title="Procurement Agents"
              style={{
                height:'15rem',
                width: '32rem',
                padding: "0rem 1rem 1rem 1rem",
              }}
            />
          </ChartWrapper>
        </StandardChartWrapperAlternate>
        <StandardChartWrapperAlternate>
          <ChartWrapper title="Funding Sources" wgInfoId="wgDonorAgents" parameter={procurementFundingParams} setParameter={setProcurementFundingParams} isLoading={procurementFundingLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]} style={{padding: "0 90px"}}>
            <CustomPieChart
              data={procurementFunding}
              label={"Funding sources"}
              fields={["Amount", "FundingSource"]}
              title="Funding Sources"
              style={{
                position: "relative",
                width: "26rem",
                height: "26rem",
                background: "White",
                padding: "1rem",
                padding: "0rem 1rem 1rem 1rem",
              }}
            />
            <DataGrid
              style={{
                padding: "1rem",
              }}
              initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
              autoHeight
              rows={parseGridRows(procurementFunding, ["FundingSource", "Amount"])}
              columns={parseGridColumns(["FundingSource", "Amount"])}
              getRowId={(row) => row.FundingSource}
              rowHeight={30}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              animateRows={true}
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
            />
            <CustomPieChart
              data={procurementFunding}
              label={"Funding sources"}
              fields={["Amount", "FundingSource"]}
              title="Funding Sources"
              style={{
                height:'15rem',
                width: '32rem',
                padding: "0rem 1rem 1rem 1rem",
              }}
            />
          </ChartWrapper>
        </StandardChartWrapperAlternate>
        <TableWrapperAlternate>
          <CustomBarChartV2
            datas={[orderFrequency]}
            labels={[["Orders"]]}
            fields={[
              [
                "OrderCount",
              ],
            ]}
            styles={[
              {
                padding: "0.5rem",
                position: "relative",
                width: "87.5vw",
                minHeight: "43.8rem",
                margin: "auto",
              },
            ]}
            chart_options={{
              title: "Top Frequently Procured Items",
              tooltips: ['Item'],
              option: "Item",
              indexAxis: "y",
              parseForGridWithMain: true,
              wgInfoId: "wgTopFrequentlyOrderedItems",
            }}
            changeLoading={orderFrequencyLoading}
          />
        </TableWrapperAlternate>
        <TableWrapperAlternate>
          <CustomDataGrid
            component_config={{
              title: "Item Price Analysis",
              width: "88vw",
              data_source_api: ITEM_UNIT_COST_API,
              data_property: "Data",
              wgInfoId: 'wg-price-history-analysis',
              columns: ITEM_PRICE_COLUMN,
              select_filter_config: [HUB_ENVIRONMENT_SELECT_CONFIG, PROGRAM_SELECT_CONFIG],
              isSOH: false,
              use_default_filter_params: false,
              defaultFilterParams: { ProgramCode: 'FP', EnvironmentCode: 'CNPH' },
            }}
          />
        </TableWrapperAlternate>
        <TableWrapperAlternate>
          <CustomDataGrid
            component_config={{
              title: "Order Frequency",
              width: "88vw",
              data_source_api: ORDER_FREQUENCY_API,
              data_property: "Data",
              wgInfoId: 'wg-price-history-analysis',
              columns: ORDER_FREQUENCY_COLUMN,
              isSOH: false,
              use_default_filter_params: false,
              defaultFilterParams: { },
            }}
          />
        </TableWrapperAlternate>
      </GridContainerAlternate>
    </Box>
  );
}

const GridContainerAlternate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  padding: 1.3rem 3.5rem;
`;

const TableWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 2;
`;

const StandardChartWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 1;
`;

const ContainerContext = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  width: 100%;
  padding: 2rem 5rem 2rem;
  flex: 1;
`;

const FlexBoxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const FlexBox = styled(Box)`
  padding-right: 1rem;
`;
