import React, { useState, useEffect } from "react";
import PyramidChart from "../../shared/Charts/PyramidChart";
import SimpleCard from "./SimpleCard";
import { DefaultDateFormatter2 } from "../Shared Utils/FormatHelpers";

import styled from "styled-components";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { MdOutlineMoreTime, MdOutlineTrendingUp  } from "react-icons/md";

import TreeMap from "./TreeMap";

import GroupedLineChart2 from "../../shared/Charts/GroupedLineChart2";

export default function PyramidGrid({
    component_config = {}, 
    pyramid_config = {}, 
    pie_chart_config = {},
    cards_config = {}, 
    grid_config = {},
    selected_row_config = {},
    line_chart_config = {}
}) {
  const labels = ['June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May' ];

  const [currentTab, setCurrentTab] = useState("Sea");
  const [tabs, setTabs] = useState(["Sea", "Air"]);
  const [showPieChart, setShowPieChart] = useState(false);
  const [showTrendChart, setShowTrendChart] = useState(false);
  
  pyramid_config.type = currentTab;
  selected_row_config.type = currentTab;
  pie_chart_config.type = currentTab;

  const [widgetData, setWidgetData] = useState(component_config.data.filter(row => row["Type"] == currentTab || row["Type"] == undefined));
  const [pyramidData, setPyramidData] = useState( pyramid_config.data_source_function(widgetData, pyramid_config) );
  const [pieChartData, setPieChartData] = useState( pie_chart_config.data_source_function(widgetData, pie_chart_config) );
  const [gridData, setSetGridData] = useState( grid_config.data_source_function(widgetData) );
  const [cardGroupData, setCardGroup1Data] = useState( cards_config.data_source_function(widgetData, cards_config));
  const [selectedRowData, setSelectedRowData] 
      = useState( selected_row_config.data_source_function(widgetData, 0, selected_row_config) );
  const [lineChartData, setLineChartData] = useState( 
    line_chart_config
        .data_source_function(line_chart_config.raw_data
        .filter(row => row["TransportationType"] == currentTab|| row["TransportationType"] == null)));
  
  
  const [activeFilter, setActiveFilter] = useState({});

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 15,
    page: 0,
  });

  useEffect(() => {
    setSetGridData(grid_config.data_source_function(widgetData));
    setLineChartData(line_chart_config
      .data_source_function(line_chart_config.raw_data
      .filter(row => row["TransportationType"] == currentTab|| row["TransportationType"] == null)));
  }, [widgetData]);

  function changeTab(tab) {
    let filteredWidgetData = component_config.data
        .filter(row => (row["Type"] == tab || row["Type"] == undefined))
    setCurrentTab(tab);
    pyramid_config.type = tab;
    selected_row_config.type = tab;
    pie_chart_config.type = tab;
    setWidgetData(filteredWidgetData);
    
    setPyramidData(pyramid_config.data_source_function(filteredWidgetData, pyramid_config));
    setSelectedRowData(selected_row_config.data_source_function(filteredWidgetData, 0, selected_row_config));
    setPieChartData(pie_chart_config.data_source_function(filteredWidgetData, pie_chart_config));
  }

  function onClickHandler (param_name, param_value) {
    if(activeFilter.paramName == param_name 
        & activeFilter.paramValue == param_value ) {
      setActiveFilter({});
      setWidgetData(component_config.data.filter(row => (row["Type"] == currentTab || row["Type"] == undefined)));
    }
    else {
      setActiveFilter( 
        { 
          paramName: param_name, 
          paramValue: param_value 
        })
      setWidgetData(component_config.data
          .filter(row => row[param_name] == param_value && (row["Type"] == currentTab || row["Type"] == undefined)));
    }
  }

  return (
    <Wrapper>
      <div className="widget-main">
        <div className="flex-column">
          <div className="header-item">
            <div className="flex-row" style={{justifyContent: "space-between"}}>
              <div className="title-item">
                <h2>{component_config.title}</h2>
              </div>
              <div className="link-item">
                <div className="flex-row">
                  <div  style={{ margin: "0 0.5rem"}}>
                    <div className="flex-column" style={{ textAlign: "center"}}>
                      <div>
                        <MdOutlineMoreTime size={35} 
                            title="Show Delay Dashboard"
                            className={ showPieChart 
                                ? "chart-header-icon chart-header-icon-active" 
                                : "chart-header-icon chart-header-icon-inactive" }
                            onClick={() => { 
                              setShowPieChart(!showPieChart);
                              setShowTrendChart(false);
                            }}/>
                      </div>
                      <div><div style={{ fontWeight: "bold"}}>Delay chart</div></div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-column" style={{ textAlign: "center"}}>
                      <div>
                        <MdOutlineTrendingUp  size={35} 
                            title="Show delay trend"
                            className={ showTrendChart 
                              ? "chart-header-icon chart-header-icon-active" 
                              : "chart-header-icon chart-header-icon-inactive" }
                            onClick={() => { 
                              setShowTrendChart(!showTrendChart);
                              setShowPieChart(false);
                            }}/>
                      </div>
                      <div><div style={{ fontWeight: "bold"}}>Delay trend</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            <div className="bottom-line">
              <div className="decorative-bar medium bg-blue-p"></div>
            </div>
          </div>
          <div className="body-item">
            <div className="flex-column">
              { component_config.title === "Contract Management Process" &&
                <div style={{maxWidth: "50%"}}>
                  <ul className="flex-row tab-ul-admin">
                    { tabs.map(function (tab) {
                        return (<li className={`tab-li ${ currentTab === tab 
                            ? 'tab-li-active' : null }`}>
                          <a onClick={() => changeTab(tab)}>{tab}</a>
                        </li>
                        );
                    })}
                  </ul>
                </div>
              }
              { !showPieChart && !showTrendChart && <PyramidChart component_config={{ 
                    data: pyramidData,
                    on_click: onClickHandler,
                    aggregation_property: pyramid_config.aggregation_property
                  }} 
              /> }

              { showPieChart && <div className="flex-row" style={{justifyContent: "space-around"}}>  
                <div className="pie-item">
                  <TreeMap data={pieChartData} />
                </div>
              </div> }

              { showTrendChart && <div className="flex-row" style={{justifyContent: "space-around"}}>  
                <div className="pie-item">
                <GroupedLineChart2
                  data={{
                    labels,
                    datasets: lineChartData
                  }}
                  style={{
                    padding: "1rem",
                    position: "relative",
                    height: "24rem",
                    width: "100%",
                    background: "White",
                  }}
                  wgInfoId='wg-location-recieve-trend'
                />
                </div>
              </div> }

              <div className="flex-row">
                <div className="card-grid-item">
                  <div className="flex-column">
                    <div className="card-item">
                      <div className="flex-row flex-row-card">
                        <div className="card-item-left">
                          <div className="flex-row">
                            {cardGroupData
                                .filter(card => card.position !== "right")
                                .map(function (row) {
                              return (
                                <SimpleCard widget_config={ row }/>
                              )
                            })}
                          </div>
                        </div>
                        <div className="card-item-right">
                          <div className="flex-row">
                            {cardGroupData
                                .filter(card => card.position === "right")
                                .map(function (row) {
                              return (
                                <SimpleCard widget_config={ row }/>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid-item">
                      <DataGrid rows={gridData}
                          columns={grid_config.columns}                         
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          density="compact"
                          disableColumnMenu
                          getCellClassName={(params) => {
                            if (params.colDef.colorCoded) {
                              if(params.value == null 
                                  || params.value == undefined) return "cell-none color-coded-cell";
                              else if(params.value < 0 ) return "cell-warning color-coded-cell";
                              else if(params.value = 0 ) return "cell-normal color-coded-cell";
                              else if(params.value > 0 ) return "cell-success color-coded-cell";
                              else return "cell-success  color-coded-cell";
                            }
                            return "";
                          }}
                          rowHeight={35}
                          onRowClick={ (params) => { 
                            if(params.row.label !== "Total")
                              onClickHandler(grid_config.id_property, params.row[grid_config.id_property]) }
                          }
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{ toolbar: { showQuickFilter: true } }}
                      />
                    </div>
                  </div>
                </div>
                <div className="selected-row-item">
                  <p>Start Dates of Process</p>
                    {selectedRowData.map(function (row, index) {
                      return (
                        <div className="selected-row-body">
                          <div> { DefaultDateFormatter2(row.value2) || "[Blank]"}</div>
                          <div>
                            <span>{index + 1}. </span>
                            <span>{row.label}</span>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
.widget-main {
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0.5rem;
  background: #fff;
}
.flex-row {
  display: flex;
}
.flex-row-card{
  justify-content: space-between;
  margin: 0 -0.5rem;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.pie-item {
  width: 60%;
  z-index: 0;
}

.chart-header-icon {
  background: green;
  color: white;
  border-radius: 1rem;
  margin: 0 0.5rem;
}

.chart-header-icon-active{
  background: green;
}

.chart-header-icon-inactive{
  background: gray;
}

.card-grid-item {
  flex-grow: 3;
  max-width: 85%;
}
.selected-row-item {
  margin: 0 0.5rem;
}
.selected-row-body {
  margin-bottom: 0.6rem;
  font-size: 0.85rem;
}
.bottom-line {
  /* width: 15rem; */
  height: 8px;
  border-top: solid 1px #ccc;
  margin-bottom: 0.5rem;
  margin-top: 4px;
  position: relative;
}

.decorative-bar.medium {
  height: 3px;
  margin-top: -2px;
}

.decorative-bar {
  position: absolute;
  width: 50px;
}

.bg-blue-p {
  background-color: #005eaa !important;
  color: #fff !important;
}
.cell-none {
  opacity: 80%;
}
.cell-success {
  background-color: #99d594;
}
.cell-warning {
  background-color: #d53e4f;
}
.cell-normal {
  background-color: #99d594;
}
.color-coded-cell {
  opacity: 80%;
  color: white;
  border-radius: 0.3rem;
  padding: 0.1rem;
  margin: 0.5px;
}
.grid-status-icon {
  color: white;
  border-radius: 1.5rem;
  margin: 0.2rem 0.2rem 0.2rem 0px;
  transition: all 0.3s ease-in-out 0s;
}
.status-success {
  background-color: rgb(153, 213, 148);
}
.status-warning {
  background-color: #fee08b;
}
@media screen and (max-width: 750px) {
  .pie-item {
    width: 100%;
  }
  .selected-row-item {
    display: none;
  }
  .card-grid-item {
    flex-grow: 3;
    max-width: 100%;
  }
}
`