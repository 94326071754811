import React, { useState } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { Divider } from "@material-ui/core";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getProductManufacturers, getProductUnits, getStockByAccount, getStockByActivity, getStockLocationByItem } from "../../../Services/chartRequests";
import { useQuery } from "@tanstack/react-query";
import { FormatDecimal } from "../../../Utils/NumberUtils";

const manufacturerColumns = [
    {
      field: "Manufacturer",
      renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const unitColumns = [
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const accountColumns = [
    {
      field: "Account",
      renderHeader: (params) => <strong>{"Account"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

  const activityColumns = [
    {
      field: "Activity",
      renderHeader: (params) => <strong>{"Activity"}</strong>,
      width: 120,
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      width: 120,
    },
  ]

export default function LlinSideNav({ programCode }) {
  const history = useNavigate();
  const [productManufacturersParams] = useState({ModeCode: "HPR", ProgramCode: programCode, ProductSN: "3215", OrderBy: "SOH DESC"});
  const {data: { data: { Data:  productManufacturers }} = { data: { Data: [] }}, isFetching: productManufacturersLoading} = useQuery({
    queryKey: ['productManufacturers', productManufacturersParams],
    queryFn: () => getProductManufacturers(productManufacturersParams),
    staleTime: 10800000,
  });
  const [productUnitsParams] = useState({ModeCode: "HPR", ProgramCode: programCode, ProductSN: "3215"});
  const {data: { data: { Data:  productUnits }} = { data: { Data: [] }}, isFetching: productUnitsLoading} = useQuery({
    queryKey: ['productUnits', productUnitsParams],
    queryFn: () => getProductUnits(productUnitsParams),
    staleTime: 10800000,
  });
  const [productAccountsParams] = useState({ModeCode: "HPR", ProgramCode: programCode, ProductSN: "3215", OrderBy: "SOH DESC"});
  const {data: { data: { Data:  productAccounts }} = { data: { Data: [] }}, isFetching: productAccountsLoading} = useQuery({
    queryKey: ['productAccounts', productAccountsParams],
    queryFn: () => getStockByAccount(productManufacturersParams),
    staleTime: 10800000,
  });
  const [productActivitiesParams] = useState({ModeCode: "HPR", ProgramCode: programCode, ProductSN: "3215", OrderBy: "SOH DESC"});
  const {data: { data: { Data:  productActivities }} = { data: { Data: [] }}, isFetching: productActivitiesLoading} = useQuery({
    queryKey: ['productActivities', productActivitiesParams],
    queryFn: () => getStockByActivity(productActivitiesParams),
    staleTime: 10800000,
  });

  return (
    <div style={{ padding: "0 0.5rem 0 0.5rem" }}>
      <Divider />
      <SidebarMenu>
        <SidebarMenu.Header>
          {/* <SidebarMenu.Nav.Link>MOS</SidebarMenu.Nav.Link> */}
          <br />{" "}
          <SidebarMenu.Brand>
            {productManufacturers.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {manufacturerColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {productManufacturers.map((row) => (
                  <tr>
                    {manufacturerColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>}
            {productUnits.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {unitColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {productUnits.map((row) => (
                  <tr>
                    {unitColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>}
            {productAccounts.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {accountColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {productAccounts.map((row) => (
                  <tr>
                    {accountColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>}
            {productActivities.length>0 && 
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  {activityColumns.map((col) => (
                    <TableHeader style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody style={{borderTop: 0}}>
                {productActivities.map((row) => (
                  <tr>
                    {activityColumns.map((col) => (
                    <TableCell style={{textAlign: col.field === 'SOH'? "right": "left"}}>{col.field === 'SOH'? FormatDecimal(row[col.field], 0): row[col.field]}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>}
          </SidebarMenu.Brand>
          <Divider />
          <br />
        </SidebarMenu.Header>
      </SidebarMenu>
    </div>
  );
}

const BinButton = styled.button`
  border: 1px solid transparent;
  height: 2rem;
  width: 6rem;
  font-size: 14px;
  background-color: cadetBlue;
  color: white;
  margin-right: 1rem;
  border-radius: 4px;
`

const TableHeader = styled.th`
  font-size: 13px;
`

const TableCell = styled.td`
  font-size: 13px;
  width: 55%;
`