import React, { useState } from "react";
import NavBar from "../shared/NavBar";
import "../shared/Charts/Charts.css";
import styled from "styled-components";
import {
  FaPlus,
  FaEdit,
} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoReturnUpBackSharp } from "react-icons/io5";

import {
  USER_MANAGEMENT_API,
  GROUP_MANAGEMENT_API,
  PAGE_MANAGEMENT_API,
  GROUP_PAGES_MANAGEMENT_API,
  GROUP_PROGRAMS_MANAGEMENT_API,
} from "../shared/Shared Utils/AppData.js";

import CustomDataGrid from "../shared/Shared Utils/CustomDataTable";
import { Container, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Box, Button, CircularProgress, Snackbar } from "@mui/material";
import { UserForm } from "./UserForm";
import { GroupForm } from "./GroupForm";
import { PageForm } from "./PageForm";
import { GroupPage } from "./GroupPage";
import { GroupProgram } from "./GroupProgram";
import { adminDeleteGroupPages, adminDeleteProgramGroup } from "../../Services/ApiManager";
import MuiAlert from '@mui/material/Alert';

function getID(params) {
    return `${params.row.autoGeneratedId + 1}`;
  }

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Admin() {
  const [userFormOpen, setUserFormOpen] = useState(false);
  const [groupFormOpen, setGroupFormOpen] = useState(false);
  const [pageFormOpen, setPageFormOpen] = useState(false);
  const [updating, setUpdating] = useState(null);
  const [showGroupPermissions, setShowGroupPermissions] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  const USER_COLUMN = [
    {
      field: 'Update',
      headerName: 'Update',
      width: 120,
      renderCell: (params) => (
        <>
          <Button type="submit" 
            style={{ 
              backgroundColor: 'orange',
              marginRight: 10,
            }}
            variant="contained" 
            onClick={() => {
            setUpdating(params.row)
            setUserFormOpen(true);
          }}>
            <FaEdit />
          </Button>
          {getID(params)}
        </>
      ),
    },
    {
      field: "FullName",
      renderHeader: (params) => <strong>{"Full Name"}</strong>,
      width: 200,
    },
    {
      field: "Username",
      renderHeader: (params) => <strong>{"User Name"}</strong>,
      width: 200,
    },
    {
      field: "Environment ",
      renderHeader: (params) => <strong>{"Environment"}</strong>,
      width: 200,
    },
    {
      field: "Group",
      renderHeader: (params) => <strong>{"Group"}</strong>,
      width: 200,
    },
    {
      field: "IsActive",
      renderHeader: (params) => <strong>{"Active"}</strong>,
      width: 200,
    },
  ];

  const GROUP_COLUMN = [
    {
      field: 'Update',
      renderHeader: (params) => <strong>{"Update"}</strong>,
      width: 100,
      renderCell: (params) => (
        <>
          <Button type="submit" 
            style={{ 
              backgroundColor: 'orange',
              marginRight: 10,
            }}
            variant="contained" 
            onClick={() => {
            setUpdating(params.row)
            setGroupFormOpen(true);
          }}>
            <FaEdit />
          </Button>
          {getID(params)}
        </>
      ),
    },
    {
      field: "GroupName",
      renderHeader: (params) => <strong>{"Group Name"}</strong>,
      width: 150,
    },
    {
      field: "GroupCode",
      renderHeader: (params) => <strong>{"Group Code"}</strong>,
      width: 150,
    },
    {
      field: "users ",
      renderHeader: (params) => <strong>{"Users"}</strong>,
      width: 150,
    },
    {
      field: "programs",
      renderHeader: (params) => <strong>{"Programs"}</strong>,
      width: 150,
    },
    {
      field: "GroupDescription",
      renderHeader: (params) => <strong>{"Group Description"}</strong>,
      width: 150,
    },
    {
      field: "IsActive",
      renderHeader: (params) => <strong>{"Active"}</strong>,
      width: 150,
    },
    {
      field: "Permission",
      renderHeader: (params) => <strong>{"Permission"}</strong>,
      width: 150,
      renderCell: (params) => (
          <Button type="submit" 
            style={{ 
              backgroundColor: '#15616d',
              marginRight: 10,
            }}
            variant="contained" 
            onClick={() => {
              setShowGroupPermissions(true);
              setSelectedGroup(params.row)
          }}>
            <FaEdit />
          </Button>
        ),
      },
    ];

   const PAGE_COLUMN = [
    {
      field: 'Update',
      headerName: 'Update',
      width: 100,
      renderCell: (params) => (
        <>
          <Button type="submit" 
            style={{ 
              backgroundColor: 'orange',
              marginRight: 10,
            }}
            variant="contained" 
            onClick={() => {
            setUpdating(params.row)
            setPageFormOpen(true);
          }}>
            <FaEdit />
          </Button>
          {getID(params)}
        </>
      ),
    },
    {
      field: "PageName",
      renderHeader: (params) => <strong>{"Page Name"}</strong>,
      width: 200,
    },
    {
      field: "PageID",
      renderHeader: (params) => <strong>{"Page ID"}</strong>,
      width: 200,
    },
    {
      field: "PageDescription ",
      renderHeader: (params) => <strong>{"Page Description"}</strong>,
      width: 200,
    },
    {
      field: "NonSortableColumn",
      renderHeader: (params) => <strong>{"URL"}</strong>,
      width: 200,
    },
    {
      field: "IsActive",
      renderHeader: (params) => <strong>{"Active"}</strong>,
      width: 200,
    },
  ];

  return (
  <Wrapper>
      <NavBar title='Administrator' />
      <Container className="card-style border-0">
        {showGroupPermissions? 
        (<GroupPermission selectedGroup={selectedGroup} setShowGroupPermissions={setShowGroupPermissions} />): 
        (<Tabs className="tabs" style={{ width: "100%"}}>
          <TabList style={{ justifyContent: "end", background: "#f9f9f9", marginBottom: "0.1rem"}}>
            <Tab className="map-tab-list">
                User Management
            </Tab>
            <Tab className="map-tab-list">
                Group Management
            </Tab>
            <Tab className="map-tab-list">
                Page Management
            </Tab>
          </TabList>
          <TabPanel>
            <Box mt={2} mb={1}>
              <Button variant="contained" color="success" onClick={() => {setUserFormOpen(true);}}><FaPlus /> &nbsp;Add</Button>
            </Box>
            <CustomDataGrid
              component_config={{
                data_source_api: USER_MANAGEMENT_API,
                data_property: "Data",
                columns: USER_COLUMN,
                isSOH: false,
                width: "100%",
                use_default_filter_params: false,
                defaultFilterParams: {},
              }}
            />
          </TabPanel>
          <TabPanel>
            <Box mt={2} mb={1}>
              <Button variant="contained" color="success" onClick={() => {setGroupFormOpen(true);}}><FaPlus /> &nbsp;Add</Button>
            </Box>
            <CustomDataGrid
              component_config={{
                data_source_api: GROUP_MANAGEMENT_API,
                data_property: "Data",
                columns: GROUP_COLUMN,
                show_search: true,
                isSOH: false,
                width: "100%",
                use_default_filter_params: false,
                defaultFilterParams: {},
              }}
            />            
          </TabPanel>
          <TabPanel>
            <Box mt={2} mb={1}>
              <Button variant="contained" color="success" onClick={() => {setPageFormOpen(true);}}><FaPlus /> &nbsp;Add</Button>
            </Box>
            <CustomDataGrid
              component_config={{
                data_source_api: PAGE_MANAGEMENT_API,
                data_property: "Data",
                columns: PAGE_COLUMN,
                show_search: true,
                isSOH: false,
                width: "100%",
                use_default_filter_params: false,
                defaultFilterParams: {},
              }}
            />
          </TabPanel>
      </Tabs>)}
    </Container>
    {userFormOpen && <UserForm dialogOpen={userFormOpen} setDialogOpen={setUserFormOpen} updating={updating} setUpdating={setUpdating} />}
    {groupFormOpen && <GroupForm dialogOpen={groupFormOpen} setDialogOpen={setGroupFormOpen} updating={updating} setUpdating={setUpdating} />}
    {pageFormOpen && <PageForm dialogOpen={pageFormOpen} setDialogOpen={setPageFormOpen} updating={updating} setUpdating={setUpdating} />}
  </Wrapper>)
}


const GroupPermission = ({selectedGroup, setShowGroupPermissions}) => {
  const [groupPageOpen, setGroupPageOpen] = useState(false);
  const [groupProgramOpen, setGroupProgramOpen] = useState(false);
  const [revokeGroupPageLoading, setRevokeGroupPageLoading] = useState({isLoading: false, id: 0});
  const [revokeGroupProgramLoading, setRevokeGroupProgramLoading] = useState({isLoading: false, id: 0});
  const [successAlertOpen, setSuccessAlertOpen] = useState(false); 
  const [errorAlertOpen, setErrorAlertOpen] = useState(false); 

  const revokePageClicked = (params) => {
    setRevokeGroupPageLoading({isLoading: true, id: params.row.GroupPageID});
    adminDeleteGroupPages([{GroupPageID: params.row.GroupPageID}]).then(() => {
      setSuccessAlertOpen(true);
      setRevokeGroupPageLoading({isLoading: false, id: 0})
    }).catch(() => {
      setRevokeGroupPageLoading({isLoading: false, id: 0})
      setErrorAlertOpen(true)
    });
  }
  
  const revokeProgramClicked = (params) => {
    setRevokeGroupProgramLoading({isLoading: true, id: params.row.ProgramGroupID});
    adminDeleteProgramGroup([{ProgramGroupID: params.row.ProgramGroupID}]).then(() => {
      setSuccessAlertOpen(true);
      setRevokeGroupProgramLoading({isLoading: false, id: 0})
    }).catch(() => {
      setRevokeGroupProgramLoading({isLoading: false, id: 0})
      setErrorAlertOpen(true)
    });
  }

  const GROUP_PAGE_COLUMN = [
    {
      field: 'Revoke',
      renderHeader: (params) => <strong>{"Revoke"}</strong>,
      width: 100,
      renderCell: (params) => (
        <>
          <Button type="submit" 
            style={{ 
              backgroundColor: 'red',
              marginRight: 10,
              minWidth: 40,
            }}
            disabled={revokeGroupPageLoading.isLoading && revokeGroupPageLoading.id === params.row.GroupPageID}
            variant="contained" 
            onClick={() => revokePageClicked(params)}
          >
            <MdDelete />
          </Button>
          {revokeGroupPageLoading.isLoading && revokeGroupPageLoading.id === params.row.GroupPageID &&
              (<CircularProgress />)}
          {getID(params)}
        </>
      ),
    },
    {
      field: "PageName",
      renderHeader: (params) => <strong>{"Page Name"}</strong>,
      flex: 1,
    },
    {
      field: "GroupPageID",
      renderHeader: (params) => <strong>{"Page ID"}</strong>,
      flex: 1,
    },
  ];

  const GROUP_PROGRAM_COLUMN = [
    {
      field: 'Revoke',
      renderHeader: (params) => <strong>{"Revoke"}</strong>,
      width: 100,
      renderCell: (params) => (
        <>
          <Button type="submit" 
            style={{ 
              backgroundColor: 'red',
              marginRight: 10,
              minWidth: 40,
            }}
            disabled={revokeGroupPageLoading.isLoading && revokeGroupProgramLoading.id === params.row.ProgramGroupID}
            variant="contained" 
            onClick={() => revokeProgramClicked(params)}
          >
            <MdDelete />
          </Button>
          {revokeGroupProgramLoading.isLoading && revokeGroupProgramLoading.id === params.row.ProgramGroupID &&
              (<CircularProgress />)}
          {getID(params)}
        </>
      ),
    },
    {
      field: "ProgramName",
      renderHeader: (params) => <strong>{"Program Name"}</strong>,
      flex: 1,
    },
    {
      field: "ProgramCode",
      renderHeader: (params) => <strong>{"Program Code"}</strong>,
      flex: 1,
    },
    {
      field: "ProgramID",
      renderHeader: (params) => <strong>{"Program ID"}</strong>,
      flex: 1,
    },
  ];


  const handleCloseSuccess = () => {
    setSuccessAlertOpen(false);
  }

  const handleCloseError = () => {
    setErrorAlertOpen(false);
  }

  return (<>
      <Tabs className="tabs" style={{ width: "100%"}}>
        <TabList style={{ justifyContent: "end", background: "#f9f9f9", marginBottom: "0.1rem"}}>
          <Tab className="map-tab-list" tabIndex="3">
              Group Page Permission
          </Tab>
          <Tab className="map-tab-list" tabIndex="4">
              Group Program Permission
          </Tab>
        </TabList>
        <TabPanel>
          <Box display="flex">
            <Box as={Button} mt={2} mb={1} mr={2} onClick={() => setShowGroupPermissions(false)}>
              <IoReturnUpBackSharp style={{ fontSize: "24px", color: "blue" }} />
            </Box>
            <Box mt={2} mb={1} mr={2}>
              <Button variant="contained" color="success" mr={2} onClick={() => setGroupPageOpen(true)}><FaPlus /> &nbsp;Add</Button>
            </Box>
            <Box display="flex" justifyContent="center" style={{alignItems: 'center', color: '#15616d', fontWeight: 700, fontSize: 20}}>
              GROUP: {selectedGroup.GroupName}
            </Box>
          </Box>
          <CustomDataGrid
            component_config={{
              data_source_api: GROUP_PAGES_MANAGEMENT_API,
              data_property: "Data",
              columns: GROUP_PAGE_COLUMN,
              show_search: true,
              isSOH: false,
              width: "100%",
              use_default_filter_params: false,
              defaultFilterParams: {UserID: selectedGroup.GroupID},
              defaultFilterParams: {},
            }}
          />
        </TabPanel>
        <TabPanel>
          <Box display="flex">
            <Box as={Button} mt={2} mb={1} mr={2} onClick={() => setShowGroupPermissions(false)}>
              <IoReturnUpBackSharp style={{ fontSize: "24px", color: "blue" }} />
            </Box>
            <Box mt={2} mb={1} mr={2}>
              <Button variant="contained" color="success" mr={2} onClick={() => setGroupProgramOpen(true)}><FaPlus /> &nbsp;Add</Button>
            </Box>
            <Box display="flex" justifyContent="center" style={{alignItems: 'center', color: '#15616d', fontWeight: 700,  fontSize: 20}}>
              GROUP: {selectedGroup.GroupName}
            </Box>
          </Box>
          <CustomDataGrid
            component_config={{
              data_source_api: GROUP_PROGRAMS_MANAGEMENT_API,
              data_property: "Data",
              columns: GROUP_PROGRAM_COLUMN,
              show_search: true,
              isSOH: false,
              width: "100%",
              use_default_filter_params: false,
              defaultFilterParams: {UserID: selectedGroup.GroupID},
              defaultFilterParams: {},
            }}
          />
        </TabPanel>
      </Tabs>
      {groupPageOpen && <GroupPage dialogOpen={groupPageOpen} setDialogOpen={setGroupPageOpen} selectedGroup={selectedGroup} />}
      {groupProgramOpen && <GroupProgram dialogOpen={groupProgramOpen} setDialogOpen={setGroupProgramOpen} selectedGroup={selectedGroup} />}
      <Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Successful!
        </Alert>
      </Snackbar>
      <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Error!
        </Alert>
      </Snackbar>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 50px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 4rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;
