import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import year from "../Shared Utils/InputConstants";
import { FaExpandAlt, FaPrint, FaSave } from "react-icons/fa";
import Dots from "../../../assets/GIFs/dotss.gif";
import styled from "styled-components";

export default function GroupedStackedBarChart({
  data,
  data1,
  label,
  label1,
  fields,
  fields1,
  filter,
  Length,
  style,
  chart_options,
}) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [recievedAmount, setReceivedAmount] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const backgroundColor = chart_options.background ? chart_options.background : [
    "#12b3b7",
    "#8ed0d1",
    "#67a789",

    "#15616d",
    "#ffddad",
    "#e06c00",
    "#098d98",
    "#ddc908",
    "#77af9c",
    "#78290f",
    "#0b7075",
    "#acd2ed",
    "#0a4f5a",
    "#c4b8a6",
    "#001524",
    "#265c00",
    "#73605b",
    "#e4ea8c",
    "#4d648d",
    "#ffddad",
    "#e06c00",
  ];
  let count = 0;
  useEffect(() => {
    const dataValue = [];
    const dataLabel = [];

    data1?.length > 0 &&
      label1?.map((l, index) => {
        let temp1 = {
          label: l,
          data: [],
          backgroundColor: backgroundColor[index + 2],
          stack: "Stack 1",
        };
        data1.map((el, index1) => {
          temp1.data.push(el[fields1[index]]?.toString());
          if (dataLabel.indexOf(el[chart_options.option]) < 0) {
            dataLabel.push(el[chart_options.option]);
            setLoading(false);
          }
        });
        dataValue.push(temp1);
      });
    data?.length > 0 &&
      label?.map((l, index) => {
        let temp = {
          label: l,
          data: [],
          backgroundColor: backgroundColor[index],
          stack: "Stack 0",
        };
        data.map((el, index1) => {
          temp.data.push(el[fields[index]]?.toString());
          if (dataLabel.indexOf(el[chart_options.option]) < 0) {
            dataLabel.push(el[chart_options.option]);
          }
        });
        dataValue.push(temp);
        setLoading(false);
      });

    setReceivedLabel(dataLabel);
    setReceivedData(dataValue);
  }, [data]);

  const options = {
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem, data) {
    //       var dataset = data.datasets[tooltipItem.datasetIndex];
    //       var meta = dataset._meta[Object.keys(dataset._meta)[0]];
    //       var total = meta.total;
    //       var currentValue = dataset.data[tooltipItem.index];
    //       var percentage = parseFloat(
    //         ((currentValue / total) * 100).toFixed(1)
    //       );
    //       return currentValue + " (" + percentage + "%)";
    //     },
    //     title: function (tooltipItem, data) {
    //       return data.labels[tooltipItem[0].index];
    //     },
    //   },
    // },

    // plugins: {
    //   datalabels: {
    //     formatter: function (value, context) {
    //       return context.dataIndex + ": " + Math.round(value * 100) + "%";
    //     },
    //   },
    // },
    plugins: {
      legend: {
        labels: {
          boxWidth: 15,
          font: {
            size: 11,
          },
        },
        display: chart_options.hideLegend? false: true,
        position: "bottom",
        maxWidth: 9,
      },
      tooltip: chart_options?.tooltip,
    },
    responsive: true,
    maintainAspectRatio: false,

    // indexAxis: fields[0] === "L3monthsAmtBirr" ? "y" : "x",
    animations: {
      tension: {
        duration: 1000,
        easing: "easeOutExpo",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
          font: {
            size: 10,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const dataset = {
    labels: [...recievedLabel],
    datasets: [...recievedData],
  };

  return (
    <Wrapper>
      <div style={style}>
        <Bar options={options} data={dataset} plugins={ChartDataLabels} />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .select {
    display: block;
    width: 6rem;
    height: 36px;
    background-color: white;
    border-radius: 4px;
    transition: 0.4s;
    margin: -30px 0px 2px 75%;
  }
`;
