import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { ERP_Distribution_API } from "../../shared/Shared Utils/AppData";
import styled from "styled-components";
import { getGShortDate } from "../../../Utils/NumberUtils";
import { Box } from "@mui/material";

const DISTRIBUTION_REPORT_COLUMN = [
  {
    field: "MaterialNumber",
    renderHeader: (params) => <strong>{"Material No"}</strong>,
    width: 150,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 300
  },
  {
    field: "CommodityTypeCode",
    renderHeader: (params) => <strong>{"Type"}</strong>,
    width: 100
  },
  {
    field: "BatchNumber",
    renderHeader: (params) => <strong>{"Batch No"}</strong>,
    width: 100
  },
  {
    field: "BasicUnitOfMeasure",
    renderHeader: (params) => <strong>{"BUOM"}</strong>,
    width: 100
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    width: 120,
    valueFormatter: getGShortDate,
  },
  {
    field: "NetPrice",
    renderHeader: (params) => <strong>{"Net Price"}</strong>,
    width: 100,
    type: 'number'
  },
  {
    field: "Amount",
    renderHeader: (params) => <strong>{"Amount"}</strong>,
    width: 100,
    type: 'number'
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 100,
    type: 'number'
  },
  {
    field: "SalesOrganisation",
    renderHeader: (params) => <strong>{"Sales Org"}</strong>,
    width: 100
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Institution"}</strong>,
    width: 200
  },
  {
    field: "Region",
    renderHeader: (params) => <strong>{"Region"}</strong>,
    width: 100
  },
  {
    field: "Zone",
    renderHeader: (params) => <strong>{"Zone"}</strong>,
    width: 100
  },
  {
    field: "Woreda",
    renderHeader: (params) => <strong>{"Woreda"}</strong>,
    width: 100
  } 
];

const DistributionReport = () => {
  return (
    <Wrapper>
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "94vw",
              title: "Distribution Report",
              data_source_api: ERP_Distribution_API,
              data_property: "Data",
              columns: DISTRIBUTION_REPORT_COLUMN,
              wgInfoId: 'wgDistributionReport',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default DistributionReport;
