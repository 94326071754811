import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function GroupedLineChart({ data, label, fields, style, yTitle }) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [recievedAmount, setReceivedAmount] = useState([]);
  const yAxisId = ["y", "y1", "y2", "y3"];
  const backgroundColor = [
    "#12b3b7",
    "#0b7075",
    "#c4b8a6",
    "#098d98",
    "#0a4f5a",
    "#8ed0d1",
    "#ddc908",
    "#67a789",
  ];
  let count = 0;
  useEffect(() => {
    const dataValue = [];
    const dataLabel = [];

    //  for(let i =0 ; i < data.length ; i++ ){

    //  }
    data?.length > 0 &&
      label?.map((l, index) => {
        let temp = {
          label: l,
          data: [],
          backgroundColor: [backgroundColor[index]],
          borderColor: [backgroundColor[index]],
          yAxisID: "y",
        };
        data.map((el, index1) => {
          temp.data.push(el[fields[index]]?.toString());
          // temp.backgroundColor.push(backgroundColor[index]);
          if (dataLabel.indexOf(el.ReceivedDate) < 0) {
            dataLabel.push(el.ReceivedDate);
          }
        });
        dataValue.push(temp);
      });
    setReceivedLabel(dataLabel);
    setReceivedData(dataValue);
  }, [data]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    radius: 5,
    hitRadius: 30,
    hoverRadius: 12,
    legend: {
      labels: {
        font: {
          size: 11,
        },
      },
      display: true,
      position: "bottom",
    },
    scales: {
      x: {
        grid: {
          display: label[0] === ">3M" ? true : false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        ticks: {
          autoSkip: false,
        },
        title: yTitle? {
          display: true,
          text: yTitle,
        }: 
        { display: false},
      },
    },
  };

  const dataset = {
    labels: [...recievedLabel],
    datasets: [...recievedData],
  };

  return (
    <div style={style}>
      <Line options={options} data={dataset} />
    </div>
  );
}
