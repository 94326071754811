import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from "react";
import Parser from 'html-react-parser';
import { getWidgetInfo, publishWidgetInfo } from "../../../Services/ApiManager";
import { useState } from "react";

export const GridInfo = ({dialogOpen, setDialogOpen, widgetId}) => {
  const [widgetModel, setWidgetModel] = useState({});
  const [widgetEditModel, setWidgetEditModel] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if(dialogOpen || !editMode){
        setInfoLoading(true);
        getWidgetInfo(widgetId).then((res) => {
            setWidgetModel(res.data.WidgetInformation[0]);
            setInfoLoading(false);
        });
    }
  }, [dialogOpen, editMode]);

  const publishInfo = () => {
    const widgetData = {
        ...widgetEditModel,
        wgid: widgetId,
        SlideID: '0',
    }
    setInfoLoading(true);
    publishWidgetInfo(widgetData).then((res) => {
        setInfoLoading(false);
        setEditMode(false);
    })
  }


  return (
    
    <DialogWrapper
        open={dialogOpen}
    >
        <DialogTitle id="form-dialog-title" className="header" display='flex' justifyContent="center">
            {infoLoading? (<CircularProgress />): (widgetModel.Title || widgetModel.Title ==='')? widgetModel.Title+ ' Documentation': 'Documentation'}
        </DialogTitle>
        {editMode? (<><DialogContent style={{
            height: '400px',
            padding: '30px 30px',
        }}>
            <TextField id="outlined-basic" label="Title" defaultValue={widgetModel.Title} variant="outlined" margin="normal" style={{width: "550px"}} onChange={(e) => setWidgetEditModel({...widgetModel, Title: e.target.value})} />
            <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                defaultValue={widgetModel.Explanation}
                multiline
                rows={5}
                margin="normal" 
                style={{width: "550px"}} 
                onChange={(e) => setWidgetEditModel({...widgetModel, Explanation: e.target.value})}
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Period"
                defaultValue={widgetModel.Period}
                multiline
                rows={8}
                margin="normal" 
                style={{width: "550px"}} 
                onChange={(e) => setWidgetEditModel({...widgetModel, Period: e.target.value})}
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Purpose"
                value={widgetModel.Purpose}
                multiline
                rows={3}
                margin="normal" 
                style={{width: "550px"}} 
                onChange={(e) => setWidgetEditModel({...widgetModel, Purpose: e.target.value})}
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Assumptions and Limitations"
                defaultValue={widgetModel.Assumption}
                multiline
                rows={8}
                margin="normal" 
                style={{width: "550px"}} 
                onChange={(e) => setWidgetEditModel({...widgetModel, Assumption: e.target.value})}
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Example"
                defaultValue={widgetModel.Example}
                multiline
                rows={8}
                margin="normal" 
                style={{width: "550px"}} 
                onChange={(e) => setWidgetEditModel({...widgetModel, Example: e.target.value})}
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setDialogOpen(false);
                    setEditMode(false);
                }}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                    position: 'absolute',
                    bottom: '15px',
                    right: '90px',
                }}
            >
            Close
            </Button>
            <Button
                onClick={publishInfo}
                style={{ 
                    color: 'white',
                    backgroundColor: '#12b3b7',
                    position: 'absolute',
                    bottom: '15px',
                    right: '10px',
                }}
            >
            Save
            </Button>
        </DialogActions></>): 
        (<><DialogContent style={{
            height: '400px',
            padding: '30px 30px',
        }}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6">Short Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>                    
                    {widgetModel.Explanation? Parser(widgetModel.Explanation): 'N/A'}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6">Purpose</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>                    
                    {widgetModel.Purpose? Parser(widgetModel.Purpose): 'N/A'}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6">Period</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    {widgetModel.Period? Parser(widgetModel.Period): 'N/A'}
                </Typography>
                </AccordionDetails>
            </Accordion>
                  <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6">Assumptions and Limitations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    
                    {widgetModel.Assumption? Parser(widgetModel.Assumption): 'N/A'}
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6">Example</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>                
                    {widgetModel.Example? Parser(widgetModel.Example): 'N/A'}
                </Typography>
                </AccordionDetails>
            </Accordion>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => setEditMode(true)}
                style={{ 
                    color: '#12b3b7',
                    backgroundColor: '',
                    position: 'absolute',
                    bottom: '15px',
                    right: '90px',
                }}
            >
            Edit
            </Button>
            <Button
                onClick={() => setDialogOpen(false)}
                style={{ 
                    color: 'white',
                    backgroundColor: '#12b3b7',
                    position: 'absolute',
                    bottom: '15px',
                    right: '10px',
                }}
            >
            Close
            </Button>
        </DialogActions></>)}
    </DialogWrapper>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;