import { useNavigate } from "react-router-dom";

// custom components
//import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
import "./style.css";
import styled from "styled-components";

function CustomCard({ widget_config, Icon }) {
  const history = useNavigate();

  const styleIcon = {
    color: "white",
    padding: "0.5rem",
    backgroundColor: widget_config.accent_color,
    borderRadius: "1.5rem",
    margin: "0.2rem 0.2rem 0.2rem 0",
  };

  return (
    <Wrapper>
      <div
        className="grow-1"
        style={widget_config.value == null ? { opacity: "0.8" } : {}}
      >
        <div
          className="main-container custom-card-container flex-column"
          style={{ justifyContent: "space-between" }}
        >
          <div className="flex-row">
            <div>
              <img alt="loading.." className="card-img" style={{color: widget_config.accent_color,}} src={widget_config.logo_url}></img>
            </div>
            <div className="widget-header-container flex-column">
              <h6 class="widget-header grow-1">{widget_config.title}</h6>
            
              <span>
                {/* <Icon size={40} style={styleIcon} />{" "} */}
                <span
                  style={{
                    fontSize: "15px",
                    verticalAlign: "top",
                    color: "grey"
                  }}
                >
                  {widget_config.dateFormat? 
                  widget_config.value:
                  widget_config.value?
                  Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((widget_config.value).toString().replace(/,/g, ''))):
                  '-'}
                </span>
              </span>
            </div>
          </div>
          <div className="flex-column"></div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CustomCard;

const Wrapper = styled.div`
  .card-img {
    width: 2.5vw;
    margin: 0 5px 0 0;
  }
`;
