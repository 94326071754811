import React, {} from "react";
import NavBar from "../../shared/NavBar";
import { HUB_SOH_API, ITEM_EXPIRY_API, PSM_CENTER_TO_HUB_API, PSM_HUB_TO_FACILITY_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import { HUB_VITAL_ENVIRONMENT_SELECT_CONFIG, PROGRAM_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";

function getItem(params) {
  return params.row.Item? params.row.Item : params.row.item;
}

function getAccount(params) {
  return params.row.Hub.replace('EPSA: ', '');
}

function getDate(params) {
  return `${moment(params.row.IssueDate).format("MMMM Do, YYYY")}`;
}

const HUB_SOH_COLUMN = [
  {
    field: "SN",
    renderHeader: (params) => <strong>{"Item SN"}</strong>,
    width: 100,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 400,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Program",
    renderHeader: (params) => <strong>{"Program"}</strong>,
    width: 100,
  },
  {
    field: "BeginningBalance",
    renderHeader: (params) => <strong>{"Beginning Balance"}</strong>,
    width: 100,
  },
  {
    field: "EndingBalance",
    renderHeader: (params) => <strong>{"Ending Balance"}</strong>,
    width: 100,
  },
  {
    field: "Received",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    width: 100,
  },
  {
    field: "Issued",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    width: 130,
  },
  {
    field: "LossOrAdjustment",
    renderHeader: (params) => <strong>{"Loss Or Adjustment"}</strong>,
    width: 100,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    width: 100,
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    width: 130,
  },
];

const HUB_SOH_DEFAULT_PARAM = {
  ProgramCode: 'FP',
  EnvironmentCode: 'CNPH',
}

const ITEM_EXPIRY_COLUMN = [
  {
    field: "SN",
    renderHeader: (params) => <strong>{"Item SN"}</strong>,
    width: 100,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 400,
    valueGetter: getItem,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Program",
    renderHeader: (params) => <strong>{"Program"}</strong>,
    width: 100,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    width: 100,
    valueGetter: getDate,
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    width: 100,
  },
];

const ITEM_EXPIRY_DEFAULT_PARAM = {
  ProgramCode: 'FP',
  EnvironmentCode: 'CNPH',
}

const CENTER_TO_HUB_COLUMN = [
  {
    field: "ItemSN",
    renderHeader: (params) => <strong>{"Item SN"}</strong>,
    width: 100,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 400,
    valueGetter: getItem,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Program",
    renderHeader: (params) => <strong>{"Program"}</strong>,
    width: 100,
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    width: 100,
  },
  {
    field: "Account",
    renderHeader: (params) => <strong>{"Account"}</strong>,
    width: 100,
    valueGetter: getAccount,
  },
  {
    field: "QtyApproved",
    renderHeader: (params) => <strong>{"Approved"}</strong>,
    width: 100,
  }, 
  {
    field: "QtyDelivered",
    renderHeader: (params) => <strong>{"Delivered"}</strong>,
    width: 130,
  },
  {
    field: "TotalPrice",
    renderHeader: (params) => <strong>{"Total Price"}</strong>,
    width: 130,
  },
  {
    field: "IssueDate",
    renderHeader: (params) => <strong>{"Issue Date"}</strong>,
    width: 130,
    valueGetter: getDate,
  },
];

const CENTER_TO_HUB_DEFAULT_PARAM = {
  EnvironmentCode: 'CNPH',
}

const HUB_TO_FACILITY_COLUMN = [
  {
    field: "ItemSN",
    renderHeader: (params) => <strong>{"Item SN"}</strong>,
    width: 50,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 400,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Program",
    renderHeader: (params) => <strong>{"Program"}</strong>,
    width: 100,
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Institution"}</strong>,
    width: 100,
  },
  {
    field: "Woreda",
    renderHeader: (params) => <strong>{"Woreda"}</strong>,
    width: 100,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    width: 100,
  },
  {
    field: "Account ",
    renderHeader: (params) => <strong>{"Account"}</strong>,
    width: 130,
  },
  {
    field: "QtyApproved",
    renderHeader: (params) => <strong>{"Approved"}</strong>,
    width: 100,
  },
  {
    field: "QtyDelivered",
    renderHeader: (params) => <strong>{"Delivered"}</strong>,
    width: 130,
  },
  {
    field: "TotalPrice",
    renderHeader: (params) => <strong>{"Total Price"}</strong>,
    width: 100,
  },
  {
    field: "UnitPrice",
    renderHeader: (params) => <strong>{"Unit Price"}</strong>,
    width: 130,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    width: 100,
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    width: 130,
  },
];

const HUB_TO_FACILLITY_DEFAULT_PARAM = {
  ProgramCode: 'FP',
  EnvironmentCode: 'CNPH',
}

const HubVitalReport = () => {
  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(7, 'day').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Hub Vital Report Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Hub SOH AMC by Program",
              data_source_api: HUB_SOH_API,
              data_property: "Data",
              columns: HUB_SOH_COLUMN,
              isSOH: false,
              wgInfoId: 'wgPsmHubSohAmcByProgram',
              use_default_filter_params: false,
              select_filter_config: [
                HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                PROGRAM_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {...HUB_SOH_DEFAULT_PARAM},
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                quarter_report: true,
              },
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Item Expiry Date by Program",
              data_source_api: ITEM_EXPIRY_API,
              data_property: "Data",
              columns: ITEM_EXPIRY_COLUMN,
              isSOH: false,
              wgInfoId: 'wgPsmItemExpiryDateByProgram',
              use_default_filter_params: false,
              select_filter_config: [
                HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                PROGRAM_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {...ITEM_EXPIRY_DEFAULT_PARAM},
                { hasDateRangeFilter: false }
              ),
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Center to Hub Issue",
              data_source_api: PSM_CENTER_TO_HUB_API,
              data_property: "Data",
              columns: CENTER_TO_HUB_COLUMN,
              isSOH: false,
              wgInfoId: 'wgPsmHubSohAmcByProgram',
              use_default_filter_params: false,
              select_filter_config: [
                HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {...CENTER_TO_HUB_DEFAULT_PARAM},
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                quarter_report: true,
              },
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Hub to Facilities Issue",
              data_source_api: PSM_HUB_TO_FACILITY_API,
              data_property: "Data",
              columns: HUB_TO_FACILITY_COLUMN,
              isSOH: false,
              wgInfoId: 'wgPsmHubToFacilitiesIssue',
              use_default_filter_params: false,
              select_filter_config: [
                HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                PROGRAM_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {...HUB_TO_FACILLITY_DEFAULT_PARAM},
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                quarter_report: true,
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default HubVitalReport;
