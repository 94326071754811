import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaExpandAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Dots from "../../../assets/GIFs/dotss.gif";

export default function DoughnutChart({ data, label, fields, style }) {
  const [receivedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useNavigate();

  useEffect(() => {
    setLoading(true);
    const dataValue = [];
    const dataLabel = [];
    data &&
      data.map((element) => {
        element && dataValue.push(parseInt(element[fields]));
        element && dataLabel.push(label);
        setLoading(false);
      });
    setReceivedData(dataValue);
    setReceivedLabel(dataLabel);
  }, [data]);

  const dataSet = {
    labels: ["current", "Off Target"],
    datasets: [
      {
        data: [receivedData, "100"],
        // needleValue: kpiData,
        backgroundColor: ["#12b3b7", "#E5E4E2"],
        hoverOffset: 4,
      },
    ],
  };

  const config = {
    responsive: true,
    circumference: 180,
    rotation: -90,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const plugins = [ChartDataLabels,{
     beforeDraw: function(chart) {
      var width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height/100).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = receivedData[0] ? receivedData[0].toString() : "";
      var textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height/2 + 7;
      ctx.fillText(text, textX, textY);
      ctx.save();
     } 
   }]

  return (
    <div className="container">
      <div className="header" style={{fontSize: "20px"}}>National MOS Below EOP</div>
      <div style={style}>
        {isLoading && (
          <span>
            <img src={Dots} alt="" />
          </span>
        )}
        {!isLoading && (
          <Doughnut
            type="doughnut"
            data={dataSet}
            plugins={plugins}
            options={config}
          />
        )}
      </div>
    </div>
  );
}
