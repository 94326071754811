import Axios from "axios";
import moment from "moment";

const key = JSON.parse(localStorage.getItem("userInfo"));
let authkey;
if (key) {
  authkey = key.Key;
}
Axios.defaults.headers.common = { Authorization: `Basic ${authkey}` };

const { REACT_APP_API_ENDPOINT } = process.env;

//Chart Requests
export const getMonthsOfStock = async (
  ModeCode,
  ProductSN,
  EnvironmentCode,
  EnvironmentGroupCode,
  OrderBy
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHMOSAMC_ByEnvronment",
    params:
      EnvironmentCode === ""
        ? {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
          }
        : {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
            EnvironmentCode: EnvironmentCode,
          },
  });
  return response;
};
export const getItemStockUtilization = async (
  ModeCode,
  ProductSN,
  EnvironmentCode,
  EnvironmentGroupCode,
  OrderBy
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/stockutilizationByEnvironment",
    params:
      EnvironmentCode === ""
        ? {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
          }
        : {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
            EnvironmentCode: EnvironmentCode,
          },
  });
  return response;
};
export const getItemPipeline = async (ModeCode, ProductSN, EnvironmentCode) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POHRIHRCH_WebApi/HubPipelineByEnvironment",
    params:
      EnvironmentCode === ""
        ? {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
          }
        : {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
            EnvironmentCode: EnvironmentCode,
          },
  });
  return response;
};
export const getItemNearExpiryBreakdown = async (
  ModeCode,
  ProductSN,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SohNearyExpiryBreakdownByEnvironment",
    params:
      EnvironmentCode === ""
        ? {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
          }
        : {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            EnvironmentCode: EnvironmentCode,
          },
  });
  return response;
};
export const getOrderFillRateBySite = async (
  params
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/FR_WebApi/OrderFillRateBySite",
    params: {
      ...params
    },
  });
  return response;
};
export const getSupplierFillRateBySite = async (
  params
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/FR_WebApi/SupplierFillRateBySite",
    params: {
      ...params
    },
  });
  return response;
};
export const getItemFundingSource = async (modeCode, ProductSN, year) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/ItemFundingSourceAndProcurer",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      FiscalYear: year,
    },
  });
  return response;
};
export const getItemProcurer = async (modeCode, ProductSN, params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/POD_WebApi/ItemProcurer",
    params: {
      ...params,
      ModeCode: modeCode,
      ProductSN: ProductSN,
    },
  });
  return response;
};

export const getNationalMos = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_webApi/NationalMOS",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
    },
  });
  return response;
};

export const getBincardManufacturer = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/BinCard_WebApi/ManufacturerSOH",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      OrderBy: 'SOH DESC',
    },
  });
  return response;
};

export const getBincardUnit = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SS_UnitSOH",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
    },
  });
  return response;
};

export const getBincardAccount = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHbyIWACC",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      OrderBy: 'SOH DESC',
    },
  });
  return response;
};
export const getBincardActivity = async (modeCode, ProductSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHbyIWAct",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      OrderBy: 'SOH DESC',
    },
  });
  return response;
};

export const getItemDistributionByFacilityType = async (
  modeCode,
  ProductSN,
  year
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByFacilityType",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      FiscalYear: year,
    },
  });
  return response;
};
export const getItemDistributionByOwnershipType = async (
  modeCode,
  ProductSN,
  year
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/DistributionByOwnershipType",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      FiscalYear: year,
    },
  });
  return response;
};
export const daysOutOfStock = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/DaysOutOfStockBySite",
    params,
  });
  return response;
};
export const itemDistributionByRegion = async (modeCode, ProductSN, year) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_webApi/SOHByRegion",
    params: {
      ModeCode: modeCode,
      ProductSN: ProductSN,
      FiscalYear: year,
    },
  });
  return response;
};

//Table requests
export const getItemStockStatus = async (
  ModeCode,
  ProductSN,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/SOHMOSAMC_ByEnvronment",
    params:
      EnvironmentCode === ""
        ? {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
            TransactionDate: TransactionDate,
          }
        : {
            ModeCode: ModeCode,
            EnvironmentGroupCode: "HUB",
            ProductSN: ProductSN,
            OrderBy: "Environment",
            TransactionDate: TransactionDate,
            EnvironmentCode: EnvironmentCode,
          },
  });
  return response;
};
export const getItemByManufacturer = async (
  ModeCode,
  ProductSN,
  FiscalYear,
  OrderBy
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemByManufacturer",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProductSN: ProductSN,
      OrderBy: "Manufacturer",
    },
  });
  return response;
};
export const getItemBySupplier = async (
  ModeCode,
  ProductSN,
  FiscalYear,
  EnvironmentGroupCode,
  OrderBy,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemBySupplier",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProductSN: ProductSN,
    },
  });
  return response;
};
export const getItemCountry = async (
  ModeCode,
  ProductSN,
  FiscalYear,
  EnvironmentGroupCode,
  OrderBy,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemCountry",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProductSN: ProductSN,
    },
  });
  return response;
};
export const getItemDistribution = async (
  ModeCode,
  ProductSN,
  FiscalYear,
  EnvironmentGroupCode,
  OrderBy,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/OIH_WebApi/ItemDistributionHub2Facility",
    params: {
      ModeCode: ModeCode,
      ProductSN: ProductSN,
    },
  });
  return response;
};
export const getItemPriceAnalysis = async (ModeCode, ItemSN) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemPriceAnalysis",
    params: {
      ModeCode: ModeCode,
      ItemSN: ItemSN,
    },
  });
  return response;
};
export const getPriceHistoryAggregate = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/AggregateItemPriceHistory",
    params,
  });
  return response;
};
export const getItemUnitForcast = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemUnitForecast",
    params,
  });
  return response;
};

export const getUnitListByItem = async (params) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/UnitsListByItem",
    params,
  });
  return response;
};
const requests = [
  //chart requests
  getMonthsOfStock,
  getItemStockUtilization,
  getItemPipeline,
  getItemNearExpiryBreakdown,
  getOrderFillRateBySite,
  getSupplierFillRateBySite,
  getItemFundingSource,
  getItemProcurer,
  getItemDistributionByFacilityType,
  getItemDistributionByOwnershipType,
  itemDistributionByRegion,
  daysOutOfStock,
  itemDistributionByRegion,

  //table requests
  getItemStockStatus,
  getItemByManufacturer,
  getItemBySupplier,
  getItemCountry,
  getItemDistribution,
  getItemPriceAnalysis,
  getItemUnitForcast,
];

export default requests;
