import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";
import Dots from "../../../assets/GIFs/dotss.gif";
import { year, ENVIRONMENT } from "../Shared Utils/InputConstants";
import CustomDatePicker from "../Shared Utils/CustomDatePicker";

export default function LineChart({
  data,
  label,
  fields,
  filter,
  filterType,
  style,
  chart_options,
}) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [recievedAmount, setReceivedAmount] = useState([]);
  const [loading, setLoading] = useState(true);
  const dataGridFilterParams = chart_options.defaultFilterParams;

  const backgroundColor = [
    "#12b3b7",
    "#0b7075",
    "#c4b8a6",
    "#098d98",
    "#0a4f5a",
    "#8ed0d1",
    "#ddc908",
    "#67a789",
  ];
  let count = 0;

  useEffect(() => {
    reload();
  }, [data]);

  const onChangeDatePicker = (dates) => {
    Object.keys(dates).forEach((key) => {
      dataGridFilterParams[key] = dates[key];
    });
    reload();
  };

  function reload() {
    setLoading(true);
    const dataValue = [];
    const dataLabel = [];

    data?.length > 0 &&
      label?.map((l, index) => {
        let temp = {
          label: l,
          data: [],
          backgroundColor: backgroundColor[index],
          fill: true,
          borderColor: "#77af9c",
        };
        data.map((el, index1) => {
          temp.data.push(el[fields[index]]?.toString());
          if (dataLabel.indexOf(el[chart_options.option]) < 0) {
            dataLabel.push(el[chart_options.option]);
            setLoading(false);
          }
        });
        dataValue.push(temp);
      });
    setReceivedLabel(dataLabel);
    setReceivedData(dataValue);
  }
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: fields[0] === "L3monthsAmtBirr" ? "y" : "x",
    animations: {
      tension: {
        duration: 1000,
        easing: "easeOutExpo",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const dataset = {
    labels: [...recievedLabel],
    datasets: [...recievedData],
  };
  return (
    <Wrapper>
      <div style={style}>
        <Line options={options} data={dataset} plugins={ChartDataLabels} />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .select {
    width: 6rem;
  }
`;
