import React, { useRef } from "react";
import ReactGA from "react-ga4";

import "./login.css";
import TextField from "@mui/material/TextField";
import logo5 from "../../assets/fanos-logo.png";
import warehousePc from "../../assets/warehouse.png";
import loginVector from "../../assets/login-vector.png";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getEnvironemntList, loginRequest, loginRequestDagu } from "../../Services/ApiManager";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchHubsList } from "../../Services/ApiManager";
import styled from "styled-components";
import LandingCarousel from "./LandingCarousel";
import CustomLoadingIndicator from "../shared/Shared Utils/CustomLoadingIndicator";
import { Alert, Snackbar } from "@mui/material";
import { ReactComponent as HeaderSvgOne } from "../../assets/login-header1.svg";
import { ReactComponent as HeaderSvgTwo } from "../../assets/login-header2.svg";
const Login = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(false);
  const [env, setEnv] = useState([]);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [details, setDetails] = useState({
    username: "",
    password: "",
    deviceIdentifier: "unknown",
    deviceType: "web",
    environmentCode: "",
    hcmisVersion: "unknown",
  });
  const submitHandler = (e) => {
    setSubmitted(true);
    setIsSubmitting(true);
    loginRequest(details)
      .then((data) => {
        setSuccessMessage(true);
        const userInfo = localStorage.getItem("userInfo");
        userInfo && localStorage.removeItem("userInfo");
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
          })
        );

        ReactGA.gtag("set", "user_properties", {
          Username: data.Username,
        });
        
        ReactGA.gtag("set", "user_properties", {
          UserID: data.UserID,
        });
        
        ReactGA.gtag("set", "user_properties", {
          UserGroup: data.UserGroup
        });
        
        ReactGA.gtag("set", "user_properties", {
          Environment: data.Environment
        });

        ReactGA.gtag("set", "user_properties", {
          EnvironmentGroup: data.EnvironmentGroup
        });

        history("/");
        setSubmitted(false);
        setIsSubmitting(false);
        loginRequestDagu().then((daguToken) => {
          const userInfoDagu = localStorage.getItem("daguToken");
          userInfoDagu && localStorage.removeItem("daguToken");
          localStorage.setItem("daguToken", daguToken);
        });
      })
      .catch((err) => {
        setErrorAlert(true)
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 20000);
        setIsSubmitting(false);
      });
  };
  // const dispatch = useDispatch();
  const [envCode, setEnvCode] = useState([]);
  let loggedIn = false;
  let deviceIdentifier = useRef(localStorage.getItem("deviceIdentifier"));

  useEffect(() => {
    fetchHubsList().then((data) => {
      setEnv(data.data.Data);
    });
  }, []);

  useRef(() => {
    deviceIdentifier = localStorage.getItem("deviceIdentifier");
    if (!deviceIdentifier) {
      localStorage.setItem("deviceIdentifier", guid());
      deviceIdentifier = localStorage.getItme("deviceIdentifier");
    }
  }, []);

  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  const handleClose = () => {
    setErrorAlert(false);
  }
 
  return (
    <Wrapper>
      <div className="login-form">
        <div className="login-card">
          <div className="loginContainer">
            <div className="left-container">
              <LandingCarousel />
            </div>
            <div className="right-container">
              <LoginInput>
                <LoginHeader>
                  <HeaderSvgOne />
                  <HeaderSvgTwo />
                </LoginHeader>
                <LoginForm className="form-inner">
                  <form>
                    <div className="form-header">
                      <div>
                        <img
                          src={logo5}
                          alt="Loading..."
                          style={{ height: "60px", width: "60px" }}
                        ></img>
                      </div>
                      
                    </div>
                    <br />
                    <div className="header-text">
                      <div>FANOS DASHBOARD</div>
                    </div>
                    {/* <p>{JSON.stringify(this.state.resultArr[0])}</p> */}
                    <div className="form-group">
                      <LoginLabel htmlFor="username">Username:</LoginLabel>
                      <BorderlessInput
                        type="text"
                        name="username"
                        id="username"
                        onChange={(e) =>
                          setDetails({ ...details, username: e.target.value })
                        }
                        value={details.username}
                      />
                    </div>

                    <div className="form-group">
                      <LoginLabel htmlFor="password">Password:</LoginLabel>
                      <div>
                        <BorderlessInput
                          type="password"
                          name="password"
                          id="password"
                          onChange={(e) =>
                            setDetails({ ...details, password: e.target.value })
                          }
                          value={details.password}
                        />
                        {/* {showPassword ? (
                          <AiOutlineEye className="eye-button" />
                        ) : (
                          <AiOutlineEyeInvisible className="eye-button" />
                        )} */}
                      </div>
                    </div>
                    <div className="form-group">
                      <LoginLabel htmlFor="site">Site:</LoginLabel>
                      <BorderlessSelect
                        className="select"
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            environmentCode: e.target.value,
                          })
                        }
                      >
                        <option value="" className="empty-option">
                          {"Others"}
                        </option>{" "}
                        {env.map((el) => (
                          <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                            {el.Environment}
                          </option>
                        ))}
                      </BorderlessSelect>
                    </div>

                    <div className="form-group" style={{display: 'flex', justifyContent: 'center'}}>
                      <LoginButton
                        type="button"
                        value="Login"
                        onClick={submitHandler}
                      >
                        Login
                        {isSubmitting && (<span style={{ position: "relative", bottom: "-0.9rem", marginLeft: "5px"}}>
                          <CustomLoadingIndicator />
                        </span>)}
                      </LoginButton>
                    </div>
                    <br />
                  </form>
                </LoginForm>
              </LoginInput>
            </div>
          </div>
        </div>
      </div>     
      <Snackbar open={errorAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error logging in!
        </Alert>
      </Snackbar> 
    </Wrapper>
  );
};

export default Login;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  .login-form {
    background-image: url(${warehousePc});
    padding: 20px 50px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-card {
    background-color: #fff;
    width: 90vw;
    max-width: 1473.27px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .login-card h2 {
    text-align: center;
  }

  .form-header {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -45px;
    transform: translateX(-50%);
    object-fit: cover;
  }

  .header-text {
    display: flex;
    justify-content: center;
    font-size: 19px;
    color: #15616D;
    margin-bottom: 10px;
  }

  .login-card label {
    margin-bottom: 5px;
  }

  .loginContainer {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .left-container {
    display: flex;
    justify-content: center;
    width: 27%;
    background-color: white;
  }
  .right-container {
    width: 73%;
    background-image: url(${loginVector});
    display: flex;
    justify-content: center;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .img {
    width: 100%;
    height: 100%;
  }


  @media screen and (max-width: 900px) {
    .left-container {
      display: none;
    }
    .right-container {
      text-align: center;
    }
    .form-header {
      flex-direction: column;
    }
    .header-text {
      margin-left: 0 !important;
      margin-top: 7px;
    }
  }
`;

const LoginInput = styled(Box)`
  background-color: white;
  margin: 30px 0;
  box-shadow: 0px 4px 4px 0px #00000040;

`;

const LoginHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  background-color: #15616D;
  height: 83px;
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const LoginLabel = styled.label`
  color: #AEADB3;
  text-align: left;
  display: block;
  font-size: 15px;
  padding-left: 15px;
  margin-bottom: 5px;
  transition: 0.4s;
`;

const BorderlessInput = styled.input`
  border: none;
  outline: none;
  background-color: #EDEDED;
  padding: 5px 15px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 8px;
  border-radius: 15px;
  color: #AEADB3;
`;

const BorderlessSelect = styled.select`
  border: none;
  outline: none;
  background-color: #EDEDED;
  padding: 5px 15px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 8px;
  border-radius: 15px;
  color: #AEADB3;
  margin-left: 0%;
`;

const LoginButton = styled.button`
  display: block;
  padding: 3px 40px;
  border-radius: 4px;
  transition: 0.4s; 
  margin-top: 12px;
  background-color: #15616d;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;