import moment from "moment";
import { FormatDecimal } from "../../../Utils/NumberUtils";
import clsx from "clsx";
import { Button } from "@mui/material";
import { FaExpandAlt } from "react-icons/fa";
import { useFanosStore } from "../../shared/Shared Utils/store";

function getShortDate(params) {
  return `${moment(params.value).format("MMM Do, YYYY")}`;
}

function getFromNow(params) {
  return `${moment(params.value).fromNow()}`;
}

export const manufacturerColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "Manufacturer", headerName: "Manufacturer", width: 250 },
  { field: "Country", headerName: "Country", width: 250 },
  { field: "ProductCN", headerName: "Item", width: 250 },
  { field: "AmountBirr", headerName: "Value (ETB)	", width: 250 },
  { field: "QuantityReceived", headerName: "Quantity", width: 250 },
  { field: "ReceivedbyAllRecipants", headerName: "Share", width: 250 },
];

export const stockOnHandColumn = [
  { field: "RowNumber", headerName: " ", width: 15 },
  { field: "Environment", headerName: "Site", width: 150 },
  { field: "ProductSN", headerName: "EC.", width: 150 },
  { field: "Imp-Nxt", headerName: "Imp-Nxt", width: 150 },
  { field: "MCn", headerName: "MCn", width: 150 },
  { field: "COC.", headerName: "COC.", width: 150 },
  { field: "IUD.", headerName: "IUD.", width: 150 },
  { field: "POP.", headerName: "POP.", width: 150 },
  { field: "Medroxy", headerName: "Medroxy", width: 150 },
  { field: "Jadelle1", headerName: "Jadelle1", width: 150 },
  { field: "PP-IUCD 1", headerName: "PP-IUCD 1", width: 150 },
];

export const recentIssueColumn = [
  { field: "RowNumber", headerName: "", width: 50 },
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1.5 },
  { field: "unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "ModeCode", renderHeader: (params) => <strong>{"Mode"}</strong>, flex: 1 },
  { field: "QuantityIssued", renderHeader: (params) => <strong>{"Issued"}</strong>, flex: 1, type: "decimalZero" },
  { field: "Institution", renderHeader: (params) => <strong>{"Institution"}</strong>, flex: 1 },
  { field: "Region", renderHeader: (params) => <strong>{"Region"}</strong>, flex: 1 },
  { field: "TransactionDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueFormatter: getFromNow },
];
export const recentReceiveColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "ModeCode", renderHeader: (params) => <strong>{"Mode"}</strong>, flex: 1 },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Received"}</strong>, flex: 1, type: "decimalZero" },
  { field: "Supplier", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "TransactionDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueFormatter: getFromNow },
];
export const pendingOrderColumn = [
  { field: "RowNumber", headerName: "", width: 50 },
  { field: "OrderNumber", renderHeader: (params) => <strong>{"Order Number"}</strong>, flex: 0.7 },
  { field: "OrderStatus", renderHeader: (params) => <strong>{"Status"}</strong>, flex: 1 },
  { field: "Institution", renderHeader: (params) => <strong>{"Site"}</strong>, flex: 1 },
  { field: "ModeCode", renderHeader: (params) => <strong>{"Mode"}</strong>, flex: 1 },
  { field: "QuantityRequested", renderHeader: (params) => <strong>{"Requested"}</strong>, flex: 1, type: "decimalZero" },
  { field: "TransactionDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueFormatter: getFromNow },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <ExpandPending params={params} />,
    width: 100,
  },
];
export const stockStatusColumn = [
  {
    field: "FullItemName",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 2,
  },
  {
    field: "ModeCode",
    renderHeader: (params) => <strong>{"Mode"}</strong>,
    flex: 0.8,
  },
  {
    field: "SS",
    renderHeader: (params) => <strong>{"Status"}</strong>,
    flex: 1,
    cellClassName: (params) =>
      clsx({
        normal: params.value === 'Normal',
        excess: params.value === 'Excess',
        belowMin: params.value === 'Below Min',
        stockedOut: params.value === 'Stocked Out',
        belowEOP: params.value === 'Below EOP',
      }),
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    flex: 1,
    type: "decimalZero"
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    flex: 1,
    type: "decimal",
  },
  {
    field: "Ordered",
    renderHeader: (params) => <div><strong>{"Ordered"}</strong><br/><span> (in MOS)</span></div>,
    flex: 1,
    renderCell: (params) => <div><div>{FormatDecimal(params.row.Ordered + params.row.GIT, 0)}</div> <div>({params.row.AMC? FormatDecimal((params.row.Ordered + params.row.GIT)/params.row.AMC): 0})</div></div>,
  },
  {
    field: "GIT",
    renderHeader: (params) => <div><strong>{"GIT"}</strong><br/><span> (in MOS)</span></div>,
    flex: 1,
    renderCell: (params) => <div><div>{FormatDecimal(params.row.GIT, 0)}</div> <div>({params.row.AMC? FormatDecimal((params.row.GIT)/params.row.AMC): 0})</div></div>,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Expired",
    renderHeader: (params) => <strong>{"Expired"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Damaged",
    renderHeader: (params) => <strong>{"Damaged"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
];

export const GITByInvoiceColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "InvoiceNumber", renderHeader: (params) => <strong>{"STV"}</strong>, flex: 1 },
  { field: "ModeCode", renderHeader: (params) => <strong>{"Mode"}</strong>, flex: 1 },
  { field: "FullActivityName", renderHeader: (params) => <strong>{"Account"}</strong>, flex: 1 },
  { field: "GRNFPercentage", renderHeader: (params) => <strong>{"GRNF"}</strong>, flex: 1 },
  { field: "GRVPercentage", renderHeader: (params) => <strong>{"GRV"}</strong>, flex: 1 },
  { field: "ReceiptInvoiceDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueFormatter: getFromNow },
  { field: "ReceivedAmount", renderHeader: (params) => <strong>{"Recieved (ETB)"}</strong>, flex: 1, type: "decimalZero" },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <ExpandGIT params={params} />,
    width: 100,
  },
];

export const invoiceColumn = [
  { field: "RowNumber", headerName: " ", width: 50 },
  { field: "ReferenceNumber", renderHeader: (params) => <strong>{"Inv No."}</strong>, flex: 1 },
  { field: "Institution", renderHeader: (params) => <strong>{"Facility"}</strong>, flex: 1 },
  { field: "Warehouse", renderHeader: (params) => <strong>{"Warehouse"}</strong>, flex: 1 },
  { field: "Account", renderHeader: (params) => <strong>{"Account"}</strong>, flex: 1 },
  { field: "Mode", renderHeader: (params) => <strong>{"Mode"}</strong>, flex: 1 },
  { field: "Model19", renderHeader: (params) => <strong>{"Model-19"}</strong>, flex: 1 },
  { field: "DocumentType", renderHeader: (params) => <strong>{"Payment Type"}</strong>, flex: 1 },
  { field: "AmountInBirr", renderHeader: (params) => <strong>{"Issued (ETB)"}</strong>, flex: 1, type: "decimalZero" },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <ExpandInvoice params={params} />,
    width: 100,
  },
];

export const PENDING_DETAIL_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "QuantityRequested",
    renderHeader: (params) => <strong>{"QuantityRequested"}</strong>,
    flex: 1,
  },
];

export const GIT_DETAIL_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "FullItemNameSH",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.5,
    valueGetter: (params) => `${params.row.FullItemNameSH}[${params.row.Unit}]`
  },
  {
    field: "Issued",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    flex: 1,
  },
  {
    field: "Received",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    flex: 1,
  },
];

export const INVOICES_DETAIL_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "QuantityIssued",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    flex: 1,
  },
  {
    field: "AmountIssued",
    renderHeader: (params) => <strong>{"Amount (ETB)"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
];

export const countryColumn = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "Country", renderHeader: (params) => <strong>{"Country"}</strong>, flex: 2 },
  { field: "AmountBirr", renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, flex: 1 },
  { field: "ReceivedbyAllRecipants", renderHeader: (params) => <strong>{"Share"}</strong>, flex: 1 },
];

export const facillityCountColumn = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "InstitutionType", renderHeader: (params) => <strong>{"Institution Type"}</strong>, flex: 1 },
  { field: "NoOfInstitution", renderHeader: (params) => <strong>{"Count"}</strong>, flex: 1 },
];

const columns = [
  stockOnHandColumn,
  manufacturerColumn,
  countryColumn,
  recentIssueColumn,
  recentReceiveColumn,
  pendingOrderColumn,
  stockStatusColumn,
  GITByInvoiceColumn,
  invoiceColumn,
];

const ExpandPending = ({params}) => {
  const setLocationPendingModalOpen = useFanosStore((state) => state.setLocationPendingModalOpen);
  const setModalLocationPending = useFanosStore((state) => state.setModalLocationPending);

  const handleLocationPendingDetailsClicked = (pendID) => {
    setLocationPendingModalOpen(true)
    setModalLocationPending(pendID)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleLocationPendingDetailsClicked([params.row.EnvironmentCode, params.row.ModeCode, params.row.RequisitionID])}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandGIT = ({params}) => {
  const setLocationGitModalOpen = useFanosStore((state) => state.setLocationGitModalOpen);
  const setModalLocationGit = useFanosStore((state) => state.setModalLocationGit);

  const handleLocationGitDetailsClicked = (gitID) => {
    setLocationGitModalOpen(true)
    setModalLocationGit(gitID)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleLocationGitDetailsClicked(params.row.ReceiptInvoiceID)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandInvoice = ({params}) => {
  const setLocationInvoiceModalOpen = useFanosStore((state) => state.setLocationInvoiceModalOpen);
  const setModalLocationInvoice = useFanosStore((state) => state.setModalLocationInvoice);

  const handleLocationInvoiceDetailsClicked = (invID) => {
    setLocationInvoiceModalOpen(true)
    setModalLocationInvoice(invID)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleLocationInvoiceDetailsClicked([params.row.ReferenceNumber, params.row.InstitutionID, params.row.DocumentTypeCode, params.row.ModeCode, params.row.IssueDate, params.row.EnvironmentCode])}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

export default columns;
