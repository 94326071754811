function getID(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

export const FACILITY_CERTIFICATION_COLUMN = [
  {
    field: "#",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "FacilityName",
    renderHeader: (params) => <strong>{"Facility Name"}</strong>,
    flex: 1.2,
  },
  {
    field: "FacilityType",
    renderHeader: (params) => <strong>{"Facility Type"}</strong>,
    flex: 1,
  },
  {
    field: "ProductType",
    renderHeader: (params) => <strong>{"Product Type"}</strong>,
    flex: 1.2,
  },
  {
    field: "ApplicationType",
    renderHeader: (params) => <strong>{"Application Type"}</strong>,
    flex: 1,
  },
  {
    field: "BranchOffice",
    renderHeader: (params) => <strong>{"Branch Office"}</strong>,
    flex: 1,
  },
];

export const PERMITTED_PRODUCTS_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "ItemName",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1,
  },
  {
    field: "Pack",
    renderHeader: (params) => <strong>{"Pack"}</strong>,
    flex: 1,
  },
  {
    field: "Country",
    renderHeader: (params) => <strong>{"Country"}</strong>,
    flex: 1,
  },
  {
    field: "ProductType",
    renderHeader: (params) => <strong>{"Product Type"}</strong>,
    flex: 1,
  },
  
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    flex: 1,
  },
  {
    field: "Supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1,
  },
  {
    field: "Importer",
    renderHeader: (params) => <strong>{"Importer"}</strong>,
    flex: 1,
  },
  
  {
    field: "UnitPrice",
    renderHeader: (params) => <strong>{"UnitPrice"}</strong>,
    flex: 1,
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    flex: 1,
  },
  {
    field: "ApprovedDate",
    renderHeader: (params) => <strong>{"Approved Date"}</strong>,
    flex: 1,
  },
];

export const APPLICATIONS_RELEASED_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "ItemName",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.2,
  },
  {
    field: "Country",
    renderHeader: (params) => <strong>{"Country"}</strong>,
    flex: 1,
  },
  {
    field: "Importer",
    renderHeader: (params) => <strong>{"Importer"}</strong>,
    flex: 1.2,
  },
  {
    field: "BatchNumber",
    renderHeader: (params) => <strong>{"Batch No"}</strong>,
    flex: 1,
  },
  {
    field: "ApplicationDate",
    renderHeader: (params) => <strong>{"Application Date"}</strong>,
    flex: 1.2,
  },
  {
    field: "ReleaseDate",
    renderHeader: (params) => <strong>{"Release Date"}</strong>,
    flex: 1,
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    flex: 1.2,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    flex: 1,
  },
];

export const REGISTERED_PRODUCTS_COLUMN = [
  {
    field: "No",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 100,
    valueGetter: getID,
  },
  {
    field: "FullItemName",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.2,
  },
  {
    field: "PackSize",
    renderHeader: (params) => <strong>{"Pack Size"}</strong>,
    flex: 1,
  },
  {
    field: "ProductType",
    renderHeader: (params) => <strong>{"Product Type"}</strong>,
    flex: 1.2,
  },
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    flex: 1,
  },
  {
    field: "Applicant",
    renderHeader: (params) => <strong>{"Applicant"}</strong>,
    flex: 1.2,
  },
  {
    field: "LicenseHolder",
    renderHeader: (params) => <strong>{"LicenseHolder"}</strong>,
    flex: 1,
  },{
    field: "ApprovalDate",
    renderHeader: (params) => <strong>{"Approved Date"}</strong>,
    flex: 1.2,
  },
  {
    field: "ExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    flex: 1,
  },
];