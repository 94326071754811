const fanosEnvironmentList =
  "/POD_WebApi/EnvironmentList?EnvironmentGroupCode=hub";

export const year = [
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
];

export const ENVIRONMENT = ["HUB", "CENTER"];

export const HUB = {
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentCode",
  place_holder: "Environment",
  param_name: "EnvironmentCode",
};
const Constants = [year];

export const MAP_CONSTANTS = {
  FacilityHeatMap: "Commodity Distribution By Value",
  FacilityCircleMap: "Facility Distribution",
  ByRegionHeatMap: "Distribution to Region",
  ByRegionThematicMap: "Distribution to Region - By Value",
  ByZoneHeatMap: "Distribution to Zone",
  ByZoneThematicMap: "Distribution to Zone - By Value",
  ByWoredaHeatMap: "Distribution to Woreda",
  ByWoredaThematicMap: "Distribution to Woreda - By Value",
  Local: "Local Supply Routes",
  Global: "Global Supply Routes: Shipment Data",
  Overlaid: "Supply Routes",
}
export default Constants;
