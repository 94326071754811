import { FaEllipsisH } from "react-icons/fa";
import 'react-circular-progressbar/dist/styles.css';
import "./style.css";
import styled from "styled-components";
import { ComposableMap, Geographies, Geography, Line, Marker } from "react-simple-maps";
import { useEffect } from "react";
import { getGlobalSupplyRoutes, getHubsLocation } from "../../../Services/ApiManager";
import { useState } from "react";
import { Tooltip } from "@mui/material";

const GlobalMapCard = ({title}) => {
  const [globalPathCoordinates, setGlobalPathCoordinates] = useState([]);

  useEffect(() => {
      getGlobalSupplyRoutes().then((res) => {
        // setErrorOccured(false)
        const tempGlobalPrivate = [];
        const tempGlobalPublic = [];
        res.data.Data.map((result) =>{ 
          if(result.Type === 'Private'){
            tempGlobalPrivate.push({lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)});
            tempGlobalPrivate.push({lat: parseFloat(result.RLatitude), lng: parseFloat(result.RLongitude)});
          }
          if(result.Type === 'Public'){
            tempGlobalPublic.push({lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)});
            tempGlobalPublic.push({lat: parseFloat(result.RLatitude), lng: parseFloat(result.RLongitude)});
          }
        })
        setGlobalPathCoordinates(res.data.Data);
      }).catch(() => {
        // setErrorOccured(true)
      })
  },[]);


  return (
      <Wrapper>
        <Header>
          <Title>
              {title}
          </Title>
        </Header>
        <div>
            <ComposableMap 
                projection="geoMercator"
                projectionConfig={{
                    scale: 0,
                    center: [0, 40.1450],
                }}
            >
            <Geographies geography="/globalFeatures.json">
                {({ geographies }) =>
                geographies.map((geo) => (
                    <Geography 
                        key={geo.rsmKey} 
                        geography={geo} 
                        style={{
                            default: {
                                fill: '#15616d',
                                stroke: '#fff',
                                strokeWidth: 1,
                                outline: 'none',
                            },
                        }}
                    />
                ))
                }
            </Geographies>
            {globalPathCoordinates.map((gpr) => (
            <Line
                from={[gpr.RLongitude, gpr.RLatitude]}
                to={[gpr.SLongitude, gpr.SLatitude]}
                stroke="#D76C05"
                strokeWidth={4}
                strokeLinecap="round"
            />
            ))}
            </ComposableMap>
        </div>
      </Wrapper>
    );
}

export default GlobalMapCard;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    padding: 1rem 2rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #545E66;
    border-radius: 8px 8px 0 0;
    gap: 40px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: 600;
`;
const DateSelected = styled.div`
    font-size: 1rem;
    color: #545E66;
`;
