import Axios from "axios";

Axios.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let token = userInfo?.Key;
    if (token) {
      config.headers["Authorization"] = "Basic " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const { REACT_APP_API_ENDPOINT } = process.env;

export const getSOHByActivity = async (filter) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/SohByActivity",
    params: { ModeCode: "HPR", EnvironmentCode: filter },
  });
  return response;
};
export const getIssueHistory = async (filter) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/IssuedByInvoice",
    params: { ModeCode: "HPR", EnvironmentCode: filter },
  });
  return response;
};
export const getRecentReceives = async (filter) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/RecentReceive",
    params: { ModeCode: "HPR", EnvironmentCode: filter },
  });
  return response;
};
export const getPipelineReport = async (filter) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/Covid19_WebApi/CovidItemsPipeline",
    params: { ModeCode: "HPR", EnvironmentCode: filter },
  });
  return response;
};
export const getStockOnHand = async (
  ModeCode,
  ProgramCode,
  TransactionDate,
  EnvironmentGroupCode,
  OrderBy
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/SS_WebApi/EnvironmentSOHByDate",
    params: {
      ModeCode: ModeCode,
      EnvironmentGroupCode: "HUB",
      ProgramCode: ProgramCode,
      OrderBy: "Environment ASC",
      TransactionDate: TransactionDate,
    },
  });
  return response;
};
export const getStockStatus = async (
  ModeCode,
  ProgramCode,
  EnvironmentTypeCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/IUBVIUI_WebApi/ProgramProducts",
    params: {
      ModeCode: ModeCode,
      ProgramCode: ProgramCode,
      EnvironmentTypeCode: EnvironmentTypeCode,
      OrderBy: "ProductCN",
    },
  });
  return response;
};
export const getItemByManufacturer = async (
  ModeCode,
  ProgramCode,
  FiscalYear,
  OrderBy
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemByManufacturer",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProgramCode: ProgramCode,
      OrderBy: "Manufacturer",
    },
  });
  return response;
};
export const getItemBySupplier = async (
  ModeCode,
  ProgramCode,
  FiscalYear,
  EnvironmentGroupCode,
  OrderBy,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemBySupplier",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProgramCode: ProgramCode,
    },
  });
  return response;
};
export const getItemCountry = async (
  ModeCode,
  ProgramCode,
  FiscalYear,
  EnvironmentGroupCode,
  OrderBy,
  TransactionDate,
  EnvironmentCode
) => {
  const response = await Axios({
    method: "get",
    url: REACT_APP_API_ENDPOINT + "/RCD_WebApi/ItemCountry",
    params: {
      ModeCode: ModeCode,
      FiscalYear: FiscalYear,
      ProgramCode: ProgramCode,
    },
  });
  return response;
};

const requests = [
  getSOHByActivity,
  getIssueHistory,
  getRecentReceives,
  getPipelineReport,
  getStockOnHand,
  getStockStatus,
  getItemByManufacturer,
  getItemBySupplier,
  getItemCountry,
];

export default requests;
