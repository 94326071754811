import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { FaEllipsisH } from "react-icons/fa";
import Dots from "../../../assets/GIFs/dotss.gif";
import { getEndToEndSupplyChain } from '../../../Services/ApiManager';
import { useRef } from 'react';
import { FormControl, MenuItem, Modal, Select } from '@mui/material';
import { CONSTANTS } from '../../../Utils/Constants';
import { toEthiopian } from 'ethiopian-date';
import moment from 'moment';
import { getFiscalYearList } from '../../../Services/locationRequest';

export const StackedBarChart = () => {
  const [supplyChain, setSupplyChain] = useState(CONSTANTS.InitialSupplyChainData);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [open, setOpen] = useState(false);
  const chartRef = useRef(null);

  const distribution = 50;

  const taperingValues = (inputValue, varianceLeft, varianceRight) => {
    const taperedData = [inputValue];
    for(let i = 2; i <= distribution; i++) {
      taperedData.unshift(inputValue + (varianceLeft - (varianceLeft/(i**(2.3/(distribution - i))))));
      taperedData.push(inputValue - (varianceRight - (varianceRight/(i**(2.3/(distribution - i))))));
    }
    taperedData.unshift(inputValue + varianceLeft);
    taperedData.push(inputValue - varianceRight);

    return taperedData;
  }

  const repeatitiveValue = (val, iteration) => {
    const repeatData = [];

    for(let i = 0; i < iteration; i++) {
      repeatData.push(val)
    }
    
    return repeatData;
  }

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const handleClose = (value) => {
    setOpen(value)
  }

  useEffect(() => {
    getFiscalYearList().then((res) => {
      setFiscalYears(res.data.Data.map((d) => d.FiscalYear))
      setYear(res.data.Data.map((d) => d.FiscalYear)[res.data.Data.map((d) => d.FiscalYear).length - 1])
    })
  }, [])

  useEffect(() => {
    if(year){
      setIsLoading(true)
      getEndToEndSupplyChain(year).then((res) => {
        setIsLoading(false);
        res.data.Data.length? setSupplyChain(res.data.Data[0]): setSupplyChain(CONSTANTS.InitialSupplyChainData);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [year]);
  

  useEffect(() => {
    setData((prevData) => {
      const updatedData = {
        labels: [
          ...repeatitiveValue("", distribution),
          'PO',
          ...repeatitiveValue("", distribution - 1),
          ...repeatitiveValue("", distribution),
          'Invoice',
          ...repeatitiveValue("", distribution - 1),
          ...repeatitiveValue("", distribution),
          'Receipt',
          ...repeatitiveValue("", distribution - 1),
          ...repeatitiveValue("", distribution),
          'Issue',
          ...repeatitiveValue("", distribution - 1),
          ...repeatitiveValue("", distribution),
          'SOH',
          ...repeatitiveValue("", distribution - 1),
        ],
        datasets: [
          {
            label: 'PO - ' + Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((supplyChain.POQuantity).toString().replace(/,/g, ''))),
            data: [...taperingValues(supplyChain.POQuantity, (supplyChain.POQuantity - supplyChain.InvoicedQuantity) / 2, (supplyChain.POQuantity - supplyChain.InvoicedQuantity) / 2)],
            borderColor: '#15616D',
            backgroundColor: 'rgba(209,234,104,255)',
            borderWidth: 0.8,
            pointRadius: 0.2,
            fill: {
              target: 'origin',
              above: 'rgba(209,234,104,255)'
            }
          },
          {
            label: 'INVOICE - ' + Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((supplyChain.InvoicedQuantity).toString().replace(/,/g, ''))),
            data: [
              ...repeatitiveValue(null, distribution * 2),
              ...taperingValues(supplyChain.InvoicedQuantity, (supplyChain.POQuantity - supplyChain.InvoicedQuantity) / 2, (supplyChain.InvoicedQuantity - supplyChain.ReceivedQuantity) / 2),
            ],
            borderColor: '#15616D',
            backgroundColor: 'rgba(143,196,124,255)',
            borderWidth: 0.8,
            pointRadius: 0.2,
            fill: {
              target: 'origin',
              above: 'rgba(143,196,124,255)' // Fill color for the area below the line
            },
          },
          {
            label: 'RECEIPT - ' + Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((supplyChain.ReceivedQuantity).toString().replace(/,/g, ''))),
            data: [
              ...repeatitiveValue(null, (distribution * 2) * 2),
              ...taperingValues(supplyChain.ReceivedQuantity, (supplyChain.InvoicedQuantity - supplyChain.ReceivedQuantity) / 2, (supplyChain.ReceivedQuantity - supplyChain.IssuedQuantity) / 2),
            ],
            borderColor: '#15616D',
            backgroundColor: 'rgba(88,159,119,255)',
            borderWidth: 0.8,
            pointRadius: 0.2,
            fill: {
              target: 'origin',
              above: 'rgba(88,159,119,255)'
            }
          },
          {
            label: 'ISSUE - ' + Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((supplyChain.IssuedQuantity).toString().replace(/,/g, ''))),
            data: [
              ...repeatitiveValue(null, (distribution * 2) * 3),
              ...taperingValues(supplyChain.IssuedQuantity, (supplyChain.ReceivedQuantity - supplyChain.IssuedQuantity) / 2, (supplyChain.IssuedQuantity - supplyChain.SOH) / 2),
            ],
            borderColor: '#15616D',
            backgroundColor: 'rgba(51,119,109,255)',
            borderWidth: 0.8,
            pointRadius: 0.2,
            fill: {
              target: 'origin',
              above: 'rgba(51,119,109,255)' // Fill color for the area below the line
            },
          },
          {
            label: 'SOH - ' + Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                  }).format(parseFloat((supplyChain.SOH).toString().replace(/,/g, ''))),
            data: [
              ...repeatitiveValue(null, (distribution * 2) * 4),
              ...taperingValues(supplyChain.SOH, (supplyChain.IssuedQuantity - supplyChain.SOH) / 2, (supplyChain.IssuedQuantity - supplyChain.SOH) / 2),
            ],
            borderColor: '#15616D',
            backgroundColor: 'rgba(32,81,85,255)',
            borderWidth: 0.8,
            pointRadius: 0.2,
            fill: {
              target: 'origin',
              above: 'rgba(32,81,85,255)' // Fill color for the area below the line
            },
          },
        ],
      };

      return updatedData;
    });
  }, [supplyChain]);


    // Chart options
    const options = {
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            maxRotation: 0,
            minRotation: 0,
          },
          grid: {
            display: false, // Turn off grid lines for the y-axis
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false, // Turn off grid lines for the y-axis
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              if(Object.values(supplyChain).includes(context.raw)){
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.raw !== null) {
                    label += context.raw;
                }
                return label;
              } else {
                return "";
              }
            }
          }
        },
      },
      elements: {
        point: {
          borderWidth: function (context) {
            return context.active ? 3 : 1;

          },
          radius: function (context) {
            return Object.values(supplyChain).includes(context.raw) ? 10: 1;
          },
        },
      },
    };

    return (
      <Wrapper>
        <Header>
          <TitleWrapper >
            <Title>
              End to End Supply chain integration
            </Title>
            {isLoading && <span>
              <img src={Dots} alt="" />
            </span>}
          </TitleWrapper>
          <TitleWrapper>
            <Select
              id="select-year"
              value={year}
              size='small'
              label="Year"
              style={{color: "#545E66", width: "8rem", marginRight: "1rem"}}
              onChange={(e) => setYear(e.target.value)}
            >
              {fiscalYears.map((year) => (
                <MenuItem value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            <Box onClick={() => setOpen(true)}>
              <FaEllipsisH size={25} />
            </Box>
          </TitleWrapper>
        </Header>
        <div style={{ height: 200, marginTop: "1rem"}}>
          <Line ref={chartRef} data={data} options={options} />
        </div>
        {open && (<StackedModal open={open} handleClose={handleClose} data={data} options={options} fiscalYears={fiscalYears} year={year} setYear={setYear} isLoading={isLoading}/>)}
      </Wrapper>
    );
}

export const StackedModal = ({open, handleClose, data, options, fiscalYears, year, setYear, isLoading}) => {
  const chartRef = useRef(null);

  return (
    
    <Modal
            open={open}
            onClose={() => handleClose(false)}
        >
      <Box 
          className="modal-class"  
          style={{
            padding: "3.5rem",
            paddingTop: "3rem",
            position: "relative",
            width: "80%",
            height: "38rem",
            background: "white",
            }}
          >
        <Header>
          <TitleWrapper >
            <Title>
              End to End Supply chain integration
            </Title>
            {isLoading && <span>
              <img src={Dots} alt="" />
            </span>}
          </TitleWrapper>
          <TitleWrapper>
            <Select
              id="select-year"
              value={year}
              size='small'
              label="Year"
              style={{color: "#545E66", width: "8rem", marginRight: "1rem"}}
              onChange={(e) => setYear(e.target.value)}
            >
              {fiscalYears.map((year) => (
                <MenuItem value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </TitleWrapper>
        </Header>
        <div style={{ height: 380, marginTop: "1rem"}}>
          <Line ref={chartRef} data={data} options={options} />
        </div>
      </Box>
    </Modal>
  )
}

const Wrapper = styled.div`
    display: flex;
    grid-column: span 2;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    padding: 1rem 2rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #545E66;
    border-radius: 8px 8px 0 0;
    gap: 40px;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 0.6rem;
`;
