import { FormatDecimal, Formatter } from "../../Utils/NumberUtils";

export function Percentage({ value, percentValue }) {
  return (  
    <div>
      { value == 0 
        ? <div>-</div> 
        : <div title={FormatDecimal(value)}>
            <div>
              { Formatter(value, 0) }
            </div>
            <div style={{ fontSize: "0.75rem" }}>
              { FormatDecimal(percentValue, 0) }%</div>
          </div>
      }
    </div>
  );
}

export default Percentage;

  