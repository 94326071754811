import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import styled from "styled-components";

import {
  getFacilityByProductType,
  getFacilityByType,
  getImportedAmountYearly,
  getImportedProducts,
  getTopApplicants,
  getTopImporters,
} from "../../../Services/ApiManager";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import {
  APPLICATION_TYPE_SELECT_CONFIG,
  FACILITY_TYPE_SELECT_CONFIG,
  PAGINATION_DEFAULT_PARAM,
  PRODUCT_TYPE_SELECT_CONFIG,
} from "../../shared/Shared Utils/DataTableConstants";
import { APPLICATIONS_RELEASED_COLUMN, FACILITY_CERTIFICATION_COLUMN, PERMITTED_PRODUCTS_COLUMN, REGISTERED_PRODUCTS_COLUMN } from "./ERISTableColumn";
import { APPLICATIONS_RELEASED_API, FACILITY_CERTIFICATION_API, PERMITTED_PRODUCTS_API, REGISTERED_PRODUCTS_API } from "../../shared/Shared Utils/AppData";
import { parseGridColumns, parseGridRows, } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import moment from "moment";
import CustomBarChart from "../../shared/Charts/CustomBarChartV2";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import LineChart from "../../shared/Charts/LineChart";
import { useQuery } from "@tanstack/react-query";

export default function ERIS() {
  const [facByProdParams, setFacByProdParams] = useState({});
  const {data: { data: { Data:  facByProd }} = { data: { Data: [] }}, isFetching: facByProdLoading} = useQuery({
    queryKey: ['facByProd', facByProdParams],
    queryFn: () => getFacilityByProductType(facByProdParams),
    staleTime: 10800000,
  });
  const [facByTypeParams, setFacByTypeParams] = useState({});
  const {data: { data: { Data:  facByType }} = { data: { Data: [] }}, isFetching: facByTypeLoading} = useQuery({
    queryKey: ['facByType', facByTypeParams],
    queryFn: () => getFacilityByType(facByTypeParams),
    staleTime: 10800000,
  });
  const [importedYearlyParams, setImportedYearlyParams] = useState([]);
  const {data: { data: { Data:  importedYearly }} = { data: { Data: [] }}, isFetching: importedYearlyLoading} = useQuery({
    queryKey: ['importedYearly', importedYearlyParams],
    queryFn: () => getImportedAmountYearly(importedYearlyParams),
    staleTime: 10800000,
  });
  const [importedProdParams, setImportedProdParams] = useState(PAGINATION_DEFAULT_PARAM);
  const {data: { data: { Data:  importedProd }} = { data: { Data: [] }}, isFetching: importedProdLoading} = useQuery({
    queryKey: ['importedProd', importedProdParams],
    queryFn: () => getImportedProducts(importedProdParams),
    staleTime: 10800000,
  });
  const [topImportersParams, setTopImportersParams] = useState(PAGINATION_DEFAULT_PARAM);
  const {data: { data: { Data:  topImporters }} = { data: { Data: [] }}, isFetching: topImportersLoading} = useQuery({
    queryKey: ['topImporters', topImportersParams],
    queryFn: () => getTopImporters(topImportersParams),
    staleTime: 10800000,
  });
  const [topApplicantsParams, setTopApplicantsParams] = useState(PAGINATION_DEFAULT_PARAM);
  const {data: { data: { Data:  topApplicants }} = { data: { Data: [] }}, isFetching: topApplicantsLoading} = useQuery({
    queryKey: ['topApplicants', topApplicantsParams],
    queryFn: () => getTopApplicants(topApplicantsParams),
    staleTime: 10800000,
  });

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(1, 'years').format("MM/DD/YYYY");
      const end = moment().format("MM/DD/YYYY");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }

  const handleChange = (event, key, chartFilterParams) => {
  };


  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos ERIS Dashboard`} />
    </div>
    <div className="container-context mt-5">
      <div>
        <GridContainerAlternate>
          <StandardChartWrapperAlternate>
            <ChartWrapper
              title="Certified Facilities by Product Type" 
              wgInfoId="wgErisCertifiedFacilitiesByProductType"
              showYearFilter={false}
              defaultFilterParams={{}}
              isLoading={facByProdLoading}
            >
              <CustomPieChart
                data={facByProd}
                label={"ProductType"}
                fields={["Count", "ProductType"]}
                style={{
                  position: "relative",
                  width: "400px",
                  height: "25rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(facByProd, ["ProductType", "Count"])}
                columns={parseGridColumns(["ProductType", "Count"])}
                getRowId={(row) => row.ProductType}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={facByProd}
                label={"ProductType"}
                fields={["Count", "ProductType"]}
                style={{
                  height:'15rem',
                  width: '28rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapperAlternate>
          <StandardChartWrapperAlternate>
            <ChartWrapper
              title="Certified Facilities by Type" 
              wgInfoId="wgErisCertifiedFacilitiesByFacilityType"
              showYearFilter={false}
              defaultFilterParams={{}}
              isLoading={facByTypeLoading}
            >
              <CustomPieChart
                data={facByType}
                label={"Facility Type"}
                fields={["Count", "FacilityType"]}
                style={{
                  position: "relative",
                  width: "400px",
                  height: "25rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(facByType, ["FacilityType", "Count"])}
                columns={parseGridColumns(["FacilityType", "Count"])}
                getRowId={(row) => row.FacilityType}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={facByType}
                label={"Facility Type"}
                fields={["Count", "FacilityType"]}
                style={{
                  height:'15rem',
                  width: '28rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapperAlternate>
          <StandardChartWrapperAlternate>
            <ChartWrapper
              title="Imported Amount Yearly Trend" 
              wgInfoId="wgErisImportedAmountYearlyTrend"
              showYearFilter={false}
              defaultFilterParams={{}}
              isLoading={importedYearlyLoading}
            >
              <LineChart
                data={importedYearly}
                label={["Amount"]}
                fields={["Amount"]}
                style={{
                  position: "relative",
                  height: "25rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
                chart_options={{
                  defaultFilterParams: {},
                  option: "Year",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(importedYearly, [ "Year", "Amount"])}
                columns={parseGridColumns(["Year", "Amount"])}
                getRowId={(row) => row.Year}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <LineChart
                data={importedYearly}
                label={["Amount"]}
                fields={["Amount"]}
                style={{
                  height:'15rem',
                  width: '28rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
                chart_options={{
                  defaultFilterParams: {},
                  option: "Year",
                }}
              />
            </ChartWrapper>
          </StandardChartWrapperAlternate>
          <StandardChartWrapperAlternate>
            <CustomBarChart
              datas={[importedProd]}
              labels={[["Amount (in birr)",]]}
              fields={[
                [
                  "Amount",
                ],
              ]}
              styles={[
                {
                  padding: "1rem",
                  position: "relative",
                  width: "44vw",
                  height: "25.8rem",
                  background: "white",
                },
              ]}
              defaultFilterParams={PAGINATION_DEFAULT_PARAM}
              chart_options={{
                title: "Top 10 Imported Products",
                indexAxis: 'y',
                showExpandAlt: true,
                option: "ItemName",
                tooltips: [
                ],
                wgInfoId: "wgErisTopTenImportedProducts",
              }}
              changeLoading={importedProdLoading}
              chartTypes={["bar-chart"]}
            />
          </StandardChartWrapperAlternate>
          <StandardChartWrapperAlternate>
            <CustomBarChart
              datas={[topImporters]}
              labels={[["Amount (in birr)"]]}
              fields={[
                [
                  "Amount"
                ],
              ]}
              styles={[
                {
                  padding: "1rem",
                  position: "relative",
                  height: "25.8rem",
                  background: "white",
                },
              ]}
              defaultFilterParams={PAGINATION_DEFAULT_PARAM}
              chart_options={{
                title: "Top 10 Importers",
                indexAxis: 'y',
                showExpandAlt: true,
                option: "Importer",
                tooltips: ["Importer"],
                wgInfoId: "wgErisTopTenImporters",
              }}
              changeLoading={topImportersLoading}
            />
          </StandardChartWrapperAlternate>
          <StandardChartWrapperAlternate>
            <CustomBarChart
              datas={[topApplicants]}
              labels={[["No of Items"]]}
              fields={[
                [
                  "Count"
                ],
              ]}
              styles={[
                {
                  padding: "1rem",
                  position: "relative",
                  width: "44vw",
                  height: "25.8rem",
                  background: "white",
                },
              ]}
              defaultFilterParams={PAGINATION_DEFAULT_PARAM}
              chart_options={{
                title: "Top 10 Applicants",
                indexAxis: 'y',
                showExpandAlt: true,
                option: "Applicant",
                tooltips: [
                ],
                wgInfoId: "wgErisTopTenApplicantsByItemCount",
              }}
              changeLoading={topApplicantsLoading}
              chartTypes={["bar-chart"]}
            />
          </StandardChartWrapperAlternate>

          <TableWrapperAlternate>
            <CustomDataGrid
              component_config={{
                title: "Facility Certification",
                data_source_api: FACILITY_CERTIFICATION_API,
                data_property: "Data",
                columns: FACILITY_CERTIFICATION_COLUMN,
                isSOH: false,
                wgInfoId: 'wgErisCertifiedFacilities',
                use_default_filter_params: false,
                select_filter_config: [
                  FACILITY_TYPE_SELECT_CONFIG,
                  APPLICATION_TYPE_SELECT_CONFIG,
                  PRODUCT_TYPE_SELECT_CONFIG,
                ],
                defaultFilterParams:{},
              }}
            />
          </TableWrapperAlternate>
          <TableWrapperAlternate>
            <CustomDataGrid
              component_config={{
                title: "Import Permitted Products",
                data_source_api: PERMITTED_PRODUCTS_API,
                data_property: "Data",
                columns: PERMITTED_PRODUCTS_COLUMN,
                isSOH: false,
                wgInfoId: 'wgErisIpPipItems',
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {...PAGINATION_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    yearly_report: true,
                  },
              }}
            />
          </TableWrapperAlternate>
          <TableWrapperAlternate>
            <CustomDataGrid
              component_config={{
                title: "Applications Released on Port",
                data_source_api: APPLICATIONS_RELEASED_API,
                data_property: "Data",
                columns: APPLICATIONS_RELEASED_COLUMN,
                isSOH: false,
                wgInfoId: 'wgErisApplicationReleasedOnPort',
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    yearly_report: true,
                  },
              }}
            />
          </TableWrapperAlternate>
          <TableWrapperAlternate>
            <CustomDataGrid
              component_config={{
                title: "Registered Products",
                data_source_api: REGISTERED_PRODUCTS_API,
                data_property: "Data",
                columns: REGISTERED_PRODUCTS_COLUMN,
                isSOH: false,
                wgInfoId: 'wgErisRegisteredProducts',
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    yearly_report: true,
                  },
              }}
            />
          </TableWrapperAlternate>
        </GridContainerAlternate  >
      </div>
    </div>
  </Wrapper>)
}

const GridContainerAlternate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 2;
`;

const StandardChartWrapperAlternate = styled.div`
    display: flex;
    grid-column: span 1;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;