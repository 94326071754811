import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import NavBar from "../../components/shared/NavBar";
import ItemSideNav from "./ItemsSideNav";
import { Divider } from "@material-ui/core";
import { getHeaderInformationByItem } from "../../Services/ApiManager";
import {
  STOCK_ON_HAND_ITEM__PARAM,
  STOCK_STATUS_COLUMN,
  MANUFACTURER_COLUMN,
  MANUFACTURER_PARAM,
  SUPPLIER_COLUMN,
  PROGRAM_PARAM,
  COUNTRY_COLUMN,
  ITEM_DISTRIBUTION_COLUMN,
  ITEM_PRICE_ANALYSIS_COLUMN,
  ITEM_PRICE_HISTORY_COLUMN,
  CENTER_TO_HUB_DEFAULT_PARAM,
  MODE_CODE_DEFAULT_PARAM,
  MODE_CODE_DEFAULT_PARAM_PAGE_SIZE,
  PO_SHIMPENT_COLUMN,
  RECEIVED_COLUMN,
  RECEIVED_PARAM,
  DAYS_OF_STOCK_PARAMS,
  ENVIRONMENT_GROUP_CODE,
  YEAR_SELECT_CONFIG,
  ITEM_ACCOUNT_SELECT_CONFIG,
} from "../shared/Shared Utils/DataTableConstants";
import {
  getMonthsOfStock,
  getItemStockUtilization,
  getItemPipeline,
  getItemNearExpiryBreakdown,
  getOrderFillRateBySite,
  getSupplierFillRateBySite,
  getItemFundingSource,
  getItemProcurer,
  getItemDistributionByFacilityType,
  getItemDistributionByOwnershipType,
  daysOutOfStock,
  itemDistributionByRegion,
  getItemStockStatus,
  getNationalMos,
  getItemByManufacturer,
  getItemBySupplier,
  getItemCountry,
  getItemDistribution,
  getItemPriceAnalysis,
  getItemUnitForcast,
  getBincardManufacturer,
  getBincardUnit,
  getBincardAccount,
  getBincardActivity,
  getUnitListByItem,
  getPriceHistoryAggregate,
} from "../../Services/ItemRequest";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  stockStatusColumn,
  manufacturerColumn,
  supplierColumn,
  countryColumn,
  itemDistributionColumn,
  itemPriceAnalysisColumn,
} from "./ItemsTableColumns";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Home/home.css";
import "./items.css";
import ItemsBarChart from "./ItemsBarChart";
import CustomPieChart from "../shared/Charts/CustomPieChart";
import LineChart from "../shared/Charts/LineChart";
import CustomBarChartV2 from "../../components/shared/Charts/CustomBarChartV2";
import GroupedStackedBarChart from "../../components/shared/Charts/GroupedStackedBarChart";
import { getStockUtilization } from "../../Services/chartRequests";
import styled from "styled-components";
import CustomCard from "../shared/Shared Utils/CustomCards";
import CustomDataGrid from "../shared/Shared Utils/CustomDataTable";
import { IssueTrendModal } from "./IssueTrend";
import { useFanosStore } from "../shared/Shared Utils/store";
import {
  ITEMS_SOH,
  MANUFACTURER_API,
  SUPPLIER_API,
  COUNTRY_API,
  ITEMS_SOH_API,
  ISSUED_API,
  ITEM_COST_HISTORY_API,
  ITEM_PRICE_HISTORY_API,
  PO_SHIPMENT_API,
  RECEIVED_API,
} from "../shared/Shared Utils/AppData.js";
import amcPic from "../../assets/cardIcons/amc.svg";
import sohPic from "../../assets/cardIcons/soh.svg";
import stackPic from "../../assets/cardIcons/stack.svg";
import gitPic from "../../assets/cardIcons/git.svg";
import expPic from "../../assets/cardIcons/expired.svg";
import qexpPic from "../../assets/cardIcons/qExpired.svg";
import dmgPic from "../../assets/cardIcons/damaged.svg";
import gapPic from "../../assets/cardIcons/gap.svg";
import ovrgPic from "../../assets/cardIcons/overage.svg";
import ChartWrapper from "../shared/Charts/ChartWrapper";
import { parseGridColumns, parseGridRows } from "../../Utils/ChartConverter";
import Maps from "../Home/Program/Maps";
import { FormatDecimal, getPercentageValues, getValueByProperty } from "../../Utils/NumberUtils";
import CompositeLineChart from "../shared/Charts/CompositeLineChart";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import { FormControlLabel, Switch } from "@mui/material";
export default function Items() {
  const location = useLocation();
  const [item, setItem] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [modeCode, setModeCode] = useState("HPR");
  const [itemUnitPrice, setItemUnitPrice] = useState([]);
  const [itemUnitForcast, setItemUnitForcast] = useState([]);  
  const [checked, setChecked] = useState(true);

  const year = ["2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008"];
  const Environemnt = ["HUB", "CENTER"];
  const ProductSN = location.state.ProductSN;
  const programCode = location.state.ProgramCode;
  const filterParam = {};

  const [itemPriceParam, setItemPriceParam] = useState({ModeCode: "HPR", ItemSN: ProductSN, From: moment().subtract(12, 'years').format("MM/DD/YYYY"), To: moment().format("MM/DD/YYYY"), });
  const [unitList, setUnitList] = useState([]);
  const [unitListFilter, setUnitListFilter] = useState({});
  const [itemPriceFilterConfigs, setItemPriceFilterConfigs] = useState([]);

  const [daysOutOfStkParams, setDaysOutOfStkParams] = useState({...DAYS_OF_STOCK_PARAMS, ProductSN: ProductSN, From: moment().subtract(1, 'months').format("MM/DD/YYYY"), To: moment().format("MM/DD/YYYY"), });
  const {data: { data: { Data:  [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoading} = useQuery({
    queryKey: ['headerInfo', modeCode, ProductSN],
    queryFn: () => getHeaderInformationByItem(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemStockStatus }} = { data: { Data: [] }}, isFetching: itemStockStatusLoading} = useQuery({
    queryKey: ['itemStockStatus', modeCode, ProductSN, startDate],
    queryFn: () => getItemStockStatus(modeCode, ProductSN, startDate, 'HUB'),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemStockUtilization }} = { data: { Data: [] }}, isFetching: itemStockUtilizationLoading} = useQuery({
    queryKey: ['itemStockUtilization', modeCode, ProductSN],
    queryFn: () => getItemStockUtilization(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemExpiryBreakdown }} = { data: { Data: [] }}, isFetching: itemExpiryBreakdownLoading} = useQuery({
    queryKey: ['itemExpiryBreakdown', modeCode, ProductSN],
    queryFn: () => getItemNearExpiryBreakdown(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  itemPipeline }} = { data: { Data: [] }}, isFetching: itemPipelineLoading} = useQuery({
    queryKey: ['itemPipeline', modeCode, ProductSN],
    queryFn: () => getItemPipeline(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  monthsOfStock }} = { data: { Data: [] }}, isFetching: monthsOfStockLoading} = useQuery({
    queryKey: ['monthsOfStock', modeCode, ProductSN],
    queryFn: () => getMonthsOfStock(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const [fundingSourceParams, setFundingSourceParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  fundingSource }} = { data: { Data: [] }}, isFetching: fundingSourceLoading} = useQuery({
    queryKey: ['fundingSource', modeCode, ProductSN],
    queryFn: () => getItemFundingSource(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const [itemProcureParams, setItemProcureParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  itemProcure }} = { data: { Data: [] }}, isFetching: itemProcureLoading} = useQuery({
    queryKey: ['itemProcure', modeCode, ProductSN, itemProcureParams],
    queryFn: () => getItemProcurer(modeCode, ProductSN, itemProcureParams),
    staleTime: 10800000,
  });
  const [distByTypeParams, setDistByTypeParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByType }} = { data: { Data: [] }}, isFetching: distByTypeLoading} = useQuery({
    queryKey: ['distByType', modeCode, ProductSN],
    queryFn: () => getItemDistributionByFacilityType(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const [distByOwnershipParams, setDistByOwnershipParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  distByOwnership }} = { data: { Data: [] }}, isFetching: distByOwnershipLoading} = useQuery({
    queryKey: ['distByOwnership', modeCode, ProductSN],
    queryFn: () => getItemDistributionByOwnershipType(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  daysOutOfStk }} = { data: { Data: [] }}, isFetching: daysOutOfStkLoading} = useQuery({
    queryKey: ['daysOutOfStk', daysOutOfStkParams],
    queryFn: () => daysOutOfStock(daysOutOfStkParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  distByRegion }} = { data: { Data: [] }}, isFetching: distByRegionLoading} = useQuery({
    queryKey: ['distByRegion', modeCode, ProductSN],
    queryFn: () => itemDistributionByRegion(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const [fillRateParams, setFillRateParams] = useState({ModeCode: modeCode, ProductSN: ProductSN, EnvironmentGroupCode: "HUB", Year: "1"});
  const {data: { data: { Data:  orderFillRate }} = { data: { Data: [] }}, isFetching: orderFillRateLoading} = useQuery({
    queryKey: ['orderFillRate', fillRateParams],
    queryFn: () => getOrderFillRateBySite(fillRateParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  supplierFillRate }} = { data: { Data: [] }}, isFetching: supplierFillRateLoading} = useQuery({
    queryKey: ['supplierFillRate', fillRateParams],
    queryFn: () => getSupplierFillRateBySite(fillRateParams),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  nationalMos }} = { data: { Data: [] }}, isFetching: nationalMosLoading} = useQuery({
    queryKey: ['nationalMos', modeCode, ProductSN],
    queryFn: () => getNationalMos(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  manufacturerData }} = { data: { Data: [] }}, isFetching: manufacturerDataLoading} = useQuery({
    queryKey: ['manufacturerData', modeCode, ProductSN],
    queryFn: () => getBincardManufacturer(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  unitData }} = { data: { Data: [] }}, isFetching: unitDataLoading} = useQuery({
    queryKey: ['unitData', modeCode, ProductSN],
    queryFn: () => getBincardUnit(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  accountData }} = { data: { Data: [] }}, isFetching: accountDataLoading} = useQuery({
    queryKey: ['accountData', modeCode, ProductSN],
    queryFn: () => getBincardAccount(modeCode, ProductSN),
    staleTime: 10800000,
  });
  const {data: { data: { Data:  activityData }} = { data: { Data: [] }}, isFetching: activityDataLoading} = useQuery({
    queryKey: ['activityData', modeCode, ProductSN],
    queryFn: () => getBincardActivity(modeCode, ProductSN),
    staleTime: 10800000,
  });
  // const {data: { data: { Data:  itemDistribution }} = { data: { Data: [] }}, isFetching: itemDistributionLoading} = useQuery({
  //   queryKey: ['itemDistribution', modeCode, ProductSN],
  //   queryFn: () => getItemDistribution(modeCode, ProductSN, 2016),
  //   staleTime: 10800000,
  // });

  const history = useNavigate();
  useEffect(() => {
    setItem(location.state.ProductName);
  }, []);

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateFilter) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["TransactionDate"] = currentDate;
    }
    filterParams["ProductSN"] = ProductSN;
    return filterParams;
  }

  function loadItemFilter(filterParams) {
    filterParams["ItemSN"] = ProductSN;
    return filterParams;
  }
  function updateDateFilter(dateOption) {
    const filterObj = {};
    let newDate = new Date();
    let day = newDate.getDate();
    let month = newDate.getMonth() - 1;
    let year = newDate.getFullYear();
    let previousMonth = `${month}/${day}/${year}`;
    let day1 = newDate.getDate();
    let month1 = newDate.getMonth() + 1;
    let year1 = newDate.getFullYear();
    let currentDate = `${month1}/${day1}/${year1}`;
    if (dateOption === "dateRange") {
      filterObj["From"] = previousMonth;
      filterObj["To"] = currentDate;
      filterObj["IssueType"] = "CTH";
    } else {
      filterObj["TransactionDate"] = currentDate;
    }
    filterObj["ProgramCode"] = programCode;
    filterObj["ModeCode"] = "hpr";

    return filterObj;
  }
  
 useEffect(() => {
    getUnitListByItem({ModeCode: "HPR", ItemSN: ProductSN}).then((res) => {
      setUnitList(res.data.Data.map((u) => ({...u, label: u.Unit, value: u.SN,})))
    })
  }, [])

  useEffect(() => {
    if(unitList.length>0){
      getPriceHistoryAggregate({ModeCode: itemPriceParam.ModeCode, ItemSN: itemPriceParam.ItemSN, From: itemPriceParam.From, To: itemPriceParam.To, }).then((data) => {
        setItemUnitPrice(data.data.Data.map((d) => ({...d, PriceForecast: d.ReceiveUnitCost, PurchaseOrderDate: moment(d.PurchaseOrderDate).format('MMM DD, YYYY')})))
        setUnitListFilter({
          hard_code_options: [
            ...unitList.filter(function(unit) {
              return (data.data.Data.some(function(record){
                return unit.Unit === record.Unit;
              }))
            })
          ],
          place_holder: unitList.filter(function(unit) {
            return (data.data.Data.some(function(record){
              return unit.Unit === record.Unit;
            }))
          })[0].value, 
          param_name: "UnitSN",
        })
        setItemPriceFilterConfigs([
          {
            hard_code_options: [
              ...unitList.filter(function(unit) {
                return (data.data.Data.some(function(record){
                  return unit.Unit === record.Unit;
                }))
              })
            ],
            place_holder: unitList.filter(function(unit) {
              return (data.data.Data.some(function(record){
                return unit.Unit === record.Unit;
              }))
            })[0].value, 
            param_name: "UnitSN",
          }
        ])
        setItemPriceParam({
          ...itemPriceParam, 
          UnitSN: unitList.filter(function(unit) {
            return (data.data.Data.some(function(record){
              return unit.Unit === record.Unit;
            }))
          })[0].SN
        })
      });
    }
  }, [ProductSN, unitList, itemPriceParam.From, itemPriceParam.To ])


  useEffect(() => {
    if(itemPriceParam.UnitSN){
      getItemUnitForcast({ModeCode: itemPriceParam.ModeCode, ItemSN: itemPriceParam.ItemSN, UnitSN: itemPriceParam.UnitSN, }).then((data2) => {
        setItemUnitForcast(data2.data.Data.filter((d) => new Date(d.Date) > new Date()).map((fd) => ({Unit: fd.Unit, PriceForecast: fd.UnitCost, PurchaseOrderDate: moment(fd.Date).format('MMM DD, YYYY')})));
      });
    }
  }, [ProductSN, itemPriceParam.UnitSN])
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: "#15616d" }} />
        <GridToolbarFilterButton style={{ color: "#15616d" }} />
        {/* <GridToolbarDensitySelector style={{ color: "#15616d" }} /> */}
        <GridToolbarExport style={{ color: "#15616d" }} />
      </GridToolbarContainer>
    );
  }
  
  const issueTrendModalOpen = useFanosStore((state) => state.issueTrendModalOpen);
  const setIssueTrendModalOpen = useFanosStore((state) => state.setIssueTrendModalOpen);
  const issueTrendModalData = useFanosStore((state) => state.issueTrendModalData);
  const setIssueTrendModalData = useFanosStore((state) => state.setIssueTrendModalData);
  
  return (
    <Wrapper>
      {/* <NavBar /> */}
      <div>
        {" "}
        <NavBar title={`Fanos Item Dashboard`} />
      </div>

      <div className="container-context">
        <ItemSideNav mosData={nationalMos} manufacturerData={manufacturerData} unitData={unitData} accountData={accountData} activityData={activityData} modeCode={'hpr'} productSN={ProductSN} />
        <div>
          <div className="item-name">
            <h4>{item}</h4>
            <FormControlLabel control={
          <Switch
            defaultChecked={true} 
            onChange={(e)=> {
              setChecked(e.target.checked);
              e.target.checked? setModeCode('HPR'): setModeCode('RDF');
            }} />} 
          label={checked?"HPR": "RDF"} />
          </div>

          <div className="card-container">
            <CustomCard
              widget_config={{
                title: "AMC",
                value: headerInfo
                  ? headerInfo.AMC.toLocaleString()
                  : 0,
                logo_url: amcPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "SOH",
                value: headerInfo ? headerInfo.SOH.toLocaleString() : 0,
                logo_url: sohPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Ordered",
                value: headerInfo
                  ? headerInfo.UnderOrderQuantity.toLocaleString()
                  : 0,
                logo_url: stackPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "GIT",
                value: headerInfo
                  ? headerInfo.GITQuantity.toLocaleString()
                  : 0,
                logo_url: gitPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Wastage",
                value: headerInfo? parseFloat(headerInfo.QuantityExpired || 0) + parseFloat(headerInfo.QuantityDamaged || 0): 0,
                logo_url: expPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "nExpiry",
                value: headerInfo
                  ? headerInfo.L3month.toLocaleString()
                  : 0,
                logo_url: qexpPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Gap",
                value: headerInfo ? headerInfo.Gap.toLocaleString() : 0,
                logo_url: gapPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Overgage",
                value: headerInfo
                  ? headerInfo.Overage.toLocaleString()
                  : 0,
                logo_url: ovrgPic,
              }}
            />
          </div>
          <GridContainer>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Stock Status",
                  data_source_api: ITEMS_SOH_API,
                  data_property: "Data",
                  columns: STOCK_STATUS_COLUMN,
                  use_default_filter_params: false,
                  isItemSOH: true,
                  pageSize: 25,
                  defaultFilterParams: loadFilterToDefaultParams(
                    STOCK_ON_HAND_ITEM__PARAM,
                    { hasDateFilter: true }
                  ),
                  date_picker: {
                    date_range: false,
                    start_date_param_name: "TransactionDate",
                    start_date_param: "TransactionDate",
                  },
                }}
              />
            </TableWrapper>

              {/* <div>
                <div>
                  <div>
                    <h6 className="absolutePosition">Months Of Stock</h6>
                    <select
                      className="envSelect"
                      onChange={(event) =>
                        handleEnvChange(event, "monthsOfStock")
                      }
                    >
                      {Environemnt.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <ItemsBarChart
                  data={monthsOfStock}
                  label={["Below EOP", "Below Min", "Below Max", "Above Max"]}
                  fields={["BelowEOP", "BelowMIN", "BelowMax", "AboveMax"]}
                  style={{
                    padding: "1rem",
                    position: "relative",
                    width: "86vw",
                    height: "18rem",
                    background: "white",
                  }}
                />
              </div> */}
            <LongChartWrapper>
              <CustomBarChartV2
                datas={[monthsOfStock]}
                labels={[
                  ["MOS"],
                ]}
                fields={[["MOS", "BelowMIN", "BelowMax", "AboveMax"]]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "22vw",
                    background: "white",
                  },
                ]}
                chart_options={{
                  title: "Months Of Stock",
                  showExpandAlt: true,
                  tooltips: ["EnvironmentCode",],
                  customTooltipsTitle: "Environment",
                  option: "EnvironmentCode",
                  parseForGrid: true,
                  annotationLines: [4, 2],
                }}
                chartTypes={["bar-chart"]}
              />
            </LongChartWrapper>
            <StandardChartWrapper>
              <ChartWrapper title="Funding Source" wgInfoId="wgDonorAgents" isLoading={fundingSourceLoading} parameter={fundingSourceParams} setParameter={setFundingSourceParams} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={fundingSource}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding source"
                  style={{
                    position: "relative",
                    width: "22vw",
                    height: "20.7vw",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(fundingSource, ["Donor", "AmountInBirr"])}
                  columns={parseGridColumns(["Donor", "AmountInBirr"])}
                  getRowId={(row) => row.Donor}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={fundingSource}
                  label={"Funding source"}
                  fields={["AmountInBirr", "Donor"]}
                  title="Funding source"
                  style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                />
              </ChartWrapper>
            </StandardChartWrapper>
            <LongChartWrapper>
              <CustomBarChartV2
                datas={[getPercentageValues(["IssuedAmtBirr", "ReservedAmtBirr", "SOHAmtBirr", "ExpiredAmtBirr", "DamagedAmtBirr", "SuspendedAmtBirr",], itemStockUtilization)]}
                labels={[
                  [
                    "Issued",
                    "Reserved",
                    "Soh",
                    "Expired",
                    "Damaged",
                    "Suspended",
                  ],
                ]}
                fields={[
                  [
                    "IssuedAmtBirrPercentage",
                    "ReservedAmtBirrPercentage",
                    "SOHAmtBirrPercentage",
                    "ExpiredAmtBirrPercentage",
                    "DamagedAmtBirrPercentage",
                    "SuspendedAmtBirrPercentage",
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "22vw",
                    background: "white",
                  },
                ]}
                chart_options={{
                  title: "Stock Utilization",
                  showExpandAlt: true,
                  tooltips: ["EnvironmentCode"],
                  option: "EnvironmentCode",
                  parseForGrid: true,
                  max: 100,
                }}
                chartTypes={["bar-chart"]}
              />
            </LongChartWrapper>
              
            <StandardChartWrapper>
              <ChartWrapper title="Procurement Agents" wgInfoId="wgProcurerAgentsDetail" isLoading={itemProcureLoading} parameter={itemProcureParams} setParameter={setItemProcureParams} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={itemProcure}
                  label={"Procurement Agent"}
                  fields={["AmountInBirr", "ProcurmentAgent"]}
                  title="Procurement Agent"
                  style={{
                    position: "relative",
                    width: "22vw",
                    height: "20.7vw",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(itemProcure, ["ProcurmentAgent", "AmountInBirr"])}
                  columns={parseGridColumns(["ProcurmentAgent", "AmountInBirr"])}
                  getRowId={(row) => row.ProcurmentAgent}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={itemProcure}
                  label={"Procurement Agent"}
                  fields={["AmountInBirr", "ProcurmentAgent"]}
                  title="Procurement Agent"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapper>
            <LongChartWrapper>
              <CustomBarChartV2
                datas={[itemPipeline]}
                labels={[["PO", "GIT", "Below Max", "Above Max", "pDOS"]]}
                fields={[["ProjectedDaysOutOfStock"]]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "22vw",
                    background: "white",
                  },
                ]}
                chart_options={{
                  title: "Pipeline",
                  showExpandAlt: true,
                  tooltips: ["EnvironmentCode"],
                  option: "EnvironmentCode",
                  parseForGrid: true,
                }}
                chartTypes={["bar-chart"]}
              />
            </LongChartWrapper>
            <StandardChartWrapper>
              <ChartWrapper title="Distribution by Facility Type" wgInfoId="wgDistrByFacilitytypeMt" isLoading={distByTypeLoading} parameter={distByTypeParams} setParameter={setDistByTypeParams} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by Facility Type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                    position: "relative",
                    width: "22vw",
                    height: "20.7vw",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByType, ["InstitutionType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["InstitutionType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.InstitutionType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByType}
                  label={"Distribution by Facility Type"}
                  fields={["AmountIssuedInBirr", "InstitutionType"]}
                  title="Distribution by Facility Type"
                  style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                />
              </ChartWrapper>
            </StandardChartWrapper>

            <LongChartWrapper>
              <CustomBarChartV2
                datas={[itemExpiryBreakdown]}
                labels={[
                  [
                    "Issued",
                    "Reserved",
                    "SOH",
                    "Expired",
                    "Damaged",
                    "Suspended",
                  ],
                ]}
                fields={[
                  [
                    "IssuedAmtBirr",
                    "ReservedAmtBirr",
                    "SOHAmtBirr",
                    "ExpiredAmtBirr",
                    "DamagedAmtBirr",
                    "SuspendedAmtBirr",
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "22vw",
                    background: "white",
                  },
                ]}
                chart_options={{
                  title: "Expiry Breakdown in Qty",
                  showExpandAlt: true,
                  tooltips: ["EnvironmentCode"],
                  option: "EnvironmentCode",
                  parseForGrid: true,
                }}
                chartTypes={["bar-chart"]}
              />
            </LongChartWrapper>
            <StandardChartWrapper>
              <ChartWrapper title="Distribution by Ownership Type" wgInfoId="wgDistrByOwnershiptype" isLoading={distByOwnershipLoading} parameter={distByOwnershipParams} setParameter={setDistByOwnershipParams} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={distByOwnership}
                  label={"Distribution by ownership Type"}
                  fields={["AmountIssuedInBirr", "OwnershipType"]}
                  title="Distribution by ownership Type"
                  style={{
                    position: "relative",
                    width: "22vw",
                    height: "20.7vw",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByOwnership, ["OwnershipType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["OwnershipType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.OwnershipType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByOwnership}
                  label={"Distribution by ownership Type"}
                  fields={["AmountIssuedInBirr", "OwnershipType"]}
                  title="Distribution by ownership Type"
                  style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                />
              </ChartWrapper>
            </StandardChartWrapper>
            {/* <LongChartWrapper>
              <ChartWrapper title="Fill Rate" wgInfoId="wg-fill-rate-detail" isLoading={orderFillRateLoading || supplierFillRateLoading}>
                <GroupedStackedBarChart
                  data={orderFillRate}
                  data1={supplierFillRate}
                  label={["HOFR"]}
                  fields={["HOFR"]}
                  label1={["HSFR"]}
                  fields1={["HSFR"]}
                  style={
                    {
                      padding: "1rem",
                      position: "relative",
                      height: "23vw",
                      background: "white",
                    }
                  }
                  chart_options={{
                    showExpandAlt: true,
                    selectFilter: false,
                    title: "Fill Rate",
                    tooltip: {
                      callbacks: {
                        title: (item) =>
                          orderFillRate.filter(
                            (d) => d.EnvironmentCode === item[0].label
                          )[0].EnvironmentCode,
                      },
                      mode: "index",
                    },
                    option: "EnvironmentCode",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={orderFillRate.map((order) => ({EnvironmentCode: order.EnvironmentCode, HOFR: FormatDecimal(order.HOFR, 0), HSFR: FormatDecimal(getValueByProperty(supplierFillRate, 'EnvironmentCode', order.EnvironmentCode, 'HSFR'), 0)}))}
                  columns={parseGridColumns(["EnvironmentCode", "HSFR", "HOFR"])}
                  getRowId={(row) => row.EnvironmentCode}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <GroupedStackedBarChart
                  data={orderFillRate}
                  data1={supplierFillRate}
                  label={["HOFR"]}
                  fields={["HOFR"]}
                  label1={["HSFR"]}
                  fields1={["HSFR"]}
                  style={
                    {
                      padding: "1rem",
                      position: "relative",
                      width: "74vw",
                      height: "33vw",
                      background: "white",
                    }
                  }
                  chart_options={{
                    showExpandAlt: true,
                    selectFilter: false,
                    title: "Fill Rate",
                    tooltip: {
                      callbacks: {
                        title: (item) =>
                          orderFillRate.filter(
                            (d) => d.EnvironmentCode === item[0].label
                          )[0].EnvironmentCode,
                      },
                      mode: "index",
                    },
                    option: "EnvironmentCode",
                  }}
                />
              </ChartWrapper>
            </LongChartWrapper> */}
            <LongChartWrapper>
              <ChartWrapper 
                selectFilterConfig={[ENVIRONMENT_GROUP_CODE]} 
                parameter={daysOutOfStkParams} 
                setParameter={setDaysOutOfStkParams} 
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                }}
                isLoading={daysOutOfStkLoading}
                title="Days Out of Stock in %" 
                wgInfoId="wg-dos-per-hub-detail"
              >
                <LineChart
                  data={daysOutOfStk}
                  label={["Days out of stock %"]}
                  fields={["DosPercent"]}
                  filterType="Environment"
                  style={{
                    padding: "1rem",
                    position: "relative",
                    height: "18rem",
                    background: "white",
                  }}
                  chart_options={{
                    option: "EnvironmentCode",
                    date_picker: {
                      date_range: true,
                      start_date_param_name: "TransactionDate",
                      start_date_param: "TransactionDate",
                    },
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(daysOutOfStk, ["Environment", "Days", "DOS", "DosPercent"])}
                  columns={parseGridColumns(["Environment", "Days", "DOS", "DosPercent"], ["Site", "Days", "DOS", "Dos Percent"])}
                  getRowId={(row) => row.Environment}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <LineChart
                  data={daysOutOfStk}
                  label={["Days out of stock %"]}
                  fields={["DosPercent"]}
                  filterType="Environment"
                  style={{
                    padding: "1rem",
                    position: "relative",
                    width: "75vw",
                    height: "28rem",
                    background: "white",
                  }}
                  chart_options={{
                    option: "EnvironmentCode",
                    date_picker: {
                      date_range: true,
                      start_date_param_name: "TransactionDate",
                      start_date_param: "TransactionDate",
                    },
                  }}
                />
              </ChartWrapper>
            </LongChartWrapper>
            
            <StandardChartWrapper>
              <ChartWrapper title="Distribution by Region" wgInfoId="wgDistrByRegion" isLoading={distByRegionLoading}>
                <CustomPieChart
                  data={distByRegion}
                  label={"Distribution by Region"}
                  fields={["AmountIssued", "Region"]}
                  title="Distribution by Region"
                  style={{
                    position: "relative",
                    width: "22vw",
                    height: "20.7vw",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByRegion, ["Region", "AmountIssued"])}
                  columns={parseGridColumns(["Region", "AmountIssued"])}
                  getRowId={(row) => row.Region}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByRegion}
                  label={"Distribution by Region"}
                  fields={["AmountIssued", "Region"]}
                  title="Distribution by Region"
                  style={{
                      height:'15rem',
                      width: '32rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                />
              </ChartWrapper>
            </StandardChartWrapper>

            {/* <TableWrapper>
              <ChartWrapper 
                selectFilterConfig={itemPriceFilterConfigs} 
                parameter={itemPriceParam} 
                setParameter={setItemPriceParam} 
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  twelve_year_report: true,
                }}
                title="Price History Forecast" 
                wgInfoId="wg-item-price-history-chart"
              >
                <CompositeLineChart
                  data={itemUnitPrice}
                  data2={itemUnitForcast}
                  label={["Price"]}
                  label2={["Price Forecast"]}
                  fields={["PriceForecast"]}
                  fields2={["PriceForecast"]}
                  style={{
                    padding: "1rem",
                    position: "relative",
                    height: "18rem",
                    background: "white",
                  }}
                  chart_options={{
                    title: "Price History Forecast",
                    showExpandAlt: true,
                    tooltips: ["PurchaseOrderDate"],
                    option: "PurchaseOrderDate",
                    date_picker: {
                      date_range: false,
                      start_date_param_name: "TransactionDate",
                      start_date_param: "TransactionDate",
                    },
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows([...itemUnitPrice, ...itemUnitForcast], ["PurchaseOrderDate", "PriceForecast"])}
                  columns={parseGridColumns(["PurchaseOrderDate", "PriceForecast"])}
                  getRowId={(row) => row.PurchaseOrderDate}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CompositeLineChart
                  data={itemUnitPrice}
                  data2={itemUnitForcast}
                  label={["Price"]}
                  label2={["Price Forecast"]}
                  fields={["PriceForecast"]}
                  fields2={["PriceForecast"]}
                  style={{
                    padding: "1rem",
                    position: "relative",
                    width: "70vw",
                    height: "18rem",
                    background: "white",
                  }}
                  chart_options={{
                    title: "Price History Forecast",
                    showExpandAlt: true,
                    tooltips: ["PurchaseOrderDate"],
                    option: "PurchaseOrderDate",
                    date_picker: {
                      date_range: false,
                      start_date_param_name: "TransactionDate",
                      start_date_param: "TransactionDate",
                    },
                  }}
                />
              </ChartWrapper>
            </TableWrapper> */}
              {/* needs attention
            Purchase Orders and Shipments */}
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Purchase Order/Incoming Shipments",
                  data_source_api: PO_SHIPMENT_API,
                  data_property: "Data",
                  columns: PO_SHIMPENT_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...MODE_CODE_DEFAULT_PARAM, ProductSN: ProductSN},
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Manufacturers",
                  data_source_api: MANUFACTURER_API,
                  data_property: "Data",
                  columns: MANUFACTURER_COLUMN,
                  notes: "*showing 24 months of data",
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    MANUFACTURER_PARAM,
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Supplier",
                  data_source_api: SUPPLIER_API,
                  data_property: "Data",
                  columns: SUPPLIER_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    PROGRAM_PARAM,
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Country",
                  data_source_api: COUNTRY_API,
                  data_property: "Data",
                  columns: COUNTRY_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    PROGRAM_PARAM,
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapper>
              {/* needs attention
            Received */}
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Received",
                  data_source_api: RECEIVED_API,
                  data_property: "Data",
                  columns: RECEIVED_COLUMN,
                  notes: "*showing 24 months of data",
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    RECEIVED_PARAM,
                    { hasDateFilter: false }
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Issued Data: Hub to Facility",
                  data_source_api: ISSUED_API,
                  data_property: "Data",
                  columns: ITEM_DISTRIBUTION_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {
                      ...MODE_CODE_DEFAULT_PARAM_PAGE_SIZE,
                      ProductSN
                    },
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "from",
                    start_date_param: "to",
                    date_field: "IssueDate",
                  },
                }}
              />
            </TableWrapper>

            {/* <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Item Price History",
                  data_source_api: ITEM_PRICE_HISTORY_API,
                  data_property: "Data",
                  columns: ITEM_PRICE_HISTORY_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadItemFilter({ ModeCode: "HPR" }),
                  dateFilterParam: updateDateFilter("dateRange"),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    start_date_param: "From",
                    end_date_param_name: "To",
                    end_data_param: "To",
                  },
                }}
              />
            </TableWrapper> */}
            {/* <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Item Cost Change History",
                  data_source_api: ITEM_COST_HISTORY_API,
                  data_property: "Data",
                  columns: ITEM_PRICE_ANALYSIS_COLUMN,
                  isSOH: false,
                  use_default_filter_params: false,
                  defaultFilterParams: loadItemFilter({}),
                  dateFilterParam: updateDateFilter("dateRange"),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    start_date_param: "From",
                    end_date_param_name: "To",
                    end_data_param: "To",
                  },
                }}
              />
            </TableWrapper> */}
            <TableWrapper>
              <Maps param={{ProductSN: ProductSN}} modeCodeParam={{ModeCode: "HPR"}} page={"Item"} />
            </TableWrapper>
          </GridContainer>
        </div>
      </div>
      
      {issueTrendModalOpen && <IssueTrendModal open={issueTrendModalOpen} handleClose={setIssueTrendModalOpen} data={{ productSN: ProductSN, row: issueTrendModalData?.row }}/>}
    </Wrapper>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const Wrapper = styled.div`
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    overflow: auto;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .item-name {
    align-content: left;
    zindex: 10;
    position: relative;
    background: #f7f9fa;
    border: 2px solid white;
    border-radius: 6px;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
    margin-bottom: 1rem;
  }
  .flex-pie {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;
