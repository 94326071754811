import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { getFanosData } from "../../../Services/ApiManager";

function CustomChartSelect({ handle_change, component_config }) {
  let [options, setOptions] = useState([]);
  const [optionsFiltered, setOptionsFiltered] = useState([]);
  const [parentFilter, setParentFilter] = useState({});

  useEffect(() => {
    async function fetchOptions() {
      await getFanosData(
        component_config.api_url,
        {},
        component_config.data_source
      ).then((data) => {
        let optionData = data.data[component_config.options_data_property];
        // if (component_config.hidden_options) {
        //   optionData = optionData.filter((option) => {
        //     return !component_config.hidden_options.includes(
        //       option[component_config.label_property]
        //     );
        //   });
        // } else if (component_config.picked_list) {
        //   optionData = optionData.filter((option) => {
        //     return component_config.picked_list.includes(
        //       option[component_config.picked_list_column]
        //     );
        //   });
        // }
        if(component_config.reverse){
          setOptionsFiltered(optionData.filter(e => e[component_config.value_property] === component_config.place_holder).length > 0 ?
            [...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})].reverse():
            [{label: component_config.place_holder, value: ''}, ...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})].reverse());
        } else {
          setOptionsFiltered(optionData.filter(e => e[component_config.value_property] === component_config.place_holder).length > 0 ?
            [...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})]:
            [{label: component_config.place_holder, value: ''}, ...optionData.map((d) => {return {...d, label: d[component_config.label_property], value: d[component_config.value_property],}})]);
        }
        
      });
    }
    component_config.api_url? fetchOptions(): setOptionsFiltered(component_config.reverse? component_config.hard_code_options.reverse(): component_config.hard_code_options);
  }, []);

  const handleChange = (selectedOption) => {
    if (selectedOption != '')
      handle_change({
        paramName: component_config.param_name,
        paramValue: selectedOption,
      });
    else
      handle_change({
        paramName: component_config.param_name,
        paramValue: null,
      });
  };

  const onFocus = () => {
    if (parentFilter) {
      if (parentFilter.value) {
        const filtered = options.filter((option) => {
          return (
            option[parentFilter.value_property] === parentFilter.value ||
            option[parentFilter.value_property] === null ||
            option[parentFilter.value_property] === ""
          );
        });
        setOptionsFiltered(filtered);
      } else {
        setOptionsFiltered(options);
      }
    }
  };

  return (
    <>
      <select
        className="select"
        style={{marginLeft: 0, marginRight: 10, marginBottom: 0, width:  component_config.width || "10rem"}}
        onChange={(e) => handleChange(e.target.value)}
      >
        {optionsFiltered.map((el) => (
        <option selected={component_config.selected_value? component_config.selected_value.toString() === el.value.toString(): optionsFiltered.filter(e => component_config.value_property? e[component_config.value_property] === component_config.place_holder:  e.value === component_config.place_holder).length > 0 && component_config.place_holder === el.value} key={el.label} value={el.value}>
            {el.label}
        </option>
        ))}
      </select>
    </>
  );
}

export default CustomChartSelect;
