import React from "react";
import { Dropdown, Table } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { MapProvider, useMap } from "../../../Utils/MapContext";
import { Circle, GoogleMap, HeatmapLayer, OverlayView, Polygon, Polyline, InfoWindow } from "@react-google-maps/api";
import CustomDropdownToggle from "../../../Utils/CustomDropdownToggle";
import { useEffect } from "react";
import { useState } from "react";
import { MAP_CONSTANTS } from "../../shared/Shared Utils/InputConstants";
import { 
  getGlobalSupplyBreakDown, 
  getGlobalSupplyRoutes,
  getLocalSupplyRoutes, 
  getMapFacilityByProgram, 
  getNumberOfFacilitiesServed, 
  getThematicByRegion, 
  getThematicByWoreda, 
  getFacilityMapGridData } from "../../../Services/ApiManager";
import Dots from "../../../assets/GIFs/dotss.gif";
import styled from "styled-components";
import { Box, IconButton, Tooltip } from "@mui/material";
import Flag from "react-world-flags";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FormatDecimal, Formatter, IsEmpty } from "../../../Utils/NumberUtils";
import { DefaultDateFormatter } from "../../shared/Shared Utils/FormatHelpers";
import CustomLoadingIndicator from "../../shared/Shared Utils/CustomLoadingIndicator";

export default function Maps({param, modeCodeParam, page, map_type, map_width, map_height, hide_header, header_title}) {
    const [mapType, setMapType] = useState(!IsEmpty(map_type) ? MAP_CONSTANTS[map_type] : MAP_CONSTANTS.Global);
    const mapWidth = map_width ? map_width : "80vw";
    const mapHeight = map_height ? map_height : "600px";

    return (
    <Wrapper>
      {!header_title && <h2 className={header_title ? header_title : "grid-title"}>
        {mapType}
      </h2>}
      <Tabs className="tabs" style={{ width: mapWidth }}>
          <TabList style={{ display: 'flex', background: "#f9f9f9", marginBottom: "0.1rem"}}>
          {/* {mapType === MAP_CONSTANTS.FacilityCircleMap && (
          <Tab className="map-tab-list">
              <Dropdown>
                <Dropdown.Toggle as={CustomDropdownToggle} setType={() => setMapType(MAP_CONSTANTS.FacilityCircleMap)}>
                    Distribution By Facility
                </Dropdown.Toggle>
              </Dropdown>
          </Tab>)} */}

          {mapType !== MAP_CONSTANTS.FacilityCircleMap && !hide_header && ( <>
          <Tab className="map-tab-list">
              <Dropdown>
              <Dropdown.Toggle as={CustomDropdownToggle} setType={() => setMapType(MAP_CONSTANTS.Global)} mapType={MAP_CONSTANTS.SupplyRoutes}>
                  Supply Routes
              </Dropdown.Toggle>
              <Dropdown.Menu>
                  <Dropdown.Item eventKey="2" onClick={() => setMapType(MAP_CONSTANTS.Global)}>Global</Dropdown.Item>
                  <Dropdown.Item eventKey="1" onClick={() => setMapType(MAP_CONSTANTS.Local)}>Local</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
          </Tab>
          {/* <Tab className="map-tab-list">
              <Dropdown>
              <Dropdown.Toggle as={CustomDropdownToggle} setType={() => setMapType(MAP_CONSTANTS.ByRegionThematicMap)}>
                  Distribution By Region
              </Dropdown.Toggle>
              </Dropdown>
          </Tab>
          <Tab className="map-tab-list">
              <Dropdown>
              <Dropdown.Toggle as={CustomDropdownToggle} setType={() => setMapType(MAP_CONSTANTS.ByWoredaThematicMap)}>
                  Distribution By Woreda
              </Dropdown.Toggle>
              </Dropdown>
          </Tab> */}
          </>)}
          </TabList>
          {mapType === MAP_CONSTANTS.FacilityCircleMap && (
          <TabPanel>
              {mapType === MAP_CONSTANTS.FacilityCircleMap &&(<MapWithCircles param={param} modeCodeParam={modeCodeParam} />)}
          </TabPanel>)}
          {
          mapType !== MAP_CONSTANTS.FacilityCircleMap &&(<><TabPanel>
              {mapType === MAP_CONSTANTS.Global &&(<MapWithPaths mapType={mapType} param={param} mapWidth={mapWidth} mapHeight={mapHeight}/>)}
          </TabPanel>
          <TabPanel>
              {mapType === MAP_CONSTANTS.ByRegionThematicMap &&(<ThematicMap api={() => getThematicByRegion(param, modeCodeParam)} />)}         
          </TabPanel>
          <TabPanel>
              {mapType === MAP_CONSTANTS.ByWoredaThematicMap &&(<ThematicMap api={() => getThematicByWoreda(param, modeCodeParam)} />)}
          </TabPanel></>)
          }
      </Tabs>
    </Wrapper>
    )
}

const Wrapper = styled.div`
  border: 1px solid #e6e6e6; 
  .grid-title {
    padding: 10px 10px;
  }
`;

const center = {
  lat: 8.95,
  lng: 40.5,
};

const MapsWrapper = ({children, zoom}) => {
    const { isLoaded } = useMap();

    const containerStyle = {
        width: '80vw',
        height: '600px'
    };

    const onUnmount = React.useCallback(function callback(map) {
    }, [])

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onUnmount={onUnmount}
        >
            {children}
        </GoogleMap>
    )
}

const HeatMap = ({api, isFacility, radius}) => {
  const { isLoaded } = useMap();

  const [map, setMap] = useState(null);
  const [heatMapData, setHeatMapData] = useState([]);

  useEffect(() => {
    api().then((res) => {
      isFacility?
        setHeatMapData(res.data.features.map((feature) => ({location: new window.google.maps.LatLng(parseFloat(feature.geometry.coordinates[1]),parseFloat(feature.geometry.coordinates[0])), weight: feature.properties.IssuedAmount}))):
        setHeatMapData(res.data.Data.map((d) => ({location: new window.google.maps.LatLng(parseFloat(d.Latitude? d.Latitude: d.LATITUDE), parseFloat(d.Longitude? d.Longitude: d.LONGITUDE)), weight: d.AmountIssued})));
    });
  },[]);


  const containerStyle = {
    width: '80vw',
    height: '600px'
  };

  var customGradient = ['rgba(0, 255, 255, 0)',
          'rgba(0, 215, 255, 0.7)',
          'rgba(0, 151, 255, 0.7)',
          'rgba(0, 127, 255, 0.7)',
          'rgba(0, 63, 255, 0.8)',
          'rgba(0, 0, 255, 0.9)',
          'rgba(0, 0, 223, 0.9)',
          'rgba(0, 0, 191, 0.9)',
          'rgba(0, 0, 159, 0.9)',
          'rgba(0, 0, 127, 0.9)',
          'rgba(63, 0, 91, 0.9)',
          'rgba(127, 0, 63, 1)',
          'rgba(191, 0, 31, 1)',
          'rgba(255, 255, 255, 1)'
        ];
  
  const heatmapOptions = {
    radius: radius,
    gradient: customGradient,
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (<>
    {!heatMapData.length && (<div>Loading Layer<img src={Dots} alt="" /></div>)}
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      onUnmount={onUnmount}
    >
      {heatMapData.length && 
      (<HeatmapLayer
        data={heatMapData}
        options={heatmapOptions} />)}
    </GoogleMap>
    </>);
  };

const MapWithCircles = ({param, modeCodeParam}) => {  
  const [circleMapData, setCircleMapData] = useState([]);
  const [numberOfInstitutions, setNumberOfInstitutions] = useState(0);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const { isLoaded } = useMap();
  
  const [facilityGridDataLoading, setFacilityGridDataLoading] = useState(false);
  
  const [facilityGridData, setFacilityGridData] = useState([]);
  
  useEffect(() => {
    getMapFacilityByProgram(param, modeCodeParam).then((res) => {
      setCircleMapData(res.data.features);
    }).catch(() => setErrorOccured(true));
    getNumberOfFacilitiesServed(param, modeCodeParam).then((res) => {
      setNumberOfInstitutions(res.data.Data[0].NoOfInstitution);
    }).catch(() => setErrorOccured(true));

  },[selectedCircle]);

  const handleClose = () => {
    setTooltipOpen(false);
    setSelectedCircle(null);
  }

  const setCircleColor = (feature) => {
    const transactionType = feature.properties.InstitutionType;
    let color = 'indianred';
    if (transactionType == 'Hospital'){
      color = '#067BC2';
    } else if (transactionType == 'Health Center'){
      color = '#008861';
    } else if (transactionType == "Clinic"){
      color = '#bf55ec';
    } else if (transactionType == 'Pharmacy') {
      color = '#007C8A';
    } else if (transactionType == 'Drug Store') {
      color = '#708090';
    } else if (transactionType == "Woreda") {
      color = '#FE6847';
    }else if (transactionType == "Center") {
      color = '#8C564B';
    }else if (transactionType == "Zone") {
      color = '#B66D0D';
    }else if (transactionType == "Region") {
      color = '#540D6E';
    }
    return color;
  }

  const tooltipColumns = [
    {field: "ItemSN", header: "SN"},
    {field: "Item", header: "Item"},
    {field: "IssueDate", header: "Issue Date", type: "date"},
    {field: "QuantitySupplied", header: "Quantity"}
  ];

  const containerStyle = {
    width: '80vw',
    height: '600px'
  };

  const handleCircleClick = (index) => {
    setSelectedCircle(circleMapData[index]);
    setTooltipOpen(true);

    setFacilityGridData([]);
    setFacilityGridDataLoading(true);
    getFacilityMapGridData(param, modeCodeParam, circleMapData[index]?.properties?.InstitutionID).then((res) => {
      setFacilityGridData(res.data.Data);
      setFacilityGridDataLoading(false);
    }).catch(() => setErrorOccured(true));
  };

  const onUnmount = React.useCallback(function callback(map) {
  }, []);

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (<>
    {errorOccured? 
      <Box color="red">Error Occured!!!</Box>:
      <>
        {!circleMapData.length && (<div>Loading Layer<img src={Dots} alt="" /></div>)}
        {numberOfInstitutions > 0 && (<div style={{fontSize: "18px", padding: "5px  10px"}}>{numberOfInstitutions} Facilities Supplied</div>)}
        <div style={{display: "flex"}}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            onUnmount={onUnmount}
          >
            {circleMapData && (circleMapData.map((circle, index) => (
              <Circle
                key={index}
                center={{
                  lat: circle.geometry.coordinates[1],
                  lng: circle.geometry.coordinates[0],
                }}
                radius={5000}
                options={{
                  fillColor: setCircleColor(circle),
                  strokeColor: 'orange',
                  scale: Math.sqrt(circle.properties.IssuedAmount) / 40,
                  strokeWeight: 0.9,
                  fillOpacity: 0.5,
                }}
                onClick={() => handleCircleClick(index)}
              />
            )))}
            {(tooltipOpen && selectedCircle && selectedCircle?.properties?.InstitutionType == "Health Center") && (<InfoWindow
              position={{
                lat: parseFloat(selectedCircle.geometry[0]),
                lng: parseFloat(selectedCircle.geometry[0]),
              }}
              options={{
                pixelOffset: new window.google.maps.Size(0, 100)  // Adjust this value to position the tooltip
              }}
              style={{maxHeight: "400px"}}
              onCloseClick={() => handleClose()}
            >
            <div style={{ minWidth: '35rem', minHeight: '15rem', maxHeight: 300, display: 'flex', flexDirection: 'column'}}>
                
              <Box style={{display: "flex"}}>
                {selectedCircle?.properties?.Institution !== null && (<h6>{selectedCircle?.properties?.Institution}</h6>)}
              </Box>
              
              {facilityGridDataLoading ?
              (<CustomLoadingIndicator />):
              facilityGridData.length> 0?
              
              <Table striped bordered hover size="sm" style={{padding: "2rem 4rem"}}>
                <thead>
                  <tr>
                    {tooltipColumns.map((col) => (
                      <TableHeader style={{textAlign: (col.field === 'QuantityReceived' || col.field === 'AmountBirr')? "right": "left"}}>{col.header}</TableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody style={{borderTop: 0}}>
                  {facilityGridData.map((row) => (
                    <tr>
                      {tooltipColumns.map((col) => (
                      <TableCell 
                          style={{textAlign: (col.field === 'QuantityReceived' || col.field === 'AmountBirr')? "right": "left", 
                          width: col.field === 'ProductCN'? '120px': col.field === 'Manufacturer'? '170px': '130px'}}>
                            {(col.type === 'date') 
                              ? DefaultDateFormatter(row[col.field], 0)
                              : ((col.field === 'QuantitySupplied') ? Formatter(row[col.field], 0) : row[col.field]) }</TableCell>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>:
              <div>No Data Available</div>}
            </div>
            </InfoWindow>)}
          </GoogleMap>
          
        </div>
      </>}
    </>);
};

const MapWithPaths = ({mapType, param, mapWidth, mapHeight}) => {
  const [localPathCoordinates, setLocalPathCoordinates] = useState([]);
  const [globalPathCoordinatesPrivate, setGlobalPathCoordinatesPrivate] = useState([]);
  const [globalPathCoordinatesPublic, setGlobalPathCoordinatesPublic] = useState([]);
  const [localCircleMapData, setLocalCircleMapData] = useState([]);
  const [globalCircleMapData, setGlobalCircleMapData] = useState([]);
  const { isLoaded } = useMap();
  const [supplierNames, setSupplierNames] = useState([]);
  const [facilityNames, setFacilityNames] = useState([]);
  const [hoveredCircle, setHoveredCircle] = useState(null);
  const [globalErrorOccured, setGloabaErrorOccured] = useState(false);
  const [localErrorOccured, setLocalErrorOccured] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [breakdownLoading, setBreakdownLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [map, setMap] = useState();
  const [typeLoading, setTypeLoading] = useState(false); 

  useEffect(() => {
    if(mapType === MAP_CONSTANTS.Global || mapType === MAP_CONSTANTS.Overlaid){
      setTypeLoading(true)
      getGlobalSupplyRoutes({...param}).then((res) => {
        setGloabaErrorOccured(false)
        setErrorOccured(mapType === MAP_CONSTANTS.Overlaid? false || localErrorOccured: false)
        const tempGlobalPrivate = [];
        const tempGlobalPublic = [];
        const circleTemp = []
        res.data.Data.map((result) =>{ 
          if (supplierNames.indexOf(result.Supplier) == -1) {
            circleTemp.push(result);
            setSupplierNames([result.Supplier, ...supplierNames]);
            setSupplierNames([result.Institution, ...supplierNames]);
          }
          if(result.Type === 'Private'){
            tempGlobalPrivate.push({lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)});
            tempGlobalPrivate.push({lat: parseFloat(result.RLatitude), lng: parseFloat(result.RLongitude)});
          }
          if(result.Type === 'Public'){
            tempGlobalPublic.push({lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)});
            tempGlobalPublic.push({lat: parseFloat(result.RLatitude), lng: parseFloat(result.RLongitude)});
          }
        })
        setGlobalPathCoordinatesPrivate(tempGlobalPrivate);
        setGlobalPathCoordinatesPublic(tempGlobalPublic);
        setGlobalCircleMapData(mapType === MAP_CONSTANTS.Overlaid? [...localCircleMapData, ...circleTemp]: circleTemp);
        setTypeLoading(false)
      }).catch(() => {
        setGloabaErrorOccured(true)
        setErrorOccured(mapType === MAP_CONSTANTS.Overlaid? true || localErrorOccured: true)
        setTypeLoading(false)
      })
    }

    if(mapType === MAP_CONSTANTS.Local || mapType === MAP_CONSTANTS.Overlaid){
      getLocalSupplyRoutes(param).then((res) => {  
        setLocalErrorOccured(false)
        setErrorOccured(mapType === MAP_CONSTANTS.Overlaid? false || globalErrorOccured: false)
        const tempLocal = [];
        const circleTemp = []
        res.data.Data.map((result) =>{ 
          if (facilityNames.indexOf(result.Supplier) == -1) {
            circleTemp.push(result);
            setFacilityNames([result.Supplier, ...facilityNames]);
          }
          if (facilityNames.indexOf(result.Institution) == -1) {
            circleTemp.push(result);
            setFacilityNames([result.Institution, ...facilityNames]);
          }
          tempLocal.push({lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)});
          tempLocal.push({lat: parseFloat(result.RLatitude), lng: parseFloat(result.RLongitude)});
        })
        setLocalPathCoordinates(tempLocal);
        setLocalCircleMapData(mapType === MAP_CONSTANTS.Overlaid? [...globalCircleMapData, ...circleTemp]: circleTemp)
      }).catch(() => {
        setLocalErrorOccured(true)
        setErrorOccured(mapType === MAP_CONSTANTS.Overlaid? true || globalErrorOccured: true)
      });
    }
    
  },[mapType]);

  useEffect(() => {
    if(hoveredCircle) {
      setBreakdownLoading(true)
      getGlobalSupplyBreakDown({Alpha3code: hoveredCircle.SupplierSH, ...param}).then((res) => {
        setBreakdownLoading(false)
        setCountryData(res.data.Data)
        map.setCenter({lat: parseFloat(hoveredCircle.SLatitude),lng: parseFloat(hoveredCircle.SLongitude),})
      })
    }
  }, [hoveredCircle])

  useEffect(() => {
    if(map){ 
      map.setCenter(center)
    }
  }, [mapType]);

  const containerStyle = {
    width: mapWidth,
    height: mapHeight
  };

  useEffect(() => {
    if(!open) {
      setOpen(false);
      setHoveredCircle(null);
      setCountryData([]);
    }
  }, [open])

  const handleClick = (circle) => {
    setOpen(!open)
    setHoveredCircle(circle)
    setCountryCode(circle.SupplierSH)
    setCountryName(circle.Supplier)
  };

  const handleClose = () => {
    setOpen(false);
    setHoveredCircle(null);
    setCountryData([]);
    setCountryCode(null)
    setCountryName(null)
  };

  const tooltipColumns = [
    {field: "Manufacturer", header: "Manufacturer"},
    {field: "ProductCN", header: "Item"},
    {field: "QuantityReceived", header: "Quantity"},
    {field: "AmountUSD", header: "Amount ($)"},
    {field: "Agent", header: "Agent"},
    {field: "IsIncoming", header: "Status "}
  ];

  const onUnmount = React.useCallback(function callback(map) {
  }, []);

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <>
    {errorOccured? 
      <Box color="red">Error Occured!!!</Box>:
      <>
        {(typeLoading || (mapType === MAP_CONSTANTS.Global && (!globalPathCoordinatesPrivate.length && !globalPathCoordinatesPublic.length)) || (mapType === MAP_CONSTANTS.Local && !localPathCoordinates.length) || (mapType === MAP_CONSTANTS.Overlaid && !globalPathCoordinatesPrivate.length && !globalPathCoordinatesPublic.length && !localPathCoordinates.length)) &&(<div>Loading Layer<img src={Dots} alt="" /></div>)}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={mapType === MAP_CONSTANTS.Local? 6: 2}
          onLoad={(map) => setMap(map)}
          onUnmount={onUnmount}
          >
              {((mapType === MAP_CONSTANTS.Global && (globalPathCoordinatesPrivate.length || globalPathCoordinatesPublic.length)) || (mapType === MAP_CONSTANTS.Local && localPathCoordinates.length) || (mapType === MAP_CONSTANTS.Overlaid && (globalPathCoordinatesPrivate.length || globalPathCoordinatesPublic.length || localPathCoordinates.length)))&& (<>
              {(mapType === MAP_CONSTANTS.Global ||  mapType === MAP_CONSTANTS.Overlaid) && 
                  (<>
                    <Polyline
                    path={globalPathCoordinatesPublic}
                    options={{
                        geodesic: true,
                        strokeColor: "orange",
                        strokeOpacity: 0.9,
                        strokeWeight: 3,
                    }}
                    />
                    <Polyline
                    path={globalPathCoordinatesPrivate}
                    options={{
                        geodesic: true,
                        strokeColor: "#4d648d",
                        strokeOpacity: 0.9,
                        strokeWeight: 3,
                    }}
                    />
                  </>)}
                  {(mapType === MAP_CONSTANTS.Local ||  mapType === MAP_CONSTANTS.Overlaid) && 
                  (<Polyline
                  path={localPathCoordinates}
                  options={{
                      geodesic: true,
                      strokeColor: "indianred",
                      strokeOpacity: 0.9,
                      strokeWeight: 1,
                  }}
                  />)}
                 {(mapType === MAP_CONSTANTS.Global || mapType === MAP_CONSTANTS.Overlaid)?
                    globalCircleMapData.map((result, index) =>  (
                      <Circle
                        key={index}
                        center={{lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)}}
                        radius={Math.sqrt(Math.sqrt(result.Amount)) * 5000}
                        onClick={() => handleClick(result)}
                        options={{
                          fillOpacity: 0.35,
                          strokeColor: result.Type ==="Public"? "orange": "#4d648d",
                          strokeOpacity: 0.5,
                          fillColor: result.Type ==="Public"? "orange": "#4d648d",
                          strokeWeight: 2,
                          za: result.SupplierSH,
                        }}
                      />)):
                  (mapType === MAP_CONSTANTS.Local || mapType === MAP_CONSTANTS.Overlaid)?
                    localCircleMapData.map((result, index) =>  (
                      <Circle
                        key={index}
                        center={{lat: parseFloat(result.SLatitude), lng: parseFloat(result.SLongitude)}}
                        radius={Math.sqrt(result.Amount) * 10}
                        options={{
                          fillOpacity: 0.35,
                          strokeColor: facilityNames.includes(result.Institutioin)? "orange": "limegreen",
                          strokeOpacity: facilityNames.includes(result.Institutioin)? 0.8: 0.5,
                          fillColor: facilityNames.includes(result.Institutioin)? "indianred": "limegreen",
                          strokeWeight: facilityNames.includes(result.Institutioin)? 1: 2,
                          za: result.SupplierSH,
                        }}
                      />)): 
                  null
                }
                {(open && hoveredCircle) && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(hoveredCircle.SLatitude),
                      lng: parseFloat(hoveredCircle.SLongitude),
                    }}
                    options={{
                      pixelOffset: new window.google.maps.Size(0, 100)  // Adjust this value to position the tooltip
                    }}
                    style={{  minWidth: '40rem', maxHeight: "400px"}}
                    onCloseClick={() => handleClose()}
                  >
                    <div style={{ minWidth: '36rem', minHeight: '15rem', maxHeight: 250, display: 'flex', flexDirection: 'column'}}>
                      <Box style={{display: "flex"}}>
                        {countryName !== null && (<h6>{countryName.toUpperCase()}</h6>)}{countryCode !== null && <Flag style={{ width: 45, height: 20, marginBottom: 10, marginLeft: 5 }} code={countryCode} fallback={ <span>Unknown</span> } />}
                      </Box>
                      
                      {breakdownLoading?
                      (<CustomLoadingIndicator />):
                      countryData.length> 0?
                      <Table striped bordered hover size="sm" style={{padding: "2rem 4rem"}}>
                        <thead>
                          <tr>
                            {tooltipColumns.map((col) => (
                              <TableHeader style={{textAlign: (col.field === 'QuantityReceived' || col.field === 'AmountBirr')? "right": "left"}}>{col.header}</TableHeader>
                            ))}
                          </tr>
                        </thead>
                        <tbody style={{borderTop: 0}}>
                          {countryData.map((row) => (
                            <tr>
                              {tooltipColumns.map((col) => (
                              <TableCell 
                                  style={{ background: (col.field == "IsIncoming" && row[col.field] == true) ? '#6eb0b1' : (col.field == "IsIncoming" ? '#6ed0a1' : ''),
                                    textAlign: (col.field === 'QuantityReceived' || col.field === 'AmountUSD')? "right": "left", 
                                    width: col.field === 'ProductCN'? '120px': col.field === 'Manufacturer'? '170px': '130px'}}
                                    title={(col.field === 'QuantityReceived' || col.field === 'AmountUSD')? FormatDecimal(row[col.field], 0): row[col.field] }>
                                      {(col.field === 'QuantityReceived' || col.field === 'AmountUSD') ? Formatter(row[col.field], 1)
                                          : (col.field === "IsIncoming" ? (row[col.field] ? "Incoming" : "Recieved") : row[col.field]) }
                              </TableCell>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>:
                      <div>No Data Available</div>}
                    </div>
                  </InfoWindow>
                )}
          </>)}
        </GoogleMap>
      </>}
    </>
  );
};

const TableHeader = styled.th`
  font-size: 13px;
  padding: 10px 15px 10px !important;
`

const TableCell = styled.td`
  font-size: 13px;
  padding: 10px 15px !important;
`


const ThematicMap = ({api}) => {
  const [thematicData, setThematicData] = useState({});
  const { isLoaded } = useMap();
  const [hoveredPolygon, setHoveredPolygon] = useState(null);
  const [overlayPosition, setOverlayPosition] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);

  const defaultOptions = {
    clickable: true,
    strokeWeight: 1,
    fillOpacity: 0.5,
  };

  useEffect(() => {
    api().then((res) => {
      setThematicData(res.data);
    }).catch(() => setErrorOccured(true));;
  },[]);

  const containerStyle = {
    width: '80vw',
    height: '600px'
  };

  const setRegionColor = (feature) => {
    const regionName = feature.properties.REGIONNAME;
    let color = 'orange';
    switch (regionName) {
      case 'Addis Ababa':
        color = '#336699';
        break;
      case 'Affar':
      case 'Afar':
        color = '#6C97F5';
        break;
      case 'Amhara':
        color = '#E6C40F';
        break;
      case 'Benishangul-Gumuz':
        color = '#A02802';
        break;
      case 'Dire Dawa':
        color = '#A00260';
        break;
      case 'Gambela':
      case 'Gambella':
        color = '#0264A0';
        break;
      case 'Harari':
        color = '#86A002';
        break;
      case 'Oromia':
        color = '#2EA538';
        break;
      case 'SNNPR':
      case 'SNNP':
        color = '#9C9EE8';
        break;
      case 'Tigray':
        color = '#9CD9E8';
        break;
      case 'Somali':
        color = '#E8AB9C';
        break;
    }
    return color;
  }

  function formatNumber(number, dec) {
    if (number == undefined) return 0;

    number = parseFloat(number);
    number = number.toFixed(dec) + '';
    let x = number.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  const handleMouseOver = (event, index) => {
    setHoveredPolygon(index);
    setOverlayPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  const handleMouseOut = () => {
    setHoveredPolygon(null);
  };

  const onUnmount = React.useCallback(function callback(map) {
  }, []);

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (<>
    {errorOccured? 
      <Box color="red">Error Occured!!!</Box>:
      <>
        {!thematicData.features && (<div>Loading Layer<img src={Dots} alt="" /></div>)}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={6}
          onUnmount={onUnmount}
        >
          {thematicData.features && (thematicData.features.map((area, index) => {
            if (area.geometry.type === 'MultiPolygon') {
                return area.geometry.coordinates.map((polygonCoords, idx) => (
                  <React.Fragment key={`${index}_${idx}`}>
                    <Polygon
                      key={`${index}_${idx}`}
                      paths={polygonCoords.map((co) =>
                        co.map((coords) => ({ lat: coords[1], lng: coords[0] }))
                      )}
                      options={hoveredPolygon === index?{
                        ...defaultOptions,
                        fillColor: setRegionColor(area),
                        strokeColor: setRegionColor(area),
                        title: area.properties.REGIONNAME,
                        strokeWeight: 4,
                      }:{
                        ...defaultOptions,
                        fillColor: setRegionColor(area),
                        strokeColor: setRegionColor(area),
                        title: area.properties.REGIONNAME,
                      }}
                      onMouseOver={(e) => handleMouseOver(e, index)}
                      onMouseOut={handleMouseOut}
                    />
                    {hoveredPolygon === index && (
                      <OverlayView
                        position={overlayPosition}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div style={{
                          backgroundColor: 'white',
                          padding: '10px 15px',
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          borderRadius: 5,
                        }}>
                          {area.properties.REGIONNAME} - Population: {area.properties.Population}<br/>
                          Amount Issued: {formatNumber(area.properties.AmountIssued, 0)}{formatNumber(area.properties.AmountIssuedInPerecent, 0)}%<br/>
                          Amount Issued Adjusted to Population %: {formatNumber(area.properties.AmountIssuedInPerecentAdjustedToPopulation, 0)}
                        </div>
                      </OverlayView>
                    )}
                  </React.Fragment>
                ));
              } else if (area.geometry.type === 'Polygon') {
                // If the feature is a single Polygon
                return (
                  <React.Fragment key={index}>
                    <Polygon
                      paths={area.geometry.coordinates[0].map((co) => ({lat: co[1], lng: co[0]}))}
                      options={hoveredPolygon === index?{
                        ...defaultOptions,
                        fillColor: setRegionColor(area),
                        strokeColor: setRegionColor(area),
                        title: area.properties.REGIONNAME,
                        strokeWeight: 4,
                      }:{
                        ...defaultOptions,
                        fillColor: setRegionColor(area),
                        strokeColor: setRegionColor(area),
                        title: area.properties.REGIONNAME,
                      }}
                      onMouseOver={(e) => handleMouseOver(e, index)}
                      onMouseOut={handleMouseOut}
                    />
                    {hoveredPolygon === index && (
                      <OverlayView
                        position={overlayPosition}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div style={{
                          backgroundColor: 'white',
                          padding: '10px 15px',
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          borderRadius: 5,
                        }}>
                          {area.properties.REGIONNAME} - Population: {area.properties.Population}<br/>
                          Amount Issued: {formatNumber(area.properties.AmountIssued, 0)}{formatNumber(area.properties.AmountIssuedInPerecent, 0)}%<br/>
                          Amount Issued Adjusted to Population %: {formatNumber(area.properties.AmountIssuedInPerecentAdjustedToPopulation, 0)}
                        </div>
                      </OverlayView>
                    )}
                  </React.Fragment>);
              } else {
                // Invalid geometry type (optional)
                return null;
              }
            }
          ))}
        </GoogleMap>
      </>}
    </>)   
};

const SelectionComponent = ({ options, setType }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const typeMap = [ "Public", "Aggregate", "Private"];

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % options.length);
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
  };

  useEffect(() => {
    setType(typeMap[selectedIndex]);
  }, [selectedIndex])

  return (
    <Box style={{border: '1px solid rgb(196, 184, 166)', borderRadius: 3, backgroundColor: 'white'}}>
      <IconButton onClick={handlePrev} disabled={selectedIndex === 0}>
        <NavigateBeforeIcon />
      </IconButton>

      <span>{options[selectedIndex]}</span>

      <IconButton onClick={handleNext} disabled={selectedIndex === options.length - 1}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};



const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const FacilityGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;