import { Box, Dialog, Modal, } from "@mui/material";
import styled from "styled-components";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { SHI_PRODUCT_API } from "../../shared/Shared Utils/AppData";
import { SHI_MODAL_DEFAULT_PARAM } from "../../shared/Shared Utils/DataTableConstants";
import moment from "moment";
import { useEffect } from "react";

const PRODUCT_COLUMN = [
    { field: "RowNumber", headerName: " ", width: 50 },
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      flex: 1,
    },
    {
      field: "ExpiryDate",
      renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
      flex: 1,
    },
    {
      field: "BB",
      renderHeader: (params) => <strong>{"Beginning Balance"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
    {
      field: "EB",
      renderHeader: (params) => <strong>{"Ending Balance"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
    {
      field: "Received",
      renderHeader: (params) => <strong>{"Received"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
    {
      field: "Issued",
      renderHeader: (params) => <strong>{"Issued"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
    {
      field: "SOH",
      renderHeader: (params) => <strong>{"SOH"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
  ];

export const ShiProductModal = ({open, handleClose, productCode, programCode, modeCode}) => {

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = dateFilterFlag.startDate || moment().subtract(1, 'months').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    
    filterParams["ProgramCode"] = programCode;
    return filterParams;
  }

  return (
    
    <Modal
            open={open}
            onClose={() => handleClose(false)}
        >
        <Box 
          className="modal-class"  
          style={{
            padding: "1rem",
            paddingTop: "3rem",
            position: "relative",
            width: "80%",
            height: "38rem",
            background: "white",
            }}
          >
            <CustomDataGrid
              component_config={{
                title: "SOH by Expiry Date",
                data_source_api: SHI_PRODUCT_API,
                data_property: "Data",
                columns: PRODUCT_COLUMN,
                isSOH: false,
                width: "72vw",
                pageSize: 5,
                use_default_filter_params: false,
                defaultFilterParams: loadFilterToDefaultParams(
                  {...SHI_MODAL_DEFAULT_PARAM, ModeCode: modeCode, UnitSN: productCode.UnitSN, ProductSN: productCode.ProductSN},
                  { hasDateRangeFilter: true }
                ),
              }}
            />
          </Box>
        </Modal>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;