import React, { } from 'react';
import { Tooltip, Treemap } from 'recharts';
import Box from "@mui/material/Box";
import TreeMapItem from './TreeMapItem';


export default function TreeMap({data}) {
  const backgroundColor = [
    "#15616d",
    "#e06c00",
    "#77af9c",
    "#c94057",
    "#acd2ed",
    "#78290f",
    "#73605b",
    "#4d648d",
    "#e4ea8c",
    "#ffddad",
    "#001524",
    "#4d648d",
    "#265c00",
  ];
    return (
      <Box width="100%">
        {data.length > 0 && (
            <Treemap isAnimationActive={false} width={800} height={400} data={data} dataKey="size" aspectRatio={4 / 3} stroke="#fff" fill="#00B5E2"
              content={<TreeMapItem bgColors={backgroundColor} />}
             >
              <Tooltip />
            </Treemap>
        )}
      </Box>
    )
  };