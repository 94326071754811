import { Box, Dialog, Modal, } from "@mui/material";
import styled from "styled-components";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { STOCK_STATUS_BY_EXPIRY_API } from "../../shared/Shared Utils/AppData";

const STOCK_EXPIRY_COLUMN = [
    { field: "RowNumber", headerName: " ", width: 50 },
    {
      field: "Item",
      renderHeader: (params) => <strong>{"Item"}</strong>,
      flex: 2,
    },
    {
      field: "Unit",
      renderHeader: (params) => <strong>{"Unit"}</strong>,
      flex: 1,
    },
    {
      field: "Institution",
      renderHeader: (params) => <strong>{"Expired Date"}</strong>,
      flex: 1,
    },
    {
      field: "QuantityIssued",
      renderHeader: (params) => <strong>{"Quantity"}</strong>,
      flex: 1,
      type: "decimalZero",
    },
  ];

export const StockExpiryModal = ({open, handleClose, productSN}) => {

  return (
    
    <Modal
            open={open}
            onClose={() => handleClose(false)}
        >
        <Box 
          className="modal-class"  
          style={{
            padding: "1rem",
            paddingTop: "3rem",
            position: "relative",
            width: "80%",
            height: "38rem",
            background: "white",
            }}
          >
            <CustomDataGrid
              component_config={{
                title: "Distribution",
                data_source_api: STOCK_STATUS_BY_EXPIRY_API,
                data_property: "Data",
                columns: STOCK_EXPIRY_COLUMN,
                isSOH: false,
                width: "72vw",
                pageSize: 5,
                use_default_filter_params: false,
                defaultFilterParams: {ProductSN: productSN },
              }}
            />
          </Box>
        </Modal>
  )
}
  
const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 11px;
  }
  .header {
    background-color: #12b3b7;
    color: white;
  }
`;