/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import axios from "axios";

export const http = {
  setBaseUrl: () => {
    axios.defaults.baseURL =
      process.env.REACT_APP_API_ENDPOINT ||
      "https://webapi.dashboard.hcmisonline.org/api";
  },
  setHeader: (type, value) => {
    axios.defaults.headers.common[type] = value;
  },
};
//   intercept: {
//     autorize: (history) => {
//       axios.interceptors.response.use(
//         function (response) {
//           return response;
//         },
//         function (error) {
//           if (error?.response?.status === 401) {
//             localStorage.removeItem("userInfo");
//             history.push("/welcome");
//           }
//         }
//       );
//     },
//   },
//   get: async (url) => {
//     try {
//       const response = await axios.get(url);
//       return response?.data;
//     } catch (error) {
//       // handle error
//       return error;
//     }
//   },
//   post: async (url, data) => {
//     try {
//       const response = await axios.post(url, data);
//       return response?.data;
//     } catch (error) {
//       return error?.response?.data;
//     }
//   },
//   put: async (url, data) => {
//     try {
//       const response = await axios.put(url, data);
//       return response?.data;
//     } catch (error) {
//       return false;
//     }
//   },
//   delete: async (url) => {
//     try {
//       const response = await axios.delete(url);
//       return response?.data;
//     } catch (error) {
//       // handle error
//       return false;
//     }
//   },
// };
