import React, {
  useState
} from "react";


function SimpleCard({ widget_config }) {
  return (
    <>
      <div
        className="grow-1"
        style={widget_config.value == null ? { opacity: "0.8" } : {}}
      >
        <div
          className="main-container custom-card-container flex-column"
          style={{ justifyContent: "space-between" }}
        >
          <div className="widget-header-container flex-column">
            <span>
              {" "}
              <span style={{ fontSize: "1.8rem", verticalAlign: "top" }}>
                {widget_config.value}
              </span>
            </span>
            
            <h6
              class="widget-header grow-1"
              style={{ color: widget_config.accent_color }}
            >
              {widget_config.title + " "}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default SimpleCard;
