import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

export default function BarChart({ data, label }) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const dataValue = [];
  const dataLabel = [];
  for (let i = 0; i < data.length; i++) {
    dataValue.push(parseInt(data[i].IssuedAmountInETB));
    dataLabel.push(data[i].Region);
  }

  return (
    <div
      style={{
        padding: "2rem",
        position: "relative",
        width: "45vw",
        height: "26.7rem",
        background: "#F7F9FA",
        border: "1px solid #77af9c",
        borderRadius: "6px",
      }}
    >
      <Chart
        type="bar"
        data={{
          labels: [...dataLabel],
          datasets: [
            {
              label: label,
              data: [...dataValue],
              backgroundColor: [
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
              ],
              borderColor: [
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
                "#15616d",
              ],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          responsive: true,
          indexAxis: "y",
          scales: {
            x: {
              ticks: {
                autoSkip: false,
              },
            },
            y: {
              ticks: {
                autoSkip: false,
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
          maintainAspectRation: false,
        }}
      />
    </div>
  );
}
