import React, { useEffect, useRef, useState } from "react";
import "chart.js/auto";
import { FaExpandAlt, FaPrint } from "react-icons/fa";
import { Modal, Typography } from "@mui/material";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Box } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';
import { GridInfo } from "../Shared Utils/InfoGrid";
import { useReactToPrint } from "react-to-print";
import Dots from "../../../assets/GIFs/dotss.gif";
import CustomChartSelect from "../Shared Utils/CustomChartSelect";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";

export default function PieChartWrapper({children, handleChange, parameter, isLoading, setParameter, title, selectFilterConfig }) {
  const [selectedDistribution, setSelectedDistribution] = useState('program');
  const [selectedYear, setSelectedYear] = useState(2016);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [distributionTypes, setDistributionTypes] = useState(["program", "ownershipType", "commodity", "mode"]);
  const widgetIds = ["wgLocationSummaryPivotedByProgram", "wgLocationPivotedByOwnershipType", "wgLocationBycommoditytype", "wgLocationBymode"];
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleTabChange = (index) => {
    setSelectedDistribution(distributionTypes[index])
    let tempDistributionTypes = ["program", "ownershipType", "commodity", "mode"];
    // tempDistributionTypes[index] = `distributionBy${tempDistributionTypes[index].charAt(0).toUpperCase() + tempDistributionTypes[index].slice(1)}`;
    setDistributionTypes(tempDistributionTypes);
    handleChange(distributionTypes[index]);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handle_change = (param) => {
    setParameter({...parameter, [param.paramName]: param.paramValue})
  };

  const splitCamelCaseToString = (s) => {
    let words = s.split(/(?=[A-Z])/);

    for(let i=1; i < words.length; i = 1+i ) {
        if(words[i-1].length === 1 && words[i].length === 1){
            words[i-1] = words[i-1]+words[i];
            words.splice(i);
        }
    }
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  }

  return (
    <div className="container">
        <div className="header">
            <Modal
                open={open}
                onClose={handleClose}
            >
            <Box className="modal-class"  style={{
                                padding: "1rem",
                                position: "relative",
                                width: "80%",
                                height: "38rem",
                                background: "white",
                                }}>
                <Typography id="modal-modal-description">
                <h6 style={{ margin: "20px" }}>
                    {title} {selectedDistribution} -{" "}
                    <FaPrint onClick={handlePrint} size={"1.2em"} />
                </h6>
                <div></div>
                <Tabs style={{ paddingTop: 10 }}>
                    <TabList>
                        <Tab>Charts</Tab>
                        <Tab>Tabular</Tab>
                    </TabList>
                    <TabPanel>
                        <div ref={componentRef} style={{display: 'flex',justifyContent:'center',}}>
                            {children[2]? children[2]: children[0]}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div ref={componentRef}>
                            {children[1]}
                        </div>
                    </TabPanel>
                </Tabs>
                
                </Typography>
            </Box>
            </Modal>
            <div>
                <FaExpandAlt className="expand-icon" style={{margin: '0 10px'}} onClick={handleOpen} />
                <InfoIcon  onClick={() => setDialogOpen(true)} style={{ color: "cadetblue" }} />
                {isLoading && (
                <span>
                    <img src={Dots} alt="" />
                </span>
                )}
            </div>
            <Tabs style={{ paddingTop: 10 }} 
                value={selectedDistribution}
                onSelect={handleTabChange}>
                <TabList>
                    {distributionTypes.map((el) => (<Tab><div className="pie-title">{splitCamelCaseToString(el)}</div></Tab>))}
                </TabList>
                {distributionTypes.map((el) => (
                <TabPanel>
                    <div className="flex-row">
                        <div>
                            <h6 style={{marginTop:'1rem'}}>Year</h6>
                            {selectFilterConfig?.map((config) => 
                                (
                                <CustomChartSelect
                                    handle_change={handle_change}
                                    component_config={{...config, width: "8rem"}}
                                />
                                )
                            )}
                        </div>
                        <div>
                            {children[0]}
                        </div>
                    </div>
                </TabPanel>))}
            </Tabs>
        </div>
        <GridInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} widgetId={widgetIds[distributionTypes.indexOf(selectedDistribution)]} />
    </div>
  );
}
